import { CampaignIcon } from "@/assets/icons/svgs";
import DoubleCheckmarkIcon from "@/assets/icons/svgs/double-checkmark.svg?react";
import LetterDotIcon from "@/assets/icons/svgs/letter-dot.svg?react";
import LetterCheckIcon from "@/assets/icons/svgs/letter-check.svg?react";
import { formatTimestamp } from "@/utils/helpers";
import { useCallback, useEffect, useState, useRef, memo } from "react";
import EmailModal from "../EmailModal/EmailModal";
import { Button } from "../ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { EmailParticipant, TThreadWithMessagesAndAccounts } from "@za-zu/types";
import { useInboxStore, useGlobalFilterStore, useCampaignStore, useEmailAccountsStore } from "@/store";
import { usePost } from "@/hooks/useQuery";
import { Loader2 } from "lucide-react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { cn } from "@/lib/utils";
import { NavigationDirection } from "../EmailModal/types";
import { z } from "zod";
import { CampaignProps } from "@/types/campaign";
import { TableCellToolbar } from "@/components/ui/table-toolbar";
import { InboxZeroWithCampaigns, InboxZeroNoCampaigns } from "./EmptyStates";
import { Skeleton } from "@/components/ui/skeleton";
import { ThreadCategoryBadge } from "./ThreadCategoryBadge";
import { NameCampaignDialog } from "../CampaignsList/NameCampaignDialog";
import { useLocation } from "wouter";
import { toast } from "sonner";

export type UnifiedInboxProps = {
	threads: TThreadWithMessagesAndAccounts[];
	isLoading?: boolean;
	onThreadModalVisibilityChange: (isVisible: boolean) => void;
};

type ThreadRowState = {
	isHovered: boolean;
	isSelected: boolean;
};

// Add animation state to track which threads are being removed
type ThreadAnimationState = {
	removing: boolean;
	direction: "archive" | "unarchive";
};

// Add an interface to track seen status changes
type SeenStatusChange = {
	changed: boolean;
	previousValue: boolean;
	currentValue: boolean;
};

const ThreadRow = memo(
	({
		thread,
		rowState,
		onMouseEnter,
		onMouseLeave,
		onClick,
		renderActions,
		animationState,
		seenStatusChange,
	}: {
		thread: TThreadWithMessagesAndAccounts & { seen?: boolean };
		rowState: ThreadRowState;
		onMouseEnter: () => void;
		onMouseLeave: () => void;
		onClick: () => void;
		renderActions: () => React.ReactNode;
		animationState?: ThreadAnimationState;
		seenStatusChange?: SeenStatusChange;
	}) => {
		const { accountsById } = useEmailAccountsStore();
		const { campaignsById } = useCampaignStore();
		const firstAccountRef = thread.accounts[0];
		if (!firstAccountRef) {
			throw new Error(`Unable to locate account ref for thread (EID: ${thread.e_id})`);
		}
		const firstAccount = accountsById[firstAccountRef.email_account_id];
		if (!firstAccount) {
			throw new Error(`Unable to locate account for thread (EID: ${thread.e_id})`);
		}
		const lastMessage = thread.messages[thread.messages.length - 1];

		if (!lastMessage) {
			throw new Error(`Unable to locate messages for thread (EID: ${thread.e_id})`);
		}
		const otherEmail: EmailParticipant =
			lastMessage.to.address === firstAccount.email ? lastMessage.from : lastMessage.to;

		const campaign: CampaignProps | undefined =
			firstAccountRef.campaign_id !== null ? campaignsById[firstAccountRef.campaign_id] : undefined;
		return (
			<TableRow
				noBorder
				className={cn(
					"h-12 border-b border-transparent transition-all duration-0 ease-out",
					animationState?.removing &&
						"animate-thread-exit motion-reduce:!transform-none motion-reduce:opacity-0 motion-reduce:transition-opacity motion-reduce:duration-150 motion-reduce:ease-out",
					rowState.isSelected &&
						"border-b border-[color(display-p3_0.098_0.4863_0.9569/0.20)] bg-[color(display-p3_0.9373_0.9647_0.9961)]",
					rowState.isHovered &&
						!rowState.isSelected &&
						"border-b border-[color(display-p3_0.098_0.4863_0.9569/0.20)] bg-[color(display-p3_0.9373_0.9647_0.9961)]",
				)}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				onClick={onClick}
				style={
					animationState?.removing
						? ({
								"--exit-direction": animationState.direction === "archive" ? "10px" : "-10px",
							} as React.CSSProperties)
						: undefined
				}>
				<TableCell className="w-14 py-0" noSidePadding noBorder>
					<div className="relative flex items-center">
						{/* Left indicator bar when hovered/selected */}
						{(rowState.isHovered || rowState.isSelected) && (
							<div className="bg-label-label-link absolute left-0 h-12 w-[3px]" />
						)}
						<div className="relative ml-[32px] h-2 w-2">
							{/* Unseen indicator (blue dot) with animation only on status change */}
							<div
								className={cn(
									"bg-brand-primary absolute h-2 w-2 rounded-full",
									"motion-reduce:!transform-none motion-reduce:transition-opacity motion-reduce:duration-75",
									seenStatusChange?.changed
										? !thread.accounts.every(acc => acc.seen)
											? "animate-dot-appear"
											: "animate-dot-disappear"
										: !thread.accounts.every(acc => acc.seen)
											? "opacity-100"
											: "opacity-0",
								)}
							/>
						</div>
					</div>
				</TableCell>
				<TableCell className="w-50 whitespace-nowrap py-0 font-medium" noSidePadding noBorder>
					<div className="truncate">{otherEmail.name}</div>
				</TableCell>
				<TableCell className="overflow-hidden py-0" noSidePadding noBorder>
					<div className="flex w-full min-w-0 flex-1 items-center gap-8">
						<div className="flex items-center gap-3">
							{thread.lead_thread_category !== null && (
								<ThreadCategoryBadge
									category={thread.lead_thread_category}
									isRowActive={rowState.isHovered || rowState.isSelected}
								/>
							)}
							<div className="text-label-label-base text-body-base leading-body-base font-geist flex shrink-0 items-center gap-1 truncate font-medium">
								<CampaignIcon className="text-label-label-base h-4 w-4 shrink-0" />
								{campaign?.title}
							</div>
						</div>
						<div className="text-preview leading-preview text-label-label-muted min-w-0 flex-1 overflow-hidden truncate text-ellipsis whitespace-nowrap font-normal">
							{lastMessage.preview}
						</div>
					</div>
				</TableCell>
				<TableCell className="w-30 py-0 pr-4" noBorder>
					<div className="relative flex items-center justify-end">
						{/* Date/time display */}
						<div
							className={cn(
								"text-label-label-muted w-25 pr-4 text-right",
								rowState.isHovered || rowState.isSelected ? "opacity-0" : "opacity-100",
							)}>
							{thread.last_sent_at ? formatTimestamp(thread.last_sent_at) : ""}
						</div>

						{/* The wrapper div creates the positioning context for TableToolbar */}
						<div className="absolute inset-y-0 right-2">{renderActions()}</div>
					</div>
				</TableCell>
			</TableRow>
		);
	},
);

ThreadRow.displayName = "ThreadRow";

/**
 * Skeleton loader that mimics the structure of a ThreadRow with staggered animation
 */
const ThreadRowSkeleton: React.FC<{ index: number }> = ({ index }) => {
	// Calculate stagger delay for each skeleton row
	const staggerDelay = index * 0.05; // 50ms stagger between each row

	return (
		<TableRow className="border-b">
			<TableCell className="w-16 py-0" noSidePadding noBorder>
				<div className="relative flex items-center">
					<div className="relative ml-[32px] h-2 w-2">
						<Skeleton className="h-2 w-2 rounded-full" delay={staggerDelay} duration={0.8} />
					</div>
				</div>
			</TableCell>
			<TableCell className="w-50 whitespace-nowrap py-0 font-medium" noSidePadding noBorder>
				<Skeleton className="h-5 w-36" delay={staggerDelay} duration={0.8} />
			</TableCell>
			<TableCell className="overflow-hidden py-0" noSidePadding noBorder>
				<div className="flex w-full min-w-0 flex-1 items-center gap-8">
					<Skeleton className="h-5 w-24 shrink-0" delay={staggerDelay} duration={0.8} />
					<Skeleton className="h-4 w-full max-w-[300px]" delay={staggerDelay} duration={0.8} />
				</div>
			</TableCell>
			<TableCell className="w-30 py-0 pr-4" noBorder>
				<div className="flex justify-end">
					<Skeleton className="h-4 w-16" delay={staggerDelay} duration={0.8} />
				</div>
			</TableCell>
		</TableRow>
	);
};

export const UnifiedInbox: React.FC<UnifiedInboxProps> = ({ threads, isLoading, onThreadModalVisibilityChange }) => {
	const [selectedThread, setSelectedThread] = useState<TThreadWithMessagesAndAccounts | null>(null);
	const { archiveThread, unarchiveThread, updateThreadSeenStatus } = useInboxStore();
	const [archivingThreadId, setArchivingThreadId] = useState<string | null>(null);
	const [seenStatusThreadId, setSeenStatusThreadId] = useState<string | null>(null);
	const post = usePost();
	const { searchText } = useGlobalFilterStore();
	const { campaignsById } = useCampaignStore();
	const threadIdsRef = useRef<string[]>([]);
	const [threadStates, setThreadStates] = useState<Record<string, ThreadRowState>>({});
	const [isThreadModalOpen, setIsThreadModalOpen] = useState(false);
	const [, navigate] = useLocation();
	const [showNameDialog, setShowNameDialog] = useState(false);
	const { createCampaign } = useCampaignStore();

	// Add state to track thread animation states
	const [threadAnimationStates, setThreadAnimationStates] = useState<Record<string, ThreadAnimationState>>({});

	// Track seen status changes for animation triggers
	const [seenStatusChanges, setSeenStatusChanges] = useState<Record<string, SeenStatusChange>>({});

	// Maintain a local cache of thread seen statuses for UI consistency
	const [threadSeenStatus, setThreadSeenStatus] = useState<Record<string, boolean>>({});

	// Reset seen status change animations after they complete
	useEffect(() => {
		const animationTimeout = setTimeout(() => {
			// Clear any seen status changes after animation completes
			setSeenStatusChanges(prev => {
				const newChanges = { ...prev };
				Object.keys(newChanges).forEach(id => {
					if (newChanges[id]?.changed) {
						newChanges[id] = {
							...newChanges[id],
							changed: false,
						};
					}
				});
				return newChanges;
			});
		}, 300); // Animation duration plus a small buffer

		return () => clearTimeout(animationTimeout);
	}, [seenStatusChanges]);

	useEffect(() => {
		// Initialize thread seen status cache when threads change
		const newStatus: Record<string, boolean> = {};
		threads.forEach(thread => {
			newStatus[thread.e_id] = thread.accounts.every(acc => acc.seen);
		});
		setThreadSeenStatus(prev => ({ ...prev, ...newStatus }));
	}, [threads]);

	const updateThreadRowState = useCallback((threadId: string, updates: Partial<ThreadRowState>) => {
		setThreadStates(current => ({
			...current,
			[threadId]: { ...(current[threadId] || { isHovered: false, isSelected: false }), ...updates },
		}));
	}, []);

	const getThreadRowState = useCallback(
		(threadId: string): ThreadRowState => threadStates[threadId] || { isHovered: false, isSelected: false },
		[threadStates],
	);

	// Archive/unarchive handling with optimistic updates
	const toggleThreadArchiveStatus = useCallback(
		async (thread: TThreadWithMessagesAndAccounts) => {
			if (!thread) return;
			const isArchived = thread.accounts.every(acc => acc.status === "archived");
			const action = isArchived ? "unarchive" : "archive";

			try {
				setArchivingThreadId(thread.e_id);

				// Start the exit animation
				setThreadAnimationStates(prev => ({
					...prev,
					[thread.e_id]: {
						removing: true,
						direction: isArchived ? "unarchive" : "archive",
					},
				}));

				// Wait for animation to complete before updating state
				// 200ms matches our animation duration
				setTimeout(async () => {
					try {
						// Update local state after animation
						if (isArchived) {
							unarchiveThread(thread);
						} else {
							archiveThread(thread);
						}

						await post(`/emails/threads/${thread.e_id}/update-archive-status`, z.object({ success: z.boolean() }), {
							status: action,
						});

						if (isThreadModalOpen) {
							setSelectedThread(null);
							setIsThreadModalOpen(false);
							onThreadModalVisibilityChange(false);
						}
					} catch (error) {
						console.error("Error toggling archive status:", error);
						// Revert the local state changes on error
						if (isArchived) {
							archiveThread(thread);
						} else {
							unarchiveThread(thread);
						}
					} finally {
						setArchivingThreadId(null);
						// Clear animation state
						setThreadAnimationStates(prev => {
							const newState = { ...prev };
							delete newState[thread.e_id];
							return newState;
						});
					}
				}, 200); // Match animation duration
			} catch (error) {
				console.error("Error setting up animation:", error);
				setArchivingThreadId(null);
				// Clear animation state
				setThreadAnimationStates(prev => {
					const newState = { ...prev };
					delete newState[thread.e_id];
					return newState;
				});
			}
		},
		[archiveThread, unarchiveThread, onThreadModalVisibilityChange, isThreadModalOpen, post, setThreadAnimationStates],
	);

	// Improved seen status toggle with local state update
	const toggleThreadSeenStatus = useCallback(
		async (thread: TThreadWithMessagesAndAccounts) => {
			if (!thread) return;

			// Use local cache to determine current status (could be different from what accounts show)
			const currentSeen = threadSeenStatus[thread.e_id] ?? thread.accounts.every(acc => acc.seen);
			const newSeen = !currentSeen;

			setSeenStatusThreadId(thread.e_id);

			// Update seen status change tracker for animation
			setSeenStatusChanges(prev => ({
				...prev,
				[thread.e_id]: {
					changed: true,
					previousValue: currentSeen,
					currentValue: newSeen,
				},
			}));

			// Update local cache immediately for responsive UI
			setThreadSeenStatus(prev => ({
				...prev,
				[thread.e_id]: newSeen,
			}));

			try {
				// Update store for global state
				updateThreadSeenStatus({ threadId: thread.e_id, seen: newSeen });

				// API call
				await post(`/emails/threads/${thread.e_id}/update-seen-status`, z.object({ success: z.boolean() }), {
					seen: newSeen,
				});
			} catch (error) {
				console.error("Error toggling seen status:", error);

				// Revert both local cache and store on error
				setThreadSeenStatus(prev => ({
					...prev,
					[thread.e_id]: currentSeen,
				}));
				updateThreadSeenStatus({ threadId: thread.e_id, seen: currentSeen });

				// Also revert the seen status change tracker
				setSeenStatusChanges(prev => ({
					...prev,
					[thread.e_id]: {
						changed: true,
						previousValue: newSeen,
						currentValue: currentSeen,
					},
				}));
			} finally {
				setSeenStatusThreadId(null);
			}
		},
		[updateThreadSeenStatus, post, threadSeenStatus],
	);

	const renderThreadActions = useCallback(
		(thread: TThreadWithMessagesAndAccounts) => {
			const isArchiving = archivingThreadId === thread.e_id;
			const isUpdatingSeenStatus = seenStatusThreadId === thread.e_id;
			const loading = isArchiving || isUpdatingSeenStatus;

			// Use the local cache for seen status
			const isSeen = threadSeenStatus[thread.e_id] ?? thread.accounts.every(acc => acc.seen);

			const rowState = getThreadRowState(thread.e_id);
			const show = rowState.isHovered || rowState.isSelected;

			return (
				<TableCellToolbar show={show} className="right-0 top-1/2 -translate-y-1/2" onClick={e => e.stopPropagation()}>
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant="naked"
									onClick={() => toggleThreadArchiveStatus(thread)}
									className="hover:bg-background-bg-base-hover flex h-6 w-6 items-center justify-center rounded-[5px] p-0"
									disabled={loading}>
									{isArchiving ? (
										<Loader2 className="h-4 w-4 animate-spin [animation-duration:600ms]" />
									) : (
										<DoubleCheckmarkIcon
											width={14}
											height={14}
											className={
												thread.accounts.every(acc => acc.status === "archived")
													? "text-brand-primary"
													: "text-label-label-muted"
											}
										/>
									)}
								</Button>
							</TooltipTrigger>
							<TooltipContent side="left">
								{thread.accounts.every(acc => acc.status === "archived") ? "Mark not Done" : "Mark Done"}
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>

					<div className="bg-background-bg-border h-3 rounded-[2px]" />

					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant="naked"
									className="hover:bg-background-bg-base-hover flex h-6 w-6 items-center justify-center rounded-[5px] p-0"
									onClick={() => toggleThreadSeenStatus(thread)}
									disabled={loading}>
									{isUpdatingSeenStatus ? (
										<Loader2 className="h-4 w-4 animate-spin [animation-duration:400ms]" />
									) : (
										<div className="relative h-4 w-4">
											{/* Mark as read icon */}
											<LetterCheckIcon
												width={14}
												height={14}
												className={cn(
													"text-label-label-muted absolute inset-0 transition-all duration-150 ease-out",
													"motion-reduce:transition-opacity motion-reduce:duration-75",
													!isSeen ? "scale-100 opacity-100" : "scale-75 opacity-0 motion-reduce:scale-100",
												)}
											/>
											{/* Mark as unread icon */}
											<LetterDotIcon
												width={14}
												height={14}
												className={cn(
													"text-label-label-muted absolute inset-0 transition-all duration-150 ease-out",
													"motion-reduce:transition-opacity motion-reduce:duration-75",
													isSeen ? "scale-100 opacity-100" : "scale-75 opacity-0 motion-reduce:scale-100",
												)}
											/>
										</div>
									)}
								</Button>
							</TooltipTrigger>
							<TooltipContent side="left">{isSeen ? "Mark Unread" : "Mark Read"}</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</TableCellToolbar>
			);
		},
		[
			archivingThreadId,
			seenStatusThreadId,
			toggleThreadArchiveStatus,
			toggleThreadSeenStatus,
			threadSeenStatus,
			getThreadRowState,
		],
	);

	const handleNavigateToAdjacentThread = useCallback(
		(direction: NavigationDirection) => {
			if (!selectedThread) return false;

			const currentIndex = threads.findIndex(t => t.e_id === selectedThread.e_id);
			if (currentIndex === -1) return false;

			const nextIndex =
				direction === "next" ? Math.min(currentIndex + 1, threads.length - 1) : Math.max(currentIndex - 1, 0);

			if (
				(direction === "next" && nextIndex === currentIndex && currentIndex === threads.length - 1) ||
				(direction === "previous" && nextIndex === currentIndex && currentIndex === 0)
			) {
				setSelectedThread(null);
				setIsThreadModalOpen(false);
				onThreadModalVisibilityChange(false);
				return false;
			}

			const nextThread = threads[nextIndex];
			setSelectedThread(nextThread);

			setThreadStates(prev => {
				const newStates: Record<string, ThreadRowState> = {};
				Object.keys(prev).forEach(id => {
					newStates[id] = { isHovered: false, isSelected: id === nextThread.e_id };
				});
				return newStates;
			});

			return true;
		},
		[selectedThread, threads, onThreadModalVisibilityChange],
	);

	const openThreadModal = useCallback(
		(thread: TThreadWithMessagesAndAccounts) => {
			setSelectedThread(thread);
			setIsThreadModalOpen(true);
			onThreadModalVisibilityChange(true);

			// Automatically mark thread as seen if it wasn't already
			if (!thread.accounts.every(acc => acc.seen)) {
				toggleThreadSeenStatus(thread);
			}

			setThreadStates(prev => {
				const newStates = { ...prev };
				Object.keys(newStates).forEach(id => {
					newStates[id] = {
						...newStates[id],
						isSelected: id === thread.e_id,
						isHovered: id === thread.e_id,
					};
				});
				return newStates;
			});
		},
		[onThreadModalVisibilityChange, toggleThreadSeenStatus],
	);

	const handleThreadRowHover = useCallback(
		(threadId: string) => {
			if (archivingThreadId) return;

			const hasSelected = Object.values(threadStates).some(state => state.isSelected);
			if (hasSelected) {
				setThreadStates(prev => {
					const newStates: Record<string, ThreadRowState> = {};
					Object.keys(prev).forEach(id => {
						newStates[id] = { isHovered: id === threadId, isSelected: id === threadId };
					});
					return newStates;
				});
			} else {
				updateThreadRowState(threadId, { isHovered: true });
			}
		},
		[threadStates, updateThreadRowState, archivingThreadId],
	);

	const handleThreadRowLeave = useCallback(
		(threadId: string) => {
			if (archivingThreadId) return;
			updateThreadRowState(threadId, { isHovered: false });
		},
		[updateThreadRowState, archivingThreadId],
	);

	// Keyboard navigation support
	useEffect(() => {
		const handleKeyNavigation = (e: KeyboardEvent) => {
			// Don't respond to keyboard shortcuts when:
			// 1. The thread modal is open
			// 2. The user is typing in an input element
			// 3. The user is typing in the search bar
			if (
				isThreadModalOpen ||
				e.target instanceof HTMLInputElement ||
				e.target instanceof HTMLTextAreaElement ||
				searchText.length > 0
			) {
				return;
			}

			const selectedEntry = Object.entries(threadStates).find(([_, state]) => state.isSelected)?.[0];
			const currentIndex = selectedEntry ? threads.findIndex(t => t.e_id === selectedEntry) : -1;

			if (e.key === "Enter" && selectedEntry) {
				e.preventDefault();
				const threadToOpen = threads.find(t => t.e_id === selectedEntry);
				if (threadToOpen) openThreadModal(threadToOpen);
				return;
			}

			let newIndex = -1;
			if (e.key === "ArrowDown" || e.key === "j") {
				e.preventDefault();
				newIndex = currentIndex === -1 ? 0 : Math.min(currentIndex + 1, threads.length - 1);
			} else if (e.key === "ArrowUp" || e.key === "k") {
				e.preventDefault();
				newIndex = currentIndex === -1 ? threads.length - 1 : Math.max(currentIndex - 1, 0);
			} else if (e.key === "e" && selectedEntry) {
				e.preventDefault();
				const threadToToggle = threads.find(t => t.e_id === selectedEntry);
				if (threadToToggle) toggleThreadArchiveStatus(threadToToggle);
				return;
			} else if (e.key.toLowerCase() === "u" && selectedEntry) {
				e.preventDefault();
				const threadToToggle = threads.find(t => t.e_id === selectedEntry);
				if (threadToToggle) toggleThreadSeenStatus(threadToToggle);
				return;
			}

			if (newIndex !== -1 && threads.length > 0) {
				const newThreadId = threads[newIndex].e_id;
				setThreadStates(prev => {
					const newStates: Record<string, ThreadRowState> = {};
					Object.keys(prev).forEach(id => {
						newStates[id] = { isHovered: false, isSelected: id === newThreadId };
					});
					return newStates;
				});
			}
		};

		window.addEventListener("keydown", handleKeyNavigation);
		return () => window.removeEventListener("keydown", handleKeyNavigation);
	}, [
		threads,
		isThreadModalOpen,
		threadStates,
		openThreadModal,
		toggleThreadArchiveStatus,
		searchText,
		toggleThreadSeenStatus,
	]);

	useEffect(() => {
		onThreadModalVisibilityChange(isThreadModalOpen);
	}, [isThreadModalOpen, onThreadModalVisibilityChange]);

	// Reset thread states when threads change significantly
	useEffect(() => {
		const currentIds = threads.map(t => t.e_id);
		const previousIds = threadIdsRef.current;

		// Check if the thread list has actually changed
		const idsChanged = currentIds.length !== previousIds.length || currentIds.some(id => !previousIds.includes(id));

		if (idsChanged) {
			threadIdsRef.current = currentIds;

			if (threads.length > 0) {
				const selectedId = Object.entries(threadStates).find(([_, state]) => state.isSelected)?.[0];
				const selectedExists = selectedId && threads.some(t => t.e_id === selectedId);

				setThreadStates(_prev => {
					const newStates: Record<string, ThreadRowState> = {};

					threads.forEach((thread, index) => {
						const isSelected = (selectedExists && thread.e_id === selectedId) || (!selectedExists && index === 0);

						newStates[thread.e_id] = {
							isHovered: false,
							isSelected,
						};
					});

					return newStates;
				});
			} else {
				setThreadStates({});
			}

			if (selectedThread) {
				const stillExists = threads.some(t => t.e_id === selectedThread.e_id);
				if (!stillExists && isThreadModalOpen) {
					// Close the modal if the selected thread was removed
					setSelectedThread(null);
					setIsThreadModalOpen(false);
					onThreadModalVisibilityChange(false);
				} else if (stillExists) {
					// Update the selected thread with the latest data
					const updatedThread = threads.find(t => t.e_id === selectedThread.e_id);
					if (updatedThread && JSON.stringify(updatedThread) !== JSON.stringify(selectedThread)) {
						setSelectedThread(updatedThread);
					}
				}
			}
		}
	}, [threads, threadStates, selectedThread, isThreadModalOpen, onThreadModalVisibilityChange]);

	// Handle create campaign button click
	const handleCreateCampaignClick = useCallback(() => {
		setShowNameDialog(true);
	}, []);

	const handleCreateCampaign = async (name: string, clientId: string | null) => {
		try {
			const campaignId = await createCampaign({
				name,
				client_id: clientId,
			});
			navigate(`/dashboard/campaigns/${campaignId}/leads/import`);
			setShowNameDialog(false);
		} catch (error) {
			console.error("Error creating campaign:", error);
			toast.error("Failed to create campaign");
		}
	};

	return (
		<div className="h-full w-full overflow-hidden bg-white p-0">
			{threads.length > 0 || isLoading ? (
				<Table noBorders className="w-full table-fixed">
					<TableHeader className="hidden">
						<TableRow>
							<TableHead className="w-14"></TableHead>
							<TableHead className="w-63"></TableHead>
							<TableHead></TableHead>
							<TableHead className="w-30"></TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{threads.length > 0
							? threads.map(thread => {
									// Use the local status cache to determine if the thread is seen
									const isSeen = threadSeenStatus[thread.e_id] ?? thread.accounts.every(acc => acc.seen);
									// Create a modified thread object with the local seen status
									const threadWithLocalStatus = {
										...thread,
										seen: isSeen,
									};

									// Get animation state for this thread if it exists
									const animationState = threadAnimationStates[thread.e_id];

									return (
										<ThreadRow
											key={thread.e_id}
											thread={threadWithLocalStatus}
											rowState={getThreadRowState(thread.e_id)}
											onMouseEnter={() => handleThreadRowHover(thread.e_id)}
											onMouseLeave={() => handleThreadRowLeave(thread.e_id)}
											onClick={() => openThreadModal(thread)}
											renderActions={() => renderThreadActions(thread)}
											animationState={animationState}
											seenStatusChange={seenStatusChanges[thread.e_id]}
										/>
									);
								})
							: // Skeleton loading state with staggered animation
								[...Array(5)].map((_, index) => <ThreadRowSkeleton key={`skeleton-${index}`} index={index} />)}
					</TableBody>
				</Table>
			) : // Render the appropriate empty state based on campaign existence
			Object.keys(campaignsById).length > 0 ? (
				<InboxZeroWithCampaigns campaignCount={Object.keys(campaignsById).length} />
			) : (
				<>
					<InboxZeroNoCampaigns onCreateCampaignClick={handleCreateCampaignClick} />
					<NameCampaignDialog
						isOpen={showNameDialog}
						onOpenChange={setShowNameDialog}
						onSubmit={handleCreateCampaign}
					/>
				</>
			)}
			{selectedThread && (
				<EmailModal
					isOpen={isThreadModalOpen}
					onClose={() => {
						setSelectedThread(null);
						setIsThreadModalOpen(false);
						onThreadModalVisibilityChange(false);
					}}
					thread={selectedThread}
					onNavigateToAdjacentThread={handleNavigateToAdjacentThread}
					onUnarchive={() => toggleThreadArchiveStatus(selectedThread)}
					onArchive={() => toggleThreadArchiveStatus(selectedThread)}
					archivingThreadId={archivingThreadId}
					toggleThreadSeenStatus={toggleThreadSeenStatus}
				/>
			)}
		</div>
	);
};

export default UnifiedInbox;
