import { stepSeparatorPath } from "@/assets/icons/paths/step-separator";
import IconSVG from "@/components/IconSVG";
import { cn } from "@/lib/utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@radix-ui/react-tooltip";
import React from "react";
import { NestedCommandsListProps } from "../types";

const NestedCommandsList: React.FC<NestedCommandsListProps> = ({
	items,
	selectedIndexChain,
	scopeIndexChain,
	command,
	selectItemChain,
	subMenuClassName,
}) => {
	const selectedIndex =
		selectedIndexChain !== null
			? scopeIndexChain.every((i, index) => selectedIndexChain[index] === i)
				? selectedIndexChain[scopeIndexChain.length]
				: null
			: null;

	const SubMenuIcon = (
		<IconSVG
			path={stepSeparatorPath}
			width={12}
			height={12}
			viewBox="0 0 12 12"
			fill="#AAAAAA"
			className="text-(--label-label-muted)"
		/>
	);

	return (
		<div
			className={cn(
				"slash-menu min-w-[240px] overflow-hidden rounded-lg bg-white shadow-[0_0_0_1px_rgba(0,0,0,0.05),0_2px_4px_rgba(0,0,0,0.05),0_4px_12px_rgba(0,0,0,0.1)]",
				subMenuClassName,
			)}>
			{items.map((item, index) => {
				return (
					<TooltipProvider key={index}>
						<Tooltip open={selectedIndex === index} delayDuration={0}>
							<div className={cn("outline-hidden flex w-full items-center bg-white p-1")}>
								<TooltipTrigger asChild>
									<button
										className={cn(
											"outline-hidden flex h-9 w-full items-center gap-3 rounded-md px-3",
											"hover:bg-background-bg-base-hover focus-visible:bg-background-bg-base-hover",
											index === selectedIndex && "bg-background-bg-base-hover",
											item.disabled && "pointer-events-none opacity-50",
											`slash-menu__item ${
												index === selectedIndex ? "slash-menu__item--selected" : ""
											} ${item.disabled ? "slash-menu__item--disabled" : ""}`,
										)}
										onClick={() => {
											command(item);
										}}
										onMouseEnter={() => {
											selectItemChain([...scopeIndexChain, index]);
										}}
										disabled={item.disabled}>
										{item.icon && item.icon}
										<span className="slash-menu__item-title text-body-base leading-body-base text-label-label-base flex-1 text-left">
											{item.title}
										</span>
										{(item.children ?? []).length > 0 && !item.disabled && <div>{SubMenuIcon}</div>}
									</button>
								</TooltipTrigger>
							</div>
							{item.children && item.children.length > 0 && !item.disabled && (
								<TooltipContent side="right">
									<NestedCommandsList
										command={command}
										items={item.children}
										scopeIndexChain={[...scopeIndexChain, index]}
										selectItemChain={selectItemChain}
										selectedIndexChain={selectedIndexChain}
										subMenuClassName={item.subMenuClassName}
									/>
								</TooltipContent>
							)}
						</Tooltip>
					</TooltipProvider>
				);
			})}
		</div>
	);
};

export default React.memo(NestedCommandsList);
