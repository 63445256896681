import { MouseEvent } from "react";
import {
	Pagination,
	PaginationContent,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
	PaginationEllipsis,
} from "@/components/ui/pagination";

interface TablePaginationProps {
	currentPage: number;
	totalPages: number;
	onPageChange: (page: number) => void;
	pageSize: number;
	onPageSizeChange: (size: number) => void;
	acceptablePageSizes: number[];
}

export const TablePagination = ({
	currentPage,
	totalPages,
	onPageChange,
	pageSize,
	onPageSizeChange,
	acceptablePageSizes,
}: TablePaginationProps) => {
	const getPageNumbers = () => {
		const maxVisible = 5;
		let pages: number[] = [];

		if (totalPages <= maxVisible) {
			pages = Array.from({ length: totalPages }, (_, i) => i + 1);
		} else if (currentPage <= 3) {
			pages = [1, 2, 3, 4, 5];
		} else if (currentPage >= totalPages - 2) {
			pages = Array.from({ length: 5 }, (_, i) => totalPages - 4 + i);
		} else {
			pages = [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
		}

		return pages;
	};

	const handleClick = (e: MouseEvent, page: number) => {
		e.preventDefault();
		onPageChange(page);
	};

	const pages = getPageNumbers();

	return (
		<div className="flex items-center justify-between px-4 py-3 sm:px-6">
			<Pagination>
				<PaginationContent>
					<PaginationItem>
						<PaginationPrevious
							href="#"
							onClick={e => currentPage > 1 && handleClick(e, currentPage - 1)}
							aria-disabled={currentPage <= 1}
							className={currentPage <= 1 ? "pointer-events-none opacity-50" : ""}
						/>
					</PaginationItem>

					{pages[0] > 1 && (
						<PaginationItem>
							<PaginationEllipsis />
						</PaginationItem>
					)}

					{pages.map(page => (
						<PaginationItem key={page}>
							<PaginationLink href="#" onClick={e => handleClick(e, page)} isActive={page === currentPage}>
								{page}
							</PaginationLink>
						</PaginationItem>
					))}

					{pages[pages.length - 1] < totalPages && (
						<PaginationItem>
							<PaginationEllipsis />
						</PaginationItem>
					)}

					<PaginationItem>
						<PaginationNext
							href="#"
							onClick={e => currentPage < totalPages && handleClick(e, currentPage + 1)}
							aria-disabled={currentPage >= totalPages}
							className={currentPage >= totalPages ? "pointer-events-none opacity-50" : ""}
						/>
					</PaginationItem>
				</PaginationContent>
			</Pagination>

			<select
				value={pageSize}
				onChange={e => onPageSizeChange(Number(e.target.value))}
				className="border-input bg-background hover:bg-accent hover:text-accent-foreground shadow-xs h-9 rounded-md border px-3 py-1 text-sm transition-colors">
				{acceptablePageSizes.map(size => (
					<option key={size} value={size}>
						{size} / page
					</option>
				))}
			</select>
		</div>
	);
};
