import { CSVRow } from "@/components/CSVUploader/types";

type LeadWithInvalidField = CSVRow & { _invalidField: string };

export const getFailedImportMessage = (failedLeads: LeadWithInvalidField[]) => {
	if (!failedLeads.length) return "";

	const mostCommonField = failedLeads
		.map(lead => lead._invalidField)
		.reduce((a, b) => {
			const countA = failedLeads.filter(l => l._invalidField === a).length;
			const countB = failedLeads.filter(l => l._invalidField === b).length;
			return countA >= countB ? a : b;
		});

	return `${failedLeads.length} leads couldn't be uploaded because the ${mostCommonField} field had more than 100 characters`;
};
