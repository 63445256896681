import type { TLeadCustomFieldValue } from "@za-zu/types";

export type LeadCustomFieldsDict = Record<string, string | number | boolean | null>;

export function parseCustomFieldsDictFromLeadCustomFieldValues(values: TLeadCustomFieldValue[]): LeadCustomFieldsDict {
	const customFieldsForLead: Record<string, string | number | boolean | null> = values.reduce(
		(acc, curr) => ({
			...acc,
			[`custom.${curr.custom_field_id}`]: curr.text_value ?? curr.boolean_value ?? curr.number_value,
		}),
		{},
	);
	return customFieldsForLead;
}
