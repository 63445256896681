"use client";

import { boldPath, bulletListPath, chainLinkPath, italicPath, orderedListPath } from "@/assets/icons/paths";
import IconSVG from "@/components/IconSVG/IconSVG";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { generateGUID } from "@/lib/utils";
import useCustomFieldsStore from "@/store/useCustomFieldsStore";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor, UseEditorOptions } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { CustomField } from "@za-zu/types";
import { ExternalLink, Trash2 } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { ConditionalLogicExtension } from "./conditionalBuilder/core/conditionals";
import { VarType } from "./conditionalBuilder/core/nodes/constants";
import SlashSuggestion from "./slashCommand/core/slashSuggestions";
import { CommandItem } from "./slashCommand/types";
import { customFieldCommandItemBuilder } from "./slashCommand/utils/customFieldCommandItemBuilder";
import { defaultCommands, defaultFields } from "./slashCommand/utils/defaultCommands";
import filterCommandItems from "./slashCommand/utils/filterCommandItems";
import { senderVariablesSubmenu } from "./slashCommand/utils/senderVariableCommands";

import DOMPurify from "dompurify";
import { TextSelection } from "@tiptap/pm/state";

interface Props {
	content: string;
	onUpdate: (html: string) => void;
	previewMode?: boolean;
	placeholder?: string;
	variant?: "subject" | "body";
	padding?: {
		x?: number;
		top?: number;
	};
	customFieldsData: {
		customFields: CustomField.CustomField[];
		customFieldsById: Record<string, CustomField.CustomField>;
		pendingCustomFieldTrackingIdMap: Record<string, string>;
	};
	campaignId: string;
}

const TipTap: React.FC<Props> = ({
	content,
	onUpdate,
	previewMode = false,
	placeholder = "Say hello",
	variant = "body",
	customFieldsData,
	campaignId,
}) => {
	const { addPendingCustomFieldChange } = useCustomFieldsStore();
	const [showPopover, setShowPopover] = useState(false);
	const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
	const [customFieldCommands, setCustomFieldCommands] = useState<CommandItem[]>(
		customFieldsData.customFields.map(customFieldCommandItemBuilder),
	);
	const [linkState, setLinkState] = useState<{
		isOpen: boolean;
		url: string;
		selection: { from: number; to: number } | null;
	}>({
		isOpen: false,
		url: "",
		selection: null,
	});

	// Update custom field commands when customFields change
	useEffect(() => {
		setCustomFieldCommands(customFieldsData.customFields.map(customFieldCommandItemBuilder));
	}, [customFieldsData.customFields]);

	// Update editor configuration when custom fields change
	const extensions = useMemo(
		() => [
			StarterKit.configure({
				orderedList: {
					HTMLAttributes: { class: "list-decimal" },
				},
				bulletList: {
					HTMLAttributes: { class: "list-disc" },
				},
				code: {
					HTMLAttributes: { class: "bg-accent rounded-md p-1" },
				},
				horizontalRule: {
					HTMLAttributes: { class: "my-2" },
				},
				codeBlock: {
					HTMLAttributes: { class: "bg-primary text-primary-foreground p-2 text-sm rounded-md" },
				},
				heading: {
					levels: [1, 2, 3, 4],
					HTMLAttributes: { class: "tiptap-heading" },
				},
			}),
			Link.configure({
				openOnClick: false,
				HTMLAttributes: {
					class: "text-[#0B6FCC] hover:text-[#0B6FCC]/80 cursor-pointer",
					target: "_blank",
				},
			}),
			SlashSuggestion.configure({
				suggestion: {
					items: ({ query, maxSuggestionsVisible, pluginState }) =>
						filterCommandItems(
							query,
							{
								defaultItems: defaultCommands,
								customItems: customFieldCommands,
								belowDividerItems: [senderVariablesSubmenu],
							},
							maxSuggestionsVisible,
							onCreateVariable,
							pluginState,
						),
					maxSuggestionsVisible: 10,
				},
				defaultVariables: defaultFields.map(f => ({ id: f.id, name: f.displayName, type: VarType.String })),
				variables: [
					...defaultFields.map(f => ({ id: f.id, name: f.displayName, type: VarType.String })),
					...customFieldsData.customFields.map(field => ({
						id: `custom.${field.id}`,
						name: field.name,
						type:
							field.type === "boolean" ? VarType.Boolean : field.type === "number" ? VarType.Number : VarType.String,
					})),
				].reduce((acc, variable) => ({ ...acc, [variable.id]: variable }), {}),
				customFieldsById: customFieldsData.customFieldsById,
			}),
			ConditionalLogicExtension.configure({
				defaultVariables: defaultFields.map(f => ({ id: f.id, name: f.displayName, type: VarType.String })),
				variables: [
					...defaultFields.map(f => ({ id: f.id, name: f.displayName, type: VarType.String })),
					...customFieldsData.customFields.map(field => ({
						id: `custom.${field.id}`,
						name: field.name,
						type:
							field.type === "boolean" ? VarType.Boolean : field.type === "number" ? VarType.Number : VarType.String,
					})),
				].reduce((acc, variable) => ({ ...acc, [variable.id]: variable }), {}),
			}),
			Placeholder.configure({
				placeholder,
			}),
			Underline.configure({
				HTMLAttributes: { class: "underline" },
			}),
		],
		[customFieldCommands, placeholder, customFieldsData],
	);

	// Extract editor props configuration
	const editorProps = {
		attributes: {
			class: [
				variant === "body"
					? [
							"min-h-[200px]",
							"focus:outline-hidden",
							"text-[#292929]",
							"text-[color(display-p3_0.1608_0.1608_0.1608)]",
							"text-[13px] font-normal leading-[21px]",
							"[&_p]:m-0",
							"[&_p]:mb-0",
							"[&_p:last-child]:mb-0",
							"[&_p]:leading-[21px]",
							"[&_p:empty]:min-h-[21px]",
							"[&_br.ProseMirror-trailingBreak]:block",
							"[&_br.ProseMirror-trailingBreak]:min-h-[21px]",
							"[&_.ProseMirror_p+p]:mt-0",
							"[&_.ProseMirror]:space-y-0",
							"[&_.ProseMirror_*]:my-0",
							"[&_.ProseMirror]:pl-0",
							"pl-0",
							"px-0",
						].join(" ")
					: [
							"flex",
							"w-full",
							"h-8",
							"items-center",
							"gap-2",
							"shrink-0",
							"border-0",
							"bg-transparent",
							"[&_.ProseMirror]:flex",
							"[&_.ProseMirror]:items-center",
							"[&_.ProseMirror]:h-full",
							"[&_.ProseMirror]:w-full",
							"[&_.ProseMirror]:px-2",
							"px-0",
							"py-0!",
						].join(" "),
			].join(" "),
		},
		handleDOMEvents: {
			mouseup: () => {
				const selection = window.getSelection();
				if (selection?.toString()) {
					// Close link popover if it's open
					if (linkState.isOpen) {
						setLinkState({
							isOpen: false,
							url: "",
							selection: null,
						});
					}

					const range = selection.getRangeAt(0);
					const rect = range.getBoundingClientRect();
					setPopoverPosition({
						top: rect.top - 38,
						left: rect.left + rect.width / 2,
					});
					setShowPopover(true);
				} else {
					setShowPopover(false);
				}
			},
		},
	};

	const editorConfig = useMemo<UseEditorOptions>(
		() => ({
			extensions: extensions,
			editorProps,
			content: content === "<p></p>" || content === "" || content === null ? "" : content,
			immediatelyRender: true,
			editable: !previewMode,
			onUpdate: ({ editor }) => {
				if (previewMode) return;
				const newHtml = editor?.getHTML();
				if (!newHtml) return;
				onUpdate(newHtml);
			},
			onBlur: ({ editor }) => {
				if (previewMode) return;
				const newHtml = editor?.getHTML();
				if (!newHtml) return;
				onUpdate(newHtml);
			},
		}),
		[content, extensions, onUpdate, previewMode],
	);

	const editor = useEditor(editorConfig);

	// Cleanup editor when unmounting or when extensions change
	useEffect(() => {
		const currentEditor = editor;
		return () => {
			if (currentEditor && !currentEditor.isDestroyed) {
				currentEditor.destroy();
			}
		};
	}, [extensions]);

	// Update content when it changes externally
	useEffect(() => {
		if (editor && !editor.isDestroyed && content !== editor.getHTML()) {
			editor.commands.setContent(content);
		}
	}, [editor, content]);

	// Handle preview mode content updates
	useEffect(() => {
		if (editor && !editor.isDestroyed && previewMode) {
			editor.commands.setContent(content);
		}
	}, [editor, content, previewMode]);

	// Update placeholder when it changes
	useEffect(() => {
		if (editor && !editor.isDestroyed) {
			editor.extensionManager.extensions.forEach(extension => {
				if (extension.name === "placeholder") {
					extension.options.placeholder = placeholder;
					editor.view.dispatch(editor.state.tr);
				}
			});
		}
	}, [editor, placeholder]);

	useEffect(
		/**
		 * This useEffect is responsible for replacing all template variables with proper nodes that have been resolved and
		 * replacing placeholder curly braces with variable nodes.
		 */
		() => {
			if (!editor) return;

			// First replace all template variables with proper nodes that are not pending
			const doc = editor.state.doc;
			const replacements: {
				from: number;
				to: number;
				fieldId: string;
				fieldDisplayName: string;
				isPending: boolean;
				isCustom: boolean;
			}[] = [];

			// Now find all the spans that are possibly for pending custom fields that have just been resolved
			doc.descendants((node, pos) => {
				if (node.type.name === "variable" && node.attrs.trackingId && node.attrs.isPending) {
					const trackingId = node.attrs.trackingId;
					const customFieldId = customFieldsData.pendingCustomFieldTrackingIdMap[trackingId];
					if (!customFieldId) return;

					const customField = customFieldsData.customFieldsById[customFieldId];
					if (!customField) return;

					replacements.push({
						from: pos,
						to: pos + node.nodeSize,
						fieldId: customField.id,
						fieldDisplayName: customField.name,
						isPending: false,
						isCustom: true,
					});
				}
			});

			if (replacements.length === 0) return;

			// Sort replacements in ascending order to track the last position correctly
			replacements.sort((a, b) => a.from - b.from);

			// Keep track of the last replacement position and node size
			let lastPos = -1;
			let lastNodeSize = 0;

			// Apply all replacements in a single chain
			let chain = editor.chain().focus();
			replacements.forEach(({ from, to, fieldId, fieldDisplayName, isPending, isCustom }) => {
				chain = chain.command(({ tr }) => {
					const node = editor.schema.nodes.variable.create({
						fieldId,
						fieldDisplayName,
						isPending,
						isCustom,
					});
					tr.replaceWith(from, to, node);
					lastPos = from;
					lastNodeSize = node.nodeSize;
					return true;
				});
			});

			// Set cursor after the last replaced variable
			chain = chain
				.command(({ tr }) => {
					if (lastPos !== -1) {
						const pos = tr.doc.resolve(lastPos + lastNodeSize);
						tr.setSelection(TextSelection.create(tr.doc, pos.pos));
					}
					return true;
				})
				.focus();

			chain.run();

			// Ensure editor focus is maintained after the transaction
			requestAnimationFrame(() => {
				if (!editor.isDestroyed) {
					editor.commands.focus();
				}
			});
		},
		[editor, customFieldsData],
	);

	const onCreateVariable = (query: string, type: "text" | "number" | "boolean") => {
		const input = {
			type,
			name: query,
			description: null,
			campaign_id: campaignId,
		} as const as CustomField.Create;
		const trackingId = generateGUID();

		addPendingCustomFieldChange({
			id: trackingId,
			displayName: query,
			fieldData: input,
			action: "create",
		});

		// Ensure editor maintains focus after variable creation
		if (editor) {
			requestAnimationFrame(() => {
				if (!editor.isDestroyed) {
					editor.commands.focus();
				}
			});
		}

		return trackingId;
	};

	// Reconfigure editor when custom fields change
	useEffect(() => {
		if (!editor || editor.isDestroyed) return;

		// Create new extension configurations
		const newVariables = [
			...defaultFields.map(f => ({ id: f.id, name: f.displayName, type: VarType.String })),
			...customFieldsData.customFields.map(field => ({
				id: `custom.${field.id}`,
				name: field.name,
				type: field.type === "boolean" ? VarType.Boolean : field.type === "number" ? VarType.Number : VarType.String,
			})),
		].reduce((acc, variable) => ({ ...acc, [variable.id]: variable }), {});

		// Create new extensions with updated configurations
		const newExtensions = extensions.map(ext => {
			if (ext.name === "slash-suggestion") {
				return SlashSuggestion.configure({
					suggestion: {
						items: ({ query, maxSuggestionsVisible, pluginState }) =>
							filterCommandItems(
								query,
								{
									defaultItems: defaultCommands,
									customItems: customFieldCommands,
									belowDividerItems: [senderVariablesSubmenu],
								},
								maxSuggestionsVisible,
								onCreateVariable,
								pluginState,
							),
						maxSuggestionsVisible: 10,
					},
					defaultVariables: defaultFields.map(f => ({ id: f.id, name: f.displayName, type: VarType.String })),
					variables: newVariables,
					customFieldsById: customFieldsData.customFieldsById,
				});
			}
			if (ext.name === "conditionalLogic") {
				return ConditionalLogicExtension.configure({
					defaultVariables: defaultFields.map(f => ({ id: f.id, name: f.displayName, type: VarType.String })),
					variables: newVariables,
				});
			}
			return ext;
		});

		// Only update if editor is still valid
		if (!editor.isDestroyed) {
			editor.setOptions({ extensions: newExtensions });
		}
	}, [editor, customFieldsData, extensions, customFieldCommands, onCreateVariable]);

	// Update the click outside handler
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			// Close formatting popover
			const selection = window.getSelection();
			if (!selection?.toString()) {
				setShowPopover(false);
			}

			// Close link popover
			const linkPopover = document.querySelector("[data-link-popover]");
			if (linkState.isOpen && linkPopover && !linkPopover.contains(event.target as Node)) {
				setLinkState({
					isOpen: false,
					url: "",
					selection: null,
				});

				// Return focus to editor
				setTimeout(() => editor?.commands.focus(), 0);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [editor, linkState.isOpen]);

	// Add the link click handler
	const handleLinkClick = (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		const anchor = target.closest("a");

		if (anchor) {
			event.preventDefault();
			const rect = anchor.getBoundingClientRect();
			setPopoverPosition({
				top: rect.top - 38,
				left: rect.left + rect.width / 2,
			});

			setLinkState({
				isOpen: true,
				url: anchor.getAttribute("href") || "",
				// Only set selection if there is one and has valid positions
				selection:
					editor?.state.selection.empty || !editor?.state.selection.from || !editor?.state.selection.to
						? null
						: {
								from: editor.state.selection.from,
								to: editor.state.selection.to,
							},
			});
		}
	};

	// Add link click effect
	useEffect(() => {
		if (!editor?.isDestroyed) {
			const editorElement = editor?.view.dom;
			editorElement?.addEventListener("click", handleLinkClick);

			return () => {
				editorElement?.removeEventListener("click", handleLinkClick);
			};
		}
	}, [editor]);

	const handleLinkSubmit = () => {
		if (!editor || !linkState.url.trim()) return;
		const url = linkState.url;

		editor.chain().focus().extendMarkRange("link").setLink({ href: url, target: "_blank" }).run();

		setLinkState({
			isOpen: false,
			url: "",
			selection: null,
		});

		setTimeout(() => editor.commands.focus(), 0);
	};

	const handleLinkCancel = () => {
		if (editor && linkState.selection) {
			editor.chain().focus().setTextSelection(linkState.selection).run();
		}
		setLinkState({
			isOpen: false,
			url: "",
			selection: null,
		});

		// Return focus to editor after closing popover
		setTimeout(() => editor?.commands.focus(), 0);
	};

	const handleLinkToggle = () => {
		const selection = editor?.state.selection;
		if (!selection) return;

		// Close the formatting popover
		setShowPopover(false);

		// Get the selection coordinates
		const range = window.getSelection()?.getRangeAt(0);
		if (range) {
			const rect = range.getBoundingClientRect();
			setPopoverPosition({
				top: rect.top - 38,
				left: rect.left + rect.width / 2,
			});
		}

		if (editor?.isActive("link")) {
			const href = editor?.getAttributes("link").href;
			setLinkState({
				isOpen: true,
				url: href || "",
				selection: { from: selection.from, to: selection.to },
			});
			return;
		}

		setLinkState({
			isOpen: true,
			url: "",
			selection: { from: selection.from, to: selection.to },
		});
	};

	// Add useEffect to handle editor editability
	useEffect(() => {
		if (editor) {
			editor.setEditable(!previewMode && !linkState.isOpen);
		}
	}, [editor, previewMode, linkState.isOpen]);

	// tricky solution for firefox cursor positioning
	useEffect(() => {
		if (!editor?.isDestroyed) {
			// we will do this just for firefox.
			// we use posAtCoords to get the position of the click.
			// we then check if the click is on a variable.
			// if it is, we set the selection to the end of the variable.
			// if it's not, we set the selection to the clicked position.
			const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
			if (!isFirefox) return;

			const editorElement = editor?.view.dom;
			if (!editorElement) return;

			const handleClick = (event: MouseEvent) => {
				const target = event.target as HTMLElement;
				if (target.closest('[data-node-type="variable"]')) return;

				const pos = editor.view.posAtCoords({ left: event.clientX, top: event.clientY });
				if (!pos?.inside) return;

				const $pos = editor.state.doc.resolve(pos.pos);
				const node = $pos.nodeAfter;

				if (node?.type.name === "variable") {
					const nodeEndPos = pos.pos + node.nodeSize;
					editor.commands.setTextSelection(nodeEndPos);
					event.preventDefault();
				}
			};

			editorElement.addEventListener("click", handleClick);
			return () => {
				editorElement.removeEventListener("click", handleClick);
			};
		}
	}, [editor]);

	if (!editor) {
		return null;
	}

	// Common styles for all toggle items
	const toggleItemBaseClass =
		"text-label-label-base hover:bg-background-bg-hover data-[state=on]:bg-background-bg-hover flex h-[24px] w-[24px] items-center justify-center gap-[4px] rounded-[5px] px-[4px]";

	return (
		<div className={`relative h-full w-full ${variant === "body" ? "pb-3" : ""}`}>
			<style
				dangerouslySetInnerHTML={{
					__html: `
						.ProseMirror {
							line-height: 21px;
							height: 100%;
							min-height: 200px;
						}
						
						.ProseMirror p {
							margin: 0;
							padding: 0;
							line-height: 21px;
						}
						
						.ProseMirror p + p {
							margin-top: 0;
						}
						
						/* Ensure variables render consistently */
						.ProseMirror [data-node-type="variable"] {
							display: inline-flex;
							vertical-align: baseline;
						}
						
						/* Consistent body mode alignment */
						div[data-body-preview="true"] {
							padding-left: 0;
							height: 100%;
						}
						div[data-body-preview="true"] .ProseMirror {
							padding-left: 0;
							height: 100%;
						}
					`,
				}}
			/>
			{showPopover && (
				<div
					style={{
						position: "fixed",
						top: `${popoverPosition.top}px`,
						left: `${popoverPosition.left}px`,
						transform: "translateX(-50%)",
						zIndex: 50,
					}}>
					<ToggleGroup
						type="multiple"
						className="border-background-bg-border bg-background-bg-base shadow-float inline-flex items-center gap-1 rounded-[8px] border-[0.5px] p-1">
						<ToggleGroupItem
							value="bold"
							aria-pressed={editor?.isActive("bold")}
							data-state={editor?.isActive("bold") ? "on" : "off"}
							onClick={() => editor?.chain().focus().toggleBold().run()}
							className={toggleItemBaseClass}>
							<IconSVG path={boldPath} width={14} height={14} fill="var(--label-label-muted)" />
						</ToggleGroupItem>
						<ToggleGroupItem
							value="italic"
							aria-pressed={editor?.isActive("italic")}
							data-state={editor?.isActive("italic") ? "on" : "off"}
							onClick={() => editor?.chain().focus().toggleItalic().run()}
							className={toggleItemBaseClass}>
							<IconSVG path={italicPath} width={14} height={14} fill="var(--label-label-muted)" />
						</ToggleGroupItem>
						<ToggleGroupItem
							value="underline"
							aria-pressed={editor?.isActive("underline")}
							data-state={editor?.isActive("underline") ? "on" : "off"}
							onClick={() => editor?.chain().focus().toggleUnderline().run()}
							className={toggleItemBaseClass}>
							<span className="text-label-label-muted font-light underline">U</span>
						</ToggleGroupItem>
						<ToggleGroupItem
							value="bulletList"
							aria-pressed={editor?.isActive("bulletList")}
							data-state={editor?.isActive("bulletList") ? "on" : "off"}
							onClick={() => editor?.chain().focus().toggleBulletList().run()}
							className={toggleItemBaseClass}>
							<IconSVG path={bulletListPath} width={14} height={14} fill="var(--label-label-muted)" />
						</ToggleGroupItem>
						<ToggleGroupItem
							value="orderedList"
							aria-pressed={editor?.isActive("orderedList")}
							data-state={editor?.isActive("orderedList") ? "on" : "off"}
							onClick={() => editor?.chain().focus().toggleOrderedList().run()}
							className={toggleItemBaseClass}>
							<IconSVG path={orderedListPath} width={14} height={14} fill="var(--label-label-muted)" />
						</ToggleGroupItem>
						<ToggleGroupItem
							value="link"
							aria-pressed={editor?.isActive("link")}
							data-state={editor?.isActive("link") ? "on" : "off"}
							onClick={handleLinkToggle}
							className={toggleItemBaseClass}>
							<IconSVG path={chainLinkPath} width={14} height={14} fill="var(--label-label-muted)" />
						</ToggleGroupItem>
					</ToggleGroup>
				</div>
			)}
			{linkState.isOpen && (
				<div
					data-link-popover
					style={{
						position: "fixed",
						top: `${popoverPosition.top}px`,
						left: `${popoverPosition.left}px`,
						transform: "translateX(-50%)",
						zIndex: 60,
					}}
					onMouseDown={e => {
						e.preventDefault();
						e.stopPropagation();
					}}
					className="border-background-bg-border bg-background-bg-base shadow-float inline-flex h-8 items-center gap-1 rounded-lg border-[0.5px] px-1">
					<Input
						type="url"
						value={linkState.url}
						onChange={e => setLinkState(prev => ({ ...prev, url: e.target.value }))}
						onKeyDown={e => {
							if (e.key === "Enter") {
								e.preventDefault();
								handleLinkSubmit();
							} else if (e.key === "Escape") {
								e.preventDefault();
								handleLinkCancel();
							}
						}}
						placeholder="Paste or type a link..."
						className="font-geist placeholder:text-label-label-muted h-full w-[195px] border-none bg-transparent px-1 text-[13px] text-sm leading-[18px] placeholder:font-normal focus-visible:ring-0 focus-visible:ring-offset-0"
						autoFocus
					/>
					<div className="bg-background-bg-border h-3 w-0.5 rounded-[2px]" />
					<div className="flex gap-1">
						<Button
							variant="ghost"
							size="icon"
							className="hover:bg-background-bg-hover h-6 w-6"
							onClick={() => {
								if (linkState.url.trim()) {
									const url = linkState.url;
									window.open(url, "_blank");
								}
							}}
							disabled={!linkState.url.trim()}>
							<ExternalLink className="text-label-label-muted h-4 w-4" />
						</Button>
						<Button
							variant="ghost"
							size="icon"
							className="hover:bg-background-bg-hover h-6 w-6"
							onClick={() => {
								// Remove link without requiring selection
								editor?.chain().focus().unsetLink().run();

								setLinkState({
									isOpen: false,
									url: "",
									selection: null,
								});

								// Return focus to editor
								setTimeout(() => editor.commands.focus(), 0);
							}}>
							<Trash2 className="text-label-label-muted h-4 w-4" />
						</Button>
					</div>
				</div>
			)}
			<div
				onClick={() => {
					if (previewMode) return;
					editor?.chain().focus().run();
				}}
				className={`h-full cursor-text ${variant === "body" ? `h-full px-0 py-0` : ""} ${
					variant === "subject"
						? `border-background-bg-border rounded-[6px] border-[0.5px] ${previewMode ? "bg-background-bg-dim" : "bg-background-bg-base"} px-0 py-0`
						: ""
				}`}>
				{previewMode ? (
					<div
						className={`outline-hidden flex h-full w-full items-center ${variant === "body" ? "h-full px-0" : ""} ${
							variant === "subject" ? "h-8 max-h-8 overflow-hidden" : ""
						}`}
						data-body-preview={variant === "body"}>
						<div
							// Copied the styles from tiptap's editor
							className={[
								"ProseMirror w-full border-0 bg-transparent",
								"text-[13px] font-normal leading-[21px] text-[#292929]",
								"focus-visible:ring-0 focus-visible:ring-offset-0",
								// Explicit matching styles for consistent spacing
								"[&_p]:m-0",
								"[&_p]:mb-0",
								"[&_p:last-child]:mb-0",
								"[&_p]:leading-[21px]",
								"[&_p:empty]:min-h-[21px]",
								"[&_br.ProseMirror-trailingBreak]:block",
								"[&_br.ProseMirror-trailingBreak]:min-h-[21px]",
								"[&_p+p]:mt-0",
								"space-y-0",
								"[&_*]:my-0",
								variant === "body" ? "pl-0" : "px-2", // Direct padding to match the editor mode based on variant
								variant === "subject"
									? [
											"flex",
											"w-full",
											"h-8",
											"max-h-8",
											"items-center",
											"gap-2",
											"shrink-0",
											"border-0",
											"bg-transparent",
											"overflow-hidden",
											"[&_.ProseMirror]:flex",
											"[&_.ProseMirror]:items-center",
											"[&_.ProseMirror]:h-full",
											"[&_.ProseMirror]:w-full",
											"[&_.ProseMirror]:px-2",
											"px-0",
											"py-0!",
										].join(" ")
									: "",
							].join(" ")}
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(content, {
									USE_PROFILES: { html: true },
									FORBID_TAGS: ["style"],
									FORBID_ATTR: ["style"],
									ADD_ATTR: ["target"],
									KEEP_CONTENT: true,
									WHOLE_DOCUMENT: false,
									SANITIZE_DOM: true,
									ALLOW_DATA_ATTR: true,
									RETURN_DOM_FRAGMENT: false,
									RETURN_DOM: false,
									FORCE_BODY: false,
									ALLOWED_TAGS: [
										"p",
										"br",
										"div",
										"span",
										"a",
										"ul",
										"ol",
										"li",
										"strong",
										"em",
										"u",
										"blockquote",
										"code",
										"pre",
										"h1",
										"h2",
										"h3",
										"h4",
										"h5",
										"h6",
									],
									ALLOWED_ATTR: [
										"href",
										"target",
										"class",
										"data-node-type",
										"data-field-id",
										"data-is-custom",
										"data-is-pending",
										"data-tracking-id",
									],
								}),
							}}
						/>
					</div>
				) : (
					<EditorContent className={editorProps.attributes.class} editor={editor} />
				)}
			</div>
		</div>
	);
};

export default TipTap;
