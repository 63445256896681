import { toast as _toast } from "sonner";
// Commenting out DEFAULT_COACH_OPTIONS as it's no longer used after disabling toast notifications
import { CoachFeedback /* , DEFAULT_COACH_OPTIONS */ } from "./types";
import { formatVariableName, getPlainTextFromHtml } from "./utils";

// Store collected feedback for alternative UX implementation
const collectedFeedback: CoachFeedback[] = [];

// Helper to check if the original text is still present in the current content
const isOriginalTextStillPresent = (originalText: string | undefined, currentContent: string): boolean => {
	if (!originalText) return true; // If no original text is provided, assume it's still present

	// Get plain text from current content
	const plainTextContent = getPlainTextFromHtml(currentContent);

	// Check if the original text is still present in the current content
	return plainTextContent.includes(originalText);
};

// Helper to display coach feedback as toast
export const displayCoachFeedback = (feedback: CoachFeedback, providedContent?: string): void => {
	// Get the current editor content
	// If content is provided, use it; otherwise, try to find the active editor element
	let currentContent = providedContent || "";

	if (!currentContent) {
		// Try to find the active editor element - first look for the focused element
		let editorElement = document.activeElement;

		// If the active element is not contenteditable, try to find a contenteditable element
		if (!editorElement || editorElement.getAttribute("contenteditable") !== "true") {
			// Look for the main editor element - this selector might need to be adjusted based on your app structure
			editorElement =
				document.querySelector('.email-editor [contenteditable="true"]') ||
				document.querySelector('[contenteditable="true"]');
		}

		currentContent = editorElement?.innerHTML || "";
	}

	// Check if the original text is still present
	if (feedback.originalText && !isOriginalTextStillPresent(feedback.originalText, currentContent)) {
		// Skip displaying feedback if the original text is no longer present
		return;
	}

	// TOAST NOTIFICATIONS TEMPORARILY DISABLED
	// Keeping the logic but commenting out the actual toast display
	// This allows for implementing an alternative UX approach

	/* Commented out to fix TS6133 error
	const _toastOptions = {
		id: `coach-${feedback.id}`,
		duration: DEFAULT_COACH_OPTIONS.dismissDuration,
		// Add quotes around the sentence in the description and truncate if needed
		description: feedback.description
			? `"${formatVariableName(feedback.description.length > 38 ? feedback.description.substring(0, 38) + "..." : feedback.description)}"`
			: undefined,
	};
	*/

	// Format variable names in the feedback message
	const formattedMessage = formatVariableName(feedback.message);

	// Comment out the toast notifications
	/*
	switch (feedback.severity) {
		case "info":
			_toast.info(formattedMessage, _toastOptions);
			break;
		case "warning":
			_toast.warning(formattedMessage, _toastOptions);
			break;
		case "error":
			_toast.error(formattedMessage, _toastOptions);
			break;
	}
	*/

	// Instead of showing toast, collect the feedback for alternative UX
	collectedFeedback.push({
		...feedback,
		message: formattedMessage, // Use the formatted message
	});

	// Optional: Log feedback to console for debugging purposes
	console.log("Email Coach Feedback (toast disabled):", {
		message: formattedMessage,
		severity: feedback.severity,
		id: feedback.id,
		description: feedback.description,
		originalText: feedback.originalText,
	});
};

/**
 * Get all collected feedback for alternative UX implementation
 * @returns Array of collected feedback items
 */
export const getCollectedFeedback = (): CoachFeedback[] => {
	return [...collectedFeedback];
};

/**
 * Clear all collected feedback
 */
export const clearCollectedFeedback = (): void => {
	collectedFeedback.length = 0;
};

// Re-export types
export * from "./types";

// Export all coach-related utilities
export * from "./rules";
export * from "./useEmailCoach";
