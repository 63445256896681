/**
 * Based on https://github.com/ueberdosis/tiptap/blob/839eb476e07fde4eb67fc5cd16848e3656e691f2/packages/suggestion/README.md
 * @see https://github.com/ueberdosis/tiptap/blob/839eb476e07fde4eb67fc5cd16848e3656e691f2/packages/suggestion/README.md
 */
import { Editor, Range } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet, EditorView } from "@tiptap/pm/view";
import { findSuggestionMatch } from "./findSuggestionMatch";

export type SuggestionPluginState = {
	active: boolean;
	range: Range;
	query: null | string;
	text: null | string;
	composing: boolean;
	decorationId?: string | null;
	escaping: boolean;
};

export interface CustomSuggestionOptions<ItemCollection, TSelected> {
	/**
	 * The plugin key for the suggestion plugin.
	 * @default 'suggestion'
	 * @example 'mention'
	 */
	pluginKey?: PluginKey;

	/**
	 * The editor instance.
	 * @default null
	 */
	editor: Editor;

	/**
	 * The character that triggers the suggestion.
	 * @default '@'
	 * @example '#'
	 */
	char?: string;

	/**
	 * The tag name of the decoration node.
	 * @default 'span'
	 * @example 'div'
	 */
	decorationTag?: string;

	/**
	 * The class name of the decoration node.
	 * @default 'suggestion'
	 * @example 'mention'
	 */
	decorationClass?: string;

	/**
	 * A common function that is called when a suggestion is selected. This is meant to pass the editor and range along
	 * with the item's props to the item's attached command property.
	 * @param props The props object.
	 * @param props.editor The editor instance.
	 * @param props.range The range of the suggestion.
	 * @param props.props The props of the selected suggestion.
	 * @returns void
	 * @example ({ editor, range, props }) => { props.command(props.props) }
	 */
	command?: (props: { editor: Editor; range: Range; props: TSelected }) => void | Promise<void>;

	/**
	 * A function that returns the suggestion items in whatever form you want. This gets called when the editor is
	 * updated, and what it returns is what gets passed to the renderer's functions (i.e. onStart, onUpdate, etc).
	 * @param props The props object.
	 * @param props.editor The editor instance.
	 * @param props.query The current suggestion query.
	 * @param props.maxSuggestionsVisible The max number of suggestions the list should show.
	 *          (see {@link CustomSuggestionOptions.maxSuggestionsVisible})
	 * @param props.command The common command that whatever item is selected is passed to.
	 *          (see {@link CustomSuggestionOptions.command})
	 * @returns An array of suggestion items.
	 * @example ({ editor, query }) => [{ id: 1, label: 'John Doe' }]
	 * @example ({ editor, query }) => Promise<[{ id: 1, label: 'John Doe' }]>
	 * @example ({ editor, query }) => {collectionA: [{ id: 1, label: 'John Doe' }], collectionB: [{ id: 2, label: 'Jane Doe' }]  }
	 */
	items: (props: {
		query: string;
		editor: Editor;
		maxSuggestionsVisible: number;
		command: (props: { editor: Editor; range: Range; props: TSelected }) => void | Promise<void>;
		pluginState: SuggestionPluginState;
	}) => ItemCollection | Promise<ItemCollection>;

	/**
	 * The max number of suggestions that should be visible
	 */
	maxSuggestionsVisible?: number;

	/**
	 * The render function for the suggestion.
	 * @returns An object with render functions.
	 */
	render?: () => {
		onBeforeStart?: (props: Omit<CustomSuggestionProps<ItemCollection, TSelected>, "items">) => void;
		onStart?: (props: CustomSuggestionProps<ItemCollection, TSelected>) => void;
		onBeforeUpdate?: (props: Omit<CustomSuggestionProps<ItemCollection, TSelected>, "items">) => void;
		onUpdate?: (props: CustomSuggestionProps<ItemCollection, TSelected>) => void;
		// onExit should have an optional items prop
		onExit?: (
			props: Omit<CustomSuggestionProps<ItemCollection, TSelected>, "items"> & { items?: ItemCollection },
		) => void;
		onKeyDown?: (props: CustomSuggestionKeyDownProps) => boolean;
	};
}

export interface CustomSuggestionProps<ItemCollection, TSelected> {
	/**
	 * The editor instance.
	 */
	editor: Editor;

	/**
	 * The range of the suggestion.
	 */
	range: Range;

	/**
	 * The current suggestion query.
	 */
	query: string;

	/**
	 * The current suggestion text.
	 */
	text: string;

	/**
	 * The suggestion items collection.
	 */
	items: ItemCollection;

	/**
	 * Whether the user is escaping.
	 */
	escaping: boolean;

	/**
	 * A function that is called when a suggestion is selected.
	 * @param props The props object.
	 * @returns void
	 */
	command: (props: TSelected) => void;

	/**
	 * The decoration node HTML element
	 * @default null
	 */
	decorationNode: Element | null;

	/**
	 * The function that returns the client rect
	 * @default null
	 * @example () => new DOMRect(0, 0, 0, 0)
	 */
	clientRect?: (() => DOMRect | null) | null;
}

export interface CustomSuggestionKeyDownProps {
	view: EditorView;
	event: KeyboardEvent;
	range: Range;
}

export const CustomSuggestionPluginKey = new PluginKey("suggestion");

/**
 * This utility allows you to create suggestions.
 * @see https://tiptap.dev/api/utilities/suggestion
 */
export function CustomSuggestion<ItemCollection, TSelected>({
	pluginKey = CustomSuggestionPluginKey,
	editor,
	char = "@",
	decorationTag = "span",
	decorationClass = "suggestion",
	command = () => void 0,
	items,
	maxSuggestionsVisible = 10,
	render = () => ({}),
}: CustomSuggestionOptions<ItemCollection, TSelected>) {
	let props: Omit<CustomSuggestionProps<ItemCollection, TSelected>, "items"> | undefined;
	const renderer = render?.();

	const plugin: Plugin<SuggestionPluginState> = new Plugin({
		key: pluginKey,

		view() {
			return {
				update: async (view, prevState) => {
					const prev: SuggestionPluginState = this.key?.getState(prevState);
					const next: SuggestionPluginState = this.key?.getState(view.state);
					if (next.escaping && !prev.escaping) {
						const slashSpanHtml = `<span data-node-type="inactiveSlash">/</span>`;
						editor.commands.insertContentAt(
							next.range,
							`${slashSpanHtml}${(next.query || "").split("/").join(slashSpanHtml)}`,
						);
					}

					// See how the state changed
					const moved = prev.active && next.active && prev.range.from !== next.range.from;
					const started = !prev.active && next.active;
					const stopped = prev.active && !next.active;
					const changed = !started && !stopped && prev.query !== next.query;

					const handleStart = started || (moved && changed);
					const handleChange = changed || moved;
					const handleExit = stopped || (moved && changed);
					const state = handleExit && !handleStart ? prev : next;
					const decorationNode = view.dom.querySelector(`[data-decoration-id="${state.decorationId}"]`);
					if (stopped && !state.escaping && decorationNode) {
						const slashSpanHtml = `<span data-node-type="inactiveSlash">/</span>`;
						editor.commands.insertContentAt(
							state.range,
							`${slashSpanHtml}${(state.query || "").split("/").join(slashSpanHtml)}`,
						);
					}

					// Cancel when suggestion isn't active
					if (!handleStart && !handleChange && !handleExit) {
						return;
					}

					props = {
						editor,
						escaping: state.escaping,
						range: state.range,
						query: state.query ?? "",
						text: state.text ?? "",
						command: commandProps => {
							return command({
								editor,
								range: state.range,
								props: commandProps,
							});
						},
						decorationNode,
						// virtual node for popper.js or tippy.js
						// this can be used for building popups without a DOM node
						clientRect: decorationNode
							? () => {
									// because of `items` can be asynchrounous we’ll search for the current decoration node
									const { decorationId } = this.key?.getState(editor.state); // eslint-disable-line
									const currentDecorationNode = view.dom.querySelector(`[data-decoration-id="${decorationId}"]`);

									return currentDecorationNode?.getBoundingClientRect() || null;
								}
							: null,
					};

					if (handleStart) {
						renderer?.onBeforeStart?.(props);
					}

					if (handleChange) {
						renderer?.onBeforeUpdate?.(props);
					}
					let itemsTemp: ItemCollection;
					if (handleChange || handleStart) {
						itemsTemp = await items({
							editor,
							query: state.query ?? "",
							maxSuggestionsVisible,
							command,
							pluginState: next,
						});
						if (handleExit) {
							renderer?.onExit?.({ ...props, items: itemsTemp });
						}
						if (handleChange) {
							renderer?.onUpdate?.({ ...props, items: itemsTemp });
						}
						if (handleStart) {
							renderer?.onStart?.({ ...props, items: itemsTemp });
						}
					} else {
						if (handleExit) {
							renderer?.onExit?.(props);
						}
					}
				},

				destroy: () => {
					if (!props) {
						return;
					}

					renderer?.onExit?.(props);
				},
			};
		},

		state: {
			// Initialize the plugin's internal state.
			init() {
				// console.log("init");
				const state: SuggestionPluginState = {
					active: false,
					range: {
						from: 0,
						to: 0,
					},
					query: null,
					text: null,
					composing: false,
					escaping: false,
				};

				return state;
			},

			// Apply changes to the plugin state from a view transaction.
			apply(transaction, prev, _oldState, _state) {
				const meta = transaction.getMeta(pluginKey);
				const { isEditable } = editor;
				const { composing } = editor.view;
				const { selection } = transaction;
				const { empty, from } = selection;
				const next = { ...prev };
				if (meta !== undefined) {
					next.escaping = meta.escaping;
				}
				next.composing = composing;

				// We can only be suggesting if the view is editable, and:
				//   * there is no selection, or
				//   * a composition is active (see: https://github.com/ueberdosis/tiptap/issues/1449)
				if (isEditable && (empty || editor.view.composing)) {
					// Reset active state if we just left the previous suggestion range
					if ((from < prev.range.from || from > prev.range.to) && !composing && !prev.composing) {
						next.active = false;
					}

					// Try to match against where our cursor currently is
					const match = findSuggestionMatch({
						char,
						$position: selection.$from,
					});
					// Check if the match starts with an inactive slash node
					const decorationId = `id_${Math.floor(Math.random() * 0xffffffff)}`;

					// If we found a match, update the current state to show it
					if (match) {
						next.active = true;
						next.decorationId = prev.decorationId ? prev.decorationId : decorationId;
						next.range = match.range;
						next.query = match.query;
						next.text = match.text;
					} else {
						next.active = false;
					}
				} else {
					next.active = false;
				}

				// Make sure to empty the range if suggestion is inactive
				if (!next.active) {
					next.escaping = false;
					next.decorationId = null;
					next.range = { from: 0, to: 0 };
					next.query = null;
					next.text = null;
				}
				return next;
			},
		},

		props: {
			// Call the keydown hook if suggestion is active.

			handleKeyDown(view, event) {
				const state = plugin.getState(view.state);
				if (!state) {
					return false;
				}
				const { active, range } = state;

				if (event.key === "Escape") {
					// view.state.apply(view.state.tr.setMeta("escape", true));
					view.dispatch(view.state.tr.setMeta(pluginKey, { escaping: true }));
				}
				if (!active) {
					return false;
				}

				return renderer?.onKeyDown?.({ view, event, range }) || false;
			},

			// Setup decorator on the currently active suggestion.
			decorations(state) {
				const latestState = plugin.getState(state);
				if (!latestState) {
					return null;
				}
				const { active, range, decorationId } = latestState;

				if (!active) {
					return null;
				}

				return DecorationSet.create(state.doc, [
					Decoration.inline(range.from, range.to, {
						"nodeName": decorationTag,
						"class": decorationClass,
						"data-decoration-id": decorationId ?? undefined,
					}),
				]);
			},
		},
	});

	return plugin;
}
