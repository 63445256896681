import { mergeAttributes, Node, NodeViewProps } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import BranchSplit from "@/assets/icons/branch-split.svg?react";
import ConditionalOperation from "./ConditionalOperation";
import {
	isLiquidOperationDefinitionFull,
	liquidConditionalBlockParser,
	liquidConditionalBlockParserToAttribute,
	LiquidOperationDefinitionTempStates,
} from "./liquidParsing";
import { isIfOrElseIfBlockNodeViewProps } from "./predicates";
import { IfOrElseIfBlockOptions } from "./types";

// The main NodeView for the IfBlock node
function IfBlockNodeView(props: NodeViewProps) {
	if (!isIfOrElseIfBlockNodeViewProps(props)) {
		throw new Error("Invalid conditional block options");
	}
	const {
		node: {
			attrs: { liquidCondition },
		},
		extension: {
			options: {
				isElseIf,
				defaultVariables,
				variables,
				variableFuse,
				stringVariableFuse,
				numberVariableFuse,
				booleanVariableFuse,
			},
		},
		editor,
	} = props;

	// const _handleDelete = () => {
	// 	const from = getPos();
	// 	const to = from + node.nodeSize;

	// 	// Create an empty slice
	// 	const emptySlice = Slice.empty;

	// 	// Replace the content in the specified range with the empty slice
	// 	const transaction = editor.state.tr.replace(from, to, emptySlice);

	// 	// Add metadata to the transaction to indicate that this is an intentional deletion
	// 	transaction.setMeta("intentionalDeletion", true);

	// 	// Apply the transaction to the editor state
	// 	editor.view.dispatch(transaction);
	// };

	const blockTitle = isElseIf ? "ELSE IF" : "IF";

	const [operation, setOperation] = useState<LiquidOperationDefinitionTempStates>(
		liquidConditionalBlockParser(liquidCondition, variables),
	);

	useEffect(() => {
		// When the operation is updated, update the liquidCondition attribute
		const pieces: string[] = [isElseIf ? "{% elsif" : "{% if"];
		if (isLiquidOperationDefinitionFull(operation)) {
			pieces.push(...liquidConditionalBlockParserToAttribute(operation));
			pieces.push("%}");
			props.updateAttributes({ liquidCondition: pieces.join(" ") });
		} else {
			// If the operation is not full, we want it to never fire
			props.updateAttributes({ liquidCondition: `{% ${isElseIf ? "elsif" : "if"} false %}` });
		}
	}, [operation]);

	return (
		<NodeViewWrapper as="span" data-node-type={props.node.type.name} className="inline" contentEditable={false}>
			<span className="inline">
				<span className="inline-flex h-6 min-w-6 items-center justify-center gap-1 rounded-l-md border-[0.5px] border-[color(display-p3_0.9326_0.8096_0.7259)] bg-[#FFEFE6] px-1.5">
					<BranchSplit className="inline h-3.5 w-3.5" />
					<span className="inline text-[#F18039]">{blockTitle}</span>
				</span>
			</span>
			<ConditionalOperation
				variables={variables}
				variableFuse={variableFuse}
				stringVariableFuse={stringVariableFuse}
				numberVariableFuse={numberVariableFuse}
				booleanVariableFuse={booleanVariableFuse}
				operation={operation}
				setOperation={setOperation}
				defaultVariables={defaultVariables}
				editor={editor}
			/>
		</NodeViewWrapper>
	);
}

export const IfBlock = Node.create<IfOrElseIfBlockOptions>({
	name: "ifBlock",
	inline: true,
	atom: true,
	group: "inline",
	// isolating: true,

	addAttributes() {
		return {
			liquidCondition: {
				default: `{% ${this.name === "elseIfBlock" ? "elsif" : "if"} false %}`,
			},
		};
	},

	addOptions() {
		return {
			defaultVariables: [],
			isElseIf: false,
			variables: {},
			variableFuse: new Fuse([], { keys: ["name"] }),
			stringVariableFuse: new Fuse([], { keys: ["name"] }),
			numberVariableFuse: new Fuse([], { keys: ["name"] }),
			booleanVariableFuse: new Fuse([], { keys: ["name"] }),
		};
	},

	parseHTML() {
		// If you want to load from HTML, parse data-condition (JSON) back into an object
		return [
			{
				tag: `span[data-node-type="${this.name}"]`,
				getAttrs: node => {
					const liquidConditionAttribute = node.getAttribute("data-liquid-condition") || "";
					return {
						liquidCondition: liquidConditionAttribute,
					};
				},
			},
		];
	},

	renderHTML({ node }) {
		return [
			"span",
			mergeAttributes({ "data-node-type": this.name, "data-liquid-condition": node.attrs.liquidCondition }),
		];
	},

	addNodeView() {
		return ReactNodeViewRenderer(IfBlockNodeView, {
			update({ newNode, oldNode, updateProps }) {
				const newAttrsString = JSON.stringify(newNode.attrs);
				const oldAttrsString = JSON.stringify(oldNode.attrs);
				if (newAttrsString !== oldAttrsString) {
					// updateProps triggers a rerender of the component by having the ReactNodeViewRenderer pass in the latest
					// props that the node would have.
					updateProps();
					return true;
				}
				return false;
			},
		});
	},
});

export const ElseIfBlock = IfBlock.extend({
	name: "elseIfBlock",
	isElseIf: true,
});
