import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { CheckIcon } from "lucide-react";

import { cn } from "@/lib/utils";

function Checkbox({ className, ...props }: React.ComponentProps<typeof CheckboxPrimitive.Root>) {
	return (
		<CheckboxPrimitive.Root
			data-slot="checkbox"
			className={cn(
				"focus-visible:border-ring focus-visible:ring-ring/50 aria-invalid:ring-destructive/20 dark:aria-invalid:ring-destructive/70 aria-invalid:border-destructive shadow-xs peer size-4 shrink-0 rounded-[4px] border-[0.5px] border-[var(--background-bg-border,#D9D9D9)] bg-[var(--background-bg-base-dim,#FDFDFD)] outline-none transition-all duration-150 ease-out focus-visible:ring-[3px] disabled:cursor-not-allowed disabled:opacity-50",
				"hover:border-background-bg-border-hover",
				"data-[state=checked]:bg-background-bg-brand data-[state=checked]:border-background-bg-brand data-[state=checked]:text-white",
				className,
			)}
			{...props}>
			<CheckboxPrimitive.Indicator
				data-slot="checkbox-indicator"
				className="motion-safe:animate-checkbox-appear flex items-center justify-center text-current">
				<CheckIcon className="size-3.5" />
			</CheckboxPrimitive.Indicator>
		</CheckboxPrimitive.Root>
	);
}

export { Checkbox };
