import { useCampaignStore } from "@/store";
import useCustomFieldsStore from "@/store/useCustomFieldsStore";
import { CampaignProps } from "@/types/campaign";
import { parseCustomFieldValue } from "@/utils/parseCustomFieldValue";
import { CustomField, TLeadWithCustomFieldValues } from "@za-zu/types";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogTitle } from "../ui/dialog";

interface LeadDetailsModalProps {
	campaign: CampaignProps;
	lead: TLeadWithCustomFieldValues | null;
	isOpen: boolean;
	onClose: () => void;
	onUpdate?: () => void;
}

type FormData = {
	firstname: string;
	lastname: string;
	email: string;
	linkedin: string;
	job_title: string;
	company: string;
	phone: string;
	custom_fields: Record<string, string>;
};

const LeadDetailsModal = ({ campaign, lead, isOpen, onClose, onUpdate }: LeadDetailsModalProps) => {
	const [formData, setFormData] = useState<Partial<FormData>>({
		firstname: "",
		lastname: "",
		email: "",
		linkedin: "",
		job_title: "",
		company: "",
		phone: "",
		custom_fields: {},
	});
	const [isSaving, setIsSaving] = useState(false);
	const { updateLead } = useCampaignStore();
	const { customFields, customFieldsById } = useCustomFieldsStore();

	useEffect(() => {
		if (!lead) return;

		setFormData({
			firstname: lead.firstname ?? "",
			lastname: lead.lastname ?? "",
			email: lead.email,
			linkedin: lead.linkedin ?? "",
			job_title: lead.job_title ?? "",
			company: lead.company ?? "",
			phone: lead.phone ?? "",
			custom_fields:
				customFields.reduce(
					(acc, field) => {
						const simpleFieldId = field.id.replace("custom.", "");
						const leadFieldValue = lead.lead_custom_field_values.find(v => v.custom_field_id === simpleFieldId) ?? {
							text_value: null,
							number_value: null,
							boolean_value: null,
						};

						return {
							...acc,
							[field.id]: `${leadFieldValue.text_value ?? leadFieldValue.boolean_value ?? leadFieldValue.number_value ?? ""}`,
						};
					},
					{} as Record<string, string>,
				) ?? {},
		} as Partial<FormData>);
	}, [lead]);

	const handleInputChange = (field: string, value: string) => {
		setFormData(prev => ({
			...prev,
			[field]: value,
		}));
	};

	const handleCustomFieldChange = (fieldId: string, value: string) => {
		setFormData(prev => ({
			...prev,
			custom_fields: {
				...prev.custom_fields,
				[fieldId]: value,
			},
		}));
	};

	const handleSave = async () => {
		if (!lead) return;

		try {
			setIsSaving(true);

			updateLead({
				campaignId: campaign.id,
				leadEmail: lead.email,
				values: {
					firstname: formData.firstname ?? "",
					lastname: formData.lastname || null,
					linkedin: formData.linkedin || null,
					job_title: formData.job_title || null,
					company: formData.company || null,
					phone: formData.phone || null,
				},
				custom: Object.entries(formData.custom_fields || {}).reduce(
					(acc, [key, value]) => ({
						...acc,
						[`${key.replaceAll("custom.", "")}`]:
							parseCustomFieldValue(value, customFieldsById[key.replaceAll("custom.", "")]?.type) ?? null,
					}),
					{} as Record<string, string | number | boolean | null>,
				),
			});

			toast.success("Lead updated successfully");
			onUpdate?.();
			onClose();
		} catch (error) {
			console.error("Error updating lead:", error);
			toast.error("Failed to update lead");
		} finally {
			setIsSaving(false);
		}
	};

	if (!lead) return null;

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent
				className="flex w-[517px] flex-col items-end justify-center gap-4 px-1 py-4"
				aria-describedby={undefined}>
				<div className="flex items-center justify-between self-stretch px-3">
					<DialogTitle className="text-body-base text-center">Edit lead</DialogTitle>
				</div>

				<div className="flex flex-col items-center gap-3 self-stretch rounded-xl border border-[#D9D9D9] bg-white p-3 shadow-[0px_1px_1px_0px_rgba(0,0,0,0.06),0px_4px_4px_-1px_rgba(0,0,0,0.02)]">
					<div className="w-full space-y-3">
						<div className="grid grid-cols-2 items-center">
							<label className="text-label-label-base prose-body-base-plus">First name</label>
							<input
								type="text"
								value={formData.firstname ?? ""}
								onChange={e => handleInputChange("firstname", e.target.value)}
								className="rounded-md border border-gray-200 px-3 py-1.5 text-sm"
							/>
						</div>

						<div className="grid grid-cols-2 items-center">
							<label className="text-label-label-base prose-body-base-plus">Last name</label>
							<input
								type="text"
								value={formData.lastname ?? ""}
								onChange={e => handleInputChange("lastname", e.target.value)}
								className="rounded-md border border-gray-200 px-3 py-1.5 text-sm"
							/>
						</div>

						<div className="grid grid-cols-2 items-center">
							<label className="text-label-label-base prose-body-base-plus">Email</label>
							<input
								type="email"
								value={formData.email}
								onChange={e => handleInputChange("email", e.target.value)}
								className="rounded-md border border-gray-200 px-3 py-1.5 text-sm"
							/>
						</div>

						<div className="grid grid-cols-2 items-center">
							<label className="text-label-label-base prose-body-base-plus">LinkedIn URL</label>
							<input
								type="url"
								value={formData.linkedin ?? ""}
								onChange={e => handleInputChange("linkedin", e.target.value)}
								className="rounded-md border border-gray-200 px-3 py-1.5 text-sm"
							/>
						</div>

						<div className="grid grid-cols-2 items-center">
							<label className="text-label-label-base prose-body-base-plus">Job title</label>
							<input
								type="text"
								value={formData.job_title ?? ""}
								onChange={e => handleInputChange("job_title", e.target.value)}
								className="rounded-md border border-gray-200 px-3 py-1.5 text-sm"
							/>
						</div>

						<div className="grid grid-cols-2 items-center">
							<label className="text-label-label-base prose-body-base-plus">Company name</label>
							<input
								type="text"
								value={formData.company ?? ""}
								onChange={e => handleInputChange("company", e.target.value)}
								className="rounded-md border border-gray-200 px-3 py-1.5 text-sm"
							/>
						</div>

						<div className="grid grid-cols-2 items-center">
							<label className="text-label-label-base prose-body-base-plus">Phone number</label>
							<input
								type="tel"
								value={formData.phone ?? ""}
								onChange={e => handleInputChange("phone", e.target.value)}
								className="rounded-md border border-gray-200 px-3 py-1.5 text-sm"
							/>
						</div>

						{customFields.length > 0 && (
							<div className="space-y-3">
								{customFields.map((field: CustomField.CustomField) => (
									<div key={field.id} className="grid grid-cols-2 items-center">
										<label className="text-label-label-base prose-body-base-plus">{field.name}</label>
										<input
											type="text"
											value={formData.custom_fields?.[field.id] ?? ""}
											onChange={e => handleCustomFieldChange(field.id, e.target.value)}
											className="rounded-md border border-gray-200 px-3 py-1.5 text-sm"
										/>
									</div>
								))}
							</div>
						)}
					</div>
				</div>

				<div className="flex justify-end gap-2">
					<Button variant="exit" onClick={onClose} className="h-8 px-3 text-sm">
						Cancel
					</Button>
					<Button onClick={handleSave} className="h-8 px-3 text-sm" disabled={isSaving}>
						{isSaving ? "Saving..." : "Save changes"}
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default LeadDetailsModal;
