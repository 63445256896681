import { useEmailAccountsStore } from "@/store";
import { ZazuAPI } from "@/utils/zazu-api";
import { toast } from "sonner";
import { useMutation } from "../../hooks/useMutation";
import { dispatchNewAccountConnectedEvent } from "./generateGoogleOauthUrl";

export type GenerateOauthUrlInput = {
	access_token: string;
};

const generateMicrosoftUrl = async ({ input }: { input: GenerateOauthUrlInput }) => {
	const uri = `/accounts/oauth/microsoft/start`;
	const response = await ZazuAPI.get(uri, {
		headers: { Authorization: `Bearer ${input.access_token}` },
	});
	return response.data;
};

type ParsableError = {
	response: {
		data: {
			message: string;
		};
	};
};

function isParsableError(err: unknown): err is ParsableError {
	return (
		typeof err === "object" &&
		err !== null &&
		"response" in err &&
		err.response !== null &&
		typeof err.response === "object" &&
		"data" in err.response &&
		err.response.data !== null &&
		typeof err.response.data === "object" &&
		"message" in err.response.data &&
		typeof err.response.data.message === "string"
	);
}

export function useGenerateMicrosoftOauthUrl(): {
	isGenerating: boolean;
	generateMicrosoftOauthUrl: (input: GenerateOauthUrlInput) => Promise<{ url: string }>;
	generateError: unknown;
} {
	const { addPendingAccountChange, accounts } = useEmailAccountsStore.getState();
	const { isMutating, mutate, error } = useMutation<{ url: string }, GenerateOauthUrlInput, void>({
		update: generateMicrosoftUrl,
		onSuccess: (data, _key, _args) => {
			// Store current accounts to compare later
			const currentAccounts = [...accounts];

			// Add a message event listener for Microsoft OAuth
			const handleOAuthMessage = (event: MessageEvent) => {
				if (event.data.type === "MICROSOFT_OAUTH_SUCCESS") {
					// Add a callback to be executed after accounts are fetched
					const fetchAndCheckForNewAccounts = async () => {
						addPendingAccountChange({
							id: "latest-accounts",
							action: "fetch",
						});

						// Wait a bit for the accounts to be fetched and updated in the store
						setTimeout(() => {
							const { accounts: updatedAccounts } = useEmailAccountsStore.getState();

							// Find newly added accounts
							const newAccounts = updatedAccounts.filter(
								account => !currentAccounts.some(existingAccount => existingAccount.id === account.id),
							);

							// If we found a new account, dispatch the event
							if (newAccounts.length > 0) {
								dispatchNewAccountConnectedEvent(newAccounts[0]);
							}
						}, 1000); // Give it a second to update the store
					};

					fetchAndCheckForNewAccounts();
					toast.success("Microsoft account connected successfully");
					window.removeEventListener("message", handleOAuthMessage);
				} else if (event.data.type === "MICROSOFT_OAUTH_FAILURE") {
					toast.error(event.data.error || "Failed to connect account");
					window.removeEventListener("message", handleOAuthMessage);
				}
			};

			window.addEventListener("message", handleOAuthMessage);
			window.open(data.url, "_blank");
		},
		onError: (err: unknown) => {
			let errorMessage = "Error generating url.";
			if (isParsableError(err)) {
				errorMessage = err.response.data.message;
			}
			toast.error(errorMessage);
			console.error(err);
		},
	});

	return {
		isGenerating: isMutating,
		generateMicrosoftOauthUrl: mutate,
		generateError: error,
	};
}
