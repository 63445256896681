import * as React from "react";

const PlayActionIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.33331 3.39086C3.33331 1.82475 5.05185 0.866286 6.3843 1.68926L13.847 6.29856C15.1123 7.08008 15.1124 8.92026 13.847 9.70178L6.3843 14.3111C5.0519 15.134 3.33331 14.1757 3.33331 12.6095V3.39086Z"
			fill="currentColor"
		/>
	</svg>
);

export default PlayActionIcon;
