import React from "react";
import { Table, TableBody, TableHeader, TableRow, TableCell, TableHeaderRow, TableHead } from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";

/**
 * Skeleton loader for client rows with staggered animation
 */
const ClientRowSkeleton: React.FC<{ index: number }> = ({ index }) => {
	// Stagger animation by 50ms per row
	const staggerDelay = index * 0.05;

	return (
		<TableRow className="border-background-bg-divider border-b-[0.5px]">
			{/* Client name */}
			<TableCell>
				<Skeleton className="h-5 w-36" delay={staggerDelay} duration={0.8} />
			</TableCell>

			{/* Created date */}
			<TableCell>
				<Skeleton className="h-5 w-28" delay={staggerDelay} duration={0.8} />
			</TableCell>

			{/* Access */}
			<TableCell>
				<Skeleton className="h-5 w-20" delay={staggerDelay} duration={0.8} />
			</TableCell>

			{/* Actions */}
			<TableCell>
				<Skeleton className="h-8 w-20 rounded-md" delay={staggerDelay} duration={0.8} />
			</TableCell>
		</TableRow>
	);
};

interface ClientsTableSkeletonProps {
	numRows?: number;
}

/**
 * Full skeleton component for the ClientsTable that can be used during loading
 */
export const ClientsTableSkeleton: React.FC<ClientsTableSkeletonProps> = ({ numRows = 3 }) => {
	return (
		<div className="rounded-lg border shadow-sm">
			<Table noScroll>
				<TableHeader>
					<TableHeaderRow className="border-t-0 [&_tr]:border-t-0">
						<TableHead label="Client" />
						<TableHead label="Created" />
						<TableHead label="Access" />
						<TableHead label="Actions" />
					</TableHeaderRow>
				</TableHeader>
				<TableBody>
					{/* Show skeleton rows while loading */}
					{[...Array(numRows)].map((_, index) => (
						<ClientRowSkeleton key={`skeleton-${index}`} index={index} />
					))}
				</TableBody>
			</Table>
		</div>
	);
};
