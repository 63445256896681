import { useCallback, useEffect, useRef } from "react";
import { CoachFeedback, CoachOptions, DEFAULT_COACH_OPTIONS } from "./types";
import { displayCoachFeedback, clearCollectedFeedback } from "./index";
import { emailCoachRules } from "./rules";
import { getPlainTextFromHtml, extractSentences } from "./utils";

// Helper to find the most recently edited sentence
const findChangedSentence = (
	currentContent: string,
	previousContent: string,
): {
	sentence: string | null;
	isCompleteSentence: boolean;
	sentenceIndex: number | null;
} => {
	// Get plain text versions
	const currentText = getPlainTextFromHtml(currentContent);
	const previousText = getPlainTextFromHtml(previousContent);

	// If no previous content or content is identical, return null
	if (!previousText || currentText === previousText) {
		return { sentence: null, isCompleteSentence: false, sentenceIndex: null };
	}

	// Use the improved sentence extraction
	const currentResult = extractSentences(currentText);
	const previousResult = extractSentences(previousText);

	// Extract sentences and completeness arrays for easier access
	const currentSentences = currentResult.sentences;
	const currentCompleteness = currentResult.isComplete;
	const previousSentences = previousResult.sentences;
	const previousCompleteness = previousResult.isComplete;

	// First priority: Check if an existing complete sentence was modified
	// This handles going back to edit a prior sentence
	if (currentSentences.length === previousSentences.length) {
		for (let i = 0; i < currentSentences.length; i++) {
			// If this sentence changed and is complete, it's likely what the user edited
			if (
				currentSentences[i] !== previousSentences[i] &&
				currentSentences[i] &&
				currentCompleteness[i] // Only consider if it's marked as complete
			) {
				return {
					sentence: currentSentences[i],
					isCompleteSentence: currentCompleteness[i],
					sentenceIndex: i,
				};
			}
		}
	}

	// Second priority: Check if we have new complete sentences
	if (currentSentences.length > previousSentences.length) {
		// Get the most recently completed sentence
		for (let i = previousSentences.length; i < currentSentences.length; i++) {
			const sentence = currentSentences[i];
			if (sentence && currentCompleteness[i]) {
				return {
					sentence,
					isCompleteSentence: true,
					sentenceIndex: i,
				};
			}
		}
	}

	// Third priority: Check if an existing sentence was modified and newly completed
	if (currentSentences.length === previousSentences.length) {
		for (let i = 0; i < currentSentences.length; i++) {
			if (
				currentSentences[i] &&
				previousSentences[i] &&
				currentSentences[i] !== previousSentences[i] &&
				currentCompleteness[i] && // Now it's complete
				!previousCompleteness[i] // But it wasn't before
			) {
				// This sentence was just completed
				return {
					sentence: currentSentences[i],
					isCompleteSentence: true,
					sentenceIndex: i,
				};
			}
		}
	}

	// If we lost sentences (delete operation), check which sentence changed
	if (currentSentences.length < previousSentences.length) {
		// Find the first sentence that differs
		for (let i = 0; i < currentSentences.length; i++) {
			if (
				currentSentences[i] &&
				i < previousSentences.length &&
				currentSentences[i] !== previousSentences[i] &&
				currentCompleteness[i] // Only if it's complete
			) {
				return {
					sentence: currentSentences[i],
					isCompleteSentence: true,
					sentenceIndex: i,
				};
			}
		}
	}

	// If no completed sentence was found, return the current edited sentence
	// but mark it as incomplete based on our new metadata
	const lastIdx = currentSentences.length - 1;
	const lastCurrentSentence = lastIdx >= 0 ? currentSentences[lastIdx] : null;
	const isLastSentenceComplete = lastIdx >= 0 ? currentCompleteness[lastIdx] : false;

	return {
		sentence: lastCurrentSentence || null,
		isCompleteSentence: isLastSentenceComplete,
		sentenceIndex: lastIdx >= 0 ? lastIdx : null,
	};
};

export const useEmailCoach = (options: CoachOptions = {}) => {
	// Merge default options with provided options
	const coachOptions = { ...DEFAULT_COACH_OPTIONS, ...options };

	// Counter for toasts shown in the current session
	const toastsShownCount = useRef<number>(0);

	// Debounce timer
	const debounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

	// Last analyzed content to detect changes
	const lastAnalyzedContent = useRef<string>("");

	// Current content of the editor
	const currentContent = useRef<string>("");

	// Keep track of completed sentences that need analysis
	const completedSentencesQueue = useRef<Set<string>>(new Set());

	// Track sentences being edited and when they were last modified
	const sentencesBeingEdited = useRef<
		Map<
			number,
			{
				sentence: string;
				lastModified: number;
				originalSentence: string;
				isComplete: boolean;
			}
		>
	>(new Map());

	// Track the current active sentence index being edited
	const activeSentenceIndex = useRef<number | null>(null);

	// Track already analyzed sentences to prevent duplicate analysis
	const analyzedSentences = useRef<Set<string>>(new Set());

	// Add a new ref to track feedback IDs we've already shown
	const shownFeedbackIds = useRef<Set<string>>(new Set());

	// Function to analyze content and provide feedback
	const analyzeContent = useCallback(
		(content: string) => {
			// Skip if content hasn't changed
			if (!content || content === lastAnalyzedContent.current) {
				return;
			}

			// Update current content ref
			currentContent.current = content;

			// Get plain text versions for detailed comparison
			const currentText = getPlainTextFromHtml(content);
			const previousText = getPlainTextFromHtml(lastAnalyzedContent.current);

			// Extract sentences from both versions
			const currentResult = extractSentences(currentText);
			const previousResult = extractSentences(previousText);

			// Find the changed sentence and check if it's complete
			const {
				sentence: _changedSentence,
				isCompleteSentence: _isCompleteSentence,
				sentenceIndex,
			} = findChangedSentence(content, lastAnalyzedContent.current);

			// Detect if a line break was just added (which indicates the user is done with the current sentence)
			const lineBreakAdded = currentText.split("\n").length > previousText.split("\n").length;
			if (lineBreakAdded && activeSentenceIndex.current !== null) {
				// Force analysis of the currently active sentence as user has likely finished it
				if (sentencesBeingEdited.current.has(activeSentenceIndex.current)) {
					const activeEditInfo = sentencesBeingEdited.current.get(activeSentenceIndex.current)!;
					const activeSentence = activeEditInfo.sentence;

					// Only analyze if it's a substantial sentence that's changed from the original
					if (
						activeSentence &&
						activeSentence !== activeEditInfo.originalSentence &&
						!analyzedSentences.current.has(activeSentence)
					) {
						analyzedSentences.current.add(activeSentence);
						analyzeCompletedSentence(activeSentence);
					}
				}
			}

			// Update the last analyzed content
			lastAnalyzedContent.current = content;

			// Clear the previous timer if it exists
			if (debounceTimer.current) {
				clearTimeout(debounceTimer.current);
			}

			// Identify which sentence is being edited
			const editedSentenceIndex: number | null = sentenceIndex;

			// If we found an edited sentence
			if (
				editedSentenceIndex !== null &&
				editedSentenceIndex >= 0 &&
				editedSentenceIndex < currentResult.sentences.length
			) {
				const now = Date.now();
				const editedSentence = currentResult.sentences[editedSentenceIndex];
				const isEditedSentenceComplete = currentResult.isComplete[editedSentenceIndex];

				// Check if sentence just became complete with ending punctuation
				const wasIncomplete =
					previousResult.sentences.length > editedSentenceIndex
						? !previousResult.isComplete[editedSentenceIndex]
						: true;

				// Only consider actual punctuation endings (not line breaks)
				const hasPunctuationEnding = editedSentence.trim().match(/[.!?]['"]?$/);
				const justCompletedWithPunctuation = isEditedSentenceComplete && wasIncomplete && hasPunctuationEnding;

				if (justCompletedWithPunctuation) {
					// Only analyze if the sentence is substantial and hasn't been analyzed before
					if (editedSentence && !analyzedSentences.current.has(editedSentence)) {
						analyzedSentences.current.add(editedSentence);
						analyzeCompletedSentence(editedSentence);
					}
				}

				// If this is a new sentence being edited (user moved to edit a different sentence)
				if (activeSentenceIndex.current !== editedSentenceIndex) {
					// If we were previously editing a different sentence and it's complete, analyze it
					if (activeSentenceIndex.current !== null && sentencesBeingEdited.current.has(activeSentenceIndex.current)) {
						const prevEditedInfo = sentencesBeingEdited.current.get(activeSentenceIndex.current)!;
						const prevEditedSentence = prevEditedInfo.sentence;

						// Only analyze if the sentence is complete and has changed from original
						// and hasn't already been analyzed (e.g., from adding punctuation)
						if (
							prevEditedSentence &&
							prevEditedInfo.isComplete && // Use isComplete from tracking instead of checking again
							prevEditedSentence !== prevEditedInfo.originalSentence &&
							!analyzedSentences.current.has(prevEditedSentence)
						) {
							completedSentencesQueue.current.add(prevEditedSentence);
							// Process this sentence right away since the user has moved on from it
							analyzedSentences.current.add(prevEditedSentence);
							analyzeCompletedSentence(prevEditedSentence);
						}

						// Clear the previous sentence from being tracked
						sentencesBeingEdited.current.delete(activeSentenceIndex.current);
					}

					// Start tracking this new sentence
					const originalSentence =
						previousResult.sentences.length > editedSentenceIndex ? previousResult.sentences[editedSentenceIndex] : "";

					sentencesBeingEdited.current.set(editedSentenceIndex, {
						sentence: editedSentence,
						lastModified: now,
						originalSentence: originalSentence,
						isComplete: isEditedSentenceComplete,
					});
					activeSentenceIndex.current = editedSentenceIndex;
				} else {
					// Update the sentence and its last modified time
					const editInfo = sentencesBeingEdited.current.get(editedSentenceIndex);
					if (editInfo) {
						sentencesBeingEdited.current.set(editedSentenceIndex, {
							...editInfo,
							sentence: editedSentence,
							lastModified: now,
							isComplete: isEditedSentenceComplete,
						});
					}
				}

				// Add this line to call our new function
				if (editedSentence) {
					analyzeWordSpecificRules(editedSentence);
				}
			}

			// Removing immediate sentence processing - we only want to analyze when user moves to a different sentence
			// All analysis happens when the user changes which sentence they're editing

			// Remove the debounce timer - we no longer need it since we're only analyzing when the user changes the active sentence
			// This effectively disables the timed analysis and depends solely on the user moving between sentences
		},
		[coachOptions.debounceMs, coachOptions.maxToastsPerSession],
	);

	// Function to analyze a completed sentence and provide feedback
	const analyzeCompletedSentence = useCallback(
		(sentence: string) => {
			// Skip if we've already shown the maximum number of toasts
			// A value of 0 means unlimited toasts
			if (coachOptions.maxToastsPerSession !== 0 && toastsShownCount.current >= coachOptions.maxToastsPerSession!) {
				return;
			}

			// Run only sentence-specific rules and collect feedback
			const allFeedback: CoachFeedback[] = [];

			emailCoachRules.forEach(rule => {
				// Skip whole-document rules
				if (rule.id === "readability") {
					return;
				}

				// Create a fake HTML content with just the changed sentence
				// for rules that expect HTML
				const singleSentenceContent = `<p>${sentence}</p>`;
				const ruleFeedback = rule.analyze(singleSentenceContent);

				// Add the original text to each feedback item
				ruleFeedback.forEach(feedback => {
					feedback.originalText = sentence;
				});

				allFeedback.push(...ruleFeedback);
			});

			// Using all feedback regardless if it was shown before
			const newFeedback = allFeedback;

			// Display all feedback items instead of just the first one
			if (newFeedback.length > 0) {
				// Check if we have enough toast quota left
				const remainingToasts =
					coachOptions.maxToastsPerSession === 0
						? newFeedback.length
						: Math.min(newFeedback.length, coachOptions.maxToastsPerSession! - toastsShownCount.current);

				// Display each feedback item up to our remaining quota
				for (let i = 0; i < remainingToasts; i++) {
					// Pass the current content to displayCoachFeedback
					displayCoachFeedback(newFeedback[i], currentContent.current);
					toastsShownCount.current += 1;
				}
			}
		},
		[coachOptions.maxToastsPerSession],
	);

	// New function to check word-specific rules in real time
	const analyzeWordSpecificRules = useCallback(
		(sentence: string) => {
			// Skip if we've already shown the maximum number of toasts
			if (coachOptions.maxToastsPerSession !== 0 && toastsShownCount.current >= coachOptions.maxToastsPerSession!) {
				return;
			}

			// Only check for qualifiers and complex words rules for real-time feedback
			const wordSpecificFeedback: CoachFeedback[] = [];

			emailCoachRules.forEach(rule => {
				// Only process qualifier, complex words, and spam words rules for real-time feedback
				if (rule.id === "qualifiers" || rule.id === "complex-words" || rule.id === "spam-words") {
					// Check just the current sentence
					const singleSentenceContent = `<p>${sentence}</p>`;
					const ruleFeedback = rule.analyze(singleSentenceContent);

					// Add the original text to each feedback item
					ruleFeedback.forEach(feedback => {
						feedback.originalText = sentence;
					});

					wordSpecificFeedback.push(...ruleFeedback);
				}
			});

			// If we have word-specific feedback, show it immediately
			if (wordSpecificFeedback.length > 0) {
				// Calculate how many toasts we can still show
				const remainingToasts =
					coachOptions.maxToastsPerSession === 0
						? wordSpecificFeedback.length
						: Math.min(wordSpecificFeedback.length, coachOptions.maxToastsPerSession! - toastsShownCount.current);

				// Display each feedback item
				for (let i = 0; i < remainingToasts; i++) {
					// Check if this exact feedback has been shown recently
					const feedbackId = wordSpecificFeedback[i].id;

					// Only show if we haven't already shown this specific feedback
					if (!shownFeedbackIds.current.has(feedbackId)) {
						// Pass the current content to displayCoachFeedback
						displayCoachFeedback(wordSpecificFeedback[i], currentContent.current);
						shownFeedbackIds.current.add(feedbackId);
						toastsShownCount.current += 1;
					}
				}
			}
		},
		[coachOptions.maxToastsPerSession],
	);

	// Clean up the timer on unmount
	useEffect(() => {
		return () => {
			if (debounceTimer.current) {
				clearTimeout(debounceTimer.current);
			}
		};
	}, []);

	// Reset the coach (e.g., when switching to a new email)
	const resetCoach = useCallback(() => {
		toastsShownCount.current = 0;
		lastAnalyzedContent.current = "";
		analyzedSentences.current.clear();
		completedSentencesQueue.current.clear();
		shownFeedbackIds.current.clear(); // Clear shown feedback IDs
		clearCollectedFeedback(); // Clear collected feedback for alternative UX

		if (debounceTimer.current) {
			clearTimeout(debounceTimer.current);
			debounceTimer.current = null;
		}
	}, []);

	// Get the current content
	const getCurrentContent = useCallback(() => {
		return currentContent.current;
	}, []);

	return {
		analyzeContent,
		resetCoach,
		getCurrentContent, // Export the current content
	};
};
