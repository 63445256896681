import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Campaign } from "@za-zu/types";

type GlobalFilterStore = {
	searchText: string;
	selectedStatuses: Campaign["status"][] | null;
	setSearchText: (searchText: string) => void;
	setSelectedStatuses: (statuses: Campaign["status"][] | null) => void;
};

const useGlobalFilterStore = create<GlobalFilterStore>()(
	persist(
		set => ({
			searchText: "",
			selectedStatuses: null,
			setSearchText: searchText => set({ searchText }),
			setSelectedStatuses: selectedStatuses => set({ selectedStatuses }),
		}),
		{
			name: "global-filter-storage",
			version: 1,
		},
	),
);

export default useGlobalFilterStore;
