import { mergeAttributes, Node, NodeViewProps } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";

// The main NodeView for the ElseBlock node
function EndIfNodeView(props: NodeViewProps) {
	return (
		<NodeViewWrapper as="span" data-node-type={props.node.type.name} className="inline" contentEditable={false}>
			<span className="inline-flex h-6 min-w-6 items-center justify-center gap-1 rounded-r-md border-[0.5px] border-[#F4CDB6] bg-[#FFEFE6] px-1.5">
				<span className="inline text-[#F18039]">ENDIF</span>
			</span>
		</NodeViewWrapper>
	);
}

export const EndIfNode = Node.create({
	name: "endIfNode",
	inline: true,
	atom: true,
	group: "inline",
	// isolating: true,

	parseHTML() {
		// If you want to load from HTML, parse data-condition (JSON) back into an object
		return [
			{
				tag: `span[data-node-type="${this.name}"]`,
			},
		];
	},

	renderHTML() {
		return ["span", mergeAttributes({ "data-node-type": this.name })];
	},

	addNodeView() {
		return ReactNodeViewRenderer(EndIfNodeView);
	},
});
