import useCampaignStore from "@/store/useCampaignStore";
import useCustomFieldsStore from "@/store/useCustomFieldsStore";
import { CampaignProps } from "@/types/campaign";
import { getFailedImportMessage } from "@/utils/leads";
import uFuzzy from "@leeoniya/ufuzzy";
import { type TLeadWithCustomFieldValues } from "@za-zu/types";
import Papa from "papaparse";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "wouter";
import Icon from "../Icon";
import { TablePagination } from "../TablePagination/TablePagination";
import { Button } from "../ui/button";
import { Table, TableBody, TableHeader, TableHeaderRow, TableHead } from "../ui/table";
import SearchIcon from "@/assets/icons/svgs/search.svg?react";
import ArrowUpIcon from "@/assets/icons/svgs/arrow-up.svg?react";
import LeadDetailsModal from "./LeadDetailsModal";
import LeadsTableRows from "./LeadsTableRows";
import { CSVRow } from "../CSVUploader/types";
import { LeadsTableSkeleton } from "./LeadsTableSkeleton";

interface LeadsTableProps {
	campaign: CampaignProps;
	campaignLeads: Record<number, TLeadWithCustomFieldValues[]>;
	showAllLeads?: boolean;
}

type EXCLUDED_FIELDS = ["id", "organization_id", "created_by", "updated_at"];

const defaultHeaders: {
	key: keyof Omit<TLeadWithCustomFieldValues, EXCLUDED_FIELDS[number]> | "fullName";
	label: string;
}[] = [
	{ key: "fullName", label: "First & last name" },
	{ key: "email", label: "Email" },
	{ key: "linkedin", label: "LinkedIn" },
	{ key: "job_title", label: "Job Title" },
	{ key: "company", label: "Company" },
	{ key: "phone", label: "Phone" },
];

const LeadsTable = ({
	campaign,
	campaignLeads,
	// showAllLeads = false,
}: LeadsTableProps) => {
	const [_location, navigate] = useLocation();
	const acceptablePageSizes = [10, 25, 50, 100];
	const { fetchAndStorePageOfLeadsForCampaign, wrongSizedLeads } = useCampaignStore();
	const { customFields } = useCustomFieldsStore();
	const [searchQuery, setSearchQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState<number>(acceptablePageSizes[1]);
	const [selectedLead, setSelectedLead] = useState<TLeadWithCustomFieldValues | null>(null);

	const [displayedLeads, setDisplayedLeads] = useState<TLeadWithCustomFieldValues[]>([]);
	const uFuzzySearch = new uFuzzy({});

	const allLeads = useMemo(() => {
		return Object.values(campaignLeads).flat();
	}, [JSON.stringify(campaignLeads), customFields]);

	const uFuzzyHaystack: string[] = useMemo(() => {
		// Memoize the haystack to avoid re-calculating it on every render. It must be a string array so that uFuzzy can
		// search it. Making each string the relevant values for each lead keeps it simple and fast.
		const rootKeys = ["id", ...defaultHeaders.map(({ key }) => key)];
		const haystack: string[] = [];
		for (const lead of allLeads) {
			const chunksOfLead: Array<string | number | boolean> = [];
			for (const key of rootKeys) {
				const part = lead[key as keyof typeof lead];
				if (typeof part === "string") {
					chunksOfLead.push(part);
				}
			}
			const parts = lead.lead_custom_field_values.map(v => v.text_value ?? v.number_value ?? v.boolean_value);
			const filteredParts: Array<string | boolean | number> = [];
			for (const p of parts) {
				if (p !== null) {
					filteredParts.push(p);
				}
			}
			chunksOfLead.push(...filteredParts);
			haystack.push(chunksOfLead.join(" "));
		}
		return haystack;
	}, [allLeads, customFields]);

	const searchResults = useMemo(() => {
		if (!searchQuery) {
			return allLeads;
		}
		const idxs = uFuzzySearch.filter(uFuzzyHaystack, searchQuery);
		if (!idxs) return [];
		return idxs.map(idx => allLeads[idx]);
	}, [searchQuery, uFuzzyHaystack]);

	useEffect(() => {
		// Determine if we have the leads for the current page, and if not, fetch them.
		// The page increments available to the user all evenly multiply into 1000 so we can round up to the nearest
		// increment of 1000. Normally, we would round down, but the pages are 1-indexed, not 0-indexed, so what would
		// normally be a page at index 0 in an array, would be a page at index 1 in the UI. Since we are using a dict with
		// page numbers as keys (to know which pages have and haven't been fetched yet), we can rely on a 1-indexed system
		// as well in this logic.
		if (campaign.isLoadingLeads) return;
		/**
		 * The page number in our store we are looking for. The "pages" in the store are in sets of 1000 leads.
		 */
		const targetPageOf1000Leads: number = Math.ceil((currentPage * pageSize) / 1000);
		/**
		 * The page of leads we are looking for from our store.
		 *
		 * This could result in 1 of the following:
		 * 1. The page of leads is already in the store.
		 * 2. The page of leads is not in the store, so we need to fetch it.
		 * 3. The page of leads is in the store, but it's not full and we may need to fetch it again it the campaign record
		 * 	indicates that there are more leads than the page size.
		 */
		const pageOfLeads: TLeadWithCustomFieldValues[] | undefined = campaignLeads[targetPageOf1000Leads];
		if (pageOfLeads) {
			// We have the page of leads already, but we need to make sure it's filled out. It's possible that user imported
			// additional leads, and we may need to fetch a page again. So check the length of the page of leads and if it
			// reflects the number of leads we know the campaign has in total.

			if (pageOfLeads.length < 1000) {
				// We may not have all the leads for this page because it isn't full, so we need to determine if there are more
				// leads that could possibly be on this page. If there are, we need to fetch this page again. This can happen
				// if the user imported leads after the page was fetched.
				/**
				 * The amount of leads we have starting from page 1 up through the current page.
				 *
				 * NOTE: This assumes that we have the leads for every previous page as it's not important whether or not we do
				 * because of what this number will be used for.
				 *
				 * We need to calculate this number because if this number is less than the total number of leads that we
				 * know the campaign has, then we know that we need to fetch this page again.
				 */
				const totalLeadsToEndOfPage = (targetPageOf1000Leads - 1) * 1000 + pageOfLeads.length;
				if (totalLeadsToEndOfPage < campaign.hasLeads) {
					// We don't have all the leads for the current page so we need to fetch this page again.
					fetchAndStorePageOfLeadsForCampaign(campaign.id, targetPageOf1000Leads);
				} else {
					// We have all the leads for the current page so we can display them.
				}
			} else {
				// We have all the leads for the current page so we can display them.
			}
			// If we have all the leads for the current page, we can display them. But if we needed to refresh the page, this
			// code will be run again when campaignLeads is updated.
		} else {
			// We don't have the page of leads already so we need to fetch it. When this resolves, it will trigger a re-render
			// of the component and we will have the leads for the current page.
			fetchAndStorePageOfLeadsForCampaign(campaign.id, targetPageOf1000Leads);
		}
	}, [JSON.stringify(campaignLeads), campaign.hasLeads, campaign.isLoadingLeads, currentPage, pageSize]);

	useEffect(() => {
		setDisplayedLeads(searchResults.slice((currentPage - 1) * pageSize, currentPage * pageSize));
	}, [searchResults, currentPage, pageSize]);

	const headers = useMemo(() => {
		const headers: { key: string; label: string }[] = [
			...defaultHeaders,
			...customFields.map(field => ({ key: `custom.${field.id}`, label: field.name })),
		];
		return headers;
	}, [customFields]);

	const handleLeadUpdate = useCallback(() => {
		// mutate();
	}, []);
	// If searching, then we can base it on the search results, otherwise we can base it on the number the campaign shows.
	const displayTotalPages = useMemo(
		() => Math.ceil((searchQuery === "" ? campaign.hasLeads : searchResults.length) / pageSize),
		[searchQuery, campaign.hasLeads, searchResults, pageSize],
	);

	const handleRowClick = (lead: TLeadWithCustomFieldValues) => {
		setSelectedLead(lead);
	};

	const handleDownloadWrongSizedLeads = () => {
		if (!wrongSizedLeads) return;

		const csvContent = Papa.unparse({
			fields: Object.keys(wrongSizedLeads[0]),
			data: wrongSizedLeads,
		});

		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = "wrong_sized_leads.csv";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	};

	if (campaign.isLoadingLeads && displayedLeads.length === 0) {
		return <LeadsTableSkeleton />;
	}

	return (
		<div className="flex h-[calc(100vh-60px)] w-full flex-col">
			{wrongSizedLeads && wrongSizedLeads.length > 0 && (
				<div className="mb-6 flex items-center justify-between rounded-lg border border-yellow-200 bg-yellow-50 p-4">
					<span className="text-sm text-yellow-800">
						{getFailedImportMessage(wrongSizedLeads as Array<CSVRow & { _invalidField: string }>)}
					</span>
					<Button onClick={handleDownloadWrongSizedLeads} variant="secondary" className="flex items-center gap-2">
						<Icon name="FaDownload" className="h-4 w-4" />
						Download failed leads
					</Button>
				</div>
			)}
			<div className="flex items-center gap-2 px-2 py-3">
				<div className="relative flex-1">
					<div className="flex h-8 w-full items-center gap-2">
						<SearchIcon className="text-label-label-muted shrink-0" />
						<input
							type="text"
							className="outline-hidden placeholder:text-label-label-muted w-full bg-transparent text-sm"
							placeholder="Search leads..."
							value={searchQuery}
							onChange={e => setSearchQuery(e.target.value)}
						/>
					</div>
				</div>
				<Button variant="secondary" onClick={() => navigate(`/dashboard/campaigns/${campaign.id}/leads/import`)}>
					<ArrowUpIcon className="text-label-label-muted" />
					Import more
				</Button>
			</div>

			<div className="relative flex-1 overflow-hidden">
				<Table className="h-full">
					<TableHeader>
						<TableHeaderRow>
							{headers.map(header => (
								<TableHead key={header.key} label={header.label} className="min-w-[170px] whitespace-nowrap" />
							))}
						</TableHeaderRow>
					</TableHeader>
					<TableBody>
						<LeadsTableRows headers={headers} displayedLeads={displayedLeads} handleRowClick={handleRowClick} />
					</TableBody>
				</Table>
			</div>

			<div className="border-t border-gray-200 bg-white px-4 py-2">
				<div className="flex items-center justify-between">
					<div className="text-sm text-gray-500">
						Showing {(currentPage - 1) * pageSize + 1} to {Math.min(currentPage * pageSize, campaign.hasLeads)} of{" "}
						{campaign.hasLeads} leads
					</div>
					<TablePagination
						currentPage={currentPage}
						totalPages={displayTotalPages}
						onPageChange={setCurrentPage}
						pageSize={pageSize}
						acceptablePageSizes={acceptablePageSizes}
						onPageSizeChange={size => {
							setPageSize(size);
							setCurrentPage(1);
						}}
					/>
				</div>
			</div>

			<LeadDetailsModal
				campaign={campaign}
				lead={selectedLead}
				isOpen={!!selectedLead}
				onClose={() => setSelectedLead(null)}
				onUpdate={handleLeadUpdate}
			/>
		</div>
	);
};

export default LeadsTable;
