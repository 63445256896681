import { mergeAttributes, Node } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { useEffect, useRef, useState } from "react";
import { Variable } from "./types";

interface RightOperandNodeOptions {
	variables: Record<string, Variable>;
	defaultVariables: Variable[];
}

function RightOperandNodeView() {
	const inputRef = useRef<HTMLSpanElement>(null);
	const [placeholderSpan] = useState(() => {
		const span = document.createElement("span");
		span.className =
			"placeholder text-center font-[Geist] text-[13px] leading-[18px] text-[#AAA] text-[color(display-p3_0.6667_0.6667_0.6667)]";
		span.textContent = "Type value or press / to use variable";
		return span;
	});

	useEffect(() => {
		if (!inputRef.current) return;

		// Add placeholder initially
		inputRef.current.appendChild(placeholderSpan);

		// Clear placeholder when content is added
		const observer = new MutationObserver(mutations => {
			mutations.forEach(mutation => {
				if (mutation.type === "characterData" || mutation.type === "childList") {
					const content = inputRef.current?.textContent || "";
					if (content && content !== placeholderSpan.textContent) {
						placeholderSpan.style.display = "none";
					} else if (!content || content === placeholderSpan.textContent) {
						placeholderSpan.style.display = "";
					}
				}
			});
		});

		observer.observe(inputRef.current, {
			characterData: true,
			childList: true,
			subtree: true,
		});

		return () => observer.disconnect();
	}, [placeholderSpan]);

	// Handle focus/blur to manage placeholder
	const handleFocus = () => {
		if (!inputRef.current) return;
		if (!inputRef.current.textContent || inputRef.current.textContent === placeholderSpan.textContent) {
			inputRef.current.textContent = "";
		}
	};

	const handleBlur = () => {
		if (!inputRef.current?.textContent) {
			placeholderSpan.style.display = "";
		}
	};

	return (
		<NodeViewWrapper as="span" className="inline-flex items-center gap-1">
			<span
				ref={inputRef}
				className="inline-flex h-[24px] min-w-[24px] items-center justify-center gap-[4px] bg-[#F1F1F1] bg-[color(display-p3_0.9451_0.9451_0.9451)] px-[6px]"
				contentEditable={true}
				onFocus={handleFocus}
				onBlur={handleBlur}
			/>
			<span className="inline-flex h-6 min-w-6 items-center justify-center gap-1 rounded-r-md border-[0.5px] border-[#F4CDB6] border-[color(display-p3_0.9326_0.8096_0.7259)] bg-[#FFEFE6] bg-[color(display-p3_0.9922_0.9412_0.9059)] px-1.5">
				<span className="inline text-[#F18039] text-[color(display-p3_0.9451_0.5001_0.2235)]">THEN</span>
			</span>
		</NodeViewWrapper>
	);
}

export const RightOperandNode = Node.create<RightOperandNodeOptions>({
	name: "rightOperand",
	group: "inline",
	inline: true,
	content: "text* variable*",

	addOptions() {
		return {
			variables: {},
			defaultVariables: [],
		};
	},

	parseHTML() {
		return [
			{
				tag: `span[data-node-type="${this.name}"]`,
			},
		];
	},

	renderHTML({ HTMLAttributes }) {
		return ["span", mergeAttributes({ "data-node-type": this.name }, HTMLAttributes)];
	},

	addNodeView() {
		return ReactNodeViewRenderer(RightOperandNodeView);
	},
});
