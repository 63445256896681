import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";

interface ArchiveConfirmationModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	campaignName?: string;
	selectedCount?: number;
}

const ArchiveConfirmationModal = ({
	isOpen,
	onClose,
	onConfirm,
	campaignName,
	selectedCount,
}: ArchiveConfirmationModalProps) => {
	const isBulkArchive = selectedCount && selectedCount > 1;

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="p-4 sm:max-w-[400px]" style={{ backdropFilter: "blur(2px)" }}>
				<DialogHeader className="space-y-3">
					<DialogTitle className="text-[15px] font-medium leading-[22px] text-[#1A1A1A]">Archive campaign</DialogTitle>
					<DialogDescription className="py-1 text-[13px] leading-[18px] text-[#666666]">
						{isBulkArchive ? (
							`Are you sure you want to archive ${selectedCount} campaigns? This action cannot be undone.`
						) : (
							<>
								Are you sure you want to archive the campaign <span className="text-label-title">{campaignName}</span>?
								This action cannot be undone.
							</>
						)}
					</DialogDescription>
				</DialogHeader>
				<DialogFooter className="mt-6">
					<Button variant="ghost" onClick={onClose} className="h-8 px-3 text-sm">
						Cancel
					</Button>
					<Button variant="destructive" onClick={onConfirm} className="text-sm">
						{isBulkArchive ? `Archive ${selectedCount} campaigns` : "Archive campaign"}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default ArchiveConfirmationModal;
