import React from "react";
import NoMessages from "@/assets/images/empty-states/no-messages.png";

interface InboxZeroWithCampaignsProps {
	campaignCount?: number;
}

/**
 * Empty state shown when the user has campaigns but an empty inbox (inbox zero)
 */
export const InboxZeroWithCampaigns: React.FC<InboxZeroWithCampaignsProps> = ({ campaignCount = 0 }) => {
	return (
		<div className="flex h-full w-full flex-col items-center pt-32">
			<div className="h-32 w-32 overflow-hidden">
				<img src={NoMessages} alt="No Messages" className="h-full w-full object-contain" />
			</div>
			<div className="flex flex-col items-center gap-[10px] self-stretch">
				<h3 className="text-label-title font-geist text-center text-[20px] font-semibold leading-[27px]">
					A moment of clarity
				</h3>
				<p className="text-label-label-muted font-geist text-body-head leading-body-head max-w-91 text-center font-normal">
					Your inbox rests at zero. New replies will appear here instantly as{" "}
					{campaignCount === 1 ? "your campaign continues its work." : "your campaigns continue their work."}
				</p>
			</div>
		</div>
	);
};
