import React from "react";
import { Button } from "../ui/button";
import { Tooltip, TooltipTrigger, TooltipContent } from "../ui/tooltip";
import FileTextIcon from "@/assets/icons/svgs/file-text.svg?react";
import DoubleCheckmarkIcon from "@/assets/icons/svgs/double-checkmark.svg?react";
import ChevronSmallUpIcon from "@/assets/icons/svgs/chevron-small-up.svg?react";
import ArrowLeftIcon from "@/assets/icons/svgs/arrow-left.svg?react";
import LetterDotIcon from "@/assets/icons/svgs/letter-dot.svg?react";
import LetterCheckIcon from "@/assets/icons/svgs/letter-check.svg?react";

// Navigation-related props
type ThreadNavigationProps = {
	onCloseModal: () => void;
	onNavigateToAdjacentThread: (direction: "previous" | "next") => boolean;
};

// Thread status-related props
type ThreadStatusProps = {
	isUnread: boolean;
	toggleReadStatus: () => void;
	isArchived: boolean;
	onArchive: () => void;
	archivingThreadId: string | null;
	threadId: string;
};

// Display-related props
type ThreadDisplayProps = {
	isHeaderBlurred: boolean;
	subject: string;
	campaignName?: string;
};

export type EmailModalHeaderProps = ThreadNavigationProps & ThreadStatusProps & ThreadDisplayProps;

export const EmailModalHeader: React.FC<EmailModalHeaderProps> = ({
	// Navigation
	onCloseModal,
	onNavigateToAdjacentThread,
	// Thread status
	isUnread,
	toggleReadStatus,
	isArchived,
	onArchive,
	archivingThreadId,
	threadId,
	// Display
	isHeaderBlurred,
	subject,
	campaignName,
}) => {
	return (
		<div className={`sticky top-0 z-10 px-8 pb-6 pt-6 ${isHeaderBlurred ? "header-blur" : ""}`}>
			<div className="mb-6 flex items-center justify-between">
				<div className="flex items-center space-x-3">
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								className="shadow-low flex h-8 items-center justify-center gap-1.5 rounded-md border bg-white px-2 hover:bg-gray-100"
								onClick={onCloseModal}>
								<ArrowLeftIcon className="text-label-label-muted h-4 w-4" />
							</Button>
						</TooltipTrigger>
						<TooltipContent className="flex items-center gap-2">
							<span>Back</span>
							<kbd className="text-label-label-muted bg-background-bg-dim rounded px-2 py-0.5 text-xs">esc</kbd>
						</TooltipContent>
					</Tooltip>

					<div className="bg-background-bg-border h-4 w-0.5 rounded-full" />

					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								className="flex h-8 w-8 items-center justify-center border bg-white p-1 hover:bg-gray-100"
								onClick={() => onNavigateToAdjacentThread("previous")}>
								<ChevronSmallUpIcon className="text-label-label-muted h-4 w-4" />
							</Button>
						</TooltipTrigger>
						<TooltipContent className="flex items-center gap-2">
							<span>Previous Conversation</span>
							<kbd className="text-label-label-muted bg-background-bg-dim rounded px-2 py-0.5 text-xs">J</kbd>
						</TooltipContent>
					</Tooltip>

					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								className="flex h-8 w-8 items-center justify-center border bg-white p-1 hover:bg-gray-100"
								onClick={() => onNavigateToAdjacentThread("next")}>
								<ChevronSmallUpIcon className="text-label-label-muted h-4 w-4 rotate-180" />
							</Button>
						</TooltipTrigger>
						<TooltipContent className="flex items-center gap-2">
							<span>Next Conversation</span>
							<kbd className="text-label-label-muted bg-background-bg-dim rounded px-2 py-0.5 text-xs">K</kbd>
						</TooltipContent>
					</Tooltip>
				</div>

				<div className="flex items-center">
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								variant="inboxAction"
								onClick={onArchive}
								disabled={archivingThreadId === threadId}
								className="shadow-low flex h-8 w-8 items-center justify-center border bg-white p-1 hover:bg-gray-100">
								{archivingThreadId === threadId ? (
									<div className="h-4 w-4 rounded-full border-2 border-gray-300" />
								) : (
									<DoubleCheckmarkIcon
										width={14}
										height={14}
										className={isArchived ? "text-brand-primary" : "text-label-label-muted"}
									/>
								)}
							</Button>
						</TooltipTrigger>
						<TooltipContent side="top">
							{isArchived ? "Mark not Done" : "Mark Done "}
							<kbd>E</kbd>
						</TooltipContent>
					</Tooltip>

					<div className="bg-background-bg-border mx-3 h-4 w-0.5 rounded-full" />
					<div className="flex space-x-3">
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									className="flex h-8 w-8 items-center justify-center border bg-white p-1 hover:bg-gray-100"
									onClick={toggleReadStatus}>
									{isUnread ? (
										<LetterCheckIcon width={14} height={14} className="text-label-label-muted" />
									) : (
										<LetterDotIcon width={14} height={14} className="text-label-label-muted" />
									)}
								</Button>
							</TooltipTrigger>
							<TooltipContent className="flex items-center gap-2">
								<span>{isUnread ? "Mark Read" : "Mark Unread"}</span>
								<kbd className="text-label-label-muted bg-background-bg-dim rounded px-2 py-0.5 text-xs">U</kbd>
							</TooltipContent>
						</Tooltip>
					</div>
				</div>
			</div>
			<div className="flex flex-col items-start gap-3 self-stretch">
				{campaignName && (
					<div className="inline-flex items-center gap-1">
						<FileTextIcon width={16} height={16} className="text-label-link" />
						<span className="text-label-link text-campaign leading-campaign font-medium">{campaignName}</span>
					</div>
				)}
				<h2 className="self-stretch text-[20px] font-medium leading-[27px] text-black">{subject}</h2>
			</div>
		</div>
	);
};
