import { TableCell } from "@/components/ui/table";
import { cn } from "@/lib/utils";

function calculatePercentage(value: number, total: number): string {
	if (total === 0) return "-";
	const percentage = (value / total) * 100;
	// Format to 2 decimal places first
	const formatted = percentage.toFixed(2);
	// Remove unnecessary decimal places
	if (formatted.endsWith(".00")) {
		return Math.floor(percentage) + "%";
	}
	if (formatted.endsWith("0")) {
		return percentage.toFixed(1) + "%";
	}
	return formatted + "%";
}

interface MetricCellProps {
	value: number;
	total: number;
	isPercentage?: boolean;
	children?: React.ReactNode;
	className?: string;
	noBorder?: boolean;
	isRightmost?: boolean;
	noData?: boolean;
}

export function MetricCell({
	value,
	total,
	isPercentage,
	children,
	className,
	noBorder = false,
	isRightmost = false,
	noData = false,
}: MetricCellProps) {
	const cellClassName = cn("relative w-[11.67%]", className, isRightmost && "rightmost-cell");
	if ((noData || value === 0 || total === 0) && !isPercentage) {
		return (
			<TableCell className={cellClassName} align="left" noBorder={noBorder}>
				<span className="text-body-base text-label-title font-normal">-</span>
				{children}
			</TableCell>
		);
	}

	const percentage = calculatePercentage(value, total);
	return (
		<TableCell className={cellClassName} align="left" noBorder={noBorder}>
			{isPercentage ? (
				<span className="text-body-base text-label-title font-normal">{percentage}</span>
			) : (
				<div className="flex w-full items-center">
					<span className="text-body-base text-label-title font-normal">{value.toLocaleString()}</span>
					<span className="text-mini leading-mini text-label-muted ml-auto whitespace-nowrap font-normal">
						{percentage}
					</span>
				</div>
			)}
			{children}
		</TableCell>
	);
}
