import { useEffect, useRef, useState } from "react";
import SearchSVG from "@/assets/icons/svgs/search.svg?react";

type SearchBarProps = {
	searchText: string;
	setSearchText: (searchText: string) => void;
	placeholder?: string;
};

const SearchBar = ({ searchText, setSearchText, placeholder = "Search..." }: SearchBarProps) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [isFocused, setIsFocused] = useState(false);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			const activeElement = document.activeElement;
			const isInputField =
				activeElement instanceof HTMLInputElement ||
				activeElement instanceof HTMLTextAreaElement ||
				(activeElement as HTMLElement)?.isContentEditable;

			// Handle "/" key for focus
			if (event.key === "/" && !isInputField) {
				event.preventDefault();
				inputRef.current?.focus();
			}

			// Handle ESC key for blur
			if (event.key === "Escape" && activeElement === inputRef.current) {
				event.preventDefault();
				inputRef.current?.blur();
				setSearchText(""); // Optional: clear search text when escaping
			}
		};

		document.addEventListener("keydown", handleKeyDown);

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	return (
		<div className="flex h-8 w-full items-center gap-2 rounded-[6px] border-[0.5px] border-[#D9D9D9] bg-white px-2 shadow-[0px_1px_1px_0px_rgba(0,0,0,0.06),0px_4px_4px_-1px_rgba(0,0,0,0.02)]">
			<SearchSVG className="text-label-muted h-4 w-4 shrink-0" />
			<input
				ref={inputRef}
				type="text"
				placeholder={placeholder}
				value={searchText}
				onChange={e => setSearchText(e.target.value)}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				className="outline-hidden w-full bg-transparent text-sm"
			/>
			<div className={`${isFocused ? "w-[32px]" : "min-w-[16px]"}`}>
				<kbd>{isFocused ? "esc" : "/"}</kbd>
			</div>
		</div>
	);
};

export default SearchBar;
