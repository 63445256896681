import { AccountSlim } from "@za-zu/types/account";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { useFetchStore } from ".";
import { z } from "zod";
import { EmailAccountMembership } from "@za-zu/types";
import { toast } from "sonner";
import { RequestConflictError, RequestNotFoundError } from "@/hooks/useQuery";

/** A unique identifier for a pending change. Should be randomly generated. */
type EmailAccountMembershipAccountId = string;
type PendingEmailAccountMembershipChange = {
	email_account_id: EmailAccountMembershipAccountId;
	campaignId: string;
	accountId: string;
	action: "add" | "remove";
};
type EmailAccountId = string;
type PendingEmailAccountChange =
	| {
			id: EmailAccountId;
			action: "delete";
	  }
	| {
			id: "latest-accounts";
			action: "fetch";
	  };

type EmailAccountsStoreProps = {
	accounts: AccountSlim[];
	accountsById: Record<string, AccountSlim>;
	isLoading: boolean;
	fetchingAndInitializingEmailAccounts: boolean;
	emailAccountsInitialized: boolean;
	/**
	 * Null means we are still fetching. Undefined means we have not fetched yet.
	 */
	accountsByCampaignId: Record<string, EmailAccountMembership[] | null>;
	/**
	 * A map of pending changes to the email account membership changes that are pending. The key is the ID of the email
	 * account membership that has a pending change. The ID is deterministic and predictable based on the email account
	 * and campaign IDs even before its creation, so it is safe to assume.
	 */
	pendingMembershipChanges: Record<EmailAccountMembershipAccountId, PendingEmailAccountMembershipChange>;
	/**
	 * A set of email account membership IDs with pending changes that have been claimed by a function invocation. If an
	 * email account membership ID is "claimed", it means that the claimant function invocation is responsible for
	 * completing the change. If the user refreshes the page, or navigates to a different page, the pending change is no
	 * longer claimed because that claimant function invocation is no longer executing, and so cannot retry the pending
	 * change. However, the pending changes are persisted, so when the user comes back to the page, the pending change is
	 * no longer claimed and so zustand can kick off a new function invocation when it rehydrates to complete the pending
	 * changes that are still around.
	 */
	claimedPendingMembershipChanges: Set<EmailAccountMembershipAccountId>;
	/**
	 * A set of email account IDs with pending changes that haven't been resolved yet.
	 */
	pendingAccountChanges: Record<EmailAccountId, PendingEmailAccountChange>;
	/**
	 * A set of email account IDs with pending changes that have been claimed by a function invocation.
	 */
	claimedPendingAccountChanges: Set<EmailAccountId>;

	/**
	 * Attempt to complete a pending email account membership change. Use exponential backoff to retry the change until it
	 * is successful.
	 */
	attemptPendingMembershipChange: (emailAccountMembershipId: EmailAccountMembershipAccountId) => Promise<void>;
	/**
	 * Attempt to complete a pending account change. Use exponential backoff to retry the change until it is successful.
	 */
	attemptPendingAccountChange: (emailAccountId: EmailAccountId) => Promise<void>;
	setAccounts: (accounts: AccountSlim[]) => void;
	updateAccount: (account: AccountSlim) => void;
	syncWithLatest: (fetcher: () => Promise<{ data: AccountSlim[] }>) => Promise<void>;
	fetchAndInitializeLatestEmailAccounts: () => Promise<void>;
	fetchAndStoreAccountsByCampaignId: (campaignId: string) => Promise<void>;
	/**
	 * Returns true if the update was successful, false otherwise.
	 */
	updateEmailAccountMembershipsForCampaign: (
		campaignId: string,
		targetAccountIds: string[],
		silent?: boolean,
	) => Promise<boolean>;
	removeAccountFromCampaign: (campaignId: string, accountId: string) => Promise<void>;
	deleteAccount: (accountId: string) => Promise<void>;
	addPendingMembershipChange: (change: PendingEmailAccountMembershipChange) => void;
	addPendingAccountChange: (change: PendingEmailAccountChange) => void;
};

const transformAccount = (account: AccountSlim): AccountSlim => ({
	...account,
	sync_status: account.sync_status ?? "connected",
});

const useEmailAccountsStore = create<EmailAccountsStoreProps>()(
	persist(
		(set, get) => ({
			accounts: [],
			accountsById: {},
			isLoading: false,
			fetchingAndInitializingEmailAccounts: false,
			emailAccountsInitialized: false,
			accountsByCampaignId: {},
			pendingMembershipChanges: {},
			claimedPendingMembershipChanges: new Set(),
			pendingAccountChanges: {},
			claimedPendingAccountChanges: new Set(),
			addPendingMembershipChange: change =>
				set(state => ({
					pendingMembershipChanges: {
						...state.pendingMembershipChanges,
						[`${(change.email_account_id, change.campaignId)}`]: change,
					},
				})),
			addPendingAccountChange: change =>
				set(state => ({ pendingAccountChanges: { ...state.pendingAccountChanges, [change.id]: change } })),
			setAccounts: accounts => set({ accounts }),
			updateAccount: updatedAccount =>
				set(state => {
					const newAccountsById = { ...state.accountsById, [updatedAccount.id]: updatedAccount };
					return {
						...state,
						accountsById: newAccountsById,
						accounts: Object.values(newAccountsById),
					};
				}),
			syncWithLatest: async fetcher => {
				set({ isLoading: true });
				try {
					const response = await fetcher();
					const transformedAccounts = response.data.map(transformAccount);
					set({ accounts: transformedAccounts });
				} finally {
					set({ isLoading: false });
				}
			},
			fetchAndInitializeLatestEmailAccounts: async () => {
				const { emailAccountsInitialized, fetchingAndInitializingEmailAccounts } = get();

				if (emailAccountsInitialized || fetchingAndInitializingEmailAccounts) return;
				set({ fetchingAndInitializingEmailAccounts: true });
				get().addPendingAccountChange({
					id: "latest-accounts",
					action: "fetch",
				});
				return;
			},
			fetchAndStoreAccountsByCampaignId: async (campaignId: string) => {
				const { accountsByCampaignId } = get();
				if (accountsByCampaignId[campaignId] || accountsByCampaignId[campaignId] === null) return;
				const { authorizedFetch } = useFetchStore.getState();
				if (!authorizedFetch) return;
				// Mark that we are fetching
				set({ accountsByCampaignId: { ...accountsByCampaignId, [campaignId]: null } });
				const response = await authorizedFetch(
					`/email-account-memberships?campaign_id=${campaignId}`,
					z.object({ data: z.array(EmailAccountMembership) }),
				);
				set({ accountsByCampaignId: { ...accountsByCampaignId, [campaignId]: response.data } });
			},
			updateEmailAccountMembershipsForCampaign: async (
				campaignId: string,
				targetAccountIds: string[],
				silent = false,
			) => {
				const { apiClient } = useFetchStore.getState();
				const { accountsByCampaignId, accountsById } = get();
				const existingMemberships = accountsByCampaignId[campaignId];
				if (!existingMemberships) return false;

				// Figure out which accounts to add and which to remove
				const existingAccountIds = new Set(existingMemberships.map(m => m.email_account_id));
				/** The account IDs that we need to add */
				const accountIdsToAdd = targetAccountIds.filter(id => !existingAccountIds.has(id));
				/** The email account memberships that we need to remove */
				const emailAccountMembershipsToRemove = existingMemberships.filter(
					em => !targetAccountIds.includes(em.email_account_id),
				);
				const emailAccountMembershipEmailsToRemove = new Set(
					emailAccountMembershipsToRemove.map(em => em.email_account_id),
				);
				const newMemberships: EmailAccountMembership[] = accountIdsToAdd.map(accountId => ({
					email: accountsById[accountId].email,
					organization_id: accountsById[accountId].organization_id,
					email_account_id: accountId,
					campaign_id: campaignId,
					created_at: new Date().toISOString(),
				}));
				// Make a set of all the email account membership IDs that we need to change so we can claim them all at once
				// from this function invocation.
				const newClaims = new Set([
					...emailAccountMembershipEmailsToRemove,
					...newMemberships.map(em => em.email_account_id),
				]);

				const newPendingChanges: Record<EmailAccountMembershipAccountId, PendingEmailAccountMembershipChange> = {
					...Object.fromEntries(
						newMemberships.map(em => [
							em.email_account_id,
							{
								email_account_id: em.email_account_id,
								campaignId,
								accountId: em.email_account_id,
								action: "add",
							} satisfies PendingEmailAccountMembershipChange,
						]),
					),
					...Object.fromEntries(
						emailAccountMembershipsToRemove.map(em => [
							em.email_account_id,
							{
								email_account_id: em.email_account_id,
								campaignId,
								accountId: em.email_account_id,
								action: "remove",
							} satisfies PendingEmailAccountMembershipChange,
						]),
					),
				};

				// Optimistically update the state,
				set(prev => ({
					accountsByCampaignId: {
						...prev.accountsByCampaignId,
						[campaignId]: [
							...existingMemberships.filter(em => !emailAccountMembershipEmailsToRemove.has(em.email_account_id)),
							...newMemberships,
						],
					},
					// Combining with union is safe because even if something already claimed one of these, we won't be preventing
					// the other function invocation from completing the change, and we'll be updating the pending change to what
					// it should be in the end anyway. So it doesn't matter which invocation completes it first.
					claimedPendingMembershipChanges: prev.claimedPendingMembershipChanges.union(newClaims),
					// Update the pending changes to the new values
					pendingMembershipChanges: {
						...prev.pendingMembershipChanges,
						// Apply the new pending changes to the pending changes, overriding any common ones that already exist.
						...newPendingChanges,
					},
				}));
				if (!apiClient) return false;
				const results = await Promise.allSettled([
					...newMemberships.map(em => apiClient?.addEmailAccountMembershipForCampaign(em.email_account_id, campaignId)),
					...emailAccountMembershipsToRemove.map(em =>
						apiClient?.removeEmailAccountMembership(em.email_account_id, em.campaign_id),
					),
				]);

				// Now figure out which membership changes were successful and which were not and tie them to the email of the
				// membership.
				const combinedMemberships: EmailAccountMembership[] = [...existingMemberships, ...newMemberships];

				const failedMembershipIds: Set<EmailAccountMembershipAccountId> = new Set();
				const successfulMembershipIds: Set<EmailAccountMembershipAccountId> = new Set();
				for (let i = 0; i < results.length; i++) {
					const result = results[i];
					const membership = combinedMemberships[i];
					if (result.status === "fulfilled") {
						// The membership was updated successfully
						successfulMembershipIds.add(membership.email_account_id);
						continue;
					}
					const membershipChange = newPendingChanges[membership.email_account_id];
					if (membershipChange.action === "add") {
						if (result.reason instanceof RequestConflictError) {
							// The membership was successfully added
							successfulMembershipIds.add(membership.email_account_id);
							continue;
						}
						// The membership was not added successfully
						failedMembershipIds.add(membership.email_account_id);
					} else if (membershipChange.action === "remove") {
						if (result.reason instanceof RequestNotFoundError) {
							// The membership was removed successfully
							successfulMembershipIds.add(membership.email_account_id);
							continue;
						}
						// The membership was not removed successfully
						failedMembershipIds.add(membership.email_account_id);
					}
					console.error(
						`Failed to ${membershipChange.action} membership (${membership.email_account_id})`,
						result.reason,
					);
				}

				// Remove the successful membership changes from the pending changes and remove all the claims. DO NOT leave any
				// claims in the claims set because we want to defer to the next invocation to complete the changes that failed.
				// There is a useEffect in `/src/app/_app.tsx` that will be triggered when the claims set changes and will kick
				// off new invocations to complete the changes that failed if it detects any of the remaining pending changes
				// have no claims (which none of them will because we just cleared the claims for these changes).
				set(prev => ({
					pendingMembershipChanges: Object.fromEntries(
						Object.entries(prev.pendingMembershipChanges).filter(([key]) => !successfulMembershipIds.has(key)),
					),
					claimedPendingMembershipChanges: prev.claimedPendingMembershipChanges.difference(newClaims),
				}));
				if (failedMembershipIds.size > 0) {
					const { accountsById } = get();
					const failedMembershipEmailsString = [...failedMembershipIds].map(id => accountsById[id].email);
					toast.warning(
						`Failed to update some memberships, but we'll keep trying to save them. Failed email accounts: ${failedMembershipEmailsString.join(", ")}`,
					);
					return false;
				}

				// Only show the success toast if not silent
				if (!silent) {
					toast.success("Email accounts updated");
				}
				return true;
			},
			removeAccountFromCampaign: async (campaignId: string, accountId: string) => {
				const { accountsByCampaignId } = get();
				const accountsForCampaign = accountsByCampaignId[campaignId];
				if (!accountsForCampaign) {
					// Likely means we got here before we fetched the accounts for this campaign so the user should not be able to
					// target the account to remove it from the campaign in the first place.
					return;
				}
				const accountsWithoutAccount = accountsForCampaign.filter(a => a.email_account_id !== accountId);
				if (accountsWithoutAccount.length === accountsForCampaign.length) {
					// Likely means it was already removed
					return;
				}
				const { authorizedFetch } = useFetchStore.getState();
				if (!authorizedFetch) return;

				// Mark it as removed by removing it from the list of accounts for this campaign
				set({ accountsByCampaignId: { ...accountsByCampaignId, [campaignId]: accountsWithoutAccount } });
				await authorizedFetch(
					`/email-account-memberships/em_${campaignId.split("_")[1]}_${accountId.split("_")[1]}`,
					z.object({ data: z.object({ success: z.boolean() }) }),
					{
						method: "DELETE",
					},
				);
			},
			deleteAccount: async (accountId: string) => {
				const { accounts, accountsById, accountsByCampaignId } = get();
				const account = accountsById[accountId];
				if (!account) {
					// Likely means it was already removed
					return;
				}
				const accountsWithoutAccount = accounts.filter(a => a.id !== accountId);
				if (accountsWithoutAccount.length === accounts.length) {
					// Likely means it was already removed
					return;
				}
				const { apiClient } = useFetchStore.getState();
				if (!apiClient) return;

				// Store the previous state for rollback if needed
				const previousState = {
					accounts,
					accountsById,
					accountsByCampaignId,
					pendingMembershipChanges: get().pendingMembershipChanges,
				};

				try {
					// Optimistically update the UI first
					set(prev => ({
						accounts: accountsWithoutAccount,
						accountsById: {
							...accounts.reduce(
								(acc, account) => {
									acc[account.id] = account;
									return acc;
								},
								{} as Record<string, AccountSlim>,
							),
						},
						accountsByCampaignId: {
							...Object.fromEntries(
								Object.entries(accountsByCampaignId).map(([campaignId, accounts]) => [
									campaignId,
									accounts ? accounts.filter(a => a.email_account_id !== accountId) : null,
								]),
							),
						},
						pendingMembershipChanges: Object.fromEntries(
							Object.entries(prev.pendingMembershipChanges).filter(([_key, { accountId: aId }]) => aId !== accountId),
						),
					}));

					// Show immediate feedback
					toast.success("Account deleted");

					// Add the account to pending changes
					get().addPendingAccountChange({
						id: accountId,
						action: "delete",
					});

					// Attempt the actual deletion in the background
					await get().attemptPendingAccountChange(accountId);
				} catch (error) {
					console.error("Failed to delete email account:", error);
					toast.error("Failed to delete email account");

					// Revert to previous state if the deletion failed
					set(() => ({
						accounts: previousState.accounts,
						accountsById: previousState.accountsById,
						accountsByCampaignId: previousState.accountsByCampaignId,
						pendingMembershipChanges: previousState.pendingMembershipChanges,
					}));
				}
			},
			attemptPendingMembershipChange: async (emailAccountMembershipId: EmailAccountMembershipAccountId) => {
				let unresolved = true;
				let attempts = 0;
				const { claimedPendingMembershipChanges } = get();
				if (claimedPendingMembershipChanges.has(emailAccountMembershipId)) {
					// This change is already claimed by another function invocation so we don't need to do anything.
					return;
				}
				set(prev => ({
					claimedPendingMembershipChanges: prev.claimedPendingMembershipChanges.union(
						new Set([emailAccountMembershipId]),
					),
				}));
				const attempt = async (): Promise<void> => {
					// Always use the latest of each of these values because they could be changing. For example, if a user
					// marked a membership to be removed but then the user marked it to be added again before we had a chance to
					// remove it, we would want to use the latest values, we would want to make sure we don't remove it.
					const { apiClient } = useFetchStore.getState();
					if (!apiClient) {
						throw new Error("No API client found");
					}
					const { pendingMembershipChanges } = get();

					const change = pendingMembershipChanges[emailAccountMembershipId];
					if (!change) {
						// This change is no longer in the state so we don't need to do anything. This likely means that the change
						// was completed already.
						return;
					}
					try {
						switch (change.action) {
							case "add":
								await apiClient.addEmailAccountMembershipForCampaign(change.accountId, change.campaignId);
								break;
							case "remove":
								await apiClient.removeEmailAccountMembership(change.accountId, change.campaignId);
								break;
						}
						// No error means the change was completed successfully
					} catch (error) {
						switch (change.action) {
							case "add":
								if (error instanceof RequestConflictError) {
									// The account is already in the campaign so we don't need to do anything.
									return;
								}
								break;
							case "remove":
								if (error instanceof RequestNotFoundError) {
									// The account is not in the campaign so we don't need to do anything.
									return;
								}
								break;
						}
						throw error;
					}
				};
				while (unresolved) {
					try {
						await attempt();
						// If we get here, we know that the change was completed successfully because the function would have
						// thrown an error if it had failed for one reason or another.
						unresolved = false;
					} catch {
						// Implement exponential backoff
						const backoff = 1000;
						// Ten minutes
						const maxBackoff = 600000;
						const jitter = Math.random() * 1000;
						const delay = Math.min((backoff + jitter) * Math.pow(2, attempts), maxBackoff);
						await new Promise(resolve => setTimeout(resolve, delay));
					}
					// Increase the number of attempts so each subsequent attempt is delayed longer
					attempts++;
				}
				// Remove the change from the state. This is safe to do even if this invocation is not the one that completed
				// the change because we only care that the change is completed, not which invocation completed it. There is no
				// concern for race conditions because we are only filtering out the change from the state, rather that deleting
				// it directly. Similarly, for the claims, we only need to do a difference operation on the set of claimed
				// changes instead of trying to remove it from the set of claimed changes directly.
				const changeSet = new Set([emailAccountMembershipId]);
				set(prev => ({
					pendingMembershipChanges: Object.fromEntries(
						Object.entries(prev.pendingMembershipChanges).filter(([key]) => key !== emailAccountMembershipId),
					),
					claimedPendingMembershipChanges: prev.claimedPendingMembershipChanges.difference(changeSet),
				}));
			},
			attemptPendingAccountChange: async (emailAccountId: EmailAccountId) => {
				let unresolved = true;
				let attempts = 0;
				const { claimedPendingAccountChanges } = get();
				if (claimedPendingAccountChanges.has(emailAccountId)) {
					// This change is already claimed by another function invocation so we don't need to do anything.
					return;
				}
				set(prev => ({
					claimedPendingAccountChanges: prev.claimedPendingAccountChanges.union(new Set([emailAccountId])),
				}));
				const attempt = async (): Promise<void> => {
					// Always use the latest of each of these values because they could be changing. For example, if a user
					// marked a membership to be removed but then the user marked it to be added again before we had a chance to
					// remove it, we would want to use the latest values, we would want to make sure we don't remove it.
					const { apiClient } = useFetchStore.getState();
					if (!apiClient) {
						throw new Error("No API client found");
					}
					const { pendingAccountChanges } = get();

					const change = pendingAccountChanges[emailAccountId];
					if (!change) {
						// This change is no longer in the state so we don't need to do anything. This likely means that the change
						// was completed already.
						return;
					}
					try {
						switch (change.action) {
							case "delete":
								await apiClient.deleteAccount(change.id);
								break;
							case "fetch": {
								try {
									const result = await apiClient.getLatestAccounts();

									set({
										accounts: result,
										accountsById: result.reduce(
											(acc, account) => {
												acc[account.id] = account;
												return acc;
											},
											{} as Record<string, AccountSlim>,
										),
										emailAccountsInitialized: true,
										fetchingAndInitializingEmailAccounts: false,
									});
									return;
								} catch (error) {
									console.error("Failed to fetch email accounts:", error);
									// Always set initialized to true even if fetch fails
									// This ensures UI doesn't get stuck in loading state
									set({
										emailAccountsInitialized: true,
										fetchingAndInitializingEmailAccounts: false,
									});
									throw error;
								}
							}
						}
						// No error means the change was completed successfully
					} catch (error) {
						switch (change.action) {
							case "delete":
								if (error instanceof RequestNotFoundError) {
									// The account is not in the campaign so we don't need to do anything.
									return;
								}
								break;
						}
						throw error;
					}
				};
				while (unresolved) {
					try {
						await attempt();
						// If we get here, we know that the change was completed successfully because the function would have
						// thrown an error if it had failed for one reason or another.
						unresolved = false;
					} catch {
						// Implement exponential backoff
						const backoff = 1000;
						// Ten minutes
						const maxBackoff = 600000;
						const jitter = Math.random() * 1000;
						const delay = Math.min((backoff + jitter) * Math.pow(2, attempts), maxBackoff);
						await new Promise(resolve => setTimeout(resolve, delay));
					}
					// Increase the number of attempts so each subsequent attempt is delayed longer
					attempts++;
				}
				// Remove the change from the state. This is safe to do even if this invocation is not the one that completed
				// the change because we only care that the change is completed, not which invocation completed it. There is no
				// concern for race conditions because we are only filtering out the change from the state, rather that deleting
				// it directly. Similarly, for the claims, we only need to do a difference operation on the set of claimed
				// changes instead of trying to remove it from the set of claimed changes directly.
				const changeSet = new Set([emailAccountId]);
				set(prev => ({
					pendingAccountChanges: Object.fromEntries(
						Object.entries(prev.pendingAccountChanges).filter(([key]) => key !== emailAccountId),
					),
					claimedPendingAccountChanges: prev.claimedPendingAccountChanges.difference(changeSet),
				}));
			},
		}),
		{
			name: "email-accounts-storage",
			version: 10,
			/**
			 * We don't want to persist the claims to the pending changes because we want them to be claimable
			 */
			partialize: state =>
				Object.fromEntries(
					Object.entries(state).filter(
						([key]) =>
							![
								"addPendingMembershipChange",
								"addPendingAccountChange",
								"claimedPendingMembershipChanges",
								"claimedPendingAccountChanges",
							].includes(key),
					),
				),
		},
	),
);

export default useEmailAccountsStore;
