import { Input } from "@/components/ui/input";
import Fuse from "fuse.js";
import { useEffect, useRef, useState } from "react";
import Tippy from "tippy.js";
import type { Instance as TippyInstance } from "tippy.js";
import { Variable } from "./types";
import Brackets from "@/assets/icons/svgs/brackets.svg?react";
import Info from "@/assets/icons/svgs/info.svg?react";
import { createRoot } from "react-dom/client";

interface Props {
	variableFuse: Fuse<Variable>;
	defaultVariables: Variable[];
	onSelect: (variable: Variable) => void;
	selectedVariable?: Variable;
}

export function VariableSelector({ variableFuse, defaultVariables, onSelect, selectedVariable }: Props) {
	const [isEditing, setIsEditing] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [searchResults, setSearchResults] = useState<Variable[]>(defaultVariables);

	const containerRef = useRef<HTMLSpanElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const popupRef = useRef<TippyInstance[]>();
	const rootRef = useRef<ReturnType<typeof createRoot>>();

	// Update search results when search text changes
	useEffect(() => {
		if (searchText === "") {
			setSearchResults(defaultVariables);
		} else {
			setSearchResults(variableFuse.search(searchText).map(result => result.item));
		}
	}, [searchText, variableFuse, defaultVariables]);

	// Handle showing/hiding the results popup
	useEffect(() => {
		if (!isEditing) {
			popupRef.current?.[0]?.destroy();
			rootRef.current?.unmount();
			return;
		}

		if (!containerRef.current) return;

		popupRef.current = Tippy("body", {
			theme: "customtippy",
			getReferenceClientRect: () =>
				containerRef.current?.getBoundingClientRect() ?? document.body.getBoundingClientRect(),
			appendTo: () => document.body,
			content: createResultsList(),
			showOnCreate: true,
			interactive: true,
			trigger: "manual",
			arrow: false,
			placement: "bottom-start",
		});

		return () => {
			popupRef.current?.[0]?.destroy();
			rootRef.current?.unmount();
		};
	}, [isEditing, searchResults]);

	// Focus input when entering edit mode
	useEffect(() => {
		if (isEditing) {
			inputRef.current?.focus();
		}
	}, [isEditing]);

	const createResultsList = () => {
		const element = document.createElement("div");
		element.className =
			"slash-menu border-background-bg-border bg-background-bg-base shadow-float flex w-[248px] flex-col items-start overflow-hidden rounded-[10px] border-[0.5px]";

		const content = (
			<div className="w-full">
				{searchResults.map(variable => (
					<div
						key={variable.id}
						className="outline-hidden flex w-full items-center bg-white p-1"
						onClick={() => {
							onSelect(variable);
							setIsEditing(false);
						}}>
						<button className="hover:bg-background-bg-base-hover outline-hidden flex h-9 w-full items-center gap-3 rounded-md px-3">
							<Brackets className="h-4 w-4 text-[#397AF1]" />
							<span className="text-body-base leading-body-base text-label-label-base flex-1 text-left">
								{variable.name}
							</span>
						</button>
					</div>
				))}
				{searchResults.length > 0 && <div className="border-background-bg-border border-b-[0.5px]" />}
				{searchText === "" && (
					<div className="flex w-full items-center bg-white p-1">
						<div className="flex h-9 w-full items-center gap-3 px-3">
							<Info className="text-label-label-muted h-4 w-4" />
							<span className="text-body-base leading-body-base text-label-label-muted">
								Type to search variables...
							</span>
						</div>
					</div>
				)}
				{searchResults.length === 0 && searchText !== "" && (
					<div className="outline-hidden flex w-full items-center bg-white p-1">
						<div className="hover:bg-background-bg-base-hover outline-hidden flex h-9 w-full items-center gap-3 rounded-md px-3">
							<Info className="text-label-label-muted h-4 w-4" />
							<span className="text-body-base leading-body-base text-label-label-muted">No results found</span>
						</div>
					</div>
				)}
			</div>
		);

		rootRef.current = createRoot(element);
		rootRef.current.render(content);
		return element;
	};

	return (
		<span
			ref={containerRef}
			className="shadow-custom-low inline-flex h-6 min-w-6 cursor-pointer items-center justify-center gap-1 border-[0.5px] border-[#F4CDB6] bg-[#FADBCA] px-1.5"
			onClick={() => !isEditing && setIsEditing(true)}>
			<Brackets className="h-4 w-4 text-orange-500" />
			{isEditing ? (
				<Input
					ref={inputRef}
					type="text"
					variant="naked"
					value={searchText}
					onChange={e => setSearchText(e.target.value)}
					onBlur={() => {
						// Small delay to allow click events on results to fire
						setTimeout(() => setIsEditing(false), 200);
					}}
					className="text-body-base leading-body-base h-4 w-full bg-transparent p-0 font-medium text-orange-500 placeholder:text-orange-500 placeholder:opacity-100"
					placeholder=""
				/>
			) : (
				<span className="text-body-base leading-body-base flex-1 font-medium text-orange-500">
					{selectedVariable ? selectedVariable.name : "select variable"}
				</span>
			)}
		</span>
	);
}
