import { RequestConfig } from "@/hooks/useQuery";
import { z } from "zod";
import { ApiClient } from "@/lib/ApiClient";
import { create } from "zustand";

type UseFetchStoreProps = {
	authorizedFetch?: <T>(url: string, zodSchema: z.ZodSchema<T>, config?: RequestConfig) => Promise<T>;
	apiClient?: ApiClient;
	setAuthorizedFetch: (
		fetch: <T>(url: string, zodSchema: z.ZodSchema<T>, config?: RequestConfig) => Promise<T>,
	) => void;
	setApiClient: (apiClient: ApiClient) => void;
};

const useFetchStore = create<UseFetchStoreProps>()(set => ({
	authorizedFetch: undefined,
	apiClient: undefined,
	setAuthorizedFetch: (fetch: <T>(url: string, zodSchema: z.ZodSchema<T>, config?: RequestConfig) => Promise<T>) => {
		set({ authorizedFetch: fetch });
	},
	setApiClient: (apiClient: ApiClient) => {
		set({ apiClient });
	},
}));

export default useFetchStore;
