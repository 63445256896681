import React from "react";

interface DynamicWarmingSVGProps {
	warmthScore: number;
	className?: string;
}

const DynamicWarmingSVG: React.FC<DynamicWarmingSVGProps> = ({ warmthScore, className }) => {
	// Define the colors
	const activeColor = "#34C759"; // Green
	const inactiveColor = "#D9D9D9"; // Gray

	// Map warmth score (0-1) to ray count (1-7)
	// 0 warmth → 1 ray lit (top ray)
	// 1 warmth → all 7 rays lit (we reserve the 8th ray for Ready state with zap icon)
	const rayCount = warmthScore <= 0 ? 1 : Math.min(Math.ceil(warmthScore * 7), 7);

	// Define the clockwise order of rays (index 0 is Top, then moving clockwise)
	// For each ray, determine if it should be lit based on its position in the clockwise sequence
	const isRayActive = (clockwisePosition: number) => {
		return clockwisePosition < rayCount;
	};

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="14"
			viewBox="0 0 15 14"
			fill="none"
			className={className}>
			{/* Ray 1 - Top (position 0 in clockwise order) */}
			<path
				d="M6.28016 2.002C6.10783 1.27819 6.65667 0.583328 7.40072 0.583328C8.14476 0.583328 8.69361 1.27819 8.52127 2.002L7.99942 4.19377C7.93341 4.47101 7.6857 4.66666 7.40072 4.66666C7.11573 4.66666 6.86802 4.47101 6.80201 4.19377L6.28016 2.002Z"
				fill={isRayActive(0) ? activeColor : inactiveColor}
				style={{ fill: isRayActive(0) ? activeColor : inactiveColor, fillOpacity: 1 }}
			/>

			{/* Ray 8 - Top-Right (position 1 in clockwise order) */}
			<path
				d="M11.3409 4.28471C11.8506 3.98964 11.9421 3.29163 11.5256 2.87521C11.1092 2.45878 10.4112 2.55025 10.1161 3.05991L9.38309 4.32606C9.26819 4.52453 9.30107 4.77544 9.46323 4.9376C9.62539 5.09976 9.87631 5.13264 10.0748 5.01774L11.3409 4.28471Z"
				fill={isRayActive(1) ? activeColor : inactiveColor}
				style={{ fill: isRayActive(1) ? activeColor : inactiveColor, fillOpacity: 1 }}
			/>

			{/* Ray 3 - Right (position 2 in clockwise order) */}
			<path
				d="M12.5442 5.73361C13.268 5.56127 13.9629 6.11012 13.9629 6.85416C13.9629 7.59821 13.268 8.14705 12.5442 7.97472L10.3524 7.45287C10.0752 7.38686 9.87956 7.13915 9.87956 6.85416C9.87956 6.56918 10.0752 6.32147 10.3524 6.25546L12.5442 5.73361Z"
				fill={isRayActive(2) ? activeColor : inactiveColor}
				style={{ fill: isRayActive(2) ? activeColor : inactiveColor, fillOpacity: 1 }}
			/>

			{/* Ray 6 - Bottom-Right (position 3 in clockwise order) */}
			<path
				d="M10.1157 10.9401C10.4107 11.4498 11.1087 11.5412 11.5252 11.1248C11.9416 10.7084 11.8501 10.0104 11.3404 9.71529L10.0743 8.98226C9.87583 8.86736 9.62492 8.90024 9.46276 9.0624C9.3006 9.22456 9.26772 9.47548 9.38262 9.67394L10.1157 10.9401Z"
				fill={isRayActive(3) ? activeColor : inactiveColor}
				style={{ fill: isRayActive(3) ? activeColor : inactiveColor, fillOpacity: 1 }}
			/>

			{/* Ray 2 - Bottom (position 4 in clockwise order) */}
			<path
				d="M6.57118 11.998C6.39884 12.7218 6.94769 13.4167 7.69173 13.4167C8.43578 13.4167 8.98462 12.7218 8.81229 11.998L8.29044 9.80623C8.22443 9.52899 7.97672 9.33334 7.69173 9.33334C7.40675 9.33334 7.15904 9.52899 7.09303 9.80623L6.57118 11.998Z"
				fill={isRayActive(4) ? activeColor : inactiveColor}
				style={{ fill: isRayActive(4) ? activeColor : inactiveColor, fillOpacity: 1 }}
			/>

			{/* Ray 7 - Bottom-Left (position 5 in clockwise order) */}
			<path
				d="M4.68513 10.9401C4.39006 11.4498 3.69205 11.5412 3.27563 11.1248C2.8592 10.7084 2.95067 10.0104 3.46033 9.71529L4.72648 8.98226C4.92495 8.86736 5.17586 8.90024 5.33802 9.0624C5.50018 9.22456 5.53306 9.47548 5.41816 9.67394L4.68513 10.9401Z"
				fill={isRayActive(5) ? activeColor : inactiveColor}
				style={{ fill: isRayActive(5) ? activeColor : inactiveColor, fillOpacity: 1 }}
			/>

			{/* Ray 4 - Left (position 6 in clockwise order) */}
			<path
				d="M2.54856 6.02528C1.82475 5.85294 1.12988 6.40179 1.12988 7.14583C1.12988 7.88988 1.82475 8.43872 2.54856 8.26639L4.74032 7.74454C5.01756 7.67853 5.21322 7.43082 5.21322 7.14583C5.21322 6.86085 5.01756 6.61314 4.74032 6.54713L2.54856 6.02528Z"
				fill={isRayActive(6) ? activeColor : inactiveColor}
				style={{ fill: isRayActive(6) ? activeColor : inactiveColor, fillOpacity: 1 }}
			/>

			{/* Ray 5 - Top-Left (position 7 in clockwise order) */}
			<path
				d="M3.45986 4.28471C2.9502 3.98964 2.85873 3.29163 3.27515 2.87521C3.69158 2.45878 4.38959 2.55025 4.68465 3.05991L5.41769 4.32606C5.53259 4.52453 5.49971 4.77544 5.33755 4.9376C5.17539 5.09976 4.92447 5.13264 4.72601 5.01774L3.45986 4.28471Z"
				fill={isRayActive(7) ? activeColor : inactiveColor}
				style={{ fill: isRayActive(7) ? activeColor : inactiveColor, fillOpacity: 1 }}
			/>
		</svg>
	);
};

export default DynamicWarmingSVG;
