import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useState, useEffect } from "react";
import { Combobox } from "../ui/combobox";
import useFetchStore from "@/store/fetchStore";
import type { TClientRow } from "@za-zu/types";

interface NameCampaignDialogProps {
	isOpen: boolean;
	onOpenChange: (open: boolean) => void;
	onSubmit: (name: string, clientId: string | null) => void;
}

export const NameCampaignDialog = ({ isOpen, onOpenChange, onSubmit }: NameCampaignDialogProps) => {
	const [campaignName, setCampaignName] = useState("");
	const [selectedClientId, setSelectedClientId] = useState("");
	const [clients, setClients] = useState<TClientRow[]>([]);
	const { apiClient } = useFetchStore();

	useEffect(() => {
		const fetchClients = async () => {
			if (!apiClient) return;
			try {
				const data = await apiClient.getClients();
				setClients(data);
			} catch (error) {
				console.error("Error fetching clients:", error);
			}
		};

		if (isOpen) {
			fetchClients();
		}
	}, [isOpen, apiClient]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (campaignName.trim()) {
			// If there are no clients, pass null as the clientId
			onSubmit(campaignName.trim(), clients.length > 0 ? selectedClientId : null);
			setCampaignName("");
			setSelectedClientId("");
		}
	};

	const clientOptions = clients.map(client => ({
		label: client.name,
		value: client.id,
	}));

	return (
		<Dialog open={isOpen} onOpenChange={onOpenChange}>
			<DialogContent className="sm:max-w-[430px]" aria-describedby={undefined}>
				<DialogHeader>
					<DialogTitle className="pb-4">Name Your Campaign</DialogTitle>
				</DialogHeader>
				<form onSubmit={handleSubmit} className="flex flex-col gap-4">
					<Input
						value={campaignName}
						onChange={e => setCampaignName(e.target.value)}
						autoFocus
						placeholder="Enter campaign name..."
					/>
					{clients.length > 0 && (
						<Combobox
							options={clientOptions}
							onChange={value => setSelectedClientId(value)}
							value={selectedClientId}
							placeholder="Select a client"
							emptyMessage="No clients found"
							className="w-full"
						/>
					)}
					<div className="flex justify-end">
						<Button type="submit" disabled={!campaignName.trim()} className="w-fit px-2.5">
							Create Campaign
						</Button>
					</div>
				</form>
			</DialogContent>
		</Dialog>
	);
};
