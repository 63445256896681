import { BracketsSVG } from "@/assets/icons/svgs";
import { CustomField } from "@za-zu/types";
import { CommandItem } from "../types";

export function customFieldCommandItemBuilder(customField: CustomField.CustomField): CommandItem {
	return {
		title: customField.name,
		icon: <BracketsSVG className="h-4 w-4 text-[#397af1]" />,
		command: ({ editor, range }) => {
			editor.commands.deleteRange(range);
			editor.commands.setCustomVariable(customField.id);
		},
	};
}
