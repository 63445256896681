import { Node } from "@tiptap/core";

export const InactiveSlashNode = Node.create({
	name: "inactiveSlash",
	group: "inline",
	inline: true,
	atom: true,
	renderText() {
		return "/";
	},

	parseHTML() {
		return [
			{
				tag: `span[data-node-type="${this.name}"]`,
			},
		];
	},

	renderHTML() {
		return [
			"span",
			{
				"data-node-type": this.name,
			},
			"/",
		];
	},
});
