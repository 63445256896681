import React from "react";
import { Button } from "@/components/ui/button";
import RepliesOpenTicket from "@/assets/images/empty-states/replies-open-ticket.png";

interface InboxZeroNoCampaignsProps {
	onCreateCampaignClick: () => void;
}

/**
 * Empty state shown when the user has no campaigns and an empty inbox
 */
export const InboxZeroNoCampaigns: React.FC<InboxZeroNoCampaignsProps> = ({ onCreateCampaignClick }) => {
	return (
		<div className="flex h-full w-full flex-col items-center pt-32">
			<div className="h-32 w-32 overflow-hidden">
				<img src={RepliesOpenTicket} alt="Replies Open Ticket" className="h-full w-full object-contain" />
			</div>
			<div className="flex flex-col items-center gap-[10px] self-stretch">
				<h3 className="text-label-title font-geist text-center text-[20px] font-semibold leading-[27px]">
					A quiet inbox, full of potential
				</h3>
				<p className="text-label-label-muted font-geist text-body-head leading-body-head max-w-92 text-center font-normal">
					Launch a campaign— then every response will flow here, instantly. The conversation begins with you.
				</p>
			</div>
			<Button variant="outline" className="mt-6" onClick={onCreateCampaignClick}>
				Create campaign
			</Button>
		</div>
	);
};
