/**
 * Transforms the HTML for the backend.
 *
 * @param html - The HTML to transform.
 * @param customFieldsTrackingIdMap - A map of custom field tracking IDs to their IDs.
 * @returns The transformed HTML.
 */
export function transformHtmlForBackend(html: string, customFieldsTrackingIdMap: Record<string, string>): string {
	const div = document.createElement("div");
	div.innerHTML = html;

	// Clean up links - keep only href and target="_blank"
	div.querySelectorAll("a").forEach(anchor => {
		const href = anchor.getAttribute("href");
		const textContent = anchor.textContent;

		const cleanAnchor = document.createElement("a");
		cleanAnchor.href = href || "";
		cleanAnchor.target = "_blank";
		cleanAnchor.textContent = textContent || "";

		// Remove all attributes except href and target
		Array.from(anchor.attributes).forEach(attr => {
			if (attr.name !== "href" && attr.name !== "target") {
				anchor.removeAttribute(attr.name);
			}
		});

		anchor.replaceWith(cleanAnchor);
	});
	div.querySelectorAll(`span[data-node-type="variable"]`).forEach(span => {
		let fieldId = span.getAttribute("data-field-id");
		const isCustom = span.getAttribute("data-is-custom") === "true";
		const trackingId = span.getAttribute("data-tracking-id");
		if (isCustom) {
			if (!fieldId && trackingId) {
				fieldId = customFieldsTrackingIdMap[trackingId];
			}
			if (!fieldId) {
				// This is a straggler from something going wrong in the past, so we can just remove it.
				span.replaceWith("");
				return;
			}
		}
		// Make sure to include the custom prefix if it is a custom field.
		span.replaceWith(`{{${isCustom ? "custom." : ""}${fieldId}}}`);
	});
	div.querySelectorAll(`span[data-node-type="inactiveSlash"]`).forEach(span => {
		span.replaceWith("/");
	});

	const transformedHtml = div.innerHTML;
	return transformedHtml;
}
