import { CommandItem } from "../types";
import { BracketsSVG } from "@/assets/icons/svgs";

export type DefaultField = {
	id: string;
	displayName: string;
};
export const defaultFields: DefaultField[] = [
	{ id: "firstname", displayName: "First name" },
	{ id: "lastname", displayName: "Last name" },
	{ id: "email", displayName: "Email" },
	{ id: "job_title", displayName: "Job title" },
	{ id: "company", displayName: "Company name" },
	{ id: "phone", displayName: "Phone" },
	{ id: "linkedin", displayName: "LinkedIn" },
];

export const defaultCommands: CommandItem[] = defaultFields.map(fieldData => ({
	title: fieldData.displayName,
	icon: <BracketsSVG className="h-4 w-4 text-[#397af1]" />,
	command: ({ editor, range }) => {
		editor.commands.deleteRange(range);
		editor.commands.setVariable(fieldData.id, fieldData.displayName);
	},
}));
