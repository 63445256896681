import { CampaignCreationStepEnum } from "@/app/enums/campaign.enum";
import { transformHtmlForBackend } from "@/lib/tiptap/transformForBackend";
import { useCampaignStore } from "@/store";
import useCustomFieldsStore from "@/store/useCustomFieldsStore";
import useDirtyStore from "@/store/useDirtyStore";
import { CampaignProps } from "@/types/campaign";
import { CampaignStatus, CampaignStep } from "@za-zu/types";
import { useEffect, useState, useMemo } from "react";
import CampaignEditorHeader from "./CampaignEditorHeader";
import Sequences from "./Sequences";
import { transformLiquidForTipTap } from "@/lib/tiptap/transformLiquidForTipTap";
import { VarType } from "../TipTap/conditionalBuilder/core/nodes/constants";
import { defaultFields } from "../TipTap/slashCommand/utils/defaultCommands";
import { Variable } from "../TipTap/conditionalBuilder/core/nodes/types";
import { useOrganization } from "@clerk/clerk-react";
import { z } from "zod";

const defaultVariablesById = defaultFields.reduce(
	(acc, f) => ({ ...acc, [f.id]: { id: f.id, name: f.displayName, type: VarType.String } }),
	{} as Record<string, Variable>,
);

const SequencesEditor = ({ selectedCampaign }: { selectedCampaign: CampaignProps }) => {
	const { organization } = useOrganization();
	const { saveCampaign } = useCampaignStore();
	const { pendingCustomFieldTrackingIdMap, customFieldsById } = useCustomFieldsStore();

	const memoizedSelectedCampaignSteps = useMemo(() => {
		return selectedCampaign.steps.reduce(
			(acc, step) => {
				acc.push({
					...step,
					subject: transformLiquidForTipTap(step.subject || "", customFieldsById, defaultVariablesById),
					body: transformLiquidForTipTap(step.body, customFieldsById, defaultVariablesById),
				});
				return acc;
			},
			[] as z.infer<typeof CampaignStep>[],
		);
	}, [JSON.stringify(selectedCampaign.steps)]);
	const [sequences, setSequences] = useState<z.infer<typeof CampaignStep>[]>(memoizedSelectedCampaignSteps);
	const { setIsDirty, setIsSaving } = useDirtyStore();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setSequences(memoizedSelectedCampaignSteps);
	}, [JSON.stringify(memoizedSelectedCampaignSteps)]);

	useEffect(() => {
		setIsDirty(JSON.stringify(sequences) !== JSON.stringify(memoizedSelectedCampaignSteps));
	}, [JSON.stringify(sequences), JSON.stringify(memoizedSelectedCampaignSteps)]);

	useEffect(() => {
		if (memoizedSelectedCampaignSteps.length === 0) {
			setIsLoading(true);
			// Normally, we should not try to directly save a campaign if it's published. But if it has zero steps, it
			// shouldn't be published in the first place. So we can just pause it.
			const update = async () => {
				const newStatus: CampaignStatus = selectedCampaign.status === "active" ? "paused" : selectedCampaign.status;
				await saveCampaign({
					...selectedCampaign,
					status: newStatus,
					steps: [
						{
							id: "seq-" + new Date().getTime(),
							step_index: 0,
							subject: "<p></p>",
							body: "<p></p>",
							delay_in_hours: 72,
							campaign_id: selectedCampaign.id,
							created_at: "",
							updated_at: "",
							organization_id: (organization ?? {}).id ?? "",
						},
					],
				});
				setIsLoading(false);
			};
			update();
		}
	}, [memoizedSelectedCampaignSteps.length]);

	useEffect(() => {
		if (sequences.length === 0 && memoizedSelectedCampaignSteps.length > 0) {
			setSequences([
				{
					id: "seq-" + new Date().getTime(),
					step_index: 0,
					subject: "<p></p>",
					body: "<p></p>",
					delay_in_hours: 72,
					campaign_id: selectedCampaign.id,
					created_at: "",
					updated_at: "",
					organization_id: (organization ?? {}).id ?? "",
				},
			]);
		}
	}, [sequences.length, memoizedSelectedCampaignSteps.length]);

	return (
		<div className="flex w-full flex-col">
			<div className="flex w-full flex-col">
				{/* Campaign editor header with navigation and controls */}
				<CampaignEditorHeader
					selectedCampaign={selectedCampaign}
					activeCampaignStep={CampaignCreationStepEnum.Sequences}
					saveCallback={async () => {
						setIsSaving(true);
						await saveCampaign({
							...selectedCampaign,
							steps: sequences.reduce(
								(acc, step) => {
									acc.push({
										...step,
										subject: transformHtmlForBackend(step.subject || "", pendingCustomFieldTrackingIdMap),
										body: transformHtmlForBackend(step.body || "<p></p>", pendingCustomFieldTrackingIdMap),
									});
									return acc;
								},
								[] as z.infer<typeof CampaignStep>[],
							),
						});
						setIsSaving(false);
						setIsDirty(false);
					}}
				/>

				{!isLoading && sequences.length > 0 ? (
					<Sequences selectedCampaign={selectedCampaign} sequences={sequences} setSequences={setSequences} />
				) : (
					<div>Loading...</div>
				)}
			</div>
		</div>
	);
};

export default SequencesEditor;
