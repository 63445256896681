import SequencesEditor from "@/components/CampaignEditor/SequencesEditor";
import { useCampaignStore } from "@/store";
import { Suspense, useEffect } from "react";
import { useParams } from "wouter";

export default function Page() {
	const params = useParams();
	const { campaignsById, setSelectedCampaign } = useCampaignStore();

	const campaignId = params.campaignId;
	if (!campaignId) {
		return <div>Campaign not found</div>;
	}

	const selectedCampaign = campaignsById[campaignId];
	if (!selectedCampaign) {
		return <div>Campaign not found</div>;
	}

	// Set the selected campaign when the page loads
	useEffect(() => {
		setSelectedCampaign(selectedCampaign);
	}, [selectedCampaign, setSelectedCampaign]);

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<SequencesEditor selectedCampaign={selectedCampaign} />
		</Suspense>
	);
}
