import { CoachFeedback, EmailCoachRule } from "../types";
import { getPlainTextFromHtml, extractSentences } from "../utils";

// Map of complex words/phrases to simpler alternatives
// This is a carefully curated list based on Hemingway style recommendations
const COMPLEX_WORDS: Record<string, string[]> = {
	"a number of": ["many", "some"],
	"abundance": ["enough", "plenty"],
	"accede to": ["allow", "agree to"],
	"accelerate": ["speed up"],
	"accentuate": ["stress"],
	"accompany": ["go with", "with"],
	"accomplish": ["do"],
	"accorded": ["given"],
	"accrue": ["add", "gain"],
	"acquiesce": ["agree"],
	"acquire": ["get"],
	"additional": ["more", "extra"],
	"adjacent to": ["next to"],
	"adjustment": ["change"],
	"admissible": ["allowed", "accepted"],
	"advantageous": ["helpful"],
	"adversely impact": ["hurt"],
	"advise": ["tell"],
	"aforementioned": ["remove"],
	"aggregate": ["total", "add"],
	"aircraft": ["plane"],
	"all of": ["all"],
	"alleviate": ["ease", "reduce"],
	"allocate": ["divide"],
	"along the lines of": ["like", "as in"],
	"already existing": ["existing"],
	"alternatively": ["or"],
	"ameliorate": ["improve", "help"],
	"anticipate": ["expect"],
	"apparent": ["clear", "plain"],
	"appreciable": ["many"],
	"as a means of": ["to"],
	"as of yet": ["yet"],
	"as to": ["on", "about"],
	"as yet": ["yet"],
	"ascertain": ["find out", "learn"],
	"assistance": ["help"],
	"at this time": ["now"],
	"attain": ["meet"],
	"attributable to": ["because"],
	"authorize": ["allow", "let"],
	"because of the fact that": ["because"],
	"belated": ["late"],
	"benefit from": ["enjoy"],
	"bestow": ["give", "award"],
	"by virtue of": ["by", "under"],
	"cease": ["stop"],
	"close proximity": ["near"],
	"commence": ["begin or start"],
	"comply with": ["follow"],
	"concerning": ["about", "on"],
	"consequently": ["so"],
	"consolidate": ["join", "merge"],
	"constitutes": ["is", "forms", "makes up"],
	"demonstrate": ["prove", "show"],
	"depart": ["leave", "go"],
	"designate": ["choose", "name"],
	"discontinue": ["drop", "stop"],
	"due to the fact that": ["because", "since"],
	"each and every": ["each"],
	"economical": ["cheap"],
	"eliminate": ["cut", "drop", "end"],
	"elucidate": ["explain"],
	"employ": ["use"],
	"endeavor": ["try"],
	"enumerate": ["count"],
	"equitable": ["fair"],
	"equivalent": ["equal"],
	"evaluate": ["test", "check"],
	"evidenced": ["showed"],
	"exclusively": ["only"],
	"expedite": ["hurry"],
	"expend": ["spend"],
	"expiration": ["end"],
	"facilitate": ["ease", "help"],
	"factual evidence": ["facts", "evidence"],
	"feasible": ["workable"],
	"finalize": ["complete", "finish"],
	"first and foremost": ["first"],
	"for the purpose of": ["to"],
	"forfeit": ["lose", "give up"],
	"formulate": ["plan"],
	"honest truth": ["truth"],
	"however": ["but", "yet"],
	"if and when": ["if", "when"],
	"impacted": ["affected", "harmed", "changed"],
	"implement": ["install", "put in place", "tool"],
	"in a timely manner": ["on time"],
	"in accordance with": ["by", "under"],
	"in addition": ["also", "besides", "too"],
	"in all likelihood": ["probably"],
	"in an effort to": ["to"],
	"in between": ["between"],
	"in excess of": ["more than"],
	"in lieu of": ["instead"],
	"in light of the fact that": ["because"],
	"in many cases": ["often"],
	"in order to": ["to"],
	"in regard to": ["about", "concerning", "on"],
	"in some instances": ["sometimes"],
	"in terms of": ["omit"],
	"in the near future": ["soon"],
	"in the process of": ["omit"],
	"inception": ["start"],
	"incumbent upon": ["must"],
	"indicate": ["say", "state", "or show"],
	"indication": ["sign"],
	"initiate": ["start"],
	"is applicable to": ["applies to"],
	"is authorized to": ["may"],
	"is responsible for": ["handles"],
	"it is essential": ["must", "need to"],
	"literally": ["omit"],
	"magnitude": ["size"],
	"maximum": ["greatest", "largest", "most"],
	"methodology": ["method"],
	"minimize": ["cut"],
	"minimum": ["least", "smallest", "small"],
	"modify": ["change"],
	"monitor": ["check", "watch", "track"],
	"multiple": ["many"],
	"necessitate": ["cause", "need"],
	"nevertheless": ["still", "besides", "even so"],
	"not certain": ["uncertain"],
	"not many": ["few"],
	"not often": ["rarely"],
	"not unless": ["only if"],
	"not unlike": ["similar", "alike"],
	"notwithstanding": ["in spite of", "still"],
	"null and void": ["use either null or void"],
	"numerous": ["many"],
	"objective": ["aim", "goal"],
	"obligate": ["bind", "compel"],
	"obtain": ["get"],
	"on the contrary": ["but", "so"],
	"on the other hand": ["omit", "but", "so"],
	"one particular": ["one"],
	"optimum": ["best", "greatest", "most"],
	"overall": ["omit"],
	"owing to the fact that": ["because", "since"],
	"participate": ["take part"],
	"particulars": ["details"],
	"pass away": ["die"],
	"pertaining to": ["about", "of", "on"],
	"point in time": ["time", "point", "moment", "now"],
	"portion": ["part"],
	"possess": ["have", "own"],
	"preclude": ["prevent"],
	"previously": ["before"],
	"prior to": ["before"],
	"prioritize": ["rank", "focus on"],
	"procure": ["buy", "get"],
	"proficiency": ["skill"],
	"provided that": ["if"],
	"purchase": ["buy", "sale"],
	"put simply": ["omit"],
	"readily apparent": ["clear"],
	"refer back": ["refer"],
	"regarding": ["about", "of", "on"],
	"relocate": ["move"],
	"remainder": ["rest"],
	"remuneration": ["payment"],
	"require": ["must", "need"],
	"requirement": ["need", "rule"],
	"reside": ["live"],
	"residence": ["house"],
	"retain": ["keep"],
	"satisfy": ["meet", "please"],
	"shall": ["must", "will"],
	"should you wish": ["if you want"],
	"similar to": ["like"],
	"solicit": ["ask for", "request"],
	"span across": ["span", "cross"],
	"strategize": ["plan"],
	"subsequent": ["later", "next", "after", "then"],
	"substantial": ["large", "much"],
	"successfully complete": ["complete", "pass"],
	"sufficient": ["enough"],
	"terminate": ["end", "stop"],
	"the month of": ["omit"],
	"therefore": ["thus", "so"],
	"this day and age": ["today"],
	"time period": ["time", "period"],
	"took advantage of": ["preyed on"],
	"transmit": ["send"],
	"transpire": ["happen"],
	"until such time as": ["until"],
	"utilization": ["use"],
	"utilize": ["use"],
	"validate": ["confirm"],
	"various different": ["various", "different"],
	"whether or not": ["whether"],
	"with respect to": ["on", "about"],
	"with the exception of": ["except for"],
	"witnessed": ["saw", "seen"],
};

// Analyze text for complex words/phrases
const analyzeComplexWords = (content: string): CoachFeedback[] => {
	// Extract plain text
	const plainText = getPlainTextFromHtml(content);

	// Get sentences using the existing extraction utility
	const sentenceResult = extractSentences(plainText);
	const sentences = sentenceResult.sentences;

	// Track feedback for each complex word found
	const feedback: CoachFeedback[] = [];

	// Check each sentence for complex words
	sentences.forEach(sentence => {
		// Convert to lowercase for case-insensitive matching
		const lowerSentence = sentence.toLowerCase();

		// Check for each complex word/phrase
		Object.entries(COMPLEX_WORDS).forEach(([complexTerm, alternatives]) => {
			// Use word boundary check for whole word/phrase matching
			const regex = new RegExp(`\\b${complexTerm}\\b`, "gi");

			// Find all matches in the sentence
			const matches = [...lowerSentence.matchAll(regex)];

			// Add feedback for each match
			matches.forEach(match => {
				const alternativesText = alternatives.join('", "');
				feedback.push({
					id: `complex-${complexTerm}`,
					message: `Consider replacing '${match[0]}' with '${alternativesText}' for clarity.`,
					severity: "info",
					description: sentence,
				});
			});
		});
	});

	return feedback;
};

// Export the rule
export const complexWordsRule: EmailCoachRule = {
	id: "complex-words",
	name: "Complex Words",
	description: "Identifies complex words or phrases that could be replaced with simpler alternatives",
	analyze: (content: string) => analyzeComplexWords(content),
};
