import { useEffect, useState } from "react";
import { isLiquidOperationDefinitionFull, LiquidOperationDefinitionTempStates } from "./liquidParsing";
import { Variable } from "./types";
import { VariableSelector } from "./VariableSelector";
import { OperatorSelector } from "../../components/OperatorSelector";
import { ContinuationOperator } from "../../components/ContinuationOperator";
import Fuse from "fuse.js";
import { Editor } from "@tiptap/core";

type Props = {
	variables: Record<string, Variable>;
	variableFuse: Fuse<Variable>;
	stringVariableFuse: Fuse<Variable>;
	numberVariableFuse: Fuse<Variable>;
	booleanVariableFuse: Fuse<Variable>;
	setOperation: React.Dispatch<React.SetStateAction<LiquidOperationDefinitionTempStates>>;
	defaultVariables: Variable[];
	/** The depth of the operation in the conditional block. Used to determine how many times to nest into the
	 * continuation operation when updating the incoming operation.
	 */
	depth?: number;
	operation: LiquidOperationDefinitionTempStates;
	editor: Editor;
};

function getOperationAtDepth(operation: LiquidOperationDefinitionTempStates, depth: number) {
	let currentOperation = operation;
	for (let i = 0; i < depth; i++) {
		if (!currentOperation.continuation) {
			return currentOperation;
		}
		currentOperation = currentOperation.continuation.operation;
	}
	return currentOperation;
}

export default function ConditionalOperation({
	variables,
	variableFuse,
	stringVariableFuse,
	numberVariableFuse,
	booleanVariableFuse,
	setOperation,
	defaultVariables,
	depth = 0,
	operation,
	editor,
}: Props) {
	const [operationAtDepth, setOperationAtDepth] = useState<LiquidOperationDefinitionTempStates>(
		getOperationAtDepth(operation, depth),
	);
	const [tempState, setTempState] = useState<LiquidOperationDefinitionTempStates>(operationAtDepth);
	const [showOperatorSearch, setShowOperatorSearch] = useState(false);
	const [rightOperandInserted, setRightOperandInserted] = useState(false);

	useEffect(() => {
		setOperationAtDepth(getOperationAtDepth(operation, depth));
	}, [operation, depth]);

	useEffect(() => {
		setTempState(operationAtDepth);
	}, [operationAtDepth]);

	return (
		<span className="inline" contentEditable={false}>
			<VariableSelector
				variableFuse={variableFuse}
				defaultVariables={defaultVariables}
				selectedVariable={tempState.variable ?? undefined}
				onSelect={variable => {
					setTempState(operation => ({
						...operation,
						variable,
					}));
				}}
			/>
			{tempState.variable && (
				<>
					<OperatorSelector
						showOperatorSearch={showOperatorSearch}
						setShowOperatorSearch={setShowOperatorSearch}
						selectedOperator={tempState.operator}
						variableType={tempState.variable.type}
						onOperatorSelect={operator => {
							setTempState(operation => ({
								...operation,
								operator,
							}));
							if (editor && !rightOperandInserted) {
								const pos = editor.state.selection.$head.pos;
								editor.commands.insertContentAt(pos, {
									type: "rightOperand",
								});
								setRightOperandInserted(true);
							}
						}}
					/>
					{tempState.operator !== undefined && (
						<>
							{isLiquidOperationDefinitionFull(tempState) && tempState.continuation && (
								<>
									<span className="relative ml-1 inline-block">
										{tempState.continuation && (
											<>
												<ContinuationOperator
													continuation={tempState.continuation}
													onVariantToggle={() => {
														setTempState(prev => {
															if (!isLiquidOperationDefinitionFull(prev) || !prev.continuation) {
																return prev;
															}
															return {
																...prev,
																continuation: {
																	...prev.continuation,
																	variant: prev.continuation.variant === "and" ? "or" : "and",
																},
															};
														});
													}}
													onRemove={() => {
														setTempState(prev => {
															const newTempState = { ...prev };
															if (prev.continuation) {
																if (prev.continuation.operation.continuation) {
																	newTempState.continuation = prev.continuation.operation.continuation;
																} else {
																	newTempState.continuation = undefined;
																}
															}
															return newTempState;
														});
													}}
												/>
												{tempState.continuation && tempState.continuation.operation && (
													<ConditionalOperation
														variables={variables}
														variableFuse={variableFuse}
														stringVariableFuse={stringVariableFuse}
														numberVariableFuse={numberVariableFuse}
														booleanVariableFuse={booleanVariableFuse}
														operation={operation}
														setOperation={setOperation}
														defaultVariables={defaultVariables}
														depth={depth + 1}
														editor={editor}
													/>
												)}
											</>
										)}
									</span>
								</>
							)}
						</>
					)}
				</>
			)}
		</span>
	);
}
