import { useRef, useState, useEffect } from "react";
import { useCampaignStore } from "@/store";
import { CampaignCreationStepEnum } from "@/app/enums/campaign.enum"; // Fixed import path
import { CSVUploader } from "../CSVUploader/CSVUploader";
import { Button } from "../ui/button";
import { CampaignProps } from "@/types/campaign";
import { useLocation } from "wouter";
import CSVIcon from "@/assets/icons/svgs/csv.svg?react";
import APIIcon from "@/assets/icons/svgs/api.svg?react";
import SkipIcon from "@/assets/icons/svgs/skip.svg?react";
import ChevronRightIcon from "@/assets/icons/svgs/chevron-right.svg?react";
import { CSVRow } from "../CSVUploader/types";
import { toast } from "sonner";

const optionClass = (position: "first" | "middle" | "last") => `
	flex h-[88px] justify-center items-center self-stretch p-6
	${position === "first" ? "rounded-t-2xl" : ""}
	${position === "last" ? "rounded-b-2xl border-b-0" : "border-b-[0.5px] border-b-[#D9D9D9]"}
	bg-white cursor-pointer hover:bg-gray-50
`;

// Lockup styling for consistent option content layout
const lockupClass = "flex w-[507px] h-[40px] py-[6px] items-center justify-between";

export const ImportLeads = ({
	selectedCampaign,
	setImportedData,
}: {
	selectedCampaign: CampaignProps;
	setImportedData: (data: CSVRow[]) => void;
}) => {
	const [_location, navigate] = useLocation();
	const { setActiveCampaignStep } = useCampaignStore();
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [showUploader, setShowUploader] = useState(false);
	const [hoveredOption, setHoveredOption] = useState<"csv" | "api" | "skip" | null>(null);
	const [fileToDrop, setFileToDrop] = useState<File | null>(null);

	// Add global drag event handlers
	useEffect(() => {
		const handleDragOver = (e: DragEvent) => {
			e.preventDefault();
		};

		const handleDragEnter = (e: DragEvent) => {
			e.preventDefault();
			e.stopPropagation();

			// Only proceed if we have files
			if (e.dataTransfer && e.dataTransfer.items.length > 0) {
				setShowUploader(true);
			}
		};

		const handleDrop = (e: DragEvent) => {
			e.preventDefault();
			e.stopPropagation();

			if (e.dataTransfer && e.dataTransfer.files.length > 0) {
				const file = e.dataTransfer.files[0];
				// Check if it's a CSV file
				if (file.type === "text/csv" || file.name.endsWith(".csv")) {
					setFileToDrop(file);
					setShowUploader(true);
				}
			}
		};

		// Add event listeners
		window.addEventListener("dragover", handleDragOver);
		window.addEventListener("dragenter", handleDragEnter);
		window.addEventListener("drop", handleDrop);

		// Clean up
		return () => {
			window.removeEventListener("dragover", handleDragOver);
			window.removeEventListener("dragenter", handleDragEnter);
			window.removeEventListener("drop", handleDrop);
		};
	}, []);

	const handleUploadCSV = () => {
		setShowUploader(true);
	};

	const handleCloseUploader = () => {
		setShowUploader(false);
		setFileToDrop(null);
	};

	const handleUploadComplete = (data: CSVRow[]) => {
		setImportedData(data);
		navigate(`/dashboard/campaigns/${selectedCampaign.id}/leads/import`);
		setShowUploader(false);
		setFileToDrop(null);
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			// Instead of directly parsing here, just pass it to the CSV uploader
			setFileToDrop(file);
			setShowUploader(true);
		}
	};

	const handleUploadFromAPI = () => {
		toast.info("Add a Za-zu column to your Clay table to push leads to your campaign. Or skip for now.");
		setActiveCampaignStep(CampaignCreationStepEnum.Sequences);
		navigate(`/dashboard/campaigns/${selectedCampaign.id}/sequences`);
	};

	const handleSkip = () => {
		setActiveCampaignStep(CampaignCreationStepEnum.Sequences);
		navigate(`/dashboard/campaigns/${selectedCampaign.id}/sequences`);
	};

	return (
		<div className="mt-[44px] flex flex-col items-center">
			<input type="file" ref={fileInputRef} onChange={handleFileChange} accept=".csv" className="hidden" />
			<div className="flex w-[558px] flex-col">
				<div className="flex w-[558px] flex-col items-start gap-3">
					<div className="font-geist w-full text-center text-[20px] font-semibold leading-[27px] text-[#1A1A1A]">
						How would you like to import your leads?
					</div>
					<div className="font-geist w-full text-center text-[15px] font-normal leading-[22px] text-[#666666]">
						Select a method to import your leads. You can import more leads later.
					</div>
				</div>

				<div className="mt-[30px] flex w-[555px] flex-col items-center rounded-2xl border-[0.5px] border-[#D9D9D9] bg-white shadow-[0px_1px_1px_0px_rgba(0,0,0,0.06),0px_4px_4px_-1px_rgba(0,0,0,0.02)]">
					<div
						className={optionClass("first")}
						onClick={handleUploadCSV}
						onMouseEnter={() => setHoveredOption("csv")}
						onMouseLeave={() => setHoveredOption(null)}>
						<div className={lockupClass}>
							<div className="flex items-center gap-4">
								<CSVIcon className={`h-5 w-5 ${hoveredOption === "csv" ? "text-[#397AF1]" : "text-[#AAAAAA]"}`} />
								<div className="flex flex-col gap-1">
									<div className="text-[15px] font-medium leading-[22px] text-[#1A1A1A]">Upload a CSV file</div>
									<p className="font-geist self-stretch text-[13px] font-normal leading-[18px] text-[#666]">
										Drag and drop a .csv file to import your leads
									</p>
								</div>
							</div>
							<div className="flex h-[24px] w-[24px] shrink-0 items-center justify-center">
								<ChevronRightIcon
									className={`h-[10px] w-[6px] transition-transform duration-150 ease-out ${
										hoveredOption === "csv" ? "translate-x-1 transform text-[#397AF1]" : "text-[#666666]"
									}`}
								/>
							</div>
						</div>
					</div>

					<div
						className={optionClass("middle")}
						onClick={handleUploadFromAPI}
						onMouseEnter={() => setHoveredOption("api")}
						onMouseLeave={() => setHoveredOption(null)}>
						<div className={lockupClass}>
							<div className="flex items-center gap-4">
								<APIIcon className={`h-6 w-6 ${hoveredOption === "api" ? "text-[#397AF1]" : "text-[#AAAAAA]"}`} />
								<div className="flex flex-col gap-1">
									<div className="text-[15px] font-medium leading-[22px] text-[#1A1A1A]">From an API</div>
									<p className="font-geist self-stretch text-[13px] font-normal leading-[18px] text-[#666]">
										Use our integration with Clay to import leads
									</p>
								</div>
							</div>
							<div className="flex h-[24px] w-[24px] shrink-0 items-center justify-center">
								<ChevronRightIcon
									className={`h-[10px] w-[6px] transition-transform duration-150 ease-out ${
										hoveredOption === "api" ? "translate-x-1 transform text-[#397AF1]" : "text-[#666666]"
									}`}
								/>
							</div>
						</div>
					</div>

					<div
						className={optionClass("last")}
						onClick={handleSkip}
						onMouseEnter={() => setHoveredOption("skip")}
						onMouseLeave={() => setHoveredOption(null)}>
						<div className={lockupClass}>
							<div className="flex items-center gap-4">
								<SkipIcon
									className={`h-[18px] w-[18px] ${hoveredOption === "skip" ? "text-[#397AF1]" : "text-[#AAAAAA]"}`}
								/>
								<div className="flex flex-col gap-1">
									<div className="text-[15px] font-medium leading-[22px] text-[#1A1A1A]">Skip for now</div>
									<p className="font-geist self-stretch text-[13px] font-normal leading-[18px] text-[#666]">
										Draft the sequence first and import the leads later
									</p>
								</div>
							</div>
							<div className="flex h-[24px] w-[24px] shrink-0 items-center justify-center">
								<ChevronRightIcon
									className={`h-[10px] w-[6px] transition-transform duration-150 ease-out ${
										hoveredOption === "skip" ? "translate-x-1 transform text-[#397AF1]" : "text-[#666666]"
									}`}
								/>
							</div>
						</div>
					</div>
				</div>
				{!!selectedCampaign?.hasLeads && (
					<Button
						variant="secondary"
						className="mt-[30px]"
						onClick={() => navigate(`/dashboard/campaigns/${selectedCampaign.id}/leads`)}>
						Back to leads table
					</Button>
				)}
			</div>

			<CSVUploader
				open={showUploader}
				onClose={handleCloseUploader}
				onUpload={handleUploadComplete}
				selectedCampaign={selectedCampaign}
				initialFile={fileToDrop}
			/>
		</div>
	);
};
