import * as React from "react";
import { cn } from "@/lib/utils";

export interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	/**
	 * Whether to apply the animated underline effect on hover
	 * @default true
	 */
	animated?: boolean;

	/**
	 * The content of the link
	 */
	children: React.ReactNode;

	/**
	 * Additional CSS classes to apply to the link
	 */
	className?: string;

	/**
	 * Whether to open the link in a new tab
	 * @default false
	 */
	external?: boolean;
}

/**
 * Link component with animated underline effect.
 *
 * This is the standard way to create links in the application. It provides a consistent
 * styling with a beautiful animated underline effect that appears on hover.
 *
 * ## Design Considerations
 * - Uses a 150ms duration with an `ease` timing function (optimal for hover effects)
 * - Underline grows from left to right, creating a natural reading direction
 * - Text color changes to brand primary color on hover
 * - Animation is hardware-accelerated for smooth performance
 * - Maintains all accessibility features of the standard HTML `<a>` element
 *
 * ## Migration
 * When updating existing links in the codebase:
 * 1. Replace all `<a>` tags with the `<Link>` component
 * 2. Import the Link component from `@/components/ui`
 * 3. For external links, add the `external` prop instead of manually adding
 *    `target="_blank"` and `rel="noopener noreferrer"`
 *
 * @example
 * ```tsx
 * // Basic usage
 * <Link href="/about">About</Link>
 *
 * // External link (opens in new tab)
 * <Link href="https://example.com" external>External Link</Link>
 *
 * // Without animation
 * <Link href="/contact" animated={false}>Contact</Link>
 *
 * // With custom class
 * <Link href="/pricing" className="text-orange">Custom Color</Link>
 * ```
 */
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
	({ animated = true, children, className, external = false, href, ...props }, ref) => {
		const externalProps = external ? { target: "_blank", rel: "noopener noreferrer" } : {};

		return (
			<a
				ref={ref}
				href={href}
				className={cn(
					"text-body-head leading-body-head text-(--label-label-base) font-medium",
					animated && "ease hover:text-brand-primary group relative transition-all duration-150",
					className,
				)}
				{...externalProps}
				{...props}>
				{animated ? (
					<span className="relative inline-block">
						{children}
						<span
							className="bg-brand-primary ease absolute bottom-0 left-0 h-[1px] w-0 origin-left transition-all duration-150 will-change-transform group-hover:w-full"
							aria-hidden="true"
						/>
					</span>
				) : (
					children
				)}
			</a>
		);
	},
);

Link.displayName = "Link";

export { Link };
