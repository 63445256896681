import NewSidebar from "@/components/NewSidebar";
import { SidebarProvider } from "@/components/ui/sidebar";
import { useFetch } from "@/hooks/useQuery";
import useSse from "@/hooks/useSse";
import { ApiClient } from "@/lib/ApiClient";
import { useCampaignStore, useEmailAccountsStore, useFetchStore, useInboxStore } from "@/store";
import useCustomFieldsStore from "@/store/useCustomFieldsStore";
import { OrganizationList, SignOutButton, useOrganization, useUser } from "@clerk/clerk-react";
import Intercom from "@intercom/messenger-js-sdk";
import { WebSocketMessageForDbChange } from "@za-zu/types";
import { useEffect, useState } from "react";
import { toast } from "sonner";

const OrganizationPickerScreen = () => {
	return (
		<div className="flex h-screen flex-col items-center justify-center gap-6">
			<OrganizationList hidePersonal />
			<p className="max-w-xs text-center text-sm font-bold">
				To join another organization, request access from your admin.
			</p>
			<SignOutButton>Sign out?</SignOutButton>
		</div>
	);
};

// Version check interval in milliseconds (5 minutes)
const VERSION_CHECK_INTERVAL = 5 * 60 * 1000;

export const App = ({ children }: { children: React.ReactNode }) => {
	const { isLoaded, isSignedIn, user } = useUser();
	const { addListener, connectionState } = useSse();

	const fetcher = useFetch();
	const { setAuthorizedFetch, authorizedFetch, setApiClient, apiClient } = useFetchStore();
	const {
		fetchAndInitializeLatestCampaigns,
		pendingCampaignChanges,
		claimedPendingCampaignChanges,
		attemptPendingCampaignChange,
	} = useCampaignStore();
	const {
		fetchAndInitializeLatestEmailAccounts,
		pendingMembershipChanges,
		claimedPendingMembershipChanges,
		attemptPendingMembershipChange,
		pendingAccountChanges,
		claimedPendingAccountChanges,
		attemptPendingAccountChange,
		updateAccount,
	} = useEmailAccountsStore();
	const {
		fetchAndInitializeLatestCustomFields,
		pendingCustomFieldChanges,
		claimedPendingCustomFieldChanges,
		attemptPendingCustomFieldChange,
	} = useCustomFieldsStore();
	const {
		sortIncomingMessage,
		pendingInboxChanges,
		claimedPendingInboxChanges,
		attemptPendingInboxChange,
		updateThread,
	} = useInboxStore();
	const { organization } = useOrganization();

	const [listeningForSse, setListeningForSse] = useState(false);
	const [currentBuildId, setCurrentBuildId] = useState<string | null>(null);
	const [isCheckingVersion, setIsCheckingVersion] = useState(false);
	const [updatePrompted, setUpdatePrompted] = useState(false);

	// Version checking effect
	useEffect(() => {
		if (!isLoaded || !isSignedIn) return;

		// On first load, store the current build ID
		const meta = document.querySelector('meta[name="build-id"]');
		const initialBuildId = meta?.getAttribute("content") || "unknown";
		setCurrentBuildId(initialBuildId);

		// Function to check if app is outdated
		const checkForNewVersion: () => Promise<void> = async () => {
			if (isCheckingVersion || updatePrompted) return;

			try {
				setIsCheckingVersion(true);

				// Fetch the HTML of the index page to check for a new build ID
				const response = await fetch("/", {
					method: "GET",
					cache: "no-cache",
					headers: { pragma: "no-cache" },
				});

				if (!response.ok) return;

				const html = await response.text();

				// Extract build ID using regex
				const buildIdMatch = html.match(/<meta name="build-id" content="([^"]+)"/);
				const latestBuildId = buildIdMatch?.[1] || null;

				// If we found a build ID and it's different from current one, show toast
				if (latestBuildId && currentBuildId && latestBuildId !== currentBuildId && !updatePrompted) {
					toast.info("Za-zu just got better", {
						duration: Infinity,
						action: {
							label: "Update now",
							onClick: () => window.location.reload(),
						},
					});
					setUpdatePrompted(true);
				}
			} catch (error) {
				console.error("Version check failed:", error);
			} finally {
				setIsCheckingVersion(false);
			}
		};

		// Check for new version periodically
		const versionCheckInterval = setInterval(checkForNewVersion, VERSION_CHECK_INTERVAL);

		// Also check when user returns to the tab
		const handleVisibilityChange = () => {
			if (document.visibilityState === "visible") {
				checkForNewVersion();
			}
		};

		// Also check when connectivity is restored
		const handleOnline = () => {
			checkForNewVersion();
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);
		window.addEventListener("online", handleOnline);

		return () => {
			clearInterval(versionCheckInterval);
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			window.removeEventListener("online", handleOnline);
		};
	}, [isLoaded, isSignedIn, currentBuildId, isCheckingVersion, updatePrompted]);

	useEffect(() => {
		if (connectionState !== "OPEN") return;
		if (listeningForSse) return;
		setListeningForSse(true);
		addListener("databaseChange", data => {
			try {
				const parsedMessage = WebSocketMessageForDbChange.parse(JSON.parse(data));
				switch (parsedMessage.dataType) {
					case "email_accounts":
						updateAccount(parsedMessage.data);
						break;
					case "organizations_threads":
						updateThread({
							...parsedMessage.data.thread,
							lead_thread_category: null,
						});
						break;
					case "messages":
						sortIncomingMessage(parsedMessage.data);
						break;
				}
			} catch (error) {
				console.error("Incoming websocket message failed to be parsed:");
				try {
					console.error(JSON.parse(data));
				} catch {
					// Likely isn't an object
					console.error(data);
				}
				console.error(error);
			}
		});
	}, [connectionState, listeningForSse]);

	useEffect(() => {
		if (!isLoaded || !isSignedIn || !user) return;
		setAuthorizedFetch(fetcher);

		// Extract user information needed for Intercom
		const name = `${user.firstName || ""} ${user.lastName || ""}`.trim();
		const email = user.primaryEmailAddress?.emailAddress || "";
		const userId = user.id;
		const createdAt = user.createdAt ? Math.floor(new Date(user.createdAt).getTime() / 1000) : undefined;
		const APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;
		if (!APP_ID || import.meta.env.VITE_ENV === "development") return;
		Intercom({
			app_id: APP_ID,
			name,
			email,
			user_id: userId,
			created_at: createdAt,
		});
	}, [isLoaded, isSignedIn, user]);

	useEffect(() => {
		if (!organization) return;
		if (!authorizedFetch) return;
		// These will run every time the app is loaded and when the authorizedFetch is set, but they are responsible for
		// making sure they don't actually do anything if they've already been executed.
		const x = new ApiClient(authorizedFetch);
		setApiClient(x);
		fetchAndInitializeLatestCampaigns();
		fetchAndInitializeLatestEmailAccounts();
		fetchAndInitializeLatestCustomFields();
	}, [organization, authorizedFetch]);

	useEffect(() => {
		// Check for any unclaimed pending changes, and kick off attmepts to complete them.
		if (!apiClient) return;
		const pendingMembershipChangeIds = new Set(Object.keys(pendingMembershipChanges));
		const unclaimedMembershipChangeIds = pendingMembershipChangeIds.difference(claimedPendingMembershipChanges);
		unclaimedMembershipChangeIds.forEach(id => {
			attemptPendingMembershipChange(id);
		});
		const pendingAccountChangeIds = new Set(Object.keys(pendingAccountChanges));
		const unclaimedAccountChangeIds = pendingAccountChangeIds.difference(claimedPendingAccountChanges);
		unclaimedAccountChangeIds.forEach(id => {
			attemptPendingAccountChange(id);
		});
		const pendingCampaignChangeIds = new Set(Object.keys(pendingCampaignChanges));
		const unclaimedCampaignChangeIds = pendingCampaignChangeIds.difference(claimedPendingCampaignChanges);
		unclaimedCampaignChangeIds.forEach(id => {
			attemptPendingCampaignChange(id);
		});
		const pendingCustomFieldChangeIds = new Set(Object.keys(pendingCustomFieldChanges));
		const unclaimedCustomFieldChangeIds = pendingCustomFieldChangeIds.difference(claimedPendingCustomFieldChanges);
		unclaimedCustomFieldChangeIds.forEach(id => {
			attemptPendingCustomFieldChange(id);
		});
		const pendingInboxChangeIds = new Set(Object.keys(pendingInboxChanges));
		const unclaimedInboxChangeIds = pendingInboxChangeIds.difference(claimedPendingInboxChanges);
		unclaimedInboxChangeIds.forEach(id => {
			attemptPendingInboxChange(id);
		});
	}, [
		apiClient,
		pendingMembershipChanges,
		pendingAccountChanges,
		pendingCampaignChanges,
		pendingCustomFieldChanges,
		pendingInboxChanges,
		claimedPendingMembershipChanges,
		claimedPendingAccountChanges,
		claimedPendingCampaignChanges,
		claimedPendingCustomFieldChanges,
		claimedPendingInboxChanges,
		attemptPendingMembershipChange,
		attemptPendingAccountChange,
		attemptPendingCampaignChange,
		attemptPendingCustomFieldChange,
		attemptPendingInboxChange,
	]);

	if (!organization) return <OrganizationPickerScreen />;

	return (
		<SidebarProvider defaultOpen>
			<div className="flex h-screen overflow-hidden">
				<NewSidebar />

				{/* Main content wrapper */}
				<main className="flex-1 overflow-auto">
					{/* Gray background container */}
					<div className="bg-background-bg-shade h-full py-2 pr-2">
						{/* Content card */}
						<div className="border-background-bg-border bg-background-bg-base shadow-low h-full overflow-hidden rounded-lg border-[0.5px]">
							{children}
						</div>
					</div>
				</main>
			</div>
			<div className="fixed bottom-4 left-4 h-fit w-[68px]"></div>
		</SidebarProvider>
	);
};
