import * as React from "react";

import { cn } from "@/lib/utils";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	variant?: "default" | "naked";
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, variant = "default", ...props }, ref) => {
		return (
			<input
				type={type}
				className={cn(
					variant === "default"
						? "text-label-label-base placeholder:text-label-muted flex h-8 w-full items-center gap-2 self-stretch rounded-[6px] border-[0.5px] border-[--background-bg-border] bg-[--background-bg-base] px-2 text-sm shadow-[0px_1px_1px_0px_rgba(0,0,0,0.06),0px_4px_4px_-1px_rgba(0,0,0,0.02)] file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:border-[#007EFF] focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
						: "outline-hidden focus:outline-hidden appearance-none border-0 focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0",
					className,
				)}
				ref={ref}
				{...props}
			/>
		);
	},
);
Input.displayName = "Input";

export { Input };
