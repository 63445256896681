import { useState } from "react";
import { Separator } from "@/components/ui/separator";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableHeaderRow, TableRow } from "@/components/ui/table";
import { usePost, useQuery } from "@/hooks/useQuery";
import { Copy, Check } from "lucide-react";
import { mutate } from "swr";
import { z } from "zod";
import { ApiKey } from "@za-zu/types";

export default function ApiKeysPage() {
	const { data, error, isLoading } = useQuery("/api-keys", z.object({ data: z.array(ApiKey.Slim) }));
	let apiKeys = data?.data;
	if (!apiKeys) {
		apiKeys = [];
	}
	const [apiKeyName, setApiKeyName] = useState("");
	const [generatedApiKey, setGeneratedApiKey] = useState("");
	const [showKeyDialog, setShowKeyDialog] = useState(false);
	const [justCopied, setJustCopied] = useState(false);
	const post = usePost<ApiKey.SlimWithKey>();

	const handleCreateApiKey = async () => {
		try {
			const resp = await post("/api-keys", ApiKey.SlimWithKey, { name: apiKeyName });
			setGeneratedApiKey(resp.apiKey);
			setShowKeyDialog(true);
			setApiKeyName("");
		} catch (error) {
			console.error("Failed to create API key:", error);
		}
	};

	const handleCopy = () => {
		navigator.clipboard.writeText(generatedApiKey);
		setJustCopied(true);
		setTimeout(() => setJustCopied(false), 2000);
	};

	const handleDone = () => {
		setShowKeyDialog(false);
		setGeneratedApiKey("");
		mutate("/api-keys");
	};

	return (
		<div className="flex justify-center">
			<div className="w-full max-w-[640px] shrink-0 space-y-6 pt-6">
				<div>
					<h3 className="text-label-title text-lg font-medium">API Keys</h3>
					<p className="text-muted-foreground pt-2 text-sm">Create and manage your API keys.</p>
					<div className="mt-4 flex gap-2">
						<Input
							className="border-background-bg-border bg-background-bg-base shadow-low h-8 w-[523px] rounded-[6px] border-[0.5px] px-2"
							placeholder="Enter your API key label"
							value={apiKeyName}
							onChange={e => setApiKeyName(e.target.value)}
						/>
						<Button className="h-8 px-2" onClick={handleCreateApiKey} disabled={!apiKeyName.trim()}>
							Create API Key
						</Button>
					</div>
				</div>

				<Separator />

				{/* API Keys Table */}
				<div className="overflow-x-auto">
					{isLoading && <p>Loading...</p>}
					{error && <p className="text-red-500">Error loading API keys.</p>}
					{apiKeys && (
						<Table>
							<TableHeader>
								<TableHeaderRow>
									<TableHead>ID</TableHead>
									<TableHead>Name</TableHead>
									<TableHead>Created At</TableHead>
								</TableHeaderRow>
							</TableHeader>
							<TableBody>
								{apiKeys.map(key => (
									<TableRow key={key.id}>
										<TableCell>{key.id}</TableCell>
										<TableCell>{key.name}</TableCell>
										<TableCell>{new Date(key.created_at).toLocaleString()}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					)}
				</div>

				<Dialog open={showKeyDialog} onOpenChange={setShowKeyDialog}>
					<DialogContent>
						<DialogHeader>
							<DialogTitle>Save your key</DialogTitle>
						</DialogHeader>
						<p className="text-muted-foreground text-sm">
							Please store the key safely as it will only be visible this one time.
						</p>
						<div className="bg-background-bg-base flex items-center gap-2 rounded-md border p-3">
							<code className="flex-1 font-mono text-sm">{generatedApiKey}</code>
							<Button variant="ghost" size="icon" onClick={handleCopy} className="h-8 w-8">
								{justCopied ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
							</Button>
						</div>
						<DialogFooter>
							<Button onClick={handleDone}>Done</Button>
						</DialogFooter>
					</DialogContent>
				</Dialog>
			</div>
		</div>
	);
}
