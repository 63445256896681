import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
	"inline-flex items-center justify-center gap-[6px] whitespace-nowrap font-medium ring-offset-background transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 h-8 select-none",
	{
		variants: {
			variant: {
				default: [
					"bg-background-bg-brand bg-[color(display-p3_0.0314_0.3686_0.8392)]",
					"text-white",
					"rounded-[8px]",
					"shadow-low",
					"hover:opacity-90",
				].join(" "),
				brand: ["bg-background-bg-brand", "text-white", "rounded-lg", "shadow-low", "hover:opacity-90"].join(" "),
				destructive: [
					"bg-destructive",
					"text-destructive-foreground",
					"rounded-[6px]",
					"shadow-low",
					"hover:bg-destructive/90",
				].join(" "),
				outline: [
					"rounded-lg border-[0.5px] border-background-bg-border",
					"bg-background-bg-base",
					"shadow-low",
					"text-[13px] font-medium leading-[18px]",
					"hover:bg-accent hover:text-accent-foreground",
				].join(" "),
				secondary: [
					"h-8 px-[10px] gap-[6px] shrink-0",
					"rounded-lg border-[0.5px] border-background-bg-border",
					"bg-background-bg-base",
					"shadow-low",
					"text-[13px] font-medium leading-[18px]",
					"text-label-label-base",
					"hover:bg-accent hover:text-accent-foreground",
				].join(" "),
				ghost: "hover:bg-accent hover:text-accent-foreground",
				link: [
					"text-body-head leading-body-head text-(--label-label-base) font-medium",
					"text-primary group relative transition-all duration-150 ease",
					"hover:text-brand-primary",
					"p-0 h-auto m-0",
					"inline align-baseline",
					"bg-transparent",
					"border-0",
					"shadow-none",
					"[&>span]:relative [&>span]:inline-block",
					"[&>span>span]:bg-brand-primary [&>span>span]:absolute [&>span>span]:bottom-0 [&>span>span]:left-0 [&>span>span]:h-[1px] [&>span>span]:w-0 [&>span>span]:origin-left [&>span>span]:transition-all [&>span>span]:duration-150 [&>span>span]:will-change-transform",
					"[&:hover>span>span]:w-full",
				].join(" "),
				exit: [
					"inline-flex h-8 items-center justify-center gap-[6px] px-[10px] shrink-0",
					"rounded-lg",
					"text-[13px] font-medium leading-[18px]",
					"text-label-label-muted",
					"cursor-pointer",
					"hover:bg-background-bg-hover",
					"hover:text-label-label-base",
					"transition-colors duration-150 ease",
				].join(" "),
				inboxAction: [
					"flex w-6 h-6 px-1 justify-center items-center gap-1",
					"rounded-[5px]",
					"[&_svg]:size-3.5 [&_svg]:shrink-0",
					"hover:bg-background-bg-base-hover",
				].join(" "),
				naked: "inline-flex disabled:pointer-events-none disabled:opacity-50",
				smtp: [
					"flex h-8 px-2 justify-center items-center gap-[6px]",
					"rounded-[6px]",
					"bg-transparent",
					"text-[13px] font-medium leading-[18px]",
					"text-label-label-muted",
					"font-geist",
				].join(" "),
				inactive: [
					"rounded-[8px]",
					"opacity-50",
					"bg-background-bg-base-hover",
					"bg-[color(display-p3_0.9451_0.9451_0.9451)]",
					"pointer-events-none",
					"text-label-label-muted",
				].join(" "),
			},
			size: {
				default: "px-[10px] py-2",
				sm: "rounded-md px-3",
				lg: "rounded-md px-8",
				icon: "w-10",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	},
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : "button";
		if (variant === "link" && props.children) {
			return (
				<Comp
					className={cn(buttonVariants({ variant, size, className }))}
					ref={ref}
					{...props}
					style={{
						...(props.style || {}),
						verticalAlign: "baseline",
						margin: "0",
						padding: "0",
					}}>
					<span>
						{props.children}
						<span aria-hidden="true" />
					</span>
				</Comp>
			);
		}
		return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
	},
);
Button.displayName = "Button";

export { Button, buttonVariants };
