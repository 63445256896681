import { DynamicWarmingSVG } from "@/assets/icons/components";
import {
	ClockSVG,
	DisconnectedPlugSVG,
	ExclamationMarkSVG,
	InfoTooltipBadgeSVG,
	StarFrameSVG,
	TrashSVG,
	ZapSVG,
} from "@/assets/icons/svgs";
import DotsVerticalIcon from "@/assets/icons/svgs/table-header/dots-vertical";
import UserIcon from "@/assets/icons/svgs/user.svg?react";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Progress } from "@/components/ui/progress-bar";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableHeaderRow, TableRow } from "@/components/ui/table";
import { TableCellToolbar } from "@/components/ui/table-toolbar";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useGenerateGoogleOauthUrl } from "@/hooks/accounts/generateGoogleOauthUrl";
import { useGenerateMicrosoftOauthUrl } from "@/hooks/accounts/generateMicrosoftOauthUrl";
import { cn } from "@/lib/utils";
import { useGlobalFilterStore } from "@/store";
import { useAuth } from "@clerk/clerk-react";
import type { AccountSlim, TClientRow } from "@za-zu/types";
import { Check } from "lucide-react";
import { MotionConfig } from "motion/react";
import { useMemo, useState } from "react";
import { DoneForYouDialog } from "../DoneForYouDialog";
import { Spinner } from "../ui/spinner";
import EmailAccountsTableSkeleton from "./EmailAccountsTableSkeleton";

type SyncStatus =
	| "init"
	| "syncing"
	| "connecting"
	| "connected"
	| "authenticationError"
	| "connectError"
	| "unset"
	| "disconnected"
	| null;

type ExtendedAccount = AccountSlim & {
	email_warmth_score?: number | null;
	client_id?: string | null;
};

interface EmailAccountsTableProps {
	accounts: AccountSlim[];
	selectedIds?: string[] | null;
	onSelect: (id: string) => void;
	onRemove: (id: string) => void;
	_onCopyEmail?: (email: string) => void;
	_onSettings?: (id: string) => void;
	_onGoogleClick?: () => void;
	_onSMTPClick?: () => void;
	onClientSelect?: (accountId: string, clientId: string | null) => Promise<void>;
	clients?: TClientRow[];
	isLoading?: boolean;
}

/** The number of days in the warming period (3 weeks) */
const WARMING_PERIOD_IN_DAYS = 21;

const DeleteConfirmationModal = ({
	isOpen,
	onClose,
	onConfirm,
	email,
}: {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	email: string;
}) => {
	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="p-4 sm:max-w-[400px]">
				<DialogHeader className="space-y-3">
					<DialogTitle className="text-body-head leading-body-head text-label-title font-medium">
						Delete email account
					</DialogTitle>
					<DialogDescription className="text-body-base leading-body-base text-label-muted py-1">
						Are you sure you want to delete the account {email}? This action cannot be undone.
					</DialogDescription>
				</DialogHeader>
				<DialogFooter className="mt-6">
					<Button variant="ghost" onClick={onClose} className="h-8 px-3 text-sm">
						Cancel
					</Button>
					<Button variant="destructive" onClick={onConfirm} className="text-sm">
						Delete account
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

const AccountActions = ({ account, onDelete }: { account: AccountSlim; onDelete: (account: AccountSlim) => void }) => {
	const { getToken } = useAuth();
	const { generateGoogleOauthUrl } = useGenerateGoogleOauthUrl();
	const { generateMicrosoftOauthUrl } = useGenerateMicrosoftOauthUrl();

	const handleReconnect = async (e: React.MouseEvent) => {
		e.stopPropagation();
		const token = await getToken();
		if (!token) return;

		if (account.sync_type?.includes("google")) {
			await generateGoogleOauthUrl({ access_token: token });
		} else if (account.sync_type?.includes("microsoft")) {
			await generateMicrosoftOauthUrl({ access_token: token });
		}
	};

	const handleRemove = (e: React.MouseEvent) => {
		e.stopPropagation();
		onDelete(account);
	};

	return (
		<div className="flex items-center gap-2" onClick={e => e.stopPropagation()}>
			<Button
				variant="outline"
				className="border-background-bg-border bg-background-bg-base shadow-custom-low text-label-label-base flex h-6 items-center justify-center gap-1 rounded-[5px] border-[0.5px] px-1 text-xs"
				onClick={handleReconnect}>
				Reconnect
			</Button>
			<Button
				variant="ghost"
				className="text-label-label-base hover:text-label-label-base flex h-6 items-center justify-center gap-1 px-1 text-xs"
				onClick={handleRemove}>
				Remove
			</Button>
		</div>
	);
};

const STATUS_CONFIG = {
	init: {
		textColor: "text-label-label-base",
		bgColor: "bg-fill-warning",
		label: "Initializing",
		showReconnect: false,
		icon: null,
	},
	syncing: {
		textColor: "text-label-label-base",
		bgColor: "bg-fill-warning",
		label: "Syncing",
		showReconnect: false,
		icon: null,
	},
	connecting: {
		textColor: "text-label-label-base",
		bgColor: "bg-fill-warning",
		label: "Connecting",
		showReconnect: false,
		icon: null,
	},
	connected: {
		textColor: "text-label-label-base",
		bgColor: "bg-fill-success",
		label: "Connected",
		showReconnect: false,
		icon: null,
	},
	authenticationError: {
		textColor: "text-orange",
		bgColor: "bg-fill-warning",
		label: "Disconnected",
		showReconnect: true,
		icon: <DisconnectedPlugSVG className="h-3.5 w-3.5" />,
	},
	connectError: {
		textColor: "text-orange",
		bgColor: "bg-fill-warning",
		label: "Disconnected",
		showReconnect: true,
		icon: <DisconnectedPlugSVG className="h-3.5 w-3.5" />,
	},
	unset: {
		textColor: "text-orange",
		bgColor: "bg-fill-warning",
		label: "Disconnected",
		showReconnect: true,
		icon: <DisconnectedPlugSVG className="h-3.5 w-3.5" />,
	},
	disconnected: {
		textColor: "text-orange",
		bgColor: "bg-fill-warning",
		label: "Disconnected",
		showReconnect: true,
		icon: <DisconnectedPlugSVG className="h-3.5 w-3.5" />,
	},
	null: {
		textColor: "text-orange",
		bgColor: "bg-fill-warning",
		label: "Not Connected",
		showReconnect: true,
		icon: <DisconnectedPlugSVG className="h-3.5 w-3.5" />,
	},
} as const;

const getStatusConfig = (status: SyncStatus, warmthScore?: number | null) => {
	if (status === "connected" && warmthScore !== undefined && warmthScore !== null) {
		// If warmth score is less than 1, it's still warming up
		// If warmth score is 1 or greater, it's ready
		if (warmthScore < 1) {
			// Calculate days left based on warmth score
			// Days left = (1 - warmth_score) * WARMING_PERIOD_IN_DAYS, rounded to nearest whole day
			const daysLeft = Math.round((1 - warmthScore) * WARMING_PERIOD_IN_DAYS);
			const daysLeftText = daysLeft === 1 ? "1 day left" : `${daysLeft} days left`;

			return {
				textColor: "text-label-label-title",
				bgColor: "bg-fill-success",
				label: (
					<>
						Warming up
						<TooltipProvider delayDuration={100}>
							<Tooltip>
								<TooltipTrigger asChild>
									<span className="text-label-faint"> · {daysLeftText}</span>
								</TooltipTrigger>
								<TooltipContent
									side="bottom"
									align="center"
									sideOffset={4}
									className="border-background-bg-border bg-background-bg-base shadow-float flex flex-col items-center gap-1.5 rounded-[12px] border-[0.5px] p-3">
									<p className="text-mini leading-mini text-label-title font-medium">
										{Math.round(warmthScore * 100)}% warm
									</p>
									<Progress value={warmthScore * 100} className="w-[160px]" />
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					</>
				),
				showReconnect: false,
				icon: (
					<TooltipProvider delayDuration={100}>
						<Tooltip>
							<TooltipTrigger asChild>
								<span>
									<DynamicWarmingSVG warmthScore={warmthScore} className="h-3.5 w-3.5" />
								</span>
							</TooltipTrigger>
							<TooltipContent
								side="left"
								align="start"
								sideOffset={4}
								className="border-background-bg-border bg-background-bg-base shadow-float flex w-[404px] flex-col items-center gap-[23px] rounded-[12px] border-[0.5px] p-6">
								<div className="flex flex-col gap-2 self-stretch">
									<div className="flex items-center gap-2 self-stretch">
										<DynamicWarmingSVG warmthScore={warmthScore} className="h-[22px] w-[22px]" />
										<p className="text-body-base-plus leading-body-base text-label-title text-center font-medium">
											Your email inbox is now warming up.
										</p>
									</div>

									<div className="flex flex-col items-center gap-3 self-stretch">
										<div className="flex items-start gap-2 self-stretch">
											<div className="flex h-[22px] w-[22px] flex-shrink-0 items-center justify-center p-[3px]">
												<ExclamationMarkSVG className="h-4 w-4 flex-shrink-0" />
											</div>
											<p className="text-mini leading-mini text-label-title flex-1 font-normal">
												You could start sending right away. But you shouldn&apos;t. Sending en masse without a built-up
												reputation may get your emails sent to spam.
											</p>
										</div>

										<div className="flex items-start gap-2 self-stretch">
											<div className="flex h-[22px] w-[22px] flex-shrink-0 items-center justify-center p-[3px]">
												<StarFrameSVG className="h-4 w-4 flex-shrink-0" />
											</div>
											<p className="text-mini leading-mini text-label-title flex-1 font-normal">
												Instead, we&apos;ve started warming your account by emailing other connected inboxes. They
												automatically mark your emails as important, reply on occasion, and rescue any messages that
												miss the inbox.
											</p>
										</div>

										<div className="flex items-start gap-2 self-stretch">
											<div className="flex h-[22px] w-[22px] flex-shrink-0 items-center justify-center p-[3px]">
												<ClockSVG className="h-4 w-4 flex-shrink-0" />
											</div>
											<p className="text-mini leading-mini text-label-title flex-1 font-normal">
												Building a healthy sender reputation takes ~3 weeks. Once you start sending cold messages,
												we&apos;ll continue our warming processes in parallel to protect your deliverability.
											</p>
										</div>
									</div>
								</div>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				),
			};
		}

		return {
			textColor: "text-label-label-title",
			bgColor: "bg-fill-success",
			label: "Ready",
			showReconnect: false,
			icon: <ZapSVG className="h-3.5 w-3.5" />,
		};
	}
	return STATUS_CONFIG[status || "null"];
};

const EmailAccountsTable = ({
	accounts,
	selectedIds,
	onSelect,
	onRemove,
	onClientSelect,
	clients = [],
	isLoading = false,
}: EmailAccountsTableProps) => {
	const [hoveredRow, setHoveredRow] = useState<string | null>(null);
	const [_isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);
	const [isDFYDialogOpen, setIsDFYDialogOpen] = useState(false);
	const [deleteModalState, setDeleteModalState] = useState<{ isOpen: boolean; account: AccountSlim | null }>({
		isOpen: false,
		account: null,
	});
	const [dropdownOpenForAccount, setDropdownOpenForAccount] = useState<string | null>(null);
	const { searchText } = useGlobalFilterStore();

	// Treat accounts as possibly having the email_warmth_score property
	const extendedAccounts = accounts as ExtendedAccount[];

	// Sort and filter accounts
	const filteredAndSortedAccounts = useMemo(() => {
		// First filter accounts based on search
		const filtered = searchText
			? extendedAccounts.filter(account => {
					const searchLower = searchText.toLowerCase();
					return (
						account.email.toLowerCase().includes(searchLower) ||
						(account.name?.toLowerCase() || "").includes(searchLower) ||
						(
							getStatusConfig(account.sync_status, account.email_warmth_score).label?.toString().toLowerCase() || ""
						).includes(searchLower)
					);
				})
			: extendedAccounts;

		// Then sort the filtered results
		return [...filtered].sort((a, b) => {
			// First sort by readiness state
			const isReadyA = a.email_warmth_score !== undefined && a.email_warmth_score !== null && a.email_warmth_score >= 1;
			const isReadyB = b.email_warmth_score !== undefined && b.email_warmth_score !== null && b.email_warmth_score >= 1;
			const isWarmingA =
				a.email_warmth_score !== undefined && a.email_warmth_score !== null && a.email_warmth_score < 1;
			const isWarmingB =
				b.email_warmth_score !== undefined && b.email_warmth_score !== null && b.email_warmth_score < 1;

			// Ready accounts come first
			if (isReadyA && !isReadyB) return -1;
			if (!isReadyA && isReadyB) return 1;

			// Then warming accounts
			if (isWarmingA && !isWarmingB) return -1;
			if (!isWarmingA && isWarmingB) return 1;

			// Extract domains from emails
			const domainA = a.email.split("@")[1]?.toLowerCase() || "";
			const domainB = b.email.split("@")[1]?.toLowerCase() || "";

			// If in same readiness group, sort by domain
			if (domainA !== domainB) {
				return domainA.localeCompare(domainB);
			}

			// If domains are the same, sort by full email
			return a.email.toLowerCase().localeCompare(b.email.toLowerCase());
		});
	}, [extendedAccounts, searchText]);

	// If loading, show skeleton
	if (isLoading) {
		return <EmailAccountsTableSkeleton showCheckbox={selectedIds !== undefined} />;
	}

	// If no accounts, show empty state
	if (accounts.length === 0) {
		return (
			<div className="flex flex-col items-center justify-center py-4">
				<div className="inline-flex flex-col items-center gap-3">
					<h2 className="text-(--label-label-title) self-stretch text-center text-xl font-semibold leading-[27px]">
						No email accounts connected
					</h2>
					<p className="text-body-head leading-body-head text-(--label-label-muted) text-center font-normal">
						Connect your first email account to start sending campaigns
					</p>
				</div>
				<div className="mt-2 flex flex-col gap-2">{/* Connect buttons moved to the header */}</div>
			</div>
		);
	}

	const handleDelete = (account: AccountSlim) => {
		setDeleteModalState({ isOpen: true, account });
	};

	const handleConfirmDelete = () => {
		if (deleteModalState.account) {
			onRemove(deleteModalState.account.id);
			setDeleteModalState({ isOpen: false, account: null });
		}
	};

	const handleClientSelect = async (account: ExtendedAccount, clientId: string | null) => {
		if (!onClientSelect) return;

		try {
			await onClientSelect(account.id, clientId);
		} catch (error) {
			console.error("Failed to update client association:", error);
		}
	};

	const getAccountButtons = (account: ExtendedAccount) => {
		// Don't show the toolbar for disconnected accounts since they have the Remove button
		if (getStatusConfig(account.sync_status, account.email_warmth_score).showReconnect) {
			return null;
		}

		const isDropdownOpen = dropdownOpenForAccount === account.id;
		const clientName = account.client_id ? clients.find(c => c.id === account.client_id)?.name : null;

		return (
			<TableCellToolbar show={isDropdownOpen || hoveredRow === account.id} onClick={e => e.stopPropagation()}>
				<DropdownMenu
					open={isDropdownOpen}
					onOpenChange={open => {
						setDropdownOpenForAccount(open ? account.id : null);
					}}>
					<DropdownMenuTrigger asChild onClick={e => e.stopPropagation()}>
						<Button
							variant="inboxAction"
							className={cn(
								"hover:bg-background-bg-base-hover flex h-6 w-6 cursor-pointer items-center justify-center rounded-[5px] px-1 hover:shadow-[0px_1px_1px_0px_rgba(0,0,0,0.06),0px_4px_4px_-1px_rgba(0,0,0,0.02)]",
								isDropdownOpen && "bg-background-bg-base-hover",
							)}>
							<DotsVerticalIcon className="shrink-0 text-[#1A1A1A]" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end" variant="wide" onCloseAutoFocus={e => e.preventDefault()} sideOffset={5}>
						<DropdownMenuItem
							onClick={e => {
								e.stopPropagation();
								handleDelete(account);
								setDropdownOpenForAccount(null);
							}}>
							<TrashSVG className="text-label-title h-4 w-4 flex-shrink-0" />
							<span className="line-clamp-1 flex-1 overflow-hidden text-ellipsis">Delete</span>
						</DropdownMenuItem>

						{clients.length > 0 && (
							<DropdownMenuSub>
								<DropdownMenuSubTrigger className="h-9 px-3 py-1">
									<UserIcon className="text-label-title h-4 w-4 flex-shrink-0" />
									<span className="line-clamp-1 flex-1 overflow-hidden text-ellipsis">
										{clientName ? clientName : "Assign client"}
									</span>
								</DropdownMenuSubTrigger>
								<DropdownMenuSubContent className="max-h-[200px] w-[200px] overflow-y-auto">
									<DropdownMenuItem
										onClick={e => {
											e.stopPropagation();
											handleClientSelect(account, null);
											setDropdownOpenForAccount(null);
										}}>
										<span className="text-label-muted">None</span>
									</DropdownMenuItem>
									{clients.map(client => (
										<DropdownMenuItem
											key={client.id}
											onClick={e => {
												e.stopPropagation();
												handleClientSelect(account, client.id);
												setDropdownOpenForAccount(null);
											}}>
											<span
												className={cn(
													"line-clamp-1 flex-1 overflow-hidden text-ellipsis",
													account.client_id === client.id && "font-medium",
												)}>
												{client.name}
											</span>
											{account.client_id === client.id && <Check className="text-primary ml-2 h-4 w-4" />}
										</DropdownMenuItem>
									))}
								</DropdownMenuSubContent>
							</DropdownMenuSub>
						)}
					</DropdownMenuContent>
				</DropdownMenu>
			</TableCellToolbar>
		);
	};

	const handleMouseMove = (accountId: string) => {
		setIsKeyboardNavigation(false);
		setHoveredRow(accountId);
	};

	const handleMouseLeave = () => {
		setHoveredRow(null);
	};

	return (
		<div className="bg-background-bg-base flex h-full w-full flex-col rounded-lg pb-2">
			<DeleteConfirmationModal
				isOpen={deleteModalState.isOpen}
				onClose={() => setDeleteModalState({ isOpen: false, account: null })}
				onConfirm={handleConfirmDelete}
				email={deleteModalState.account?.email ?? ""}
			/>
			<DoneForYouDialog isOpen={isDFYDialogOpen} onClose={() => setIsDFYDialogOpen(false)} />
			<div className="flex flex-1 flex-col overflow-hidden">
				<div className="flex-none overflow-hidden">
					<MotionConfig transition={{ type: "spring", bounce: 0, duration: 0.25 }}>
						<div className="max-h-[calc(100vh-115px)] overflow-y-auto">
							<Table>
								<TableHeader>
									<TableHeaderRow>
										{selectedIds !== undefined && <TableHead className="w-[56px]" />}
										<TableHead label="Email" />
										<TableHead label="Name" />
										<TableHead label="Daily limit" />
										<TableHead label="Wait between sends" />
										<TableHead icon={<InfoTooltipBadgeSVG />} label="Status" />
										<TableHead label="Connected" noBorder />
									</TableHeaderRow>
								</TableHeader>
								<TableBody>
									{filteredAndSortedAccounts.map(account => (
										<TableRow
											key={account.id}
											className={cn(
												"relative cursor-pointer",
												hoveredRow === account.id ? "border-label-link/20 bg-background-bg-base-hover" : "",
												dropdownOpenForAccount === account.id ? "border-label-link/20 bg-background-bg-base-hover" : "",
											)}
											onMouseEnter={() => handleMouseMove(account.id)}
											onMouseLeave={handleMouseLeave}
											onClick={() => onSelect(account.id)}>
											{selectedIds !== undefined && (
												<TableCell className="w-[56px]">
													{selectedIds === null ? (
														<Spinner />
													) : (
														<Checkbox
															checked={selectedIds.includes(account.id)}
															onCheckedChange={() => onSelect(account.id)}
															onClick={e => e.stopPropagation()}
														/>
													)}
												</TableCell>
											)}
											<TableCell>
												<div className="flex items-center">
													<div className="truncate font-medium" title={account.email}>
														{account.email}
														{account.client_id && (
															<span className="text-label-label-muted ml-1 inline-block">
																{" "}
																— {clients.find(c => c.id === account.client_id)?.name}
															</span>
														)}
													</div>
												</div>
											</TableCell>
											<TableCell>
												<span className="text-label-label-base text-body-mini font-normal">{account.name || "-"}</span>
											</TableCell>
											<TableCell>
												<span className="text-label-label-base text-body-mini font-normal">
													{account.daily_send_limit} emails
												</span>
											</TableCell>
											<TableCell>
												<span className="text-label-label-base text-body-mini font-normal">
													~{account.wait_time_minutes} min
												</span>
											</TableCell>
											<TableCell className={getStatusConfig(account.sync_status, account.email_warmth_score).textColor}>
												<div className="flex flex-1 items-center gap-[8px]">
													{getStatusConfig(account.sync_status, account.email_warmth_score).icon || (
														<div
															className={cn(
																"h-1.5 w-1.5 rounded-full",
																getStatusConfig(account.sync_status, account.email_warmth_score).bgColor,
															)}
														/>
													)}
													<span className="text-body-base-plus leading-body-base font-medium">
														{getStatusConfig(account.sync_status, account.email_warmth_score).label}
													</span>
												</div>
											</TableCell>
											<TableCell className="relative" noBorder>
												{getStatusConfig(account.sync_status, account.email_warmth_score).showReconnect ? (
													<AccountActions account={account} onDelete={handleDelete} />
												) : (
													<span className="text-body-mini text-label-label-base" title={account.created_at}>
														{new Date(account.created_at).toLocaleDateString()}
													</span>
												)}
												{getAccountButtons(account)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</MotionConfig>
				</div>
				<div className="border-background-bg-divider px-4 py-3 text-left">
					<p className="text-label-title body-mini-plus">
						Need help spinning up accounts? Work with a{" "}
						<Button
							variant="link"
							onClick={() => setIsDFYDialogOpen(true)}
							className="body-mini-plus text-label-link p-0">
							done-for-you vendor
						</Button>
					</p>
				</div>
			</div>
		</div>
	);
};

export default EmailAccountsTable;
