import { TextSVG, NumberHashSVG, ToggleBooleanSVG } from "@/assets/icons/svgs";
import StopSignSVG from "@/assets/icons/svgs/stop-sign.svg?react";
import PlusNoCircleSVG from "@/assets/icons/svgs/plus-no-circle.svg?react";
import ChevronSmallDownSVG from "@/assets/icons/svgs/chevron-small-down.svg?react";
import SearchSVG from "@/assets/icons/svgs/search.svg?react";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuGroup,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuSub,
	DropdownMenuSubTrigger,
	DropdownMenuSubContent,
} from "@/components/ui/dropdown-menu";
import { AvailableField } from "../types";
import { useRef } from "react";
import useCustomFieldsStore from "@/store/useCustomFieldsStore";

interface Props {
	currentColumnId: string;
	mappedTo: string | null;
	searchQuery: string;
	onSearchChange: (query: string) => void;
	filteredDefaultFields: AvailableField[];
	filteredCustomFields: AvailableField[];
	onFieldMapping: (columnId: string, fieldId: string | null) => void;
	onCustomFieldCreate: (type: "text" | "number" | "boolean", columnName: string) => void;
	onColumnSelect: () => void;
	allDefaultFields: AvailableField[];
	columnName: string;
}

export function FieldMappingDropdown({
	currentColumnId,
	mappedTo,
	searchQuery,
	onSearchChange,
	filteredDefaultFields,
	filteredCustomFields,
	onFieldMapping,
	onCustomFieldCreate,
	onColumnSelect,
	allDefaultFields,
	columnName,
}: Props) {
	const searchInputRef = useRef<HTMLInputElement>(null);

	return (
		<DropdownMenu modal>
			<DropdownMenuTrigger
				className="flex h-8 w-full items-center justify-between gap-2 rounded-lg border-[#e3e3e3] bg-white px-2"
				onPointerDown={onColumnSelect}>
				<TriggerContent mappedTo={mappedTo} filteredDefaultFields={allDefaultFields} />
				<ChevronSmallDownSVG className="h-4 w-4 text-[#aaaaaa] transition-transform duration-200 ease-out" />
			</DropdownMenuTrigger>
			<DropdownMenuContent
				side="bottom"
				sideOffset={4}
				style={{ width: "var(--radix-dropdown-menu-trigger-width)" }}
				className="animate-in fade-in-50 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=top]:slide-in-from-bottom-2 duration-200 ease-out">
				<div className="border-background-bg-divider-faint flex items-center border-b p-2">
					<SearchSVG className="text-label-faint mr-2 h-4 w-4" />
					<input
						ref={searchInputRef}
						className="text-body-base leading-body-base placeholder:text-label-faint outline-hidden flex-1 border-0 bg-transparent"
						placeholder="Search variables..."
						value={searchQuery}
						onChange={e => onSearchChange(e.target.value)}
					/>
				</div>
				<DropdownMenuGroup className="w-full">
					<DropdownMenuLabel className="font-geist text-label-label-base line-clamp-1 flex-1 overflow-hidden px-3 py-2 text-[11px] font-medium leading-[16px]">
						Default variables
					</DropdownMenuLabel>
					{filteredDefaultFields.map(field => (
						<DropdownMenuItem
							key={field.id}
							onSelect={() => onFieldMapping(currentColumnId, field.id)}
							className="flex w-full justify-start px-2 transition-colors duration-150 ease-out">
							<div className="flex w-full items-center px-1">
								<span className="mr-2 text-[#397af1]">{"{ }"}</span>
								<span className="truncate">{field.label}</span>
							</div>
						</DropdownMenuItem>
					))}
				</DropdownMenuGroup>
				<DropdownMenuGroup className="w-full">
					<DropdownMenuLabel className="font-geist text-label-label-base line-clamp-1 flex-1 overflow-hidden px-3 py-2 text-[11px] font-medium leading-[16px]">
						Custom variables
					</DropdownMenuLabel>
					{filteredCustomFields.map(field => (
						<DropdownMenuItem
							key={field.id}
							onSelect={() => onFieldMapping(currentColumnId, field.id)}
							className="flex w-full justify-start px-2 transition-colors duration-150 ease-out">
							<div className="flex w-full items-center px-1">
								<span className="mr-2 text-[#5FA97B]">{"{ }"}</span>
								<span className="truncate">{field.label}</span>
							</div>
						</DropdownMenuItem>
					))}
					<DropdownMenuSeparator className="border-background-bg-divider-faint" />
					<DropdownMenuSub>
						<DropdownMenuSubTrigger className="flex w-full justify-start px-2 transition-colors duration-150 ease-out">
							<div className="flex w-full items-center px-1">
								<span className="mr-2 text-[#5FA97B]">
									<PlusNoCircleSVG className="h-4 w-4" />
								</span>
								<span className="text-body-base truncate">Create new variable</span>
							</div>
						</DropdownMenuSubTrigger>
						<DropdownMenuSubContent className="animate-in fade-in-50 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 duration-150 ease-out">
							<DropdownMenuItem
								onClick={() => onCustomFieldCreate("text", columnName)}
								className="flex w-full justify-start px-2 transition-colors duration-150 ease-out">
								<div className="flex w-full items-center px-1">
									<span className="mr-2">
										<TextSVG className="h-4 w-4" />
									</span>
									<span className="truncate">Text</span>
								</div>
							</DropdownMenuItem>
							<DropdownMenuItem
								onClick={() => onCustomFieldCreate("number", columnName)}
								className="flex w-full justify-start px-2 transition-colors duration-150 ease-out">
								<div className="flex w-full items-center px-1">
									<span className="mr-2">
										<NumberHashSVG className="h-4 w-4" />
									</span>
									<span className="truncate">Number</span>
								</div>
							</DropdownMenuItem>
							<DropdownMenuItem
								onClick={() => onCustomFieldCreate("boolean", columnName)}
								className="flex w-full justify-start px-2 transition-colors duration-150 ease-out">
								<div className="flex w-full items-center px-1">
									<span className="mr-2">
										<ToggleBooleanSVG className="h-4 w-4" />
									</span>
									<span className="truncate">True / False</span>
								</div>
							</DropdownMenuItem>
						</DropdownMenuSubContent>
					</DropdownMenuSub>
				</DropdownMenuGroup>
				<DropdownMenuItem
					onSelect={() => onFieldMapping(currentColumnId, null)}
					className="flex w-full justify-start px-2 transition-colors duration-150 ease-out">
					<div className="flex w-full items-center px-1">
						<span className="mr-2 text-[#E33A31]">
							<StopSignSVG className="h-4 w-4" />
						</span>
						<span className="text-body-base truncate">Do not import (skip)</span>
					</div>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}

function TriggerContent({
	mappedTo,
	filteredDefaultFields,
}: {
	mappedTo: string | null;
	filteredDefaultFields: AvailableField[];
}) {
	const { customFields } = useCustomFieldsStore();
	if (mappedTo === null) {
		return (
			<div className="flex w-full items-center">
				<StopSignSVG className="mr-2 h-4 w-4 text-[#E33A31]" />
				<span>Do not import (skip)</span>
			</div>
		);
	}

	// Get the field label from the available fields
	const getFieldLabel = (fieldId: string) => {
		// Check default fields
		const defaultField = filteredDefaultFields.find(f => f.id === fieldId);
		if (defaultField) return { label: defaultField.label, iconColor: "#397af1" };

		// If it's a custom field, remove the "custom." prefix and find in store
		if (fieldId.startsWith("custom.")) {
			const customFieldId = fieldId.replace("custom.", "");
			const customField = customFields.find(f => f.id === customFieldId);
			if (customField) return { label: customField.name, iconColor: "#397af1" };
		}
		return { label: fieldId?.replace("custom.", ""), iconColor: "#5FA97B" }; // Fallback to ID if field not found
	};

	const result = getFieldLabel(mappedTo);
	return (
		<div className="flex w-full items-center">
			<span className={`mr-2 text-[${result.iconColor}]`}>{"{ }"}</span>
			<span className="truncate">{result.label}</span>
		</div>
	);
}
