import { EMAIL_ICON_PATH } from "@/assets/paths/email";
import { TrashSVG } from "@/assets/icons/svgs";
import { CampaignStep } from "@za-zu/types";
import { motion, Reorder, useDragControls } from "motion/react";
import { useState, useEffect, forwardRef } from "react";
import { z } from "zod";

// Function to convert a number to its ordinal word representation
const getOrdinalWord = (num: number): string => {
	const ordinals = ["First", "Second", "Third", "Fourth", "Fifth"];
	if (num <= ordinals.length) {
		return ordinals[num - 1];
	}
	// For numbers beyond 5, generate the ordinal suffix
	const suffix = ["th", "st", "nd", "rd"][num % 10 > 3 ? 0 : (num % 100) - (20 % 10) || num % 10];
	return `${num}${suffix}`;
};

interface SequenceStepProps {
	seq: z.infer<typeof CampaignStep>;
	isActive: boolean;
	isLast: boolean;
	onStepClick: (id: string) => void;
	onStepDelete: (id: string) => void;
	onDelayChange: (id: string, delay: number) => void;
	value?: z.infer<typeof CampaignStep>;
}

// Convert to forwardRef
const SequenceStep = forwardRef<HTMLDivElement, SequenceStepProps>(
	({ seq, isActive, isLast, onStepClick, onStepDelete, onDelayChange, value }, ref) => {
		const dragControls = useDragControls();
		const [localDelay, setLocalDelay] = useState<string>(Math.floor((seq.delay_in_hours || 72) / 24).toString());
		const [isDragging, setIsDragging] = useState(false);

		// Update local state when seq.delay_in_hours changes externally
		useEffect(() => {
			setLocalDelay(Math.floor((seq.delay_in_hours || 72) / 24).toString());
		}, [seq.delay_in_hours]);

		const handleDelayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value;
			setLocalDelay(value);

			// Only update parent if value is a valid number
			if (value !== "") {
				const numValue = parseInt(value, 10);
				if (!isNaN(numValue)) {
					onDelayChange(seq.id, numValue * 24);
				}
			}
		};

		return (
			<div className="mb-[16px]" id={`sequence-step-${seq.id}`} ref={ref}>
				<Reorder.Item
					value={value || seq}
					dragListener={false}
					dragControls={dragControls}
					initial={{ opacity: 0, y: 10, height: 0 }}
					animate={{
						opacity: 1,
						y: 0,
						height: "auto",
						transition: {
							opacity: { duration: 0.25, ease: [0.16, 1, 0.3, 1] },
							y: { duration: 0.35, ease: [0.34, 1.56, 0.64, 1] },
							height: { duration: 0.3, ease: [0.33, 1, 0.68, 1] },
						},
					}}
					exit={{
						opacity: 0,
						height: 0,
						y: 10,
						transition: {
							opacity: { duration: 0.25, ease: [0.16, 1, 0.3, 1] },
							y: { duration: 0.35, ease: [0.34, 1.56, 0.64, 1] },
							height: { duration: 0.3, ease: [0.33, 1, 0.68, 1] },
						},
					}}
					onDragStart={() => setIsDragging(true)}
					onDragEnd={() => setIsDragging(false)}
					layout
					className="overflow-hidden !border-none !bg-transparent"
					style={{
						background: "transparent",
						border: "none",
						boxShadow: "none",
						outline: "none",
					}}>
					<motion.div
						onPointerDown={e => dragControls.start(e)}
						className={`flex h-[32px] w-full shrink-0 cursor-grab items-center rounded-[6px] pl-[8px] ${
							isActive ? "bg-[#F1F1F1]" : "border-[0.5px] border-[#E3E3E3]"
						}`}
						onClick={() => onStepClick(seq.id)}
						whileTap={{ cursor: "grabbing" }}
						animate={{
							scale: isDragging ? 1.02 : 1,
							boxShadow: isDragging ? "0px 5px 10px rgba(0,0,0,0.1)" : "none",
						}}
						transition={{
							type: "spring",
							stiffness: 400,
							damping: 30,
							duration: 0.2,
						}}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill={isActive ? "#1A1A1A" : "#AAAAAA"}
							className="shrink-0">
							<path d={EMAIL_ICON_PATH} />
						</svg>
						<span className="ml-[8px] grow text-[13px] font-medium leading-[18px] text-[#1A1A1A]">
							{seq.step_index === 0 ? "Initial email" : `${getOrdinalWord(seq.step_index)} follow up`}
						</span>
						{seq.step_index > 0 && (
							<motion.button
								className="group mr-[4px] flex h-[24px] w-[24px] shrink-0 items-center justify-center hover:rounded-[5px] hover:border-[0.5px] hover:border-[#D9D9D9] hover:bg-white hover:shadow-[0px_1px_1px_0px_rgba(0,0,0,0.06),0px_4px_4px_-1px_rgba(0,0,0,0.02)]"
								onClick={e => {
									e.stopPropagation();
									onStepDelete(seq.id);
								}}
								whileHover={{ scale: 1.05 }}
								whileTap={{ scale: 0.95 }}
								transition={{
									type: "spring",
									stiffness: 500,
									damping: 25,
									mass: 0.8,
								}}>
								<TrashSVG className="text-label-muted group-hover:fill-text-label-title" width={14} height={14} />
							</motion.button>
						)}
					</motion.div>
				</Reorder.Item>
				{!isLast && (
					<motion.div
						className="mt-[16px] inline-flex items-center gap-[10px] px-4"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{
							delay: 0.15,
							duration: 0.2,
							ease: [0.33, 1, 0.68, 1],
						}}>
						<span className="text-[13px] font-normal leading-[18px] text-[#666666]">Wait</span>
						<input
							type="number"
							value={localDelay}
							onChange={handleDelayChange}
							className="flex h-[20px] w-[41.44px] items-center justify-center gap-[2px] rounded-[5px] border-[0.5px] border-[#D9D9D9] bg-white px-[6px] py-[4px] text-center text-[11px] font-medium leading-[16px] text-[#1A1A1A] [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
							min="0"
						/>
						<span className="text-[13px] font-normal leading-[18px] text-[#666666]">days then</span>
					</motion.div>
				)}
			</div>
		);
	},
);

// Add display name
SequenceStep.displayName = "SequenceStep";

export default SequenceStep;
