import { useUser } from "@clerk/clerk-react";

function getTimeBasedGreeting(): string {
	const hour = new Date().getHours();
	if (hour < 12) return "Good morning";
	if (hour < 17) return "Good afternoon";
	return "Good evening";
}

export default function Home() {
	const { user } = useUser();

	return (
		<main className="flex min-h-screen flex-col items-center justify-center p-6 text-lg font-bold">
			{getTimeBasedGreeting()}, {user?.firstName}
		</main>
	);
}
