import * as React from "react";
import { cn } from "@/lib/utils";

interface BaseToolbarProps {
	/**
	 * Whether the toolbar should be visible
	 */
	show: boolean;
	/**
	 * Optional class name to override the default styles
	 */
	className?: string;
}

interface TableToolbarProps extends React.HTMLAttributes<HTMLDivElement>, BaseToolbarProps {
	/**
	 * Reference to a row element to position the toolbar next to.
	 * When provided, the toolbar will be positioned as a floating element
	 * next to the rightmost visible part of the row.
	 */
	rowRef?: React.RefObject<HTMLTableRowElement>;
	/**
	 * Whether the toolbar should be positioned as a floating element
	 * instead of being positioned within a cell
	 */
	floating?: boolean;
	/**
	 * Optional offset from the right edge of the table for the floating toolbar
	 */
	rightOffset?: number;
}

// Separate interface for the TableCellToolbar
interface TableCellToolbarProps extends React.HTMLAttributes<HTMLDivElement>, BaseToolbarProps {}

// Content of the toolbar without any wrapping
const ToolbarContent = ({ children }: { children: React.ReactNode }) => (
	<div className="border-background-bg-border shadow-low backdrop-blur-modal flex items-center gap-1 rounded-lg border-[0.5px] bg-white/80 p-1">
		{children}
	</div>
);

// A special version of the toolbar specifically for use inside table cells
export const TableCellToolbar = React.forwardRef<HTMLDivElement, TableCellToolbarProps>(
	({ show, children, className, ...props }, ref) => {
		if (!show) return null;

		return (
			<div ref={ref} className={cn("absolute right-[8px] top-[4px] z-10", className)} {...props}>
				<ToolbarContent>{children}</ToolbarContent>
			</div>
		);
	},
);

TableCellToolbar.displayName = "TableCellToolbar";

// The standard toolbar for use outside of table cells
const TableToolbar = React.forwardRef<HTMLDivElement, TableToolbarProps>(
	({ show, children, className, rowRef: _rowRef, floating = false, rightOffset = 16, ...props }, ref) => {
		if (!show) return null;

		if (floating) {
			return (
				<div
					ref={ref}
					className={cn("absolute right-[16px] top-1/2 z-50 -translate-y-1/2", className)}
					style={{ right: `${rightOffset}px` }}
					{...props}>
					<ToolbarContent>{children}</ToolbarContent>
				</div>
			);
		}

		return (
			<div ref={ref} className={cn("absolute right-[8px] top-[4px] z-10", className)} {...props}>
				<ToolbarContent>{children}</ToolbarContent>
			</div>
		);
	},
);

TableToolbar.displayName = "TableToolbar";

export { TableToolbar };
