import { CampaignCreationStepEnum } from "@/app/enums/campaign.enum";
import { CampaignProps } from "@/types/campaign";
import { TLeadWithCustomFieldValues } from "@za-zu/types";
import CampaignEditorHeader from "./CampaignEditorHeader";

import LeadsTable from "../LeadsTable/LeadsTable";
const LeadsEditor = ({
	selectedCampaign,
	campaignLeads,
}: {
	selectedCampaign: CampaignProps;
	campaignLeads: Record<string, TLeadWithCustomFieldValues[]>;
}) => {
	return (
		<div className="flex w-full flex-col">
			<div className="flex w-full flex-col">
				{/* Campaign editor header with navigation and controls */}
				<CampaignEditorHeader
					saveCallback={async () => undefined}
					selectedCampaign={selectedCampaign}
					activeCampaignStep={CampaignCreationStepEnum.Leads}
				/>

				<LeadsTable campaign={selectedCampaign} campaignLeads={campaignLeads} />
			</div>
		</div>
	);
};

export default LeadsEditor;
