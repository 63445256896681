import { NodeViewProps } from "@tiptap/react";
import { IfOrElseIfBlockOptions, OperatorOptions, Variable, VariableOptions } from "./types";

export function isVariable(value: unknown): value is Variable {
	return typeof value === "object" && value !== null && "id" in value && "name" in value && "type" in value;
}

export function isVariableOptions(options: unknown): options is VariableOptions {
	return typeof options === "object" && options !== null && "variables" in options && "variableFuse" in options;
}
export function isOperatorOptions(options: unknown): options is OperatorOptions {
	return typeof options === "object" && options !== null && "variables" in options && "operator" in options;
}
export type IfOrElseIfBlockNodeViewProps = Omit<NodeViewProps, "extension" | "node"> & {
	node: Omit<NodeViewProps["node"], "attrs"> & {
		attrs: {
			liquidCondition: string;
		};
	};
	extension: Omit<NodeViewProps["extension"], "options"> & {
		options: IfOrElseIfBlockOptions;
	};
};

export function isIfOrElseIfBlockOptions(options: unknown): options is IfOrElseIfBlockOptions {
	return typeof options === "object" && options !== null && "isElseIf" in options;
}

export function isIfOrElseIfBlockNodeViewProps(props: NodeViewProps): props is IfOrElseIfBlockNodeViewProps {
	return isIfOrElseIfBlockOptions(props.extension.options);
}
