import { ReactNode, useState, useEffect, useRef } from "react";
import { cn } from "@/lib/utils";

interface SelectionToolbarProps {
	selectedCount: number;
	children?: ReactNode;
	className?: string;
	showToolbar?: boolean;
	entityName?: string;
	entityNameSingular?: string;
	position?: "bottom" | "top";
}

export const SelectionToolbar = ({
	selectedCount,
	children,
	className,
	showToolbar = true,
	entityName = "items",
	entityNameSingular,
	position = "bottom",
}: SelectionToolbarProps) => {
	const [isVisible, setIsVisible] = useState(false);
	const [shouldRender, setShouldRender] = useState(false);
	const [animationClass, setAnimationClass] = useState("animate-toolbar-appear");
	const [displayCount, setDisplayCount] = useState(selectedCount);
	const prevCountRef = useRef(selectedCount);
	const animationTimeoutRef = useRef<NodeJS.Timeout | null>(null);
	const isDisappearingRef = useRef(false);

	// Handle appearance and disappearance based on selectedCount changes
	useEffect(() => {
		// If toolbar should be visible (count > 0 and showToolbar is true)
		if (selectedCount > 0 && showToolbar) {
			// Clear any existing timeouts
			if (animationTimeoutRef.current) {
				clearTimeout(animationTimeoutRef.current);
				animationTimeoutRef.current = null;
			}

			// Reset disappearing state
			isDisappearingRef.current = false;

			// Start appearing
			setShouldRender(true);
			setAnimationClass("animate-toolbar-appear");
			setIsVisible(true);
			setDisplayCount(selectedCount);
		}
		// If toolbar was visible but now should disappear
		else if (prevCountRef.current > 0 && selectedCount === 0 && isVisible) {
			// Set disappearing state flag
			isDisappearingRef.current = true;

			// Keep the previous count for display during disappearing animation
			// (we don't update displayCount here)

			// Start disappearing animation
			setAnimationClass("animate-toolbar-disappear");

			// Schedule removal from DOM after animation completes
			animationTimeoutRef.current = setTimeout(() => {
				setShouldRender(false);
				setIsVisible(false);
				isDisappearingRef.current = false;
			}, 250); // Match the animation duration
		}
		// Only update display count when not disappearing
		else if (!isDisappearingRef.current) {
			setDisplayCount(selectedCount);
		}

		// Update ref for next comparison
		prevCountRef.current = selectedCount;

		// Clean up timeout on unmount
		return () => {
			if (animationTimeoutRef.current) {
				clearTimeout(animationTimeoutRef.current);
			}
		};
	}, [selectedCount, showToolbar, isVisible]);

	// Don't render anything if we shouldn't
	if (!shouldRender) return null;

	// Use entityNameSingular if provided and count is 1, otherwise use entityName
	const displayName = displayCount === 1 && entityNameSingular ? entityNameSingular : entityName;

	return (
		<div
			className={cn(
				"fixed left-1/2 z-50 -translate-x-1/2 transform",
				position === "bottom" ? "bottom-10" : "top-6",
				"border-background-bg-border bg-background-bg-base inline-flex items-center gap-3 rounded-[10px] border-[0.5px] p-1 pl-3 shadow-[0px_1px_1px_0px_rgba(0,0,0,0.04),0px_2px_8px_0px_rgba(0,0,0,0.04),0px_3px_17px_0px_rgba(0,0,0,0.04),0px_4px_30px_0px_rgba(0,0,0,0.12)]",
				animationClass,
				className,
			)}
			style={{
				backdropFilter: "blur(4px)",
				WebkitBackdropFilter: "blur(4px)",
			}}>
			<span className="text-label-title whitespace-nowrap">
				<span className="body-base-plus">{displayCount}</span> {displayName} selected
			</span>
			<div className="bg-background-bg-border h-[12px] w-[2px] rounded-[2px]" />
			<div className="flex items-center gap-2">{children}</div>
		</div>
	);
};
