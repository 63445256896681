import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { CustomFieldModalState } from "../types";

interface Props {
	state: CustomFieldModalState;
	onStateChange: (state: CustomFieldModalState) => void;
	onSubmit: () => void;
}

export function CustomFieldModal({ state, onStateChange, onSubmit }: Props) {
	return (
		<Dialog
			open={state.isOpen}
			onOpenChange={open => {
				if (!open) {
					onStateChange({ ...state, isOpen: false });
				}
			}}>
			<DialogContent className="border-background-bg-border bg-background-bg-shade shadow-float flex w-[441px] flex-col items-end justify-center gap-4 rounded-2xl border-[0.5px] p-4">
				<DialogHeader className="w-full text-left">
					<DialogTitle className="font-geist text-[15px] font-medium leading-[22px] text-[#1A1A1A]">
						Create new variable
					</DialogTitle>
				</DialogHeader>
				<div className="w-full space-y-4 py-4">
					<Input
						placeholder="Enter variable name"
						value={state.name}
						onChange={e => onStateChange({ ...state, name: e.target.value })}
						autoFocus
						className="flex h-8 items-center gap-2 rounded-md border-[0.5px] border-[#D9D9D9] bg-white px-2"
					/>
					<div className="flex justify-end gap-2">
						<Button variant="exit" onClick={() => onStateChange({ isOpen: false, type: null, name: "" })}>
							Cancel
						</Button>
						<Button variant="brand" onClick={onSubmit} disabled={!state.name.trim()}>
							Create variable
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
