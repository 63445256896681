import { ACCEPTABLE_FALSE_VALUES, ACCEPTABLE_TRUE_VALUES } from "@/store/useCampaignStore";
import { CustomField } from "@za-zu/types";

export function parseCustomFieldValue(
	rawValue: string | number | boolean | null | undefined,
	fieldType: CustomField.CustomFieldType,
): string | number | boolean | null {
	if (rawValue !== "" && rawValue !== undefined && rawValue !== null) {
		switch (fieldType) {
			case "number": {
				if (typeof rawValue === "number") return rawValue;
				if (typeof rawValue !== "string") break;
				const result = Number(rawValue.trim());
				if (Number.isNaN(result)) {
					break;
				}
				return result;
			}
			case "boolean": {
				if (typeof rawValue === "boolean") return rawValue;
				if (typeof rawValue !== "string") break;
				const normalized = rawValue.trim().toLowerCase();
				if (ACCEPTABLE_TRUE_VALUES.includes(normalized)) {
					return true;
				} else if (ACCEPTABLE_FALSE_VALUES.includes(normalized)) {
					return false;
				}
				break;
			}
			case "text":
				return `${rawValue}`;
		}
	}
	return null;
}
