import * as React from "react";

const DotsVerticalIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.83331 2.33366C5.83331 1.68934 6.35563 1.16699 6.99998 1.16699C7.64433 1.16699 8.16665 1.68934 8.16665 2.33366C8.16665 2.97798 7.64433 3.50033 6.99998 3.50033C6.35563 3.50033 5.83331 2.97798 5.83331 2.33366Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.83331 7.00033C5.83331 6.35598 6.35564 5.83366 6.99998 5.83366C7.64432 5.83366 8.16665 6.35598 8.16665 7.00033C8.16665 7.64467 7.64432 8.16699 6.99998 8.16699C6.35564 8.16699 5.83331 7.64467 5.83331 7.00033Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.83331 11.667C5.83331 11.0227 6.35564 10.5003 6.99998 10.5003C7.64432 10.5003 8.16665 11.0227 8.16665 11.667C8.16665 12.3113 7.64432 12.8337 6.99998 12.8337C6.35564 12.8337 5.83331 12.3113 5.83331 11.667Z"
			fill="currentColor"
		/>
	</svg>
);

export default DotsVerticalIcon;
