import { useEmailAccountsStore } from "@/store";
import { CampaignProps } from "@/types/campaign";
import { useState, useEffect } from "react";
import {
	ConnectGoogleButton,
	ConnectMicrosoftButton,
	ConnectSMTPButton,
} from "../ConnectAccountsButton/ConnectAccountButton";
import { EmailAccountsTable } from "../EmailAccountsTable";
import { GoogleAdminPermissionsDialog } from "../GoogleAdminPermissionsDialog";
import { Link, Callout } from "@/components/ui";
import { Button } from "@/components/ui/button";
import { DoneForYouDialog } from "../DoneForYouDialog";
import useFetchStore from "@/store/fetchStore";
import type { TClientRow } from "@za-zu/types";
import { toast } from "sonner";

type SelectEmailAccountsProps =
	| {
			selectedCampaign: CampaignProps;
			/** Null means we are still fetching. */
			selectedAccountIds: string[] | null;
			onSelectedAccountsChange: React.Dispatch<React.SetStateAction<string[]>>;
	  }
	| {
			selectedCampaign?: never;
			selectedAccountIds?: never;
			onSelectedAccountsChange?: never;
	  };

const SelectEmailAccounts = ({ selectedAccountIds, onSelectedAccountsChange }: SelectEmailAccountsProps) => {
	const { accounts, emailAccountsInitialized, deleteAccount } = useEmailAccountsStore();
	const [clients, setClients] = useState<TClientRow[]>([]);
	const { apiClient } = useFetchStore();

	const [isGoogleDialogOpen, setIsGoogleDialogOpen] = useState(false);
	const [isDFYDialogOpen, setIsDFYDialogOpen] = useState(false);
	const [showCallout, setShowCallout] = useState(true);

	// Fetch clients when component mounts
	useEffect(() => {
		const fetchClients = async () => {
			if (!apiClient) return;
			try {
				const fetchedClients = await apiClient.getClients();
				setClients(fetchedClients);
			} catch (error) {
				console.error("Error fetching clients:", error);
				toast.error("Error fetching clients");
			}
		};

		fetchClients();
	}, [apiClient]);

	const handleOnGoogleClick = () => {
		setIsGoogleDialogOpen(true);
	};

	const toggleSelect = (id: string) => {
		if (!selectedAccountIds) return;
		const newSelectedIds = selectedAccountIds.includes(id)
			? selectedAccountIds.filter(existingId => existingId !== id)
			: [...selectedAccountIds, id];

		onSelectedAccountsChange(newSelectedIds);
	};

	const handleClientSelect = async (accountId: string, clientId: string | null) => {
		try {
			// Get access to the email accounts store methods
			const { updateAccount } = useEmailAccountsStore.getState();

			// Find the account to update
			const account = accounts.find(acc => acc.id === accountId);
			if (!account) {
				throw new Error("Account not found");
			}

			// Create the updated account
			const updatedAccount = {
				...account,
				client_id: clientId,
			};

			// Update the account in the store immediately for a responsive UI
			updateAccount(updatedAccount);

			// Make the API call to update the client association
			if (!apiClient) {
				throw new Error("API client not available");
			}

			try {
				await apiClient.updateEmailAccountClient(accountId, clientId);
				// Remove success toast for optimistic UI
			} catch (apiError) {
				console.error("API error updating client association:", apiError);
				toast.error("Failed to update client");
			}

			return Promise.resolve();
		} catch (error) {
			console.error("Failed to update client:", error);
			toast.error("Failed to update client");
			return Promise.reject(error);
		}
	};

	// Only show loading state if we're initializing AND there are accounts to load
	// If there are no accounts, show empty state immediately
	if (!emailAccountsInitialized && accounts.length > 0) {
		return (
			<div className="mt-4 flex flex-col items-center">
				<div className="w-full max-w-[555px] p-4">
					<div className="flex flex-col items-center justify-center py-4">
						<div className="inline-flex flex-col items-center gap-3">
							<h2 className="text-(--label-label-title) self-stretch text-center text-xl font-semibold leading-[27px]">
								Loading email accounts...
							</h2>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return (
		<>
			{isGoogleDialogOpen && (
				<GoogleAdminPermissionsDialog isOpen={isGoogleDialogOpen} onClose={() => setIsGoogleDialogOpen(false)} />
			)}
			<DoneForYouDialog isOpen={isDFYDialogOpen} onClose={() => setIsDFYDialogOpen(false)} />
			{accounts.length === 0 ? (
				<div className="flex flex-col items-center">
					<div className="w-full max-w-[555px] p-2">
						{showCallout && (
							<Callout
								className="mb-4 mt-8 w-full"
								onClose={() => setShowCallout(false)}
								primaryText="Email infrastructure 101"
								secondaryText="Protect your main domain— use dedicated sending domains for cold emails. Diversify, isolate, and keep reaching humans— not spam folders."
							/>
						)}

						<div className="flex flex-col items-center justify-center py-6">
							<div className="inline-flex flex-col items-center gap-3">
								<h2 className="text-(--label-label-title) self-stretch text-center text-xl font-semibold leading-[27px]">
									Start connecting your email accounts
								</h2>
								<p className="text-body-head leading-body-head text-(--label-label-muted) max-w-100 text-center font-normal">
									Add as many as you want. We&apos;ll start warming them up. <br />
									Need help spinning up accounts?{" "}
									<Link href="https://www.za-zu.com/blog/handbook#the-infrastructure" external>
										Read our handbook
									</Link>
									{" or work with a "}
									<Button variant="link" onClick={() => setIsDFYDialogOpen(true)}>
										done-for-you vendor
									</Button>
									.
								</p>
							</div>

							<div className="mt-8 flex w-[224px] flex-col items-center gap-3">
								<ConnectGoogleButton mode="dialog" onClick={handleOnGoogleClick} />
								<ConnectMicrosoftButton />
								<ConnectSMTPButton />
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="h-full overflow-hidden">
					<EmailAccountsTable
						accounts={accounts}
						selectedIds={selectedAccountIds}
						onSelect={toggleSelect}
						onRemove={deleteAccount}
						_onGoogleClick={handleOnGoogleClick}
						clients={clients}
						onClientSelect={handleClientSelect}
					/>
				</div>
			)}
		</>
	);
};

export default SelectEmailAccounts;
