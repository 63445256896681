import { stepSeparatorPath } from "@/assets/icons/paths/step-separator";
import IconSVG from "@/components/IconSVG";
import { cn } from "@/lib/utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@radix-ui/react-tooltip";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CommandItem, CommandsListProps } from "../types";
import NestedCommandsList from "./NestedCommandsList";

const CommandsList: React.FC<CommandsListProps> = ({ items, command }) => {
	// Start with first regular item selected (index 0)
	const [selectedIndexChain, setSelectedIndexChain] = useState<number[]>([0]);
	const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);
	const itemsRef = useRef<HTMLDivElement>(null);

	const allItems = [...items.defaultItems, ...items.customItems, ...items.belowDividerItems];

	// Get the number of visible regular items and their indices
	const visibleItemsInfo = useMemo(() => {
		const regularItems = allItems.filter(
			item =>
				item.title !== "Type to create a new variable" &&
				!(item.title.startsWith('Create "') && item.title.endsWith('"')),
		);

		// Get the index of the first visible item
		const firstVisibleIndex = allItems.findIndex(
			item =>
				item.title !== "Type to create a new variable" &&
				!(item.title.startsWith('Create "') && item.title.endsWith('"')),
		);

		return {
			count: regularItems.length,
			firstVisibleIndex: firstVisibleIndex >= 0 ? firstVisibleIndex : -1,
		};
	}, [items]);

	// Update selection when visible items change
	useEffect(() => {
		if (visibleItemsInfo.count === 0) {
			// If no regular items visible, select Create row
			setSelectedIndexChain([-1]);
		} else {
			// When items are filtered, select the first visible item
			setSelectedIndexChain([visibleItemsInfo.firstVisibleIndex]);
		}
	}, [visibleItemsInfo]);

	/** The potentially nested list of items the user is currently selecting with arrow keys */
	const [currentItemSelectionScope, setCurrentItemSelectionScope] = useState<CommandItem[]>(allItems);

	const scrollToItemAtEndOfItemChain = useCallback((indexChain: number[]) => {
		if (itemsRef.current) {
			const lastIndex = indexChain[0];
			// Handle Create row specially
			const targetElement =
				lastIndex === -1
					? itemsRef.current.querySelector('button[data-create-row="true"]')
					: itemsRef.current.querySelector(`button[data-index="${lastIndex}"]`);

			if (targetElement) {
				targetElement.scrollIntoView({
					behavior: "smooth",
					block: "nearest",
				});
			}
		}
	}, []);

	const selectItemChain = useCallback(
		(indexChain: number[] | null) => {
			if (!indexChain) {
				// If clearing selection, default to Create row
				setSelectedIndexChain([-1]);
				setCurrentItemSelectionScope(allItems);
				return;
			}

			setSelectedIndexChain(indexChain);
			setCurrentItemSelectionScope(allItems);
			scrollToItemAtEndOfItemChain(indexChain);
		},
		[allItems, scrollToItemAtEndOfItemChain],
	);

	const upHandlerNested = useCallback(() => {
		setIsKeyboardNavigation(true);
		const currentIndex = selectedIndexChain[0];

		if (currentIndex === -1) {
			// From Create row, go to last item
			selectItemChain([allItems.length - 1]);
		} else if (currentIndex === 0) {
			// From first item, go to Create row
			selectItemChain([-1]);
		} else {
			// Normal upward navigation
			selectItemChain([currentIndex - 1]);
		}
	}, [allItems, selectedIndexChain, selectItemChain]);

	const downHandlerNested = useCallback(() => {
		setIsKeyboardNavigation(true);
		const currentIndex = selectedIndexChain[0];

		if (currentIndex === -1) {
			// From Create row, go to first item
			selectItemChain([0]);
		} else if (currentIndex === allItems.length - 1) {
			// From last item, go to Create row
			selectItemChain([-1]);
		} else {
			// Normal downward navigation
			selectItemChain([currentIndex + 1]);
		}
	}, [allItems, selectedIndexChain, selectItemChain]);

	const rightHandlerNested = useCallback(() => {
		// Find the first child, if it exists.
		const relevantIndex = selectedIndexChain ?? [0];
		const lastIndex = relevantIndex.slice(-1)[0] ?? 0;
		const currentlySelectedItem = currentItemSelectionScope[lastIndex] ?? {};
		const firstChild = (currentlySelectedItem.children ?? [])[0];
		if (!firstChild) {
			// No children, so just return;
			return;
		}
		// We have a child, which means we can change the currently selected scope
		selectItemChain([...relevantIndex, 0]);
	}, [currentItemSelectionScope, selectedIndexChain, selectItemChain]);

	const leftHandlerNested = useCallback(() => {
		// Lop one off the end if it can be done
		const relevantIndex = (selectedIndexChain ?? [0, 0]).slice(0, -1);
		if (relevantIndex.length === 0) {
			relevantIndex.push(0);
		}

		selectItemChain(relevantIndex);
	}, [currentItemSelectionScope, selectedIndexChain, selectItemChain]);

	const enterHandlerNested = useCallback(() => {
		if (selectedIndexChain !== null && selectedIndexChain.length > 0) {
			// Something must be selected. This assumes the selectedIndexChain is valid.
			const lastIndexInChain = selectedIndexChain.slice(-1)[0];

			let item: CommandItem | undefined;
			if (lastIndexInChain === -1) {
				item = items.belowDividerItems.find(item => item.title.startsWith('Create "') && item.title.endsWith('"'));
			} else {
				item = allItems[lastIndexInChain];
			}

			if (!item) {
				return false;
			}

			if (!item.disabled && typeof command === "function") {
				command(item);
				return true;
			}
		}
		return false;
	}, [selectedIndexChain, items, allItems, command]);

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			if (event.key === "ArrowUp") {
				event.preventDefault();
				upHandlerNested();
			} else if (event.key === "ArrowDown") {
				event.preventDefault();
				downHandlerNested();
			} else if (event.key === "ArrowRight") {
				event.preventDefault();
				rightHandlerNested();
			} else if (event.key === "ArrowLeft") {
				event.preventDefault();
				leftHandlerNested();
			} else if (event.key === "Enter") {
				event.preventDefault();
				if (enterHandlerNested()) {
					event.stopPropagation();
				}
			}
		};

		document.addEventListener("keydown", onKeyDown, true);
		return () => {
			document.removeEventListener("keydown", onKeyDown, true);
		};
	}, [upHandlerNested, downHandlerNested, enterHandlerNested]);

	const SubMenuIcon = (
		<IconSVG
			path={stepSeparatorPath}
			width={12}
			height={12}
			viewBox="0 0 12 12"
			fill="#AAAAAA"
			className="text-(--label-label-muted)"
		/>
	);

	return (
		<div
			className="slash-menu border-background-bg-border shadow-float flex w-[248px] flex-col items-start overflow-hidden rounded-[10px] border-[0.5px] bg-white"
			ref={itemsRef}>
			{/* Variable creation related items */}
			{items.belowDividerItems
				.filter(
					item =>
						item.title === "Type to create a new variable" ||
						(item.title.startsWith('Create "') && item.title.endsWith('"')),
				)
				.map(item => (
					<div key={item.title} className="outline-hidden flex w-full items-center bg-white p-1">
						{item.title === "Type to create a new variable" ? (
							// Info row - not interactive
							<div className="flex h-9 w-full items-center gap-3 rounded-md px-3">
								{item.icon}
								<span className="slash-menu__item-title text-body-base leading-body-base text-label-label-muted flex-1 text-left font-normal">
									{item.title}
								</span>
							</div>
						) : (
							// Create row - interactive
							<TooltipProvider>
								<Tooltip
									open={((selectedIndexChain ?? [])[0] === -1 || visibleItemsInfo.count === 0) && !item.disabled}
									delayDuration={0}>
									<div className="outline-hidden flex w-full items-center bg-white p-1">
										<TooltipTrigger asChild>
											<button
												data-create-row="true"
												className={cn(
													"outline-hidden flex h-9 w-full items-center gap-3 rounded-md px-3",
													((selectedIndexChain ?? [])[0] === -1 || visibleItemsInfo.count === 0) &&
														!item.disabled &&
														"bg-background-bg-base-hover",
													!isKeyboardNavigation && !item.disabled && "hover:bg-background-bg-base-hover",
													item.disabled && "pointer-events-none",
												)}
												onClick={() => {
													if (!item.disabled) {
														command(item);
													}
												}}
												onMouseMove={() => {
													if (!item.disabled) {
														setIsKeyboardNavigation(false);
														selectItemChain([-1]);
													}
												}}
												disabled={item.disabled}>
												<div className={cn(item.disabled && "text-label-label-muted")}>{item.icon}</div>
												<span className="slash-menu__item-title flex-1 text-left">
													<span
														className={cn(
															"text-body-base leading-body-base font-normal",
															item.disabled ? "text-label-label-muted" : "text-label-label-base",
														)}>
														Create{" "}
													</span>
													<span
														className={cn(
															"text-body-base-plus leading-body-base-plus font-medium",
															item.disabled ? "text-label-label-muted" : "text-label-label-base",
														)}>
														{item.title.slice(6)}
													</span>
												</span>
												{(item.children ?? []).length > 0 && !item.disabled && <div>{SubMenuIcon}</div>}
											</button>
										</TooltipTrigger>
									</div>
									{item.children && item.children.length > 0 && !item.disabled && (
										<TooltipContent side="right">
											<NestedCommandsList
												command={command}
												items={item.children}
												scopeIndexChain={[-1]}
												selectItemChain={selectItemChain}
												selectedIndexChain={selectedIndexChain}
												subMenuClassName={item.subMenuClassName}
											/>
										</TooltipContent>
									)}
								</Tooltip>
							</TooltipProvider>
						)}
					</div>
				))}

			{/* Group for default and custom items */}
			{(items.defaultItems.length > 0 || items.customItems.length > 0) && (
				<div className="border-background-bg-border w-full border-b">
					{allItems.slice(0, items.defaultItems.length + items.customItems.length).map((item, index) => (
						<TooltipProvider key={index}>
							<Tooltip open={(selectedIndexChain ?? [])[0] === index} delayDuration={0}>
								<div className="outline-hidden flex w-full items-center bg-white p-1">
									<TooltipTrigger asChild>
										<button
											data-index={index}
											className={cn(
												"outline-hidden flex h-9 w-full items-center gap-3 rounded-md px-3",
												(selectedIndexChain ?? [])[0] === index && "bg-background-bg-base-hover",
												!isKeyboardNavigation && "hover:bg-background-bg-base-hover",
												item.disabled && "pointer-events-none opacity-50",
											)}
											onClick={() => {
												command(item);
											}}
											onMouseMove={() => {
												setIsKeyboardNavigation(false);
												selectItemChain([index]);
											}}
											disabled={item.disabled}>
											{item.icon}
											<span
												className={cn(
													"slash-menu__item-title text-body-base leading-body-base flex-1 text-left",
													item.className ?? "text-label-label-base",
												)}>
												{item.title}
											</span>
											{(item.children ?? []).length > 0 && !item.disabled && <div>{SubMenuIcon}</div>}
											{item.rightIcon && item.rightIcon}
										</button>
									</TooltipTrigger>
								</div>
								{item.children && item.children.length > 0 && !item.disabled && (
									<TooltipContent side="right">
										<NestedCommandsList
											command={command}
											items={item.children}
											scopeIndexChain={[index]}
											selectItemChain={selectItemChain}
											selectedIndexChain={selectedIndexChain}
											subMenuClassName={item.subMenuClassName}
										/>
									</TooltipContent>
								)}
							</Tooltip>
						</TooltipProvider>
					))}
				</div>
			)}

			{/* Below divider items */}
			{allItems
				.slice(items.defaultItems.length + items.customItems.length)
				.filter(
					item =>
						item.title !== "Type to create a new variable" &&
						!(item.title.startsWith('Create "') && item.title.endsWith('"')),
				)
				.map((item, index) => {
					const isConditionCommand = item.title === "Add condition";
					return (
						<TooltipProvider key={index}>
							<Tooltip
								open={
									item.title !== "Type to create a new variable" &&
									(selectedIndexChain ?? [])[0] === index + items.defaultItems.length + items.customItems.length
								}
								delayDuration={0}>
								<div
									className={cn(
										"outline-hidden flex w-full items-center bg-white p-1",
										isConditionCommand && "border-border-subtle border-b",
									)}>
									<TooltipTrigger asChild>
										<button
											className={cn(
												"outline-hidden flex h-9 w-full items-center gap-3 rounded-md px-3",
												item.title !== "Type to create a new variable" &&
													(selectedIndexChain ?? [])[0] ===
														index + items.defaultItems.length + items.customItems.length &&
													"bg-background-bg-base-hover",
												!isKeyboardNavigation && "hover:bg-background-bg-base-hover",
												item.disabled && "pointer-events-none opacity-50",
											)}
											onClick={() => {
												command(item);
											}}
											onMouseMove={() => {
												setIsKeyboardNavigation(false);
												if (item.title !== "Type to create a new variable") {
													selectItemChain([index + items.defaultItems.length + items.customItems.length]);
												}
											}}
											disabled={item.disabled}>
											{item.icon}
											<span
												className={cn(
													"slash-menu__item-title text-body-base leading-body-base flex-1 text-left",
													item.className ?? "text-label-label-base",
												)}>
												{item.title}
											</span>
											{(item.children ?? []).length > 0 && !item.disabled && <div>{SubMenuIcon}</div>}
											{item.rightIcon && item.rightIcon}
										</button>
									</TooltipTrigger>
								</div>
								{item.children && item.children.length > 0 && !item.disabled && (
									<TooltipContent side="right">
										<NestedCommandsList
											command={command}
											items={item.children}
											scopeIndexChain={[index + items.defaultItems.length + items.customItems.length]}
											selectItemChain={selectItemChain}
											selectedIndexChain={selectedIndexChain}
											subMenuClassName={item.subMenuClassName}
										/>
									</TooltipContent>
								)}
							</Tooltip>
						</TooltipProvider>
					);
				})}
		</div>
	);
};

export default React.memo(CommandsList);
