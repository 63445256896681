import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useOrganization } from "@clerk/clerk-react";
import SearchBar from "@/components/SearchBar/SearchBar";
import { Button } from "@/components/ui/button";
import PlusButton from "@/assets/icons/svgs/plus-button.svg?react";
{
	/* import ImagePlus from "@/assets/icons/svgs/image-plus.svg?react"; */
}
import { Table, TableBody, TableCell, TableHead, TableHeader, TableHeaderRow, TableRow } from "@/components/ui/table";
import { TableCellToolbar } from "@/components/ui/table-toolbar";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import DotsVerticalIcon from "@/assets/icons/svgs/table-header/dots-vertical";
import ArchiveBoxIcon from "@/assets/icons/svgs/table-header/archive-box";
import useFetchStore from "@/store/fetchStore";
import { ClientsTableSkeleton } from "./ClientsTableSkeleton";
import { isEqual } from "lodash";
import { z } from "zod";
import { toast } from "sonner";
import { cn } from "@/lib/utils";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

// Using type imports to avoid the "refers to a value" linter error
import type { ClientUserRow, ClientUserInvitationRow } from "@za-zu/types";

type Client = {
	id: string;
	name: string;
	created_at: string;
	organization_id: string;
	created_by: string;
	members: z.infer<typeof ClientUserRow>[];
	invitations: z.infer<typeof ClientUserInvitationRow>[];
};

// Form validation schema
const clientFormSchema = z.object({
	name: z.string().min(1, { message: "Client name is required" }),
	inviteEmails: z.string().optional(),
});

type ClientFormValues = z.infer<typeof clientFormSchema>;

const ClientsPage: React.FC = () => {
	const [searchText, setSearchText] = useState("");
	const [showClientDetails, setShowClientDetails] = useState(false);
	const [clients, setClients] = useState<Client[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const [hoveredRow, setHoveredRow] = useState<string | null>(null);
	const [archiveModalState, setArchiveModalState] = useState<{ isOpen: boolean; client: Client | null }>({
		isOpen: false,
		client: null,
	});

	const form = useForm<ClientFormValues>({
		resolver: zodResolver(clientFormSchema),
		defaultValues: {
			name: "",
			inviteEmails: "",
		},
	});

	const { organization } = useOrganization();
	const { apiClient } = useFetchStore();

	// Focus the client name input when the form opens
	useEffect(() => {
		if (showClientDetails) {
			form.reset();
			setTimeout(() => {
				form.setFocus("name");
			}, 0);
		}
	}, [showClientDetails, form]);

	// Fetch clients on component mount
	useEffect(() => {
		if (organization?.id && apiClient) {
			fetchClients();
		}
	}, [organization?.id, apiClient]);

	const fetchClients = async () => {
		if (!organization?.id || !apiClient) return;

		setIsDataLoading(true);
		try {
			const data = await apiClient.getClients();
			// Only update state if the data has actually changed
			setClients(prevClients => {
				if (isEqual(prevClients, data)) {
					return prevClients; // No change needed
				}
				return data;
			});
		} catch (error) {
			console.error("Error fetching clients:", error);
			alert("Failed to load clients. Please try again.");
		} finally {
			setIsDataLoading(false);
		}
	};

	// Handle form submission
	const onSubmit = async (values: ClientFormValues) => {
		if (!apiClient) return;

		setIsLoading(true);
		try {
			// Create the client
			const client = await apiClient.createClient(values.name.trim());

			// Handle invitations if any
			if (values.inviteEmails?.trim()) {
				const emails = values.inviteEmails
					.split(/[,\s]+/)
					.map((email: string) => email.trim())
					.filter((email: string) => email.includes("@"));

				// Process each invitation sequentially
				for (const email of emails) {
					try {
						await apiClient.inviteToClient(
							client.id,
							"org:member", // Default org role
							email,
							"admin", // Default membership tier (using a valid tier value)
						);
					} catch (inviteError) {
						console.error(`Error inviting ${email}:`, inviteError);
						// Continue with other invitations even if one fails
					}
				}
			}

			toast.success("Client created successfully");

			// Reset form and close dialog
			form.reset();
			setShowClientDetails(false);

			// Refresh client list
			fetchClients();
		} catch (error) {
			console.error("Error creating client:", error);
			toast.error("Failed to create client. Please try again.");
		} finally {
			setIsLoading(false);
		}
	};

	// Memoize the filtered clients to prevent recalculation on every render
	const filteredClients = useMemo(() => {
		return clients.filter(client => client.name.toLowerCase().includes(searchText.toLowerCase()));
	}, [clients, searchText]);

	// Memoize a function that formats dates to prevent recalculation on every render
	const formatDate = useCallback((dateString: string) => {
		const date = new Date(dateString);
		const month = date.getMonth() + 1; // getMonth() is zero-based
		const day = date.getDate();
		const year = date.getFullYear();
		return `${month}/${day}/${year}`;
	}, []);

	const handleArchive = (client: Client) => {
		setArchiveModalState({ isOpen: true, client });
	};

	const handleConfirmArchive = async () => {
		// For now, just close the modal
		setArchiveModalState({ isOpen: false, client: null });
		toast.success("Archive functionality coming soon");
	};

	return (
		<div>
			<div className="pb-6">
				<h1 className="prose-title-title">Clients</h1>
			</div>

			<div className="flex items-center justify-between pb-4">
				<div className="max-w-68.25">
					<SearchBar searchText={searchText} setSearchText={setSearchText} placeholder="Search clients..." />
				</div>
				<Button
					variant={showClientDetails ? "inactive" : "default"}
					onClick={() => !showClientDetails && setShowClientDetails(true)}>
					<PlusButton className="h-4 w-4" /> New client
				</Button>
			</div>

			{showClientDetails && (
				<div className="mb-4 flex flex-col items-start justify-center gap-4 self-stretch rounded-lg border p-4">
					<h2 className="prose-body-base-plus text-label-title">Client details</h2>
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-4">
							<FormField
								control={form.control}
								name="name"
								render={({ field }) => (
									<FormItem>
										<div className="flex w-full items-center gap-3">
											<FormControl>
												<Input
													{...field}
													placeholder="Client name"
													className="border-background-bg-border bg-background-bg-base shadow-low text-body-base-plus h-8 flex-1 rounded-[6px] border-[0.5px] px-2 py-0"
												/>
											</FormControl>
										</div>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="inviteEmails"
								render={({ field }) => (
									<FormItem>
										<FormLabel className="prose-body-base-plus text-label-title">Invite new client-users</FormLabel>
										<FormControl>
											<div className="space-y-1">
												<div className="prose-body-base-plus text-label-label-muted">
													Enter or paste one or more email addresses, separated by spaces or commas.
												</div>
												<Textarea
													{...field}
													placeholder="example@email.com, another@email.com"
													className="border-background-bg-border bg-background-bg-base shadow-low text-body-base-plus h-24 w-full resize-none rounded-[6px] border-[0.5px] px-2 py-2"
												/>
											</div>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<div className="flex items-center justify-end gap-3 pt-2">
								<Button
									type="button"
									variant="exit"
									onClick={() => {
										setShowClientDetails(false);
										form.reset();
									}}>
									Cancel
								</Button>
								<Button type="submit" variant="default" disabled={isLoading || !apiClient}>
									{isLoading ? "Creating..." : "Create client"}
								</Button>
							</div>
						</form>
					</Form>
				</div>
			)}

			<Dialog
				open={archiveModalState.isOpen}
				onOpenChange={() => setArchiveModalState({ isOpen: false, client: null })}>
				<DialogContent className="p-4 sm:max-w-[400px]" style={{ backdropFilter: "blur(2px)" }}>
					<DialogHeader className="space-y-3">
						<DialogTitle className="text-[15px] font-medium leading-[22px] text-[#1A1A1A]">Archive client</DialogTitle>
						<DialogDescription className="py-1 text-[13px] leading-[18px] text-[#666666]">
							Are you sure you want to archive the client{" "}
							<span className="text-label-title">{archiveModalState.client?.name}</span>? This action cannot be undone.
						</DialogDescription>
					</DialogHeader>
					<DialogFooter className="mt-6">
						<Button
							variant="ghost"
							onClick={() => setArchiveModalState({ isOpen: false, client: null })}
							className="h-8 px-3 text-sm">
							Cancel
						</Button>
						<Button variant="destructive" onClick={handleConfirmArchive} className="text-sm">
							Archive client
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			{isDataLoading ? (
				<ClientsTableSkeleton numRows={3} />
			) : (
				<div className="rounded-lg border shadow-sm">
					<Table>
						<TableHeader>
							<TableHeaderRow className="border-t-0 [&_tr]:border-t-0">
								<TableHead label="Client" />
								<TableHead label="Created" />
								<TableHead label="Access" noBorder />
							</TableHeaderRow>
						</TableHeader>
						<TableBody>
							{filteredClients.length > 0 ? (
								filteredClients.map(client => (
									<TableRow
										key={client.id}
										onMouseEnter={() => setHoveredRow(client.id)}
										onMouseLeave={() => setHoveredRow(null)}
										className={cn(
											"relative cursor-pointer",
											hoveredRow === client.id ? "border-label-link/20 bg-background-bg-base-hover" : "",
										)}>
										<TableCell>{client.name}</TableCell>
										<TableCell>{formatDate(client.created_at)}</TableCell>
										<TableCell className="flex items-center justify-between">
											<span className="text-label-label-muted bg-background-bg-dim flex h-6 items-center rounded-full px-2">
												{client.members?.length || 0} client-users
											</span>
											<TableCellToolbar show={hoveredRow === client.id}>
												<DropdownMenu>
													<DropdownMenuTrigger asChild onClick={e => e.stopPropagation()}>
														<Button
															variant="inboxAction"
															className={cn(
																"flex h-6 w-6 cursor-pointer items-center justify-center rounded-[5px] px-1 transition-all",
															)}>
															<DotsVerticalIcon className="shrink-0 text-[#1A1A1A]" />
														</Button>
													</DropdownMenuTrigger>
													<DropdownMenuContent align="end" onCloseAutoFocus={e => e.preventDefault()}>
														<DropdownMenuItem
															onClick={e => {
																e.stopPropagation();
																handleArchive(client);
															}}>
															<ArchiveBoxIcon />
															<span className="line-clamp-1 flex-1 overflow-hidden text-ellipsis">Archive</span>
														</DropdownMenuItem>
													</DropdownMenuContent>
												</DropdownMenu>
											</TableCellToolbar>
										</TableCell>
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={4}>
										<div className="text-label-muted text-center">
											<p className="text-sm">
												{clients.length > 0
													? "No clients match your search criteria."
													: "No clients found. Create your first client to get started."}
											</p>
										</div>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>
			)}
		</div>
	);
};

export default ClientsPage;
