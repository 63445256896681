import { InboxTabs } from "@/app/enums/inbox.enum";
import { UnifiedInboxHeader } from "@/components";
import UnifiedInbox from "@/components/UnifiedInbox/UnifiedInbox";
import { useGlobalFilterStore, useInboxStore } from "@/store";
import { TThreadWithMessagesAndAccounts } from "@za-zu/types";
import Fuse from "fuse.js";
import { useEffect, useMemo, useRef, useState } from "react";

const Inbox = () => {
	const { syncWithLatest, threadsLoading, allThreads } = useInboxStore(state => ({
		syncWithLatest: state.syncWithLatest,
		threadsLoading: state.threadsLoading,
		allThreads: state.allThreads,
	}));

	const { searchText } = useGlobalFilterStore();
	const [sortOrder] = useState("Newest");
	const [activeTab, setActiveTab] = useState(InboxTabs.PRIMARY);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);

	// Use store selectors directly for thread filtering
	const { getPrimary, getOther, getSent, getUntracked, getDone } = useInboxStore(state => ({
		getPrimary: state.getPrimary,
		getOther: state.getOther,
		getSent: state.getSent,
		getUntracked: state.getUntracked,
		getDone: state.getDone,
	}));

	// Compute threads using the appropriate store selector
	const computedThreads = useMemo(() => {
		switch (activeTab) {
			case InboxTabs.PRIMARY:
				return getPrimary();
			case InboxTabs.OTHER:
				return getOther();
			case InboxTabs.SENT:
				return getSent();
			case InboxTabs.UNTRACKED:
				return getUntracked();
			case InboxTabs.DONE:
				return getDone();
			default:
				return Object.values(allThreads);
		}
	}, [activeTab, getPrimary, getOther, getSent, getUntracked, getDone, allThreads]);

	const fuse = useMemo(
		() =>
			new Fuse(computedThreads, {
				keys: ["to.name", "to.address", "campaign.name", "thread.content", "subject"],
				threshold: 0.4,
				ignoreLocation: true,
				useExtendedSearch: true,
			}),
		[computedThreads],
	);

	// Filter threads based on search text
	const filteredThreads = useMemo(() => {
		if (!searchText || searchText.trim().length === 0) return computedThreads;

		const results = fuse.search(searchText).map(result => result.item);
		return [...results].sort((a, b) => {
			const timeA = new Date((a as TThreadWithMessagesAndAccounts).last_sent_at).getTime();
			const timeB = new Date((b as TThreadWithMessagesAndAccounts).last_sent_at).getTime();
			return sortOrder === "Newest" ? timeB - timeA : timeA - timeB;
		});
	}, [computedThreads, fuse, searchText, sortOrder]);

	useEffect(() => {
		syncWithLatest(activeTab);

		if (pollingIntervalRef.current) {
			clearInterval(pollingIntervalRef.current);
		}

		return () => {
			if (pollingIntervalRef.current) {
				clearInterval(pollingIntervalRef.current);
			}
		};
	}, [activeTab, syncWithLatest]);

	// Get primary thread count separately for the header and ensure it updates
	const primaryThreadsCount = useMemo(() => {
		return getPrimary().length;
	}, [getPrimary, allThreads]);

	return (
		<div className="flex h-full w-full flex-col">
			<UnifiedInboxHeader
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				primaryCount={primaryThreadsCount}
				isModalOpen={isModalOpen}
			/>
			<UnifiedInbox
				threads={filteredThreads}
				onThreadModalVisibilityChange={setIsModalOpen}
				isLoading={threadsLoading}
			/>
		</div>
	);
};

export default Inbox;
