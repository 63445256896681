"use client";

import CampaignsList from "@/components/CampaignsList";
import { Button } from "@/components/ui/button";
import PlusButtonIcon from "@/assets/icons/svgs/plus-button.svg?react";
import { useState, useEffect } from "react";
import useCampaignStore from "@/store/useCampaignStore";

const Campaigns = () => {
	const [activeFilter, setActiveFilter] = useState<"open" | "archived">("open");
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	const { fetchCampaignsWithoutClearing } = useCampaignStore(state => ({
		fetchCampaignsWithoutClearing: state.fetchCampaignsWithoutClearing,
	}));

	useEffect(() => {
		fetchCampaignsWithoutClearing();
	}, [fetchCampaignsWithoutClearing]);

	const handleFilterClose = () => {
		setIsFilterModalOpen(false);
	};

	const renderHeader = (hasArchivedCampaigns: boolean, campaignsLength: number, onNewCampaign: () => void) => {
		// If there are no archived campaigns, we should treat the filter as "open"
		const effectiveFilter = hasArchivedCampaigns ? activeFilter : "open";

		return (
			<header>
				<div className="flex h-[52px] items-center justify-between px-4">
					<div className="flex items-center gap-2">
						<div className="flex items-center gap-2">
							<h1 className="text-(--label-label-title) pr-2 text-[15px] font-medium leading-[22px]">Campaigns</h1>
						</div>
						{hasArchivedCampaigns && (
							<div className="flex gap-2">
								<Button
									variant="outline"
									className={effectiveFilter === "open" ? "bg-background-bg-base-hover" : ""}
									onClick={() => setActiveFilter("open")}>
									Open
								</Button>
								<Button
									variant="outline"
									className={effectiveFilter === "archived" ? "bg-background-bg-base-hover" : ""}
									onClick={() => setActiveFilter("archived")}>
									Archived
								</Button>
							</div>
						)}
					</div>
					<div className="campaigns-list-actions flex gap-2">
						<Button onClick={() => setIsFilterModalOpen(true)} aria-label="Open filter options" variant="exit">
							Filter
						</Button>
						<Button onClick={onNewCampaign} aria-label="Create new campaign" variant="outline">
							<PlusButtonIcon className="h-4 w-4" />
							New Campaign
						</Button>
					</div>
				</div>
				{/* Only show the divider when in empty state to avoid double borders */}
				{campaignsLength === 0 && <div className="bg-background-bg-border h-[0.5px] w-full" />}
			</header>
		);
	};

	return (
		<div className="w-full">
			<CampaignsList
				renderHeader={renderHeader}
				activeFilter={activeFilter}
				isFilterModalOpen={isFilterModalOpen}
				onFilterClose={handleFilterClose}
			/>
		</div>
	);
};

export default Campaigns;
