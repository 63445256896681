import { InboxTabs } from "@/app/enums/inbox.enum";
import { RequestConfig } from "@/hooks/useQuery";
import { CampaignProps } from "@/types/campaign";
import {
	AccountSlim,
	Campaign,
	CampaignCreate,
	CampaignDailyStatsRow,
	CampaignRow,
	ClientRow,
	ClientUserInvitationRow,
	ClientUserRow,
	CustomField,
	EmailAccountMembership,
	LeadCustomFieldValue,
	LeadsResponse,
	MessageNewRow,
	OrgMembershipTier,
	OrgRole,
	OutboundMessageTrackingNew,
	ReplyMessageTrackingRow,
	SendEmailPayload,
	SuccessResponse,
	ThreadsPageResponse,
	TUpdateLeadPayload,
	UpdateLeadResponse,
} from "@za-zu/types";
import { z } from "zod";

export class ApiClient {
	constructor(
		private readonly authorizedFetch: <T>(url: string, zodSchema: z.ZodSchema<T>, config?: RequestConfig) => Promise<T>,
	) {}

	async addEmailAccountMembershipForCampaign(emailAccountId: string, campaignId: string) {
		return await this.authorizedFetch(`/email-account-memberships`, EmailAccountMembership, {
			method: "POST",
			body: JSON.stringify({ campaign_id: campaignId, email_account_id: emailAccountId }),
		});
	}

	async removeEmailAccountMembership(emailAccountId: string, campaignId: string) {
		return await this.authorizedFetch(`/email-account-memberships`, SuccessResponse, {
			method: "DELETE",
			body: JSON.stringify({ campaign_id: campaignId, email_account_id: emailAccountId }),
		});
	}

	async deleteAccount(accountId: string) {
		return await this.authorizedFetch(
			`/accounts/${accountId}`,
			z.object({
				success: z.boolean(),
				message: z.string().optional(),
			}),
			{
				method: "DELETE",
			},
		);
	}

	async getLatestAccounts() {
		const response = await this.authorizedFetch("/accounts", z.object({ data: z.array(AccountSlim) }));

		const accounts: AccountSlim[] = response.data.map(a => ({
			...a,
			sync_status: a.sync_status ?? "connected",
			email_warmth_score: a.email_warmth_score ?? 0,
		}));
		return accounts;
	}

	async deleteCampaign(campaignId: string, onSuccess?: () => void) {
		const result = await this.authorizedFetch(`/campaigns/${campaignId}`, z.any(), {
			method: "DELETE",
			body: JSON.stringify({ id: campaignId }),
		});
		onSuccess?.();
		return result;
	}

	async saveCampaign(campaign: CampaignProps, onSuccess?: () => void) {
		const backendCampaign = {
			name: campaign.title,
			client_id: campaign.client_id || null,
			status: campaign.status,
			steps: campaign.steps.map((step, index) => ({
				...step,
				step_index: index,
			})),
			// TODO: Remove hardcoding when we have the user select this.
			time_zone: "America/New_York",
			start_time: 9,
			end_time: 18,
			sending_days: [1, 2, 3, 4, 5],
		};
		try {
			const result = await this.authorizedFetch(`/campaigns/${campaign.id}`, Campaign.passthrough(), {
				method: "PUT",
				body: JSON.stringify(backendCampaign),
			});
			onSuccess?.();
			return result;
		} catch (error) {
			console.error("Error saving campaign:", error);
			throw error;
		}
	}

	async createCampaign(campaign: CampaignCreate, onSuccess?: () => void) {
		try {
			const result = await this.authorizedFetch(`/campaigns`, Campaign.passthrough(), {
				method: "POST",
				body: JSON.stringify(campaign),
			});
			onSuccess?.();
			return result;
		} catch (error) {
			console.error("Error creating campaign:", error);
			throw error;
		}
	}
	async migrateCampaign(campaignId: string, onSuccess?: () => void) {
		const result = await this.authorizedFetch(
			`/campaigns/migrate`,
			z.object({ idMap: z.record(z.string()), createdFields: z.array(CustomField.CustomField) }),
			{
				method: "PUT",
				body: JSON.stringify({ id: campaignId }),
			},
		);
		onSuccess?.();
		return result;
	}

	async getLatestCustomFields() {
		const customFields = await this.authorizedFetch(
			"/custom-fields",
			z.object({ data: z.array(CustomField.CustomField) }),
		);
		return customFields.data;
	}

	async createCustomField(customField: CustomField.Create) {
		return await this.authorizedFetch("/custom-fields", CustomField.CustomField, {
			method: "POST",
			body: JSON.stringify(customField),
		});
	}

	async updateCustomField(customFieldId: string, customField: CustomField.Update) {
		return await this.authorizedFetch(`/custom-fields/${customFieldId}`, CustomField.CustomField, {
			method: "PUT",
			body: JSON.stringify(customField),
		});
	}

	async deleteCustomField(customFieldId: string) {
		return await this.authorizedFetch(`/custom-fields/${customFieldId}`, SuccessResponse, {
			method: "DELETE",
			body: JSON.stringify({ id: customFieldId }),
		});
	}

	async sendEmail(message: SendEmailPayload) {
		return await this.authorizedFetch(
			"/emails/send",
			ReplyMessageTrackingRow.extend({
				outbound: OutboundMessageTrackingNew.nullable(),
			}),
			{
				method: "POST",
				body: JSON.stringify(message),
			},
		);
	}

	async getThreadMessage(accountId: string, messageId: string) {
		return await this.authorizedFetch(`/emails/${accountId}/${messageId}`, MessageNewRow, {
			method: "GET",
		});
	}
	async getThreadsPage(activeTabValue: InboxTabs, before: string | null = null, after: string | null = null) {
		let queryParams = `?limit=50`;
		if (before) {
			queryParams += `&before=${encodeURIComponent(new Date(before).toISOString())}`;
		}

		if (after) {
			queryParams += `&after=${encodeURIComponent(new Date(after).toISOString())}`;
		}
		return await this.authorizedFetch(`/emails/threads/for/${activeTabValue}${queryParams}`, ThreadsPageResponse, {
			method: "GET",
		});
	}

	async getOutboundTrackingInfo(trackingId: string) {
		return await this.authorizedFetch(
			`/emails/tracking/${trackingId}`,
			ReplyMessageTrackingRow.extend({ outbound: OutboundMessageTrackingNew.nullable() }),
			{
				method: "GET",
			},
		);
	}
	async getPageOfLeads(campaignId: string, page: number = 1) {
		return await this.authorizedFetch(
			`/leads/paginated?campaign_id=${campaignId}&page=${page}&pageSize=${1000}`,
			LeadsResponse,
		);
	}
	async updateLeadCustomFieldValues(
		email: string,
		campaign_id: string,
		customFieldUpdates: Record<string, string | number | boolean | null>,
	) {
		return await this.authorizedFetch(`/leads/custom-fields`, z.array(LeadCustomFieldValue), {
			method: "PUT",
			body: JSON.stringify({ lead: { email, campaign_id, values: customFieldUpdates } }),
		});
	}
	async updateLead(data: TUpdateLeadPayload) {
		return await this.authorizedFetch(`/leads`, UpdateLeadResponse, {
			method: "PUT",
			body: JSON.stringify(data),
		});
	}
	async deleteLead(leadEmail: string, campaignId: string) {
		return await this.authorizedFetch(`/leads`, z.object({ success: z.boolean(), message: z.string() }), {
			method: "DELETE",
			body: JSON.stringify({ leadEmail, campaignId }),
		});
	}
	async getClients() {
		return await this.authorizedFetch(
			`/clients`,
			ClientRow.extend({ members: ClientUserRow.array(), invitations: ClientUserInvitationRow.array() }).array(),
		);
	}
	async createClient(name: string) {
		return await this.authorizedFetch(`/clients`, ClientRow, {
			method: "POST",
			body: JSON.stringify({ name }),
		});
	}
	async inviteToClient(
		clientId: string,
		orgRole: z.infer<typeof OrgRole>,
		emailAddress: string,
		membershipTier: z.infer<typeof OrgMembershipTier>,
	) {
		return await this.authorizedFetch(`/clients/invite`, ClientUserInvitationRow, {
			method: "POST",
			body: JSON.stringify({ clientId, orgRole, emailAddress, membershipTier }),
		});
	}
	async revokeInvitation(invitationId: string) {
		return await this.authorizedFetch(`/clients/invitation/revoke`, ClientUserInvitationRow, {
			method: "PUT",
			body: JSON.stringify({ invitationId }),
		});
	}
	async removeUserFromOrg(userId: string) {
		return await this.authorizedFetch(`/clients/member`, ClientUserRow.nullable(), {
			method: "DELETE",
			body: JSON.stringify({ userId }),
		});
	}
	async getCampaigns() {
		return await this.authorizedFetch(
			`/campaigns`,
			z.object({
				data: z.array(
					Campaign.extend({
						hasLeads: z.number(),
					}).passthrough(),
				),
			}),
		);
	}
	async getClientCampaigns(clientId: string) {
		return await this.authorizedFetch(
			`/client-campaigns/${clientId}`,
			ClientRow.extend({
				campaigns: CampaignRow.extend({ campaign_daily_stats: CampaignDailyStatsRow.array() }).array(),
			}),
		);
	}
	async updateEmailAccountClient(accountId: string, clientId: string | null) {
		return await this.authorizedFetch(
			`/accounts/${accountId}`,
			z.object({
				success: z.boolean(),
				message: z.string().optional(),
			}),
			{
				method: "PUT",
				body: JSON.stringify({ client_id: clientId }),
			},
		);
	}
}
