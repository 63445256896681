import React, { useEffect, useState } from "react";

interface ToggleSwitchProps {
	isOn: boolean;
	onToggle: () => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isOn, onToggle }) => {
	const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

	useEffect(() => {
		// Check if the user prefers reduced motion
		const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
		setPrefersReducedMotion(mediaQuery.matches);

		// Listen for changes to the prefers-reduced-motion media query
		const handleChange = (e: MediaQueryListEvent) => {
			setPrefersReducedMotion(e.matches);
		};

		mediaQuery.addEventListener("change", handleChange);
		return () => {
			mediaQuery.removeEventListener("change", handleChange);
		};
	}, []);

	// Define animation durations based on motion preference
	const duration = prefersReducedMotion ? "0.1s" : "0.2s";
	// Use ease-out for user-initiated interactions as per animation guidelines
	const easing = "cubic-bezier(0.2, 0, 0, 1)";

	return (
		<button
			onClick={onToggle}
			className="relative h-[16px] w-[28px] rounded-full focus:outline-none focus-visible:ring-1 focus-visible:ring-gray-300 focus-visible:ring-offset-1"
			aria-checked={isOn}
			role="switch">
			<svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				{/* Background */}
				<rect
					width="28"
					height="16"
					rx="8"
					fill={isOn ? "#397AF1" : "#F1F1F1"}
					style={{
						transition: `fill ${duration} ${easing}`,
					}}
				/>

				{/* White Circle */}
				<g
					style={{
						transform: `translateX(${isOn ? "12px" : "0px"})`,
						transition: `transform ${duration} ${easing}`,
					}}>
					{/* Drop shadow for OFF state */}
					{!isOn && (
						<circle
							cx="8"
							cy="8"
							r="6"
							fill="black"
							style={{
								filter: "blur(15px)",
								opacity: 0.12,
							}}
						/>
					)}

					{/* Main white circle */}
					<circle
						cx="8"
						cy="8"
						r="6"
						fill="white"
						style={{
							filter: isOn
								? "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.07))"
								: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.04))",
							transition: `filter ${duration} ${easing}`,
						}}
					/>
				</g>
			</svg>
		</button>
	);
};

export default ToggleSwitch;
