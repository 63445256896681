import { Extension } from "@tiptap/core";
import RenderSuggestions from "../components/RenderSuggestions";
import { CustomSuggestion, CustomSuggestionKeyDownProps, CustomSuggestionProps } from "./suggestion";
import { CommandItem, ItemCollection, SlashSuggestionOptions } from "../types";
import { VariableNode } from "../nodes/VariableNode";
import { Plugin } from "@tiptap/pm/state";
import { InactiveSlashNode } from "../nodes/InactiveSlashNode";
import { formatSenderVariableDisplayName } from "../utils/senderVariableCommands";

declare module "@tiptap/core" {
	interface Commands<ReturnType> {
		slashSuggestion: {
			setSlashSuggestion: () => ReturnType;
		};
	}
}

const SlashSuggestion = Extension.create<SlashSuggestionOptions>({
	name: "slash-suggestion",

	addExtensions() {
		const defaultVariables = this.options.defaultVariables || [];
		const variables = this.options.variables || {};
		const customFieldsById = this.options.customFieldsById || {};
		return [VariableNode.configure({ defaultVariables, variables, customFieldsById }), InactiveSlashNode];
	},

	addOptions() {
		return {
			suggestion: {
				char: "/",
				decorationClass:
					"inline-flex h-6 items-center justify-center gap-1 rounded-md border border-background-bg-border bg-background-bg-dim px-1.5",
				allowSpaces: true,
				exit: {
					backspace: true,
					enter: false,
					space: false,
					escape: true,
				},
				/**
				 * This makes it so that selecting a suggestion list item would cause it to pull its command prop and try to
				 * call it if it exists. This ensure the latest reference to the editor and the relevant range can be passed
				 * to the command for each item.
				 */
				command: ({ editor, range, props: { command } }) => {
					if (!command) return;
					command({ editor, range });
				},
				items: _props => {
					throw new Error("Not implemented");
				},
				render: () => {
					let component: ReturnType<typeof RenderSuggestions>;

					return {
						onStart: (props: CustomSuggestionProps<ItemCollection, CommandItem>) => {
							if (!props.clientRect) return;

							component = RenderSuggestions();
							if (!props.clientRect) return;
							component.onStart({
								...props,
								clientRect: props.clientRect,
							});
						},
						onUpdate(props: CustomSuggestionProps<ItemCollection, CommandItem>) {
							if (!props.clientRect) return;
							component.onUpdate({
								...props,
								clientRect: props.clientRect,
							});
						},
						onKeyDown(props: CustomSuggestionKeyDownProps) {
							if (props.event.key === "Escape") {
								return true;
							}
							return component.onKeyDown?.(props) ?? false;
						},
						onExit() {
							component.onExit();
						},
					};
				},
			},
			commandItems: [],
			maxSuggestions: [],
			variables: {},
			defaultVariables: [],
			customFieldsById: {},
		};
	},

	addProseMirrorPlugins() {
		return [
			CustomSuggestion<ItemCollection, CommandItem>({
				editor: this.editor,
				...this.options.suggestion,
				render: RenderSuggestions,
			}),
			new Plugin({
				appendTransaction: (_transactions, _oldState, newState) => {
					if (!newState.doc.textContent.includes("{{") && !newState.doc.textContent.includes("/")) {
						// Nothing to do, so don't do anything.
						return null;
					}
					let { tr } = newState;
					// There must be some liquid variable tags in the document, so we need to find all of them and replace them
					// with the appropriate variable nodes.
					const replacements: {
						from: number;
						to: number;
						fieldId: string;
						fieldDisplayName: string;
						isPending: boolean;
						isCustom: boolean;
					}[] = [];
					newState.doc.descendants((node, pos) => {
						if (node.isText) {
							// First find all instances of {{custom.fieldId}} or {{fieldId}} (the latter would be the case for non-custom
							// fields like {{firstname}} or {{lastname}})
							const text = node.text || "";
							let match;
							const regex = /{{([^}]+)}}/g;

							while ((match = regex.exec(text)) !== null) {
								// We found a match, now we need to determine if it's a custom field or not.
								const fieldId = match[1].replace(/^custom\./, "");
								// If it's a custom field, we need to get the display name from the customFields state. If it's not a
								// custom field, we can just use the fieldId as the display name.
								let fieldDisplayName = "unknown";
								const defaultField = this.options.defaultVariables.find(field => field.id === fieldId);
								if (defaultField) {
									fieldDisplayName = defaultField.name;
								} else if (this.options.customFieldsById[fieldId]) {
									fieldDisplayName = this.options.customFieldsById[fieldId].name;
								} else if (match[1].startsWith("sender.")) {
									fieldDisplayName = formatSenderVariableDisplayName(fieldId);
								}

								const isCustom = match[1].startsWith("custom.");

								// Track the data we need to replace the curly braces with a variable node along with the position of the
								// match so we can replace it later.
								replacements.push({
									from: pos + match.index,
									to: pos + match.index + match[0].length,
									fieldId,
									fieldDisplayName,
									isPending: false,
									isCustom,
								});
							}
						}
					});

					// Now find all instances of / that are after the user's cursor, or in other blocks and replace them with
					// inactive slash nodes.
					// newState.doc.descendants((node, pos, parent, index) => {
					// 	if (node.type.name !== "text") {
					// 		return true;
					// 	}
					// 	const paragraphNode = parent;
					// 	if (!paragraphNode) {
					// 		return true;
					// 	}
					// 	if (paragraphNode.type.name !== "paragraph") {
					// 		return true;
					// 	}
					// 	const selectionFrom = newState.selection.$from;
					// 	const selectionTo = newState.selection.$to;
					// 	const [selectionLow, selectionHigh] = (
					// 		[selectionFrom.pos, selectionTo.pos] satisfies [number, number]
					// 	).sort((a, b) => a - b);
					// 	// Determine the bounds of the node.
					// 	const nodeLowPos = pos;
					// 	const nodeHighPos = pos + node.nodeSize;
					// 	// Check to see if the selection overlaps with any space in this node after
					// 	if (selectionHigh <= nodeLowPos) {
					// 		// Replace all slashes with inactive slash nodes.
					// 	}
					// 	// Check for overlap between the selection and the paragraph. If they don't overlap, we can allow
					// 	// descending into the paragraph node to replace all slashes with inactive slash nodes. But if they do
					// 		// o
					// 		if (paragraphHighPos >= selectionLow && paragraphLowPos <= selectionHigh) {
					// 			// The paragraph is part of the selection, so we need to replace it with an inactive slash node.
					// 		}
					// 	}
					// 	if (node.type.name === "inactiveSlash") {
					// 		return;
					// 	}
					// 	if (node.isText) {
					// 		// node.
					// 		// const text = node.text || "";
					// 		// let match;
					// 		// const regex = /\//g;
					// 		// while ((match = regex.exec(text)) !== null) {
					// 		// 	replacements.push({
					// 		// 		from: pos + match.index,
					// 		// 		to: pos + match.index + match[0].length,
					// 		// 	});
					// 	}
					// 	let match;
					// 		const regex = /{{([^}]+)}}/g;

					// 		while ((match = regex.exec(text)) !== null) {
					// 			// We found a match, now we need to determine if it's a custom field or not.
					// 			const fieldId = match[1].replace(/^custom\./, "");
					// 			// If it's a custom field, we need to get the display name from the customFields state. If it's not a
					// 			// custom field, we can just use the fieldId as the display name.
					// 			let fieldDisplayName = "unknown";
					// 			const defaultField = this.options.defaultVariables.find(field => field.id === fieldId);
					// 			if (defaultField) {
					// 				fieldDisplayName = defaultField.name;
					// 			} else if (this.options.customFieldsById[fieldId]) {
					// 				fieldDisplayName = this.options.customFieldsById[fieldId].name;
					// 			}

					// 			const isCustom = match[1].startsWith("custom.");

					// 			// Track the data we need to replace the curly braces with a variable node along with the position of the
					// 			// match so we can replace it later.
					// 			replacements.push({
					// 				from: pos + match.index,
					// 				to: pos + match.index + match[0].length,
					// 				fieldId,
					// 				fieldDisplayName,
					// 				isPending: false,
					// 				isCustom,
					// 			});
					// 		}
					// 	return true;
					// });

					replacements.sort((a, b) => b.from - a.from);

					// Apply all replacements in a single chain
					replacements.forEach(({ from, to, fieldId, fieldDisplayName, isPending, isCustom }) => {
						const node = this.editor.schema.nodes.variable.create({
							fieldId,
							fieldDisplayName,
							isPending,
							isCustom,
						});
						tr = newState.tr.replaceRangeWith(from, to, node);
					});
					return tr;
				},
			}),
		];
	},
});

export default SlashSuggestion;
