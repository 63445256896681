import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogClose } from "@/components/ui/dialog";

// Import the logo images
import PremiumInboxesLogo from "@/assets/icons/images/logos/premiuminboxes.png";
import HypertideLogo from "@/assets/icons/images/logos/hypertide.png";
import MailreefLogo from "@/assets/icons/images/logos/mailreef.png";
import InboxAutomateLogo from "@/assets/icons/images/logos/inboxautomate.png";

type VendorInfo = {
	name: string;
	description: string;
	url: string;
	logo: string;
};

const vendors: VendorInfo[] = [
	{
		name: "Premium Inboxes",
		description: "Cold email infrastructure on Google Workspace.",
		url: "https://premiuminboxes.com",
		logo: PremiumInboxesLogo,
	},
	{
		name: "Hypertide",
		description: "Cold email infrastructure on Microsoft Azure servers.",
		url: "https://hypertide.io",
		logo: HypertideLogo,
	},
	{
		name: "Mailreef",
		description: "Cold email infrastructure on private SMTP servers.",
		url: "https://mailreef.com",
		logo: MailreefLogo,
	},
	{
		name: "InboxAutomate",
		description: "Cold email infrastructure on Google Workspace.",
		url: "https://inboxautomate.com",
		logo: InboxAutomateLogo,
	},
];

interface DoneForYouDialogProps {
	isOpen: boolean;
	onClose: () => void;
}

const DoneForYouDialog = ({ isOpen, onClose }: DoneForYouDialogProps) => {
	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="max-w-[550px]">
				<DialogHeader>
					<div className="flex items-center justify-between">
						<DialogTitle className="text-xl font-semibold">Choose a done-for-you email setup partner</DialogTitle>
						<DialogClose className="rounded-full p-1.5 hover:bg-gray-100"></DialogClose>
					</div>
				</DialogHeader>

				<div className="space-y-4">
					<p className="text-body-head text-label-label-muted">
						Skip the technical setup and start sending faster. These partners will configure your cold email
						infrastructure and connect it to Za-zu, so you can focus on campaigns.
					</p>

					<div className="mt-6">
						<h3 className="mb-3 font-medium">Trusted setup partners our customers recommend:</h3>
						<div className="space-y-3">
							{vendors.map(vendor => (
								<div
									key={vendor.name}
									className="border-border hover:border-brand-primary flex flex-col rounded-lg border p-4 hover:cursor-pointer"
									onClick={() => window.open(vendor.url, "_blank", "noopener,noreferrer")}>
									<div className="flex justify-between">
										<div className="flex items-center gap-2">
											{vendor.logo && (
												<img src={vendor.logo} alt={`${vendor.name} logo`} className="h-3 w-3 object-contain" />
											)}
											<h4 className="font-medium">{vendor.name}</h4>
										</div>
									</div>
									<p className="text-label-label-muted mt-1 text-sm">{vendor.description}</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default DoneForYouDialog;
