import { create } from "zustand";

type UseDirtyStoreProps = {
	isDirty: boolean;
	isSaving: boolean;
	setIsDirty: (isDirty: boolean) => void;
	setIsSaving: (isSaving: boolean) => void;
};

const useDirtyStore = create<UseDirtyStoreProps>()(set => ({
	isDirty: false,
	isSaving: false,
	setIsDirty: (isDirty: boolean) => set({ isDirty }),
	setIsSaving: (isSaving: boolean) => set({ isSaving }),
}));

export default useDirtyStore;
