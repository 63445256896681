import { CampaignCreationStepEnum } from "@/app/enums/campaign.enum";
import { completedPath, stepSeparatorPath } from "@/assets/icons/paths";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { StatusIndicator } from "@/components/status-indicator";
import { useCampaignStore } from "@/store";
import useDirtyStore from "@/store/useDirtyStore";
import { CampaignProps } from "@/types/campaign";
import React, { useCallback, useState, useEffect } from "react";
import { toast } from "sonner";
import { useLocation } from "wouter";
import { Input } from "../ui/input";
import IconSVG from "../IconSVG";
import { Button } from "../ui/button";
import { Spinner } from "../ui/spinner";
import { Combobox } from "../ui/combobox";
import useFetchStore from "@/store/fetchStore";
import type { TClientRow } from "@za-zu/types";

const CampaignEditorHeader = ({
	selectedCampaign,
	activeCampaignStep,
	onStepChange = () => Promise.resolve(),
	selectedAccountIds = [],
	saveCallback,
}: {
	selectedCampaign: CampaignProps;
	activeCampaignStep: CampaignCreationStepEnum;
	onStepChange?: () => Promise<void>;
	selectedAccountIds?: string[];
	saveCallback?: () => Promise<void>;
}) => {
	const [_location, navigate] = useLocation();
	const { campaigns, saveCampaign, pauseCampaign } = useCampaignStore();
	const [isEditing, setIsEditing] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const { isDirty, isSaving } = useDirtyStore();
	const [clients, setClients] = useState<TClientRow[]>([]);
	const { apiClient } = useFetchStore();

	useEffect(() => {
		const fetchClients = async () => {
			if (!apiClient) return;
			try {
				const data = await apiClient.getClients();
				setClients(data);
			} catch (error) {
				console.error("Error fetching clients:", error);
				toast.error("Failed to fetch clients");
			}
		};

		fetchClients();
	}, [apiClient]);

	const clientOptions = clients.map(client => ({
		label: client.name,
		value: client.id,
	}));

	const handleClientSelect = (value: string) => {
		const updatedCampaign = {
			...selectedCampaign,
			client_id: value || "",
		};
		saveCampaign(updatedCampaign);

		if (value) {
			const selectedClientName = clients.find(c => c.id === value)?.name;
			if (selectedClientName) {
				toast.success(`Added client: ${selectedClientName}`);
			}
		} else {
			toast.success("Removed client");
		}
	};

	const isImportPage = window.location.pathname.includes("/import");

	/** Used for switching between campaign steps when setting up a campaign. */
	const handleNextStep = async () => {
		switch (activeCampaignStep) {
			case CampaignCreationStepEnum.Leads:
				onStepChange();
				navigate(`/dashboard/campaigns/${selectedCampaign.id}/sequences`);
				break;
			case CampaignCreationStepEnum.Sequences:
				onStepChange();
				navigate(`/dashboard/campaigns/${selectedCampaign.id}/emails`);
				break;
			case CampaignCreationStepEnum.SelectEmails:
				onStepChange();
				break;
		}
	};

	/** Used for switching between campaign steps arbitrarily. */
	const handleStepChange = async (step: CampaignCreationStepEnum) => {
		switch (step) {
			case CampaignCreationStepEnum.Leads:
				navigate(`/dashboard/campaigns/${selectedCampaign.id}/leads`);
				break;
			case CampaignCreationStepEnum.Sequences:
				navigate(`/dashboard/campaigns/${selectedCampaign.id}/sequences`);
				break;
			case CampaignCreationStepEnum.SelectEmails:
				navigate(`/dashboard/campaigns/${selectedCampaign.id}/emails`);
				break;
		}
	};

	const handleNameChange = (event: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
		if (!selectedCampaign || !("target" in event)) return;

		const target = event.target as HTMLInputElement;
		const newTitle = target.value.trim();
		if (newTitle === selectedCampaign.title) {
			setIsEditing(false);
			setIsHovered(false);
			return;
		}

		const updatedCampaign = {
			...selectedCampaign,
			title: newTitle || `New Campaign ${getNextCampaignNumber(campaigns)}`,
		};

		saveCampaign(updatedCampaign);
		setIsEditing(false);
		setIsHovered(false);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			handleNameChange(event);
		}
	};

	useEffect(() => {
		const handleKeyDown = async (e: KeyboardEvent) => {
			if ((e.metaKey || e.ctrlKey) && e.key === "s") {
				e.preventDefault(); // Prevent browser's save dialog
				if (saveCallback && isDirty) {
					await saveCallback();
				}
			}
		};

		document.addEventListener("keydown", handleKeyDown);
		return () => document.removeEventListener("keydown", handleKeyDown);
	}, [saveCallback, isDirty]);

	const StepSeparatorIcon = (
		<IconSVG
			path={stepSeparatorPath}
			width={12}
			height={12}
			viewBox="0 0 12 12"
			fill="#AAAAAA"
			className="text-(--label-label-muted)"
		/>
	);

	const isStepCompleted = (step: CampaignCreationStepEnum) => {
		const stepOrder = Object.values(CampaignCreationStepEnum);
		const currentStepIndex = stepOrder.indexOf(activeCampaignStep);
		const stepIndex = stepOrder.indexOf(step);
		return stepIndex < currentStepIndex;
	};

	const getNextCampaignNumber = (campaigns: CampaignProps[]): number => {
		const numbers = campaigns
			.map(c => {
				const match = c.title?.match(/New Campaign (\d+)/);
				return match ? parseInt(match[1]) : 0;
			})
			.filter(n => !isNaN(n));

		return numbers.length ? Math.max(...numbers) + 1 : 1;
	};

	const getButtonText = () => {
		switch (activeCampaignStep) {
			case CampaignCreationStepEnum.SelectEmails:
				return selectedCampaign?.status === "active" ? "Update" : "Publish";
			case CampaignCreationStepEnum.Leads:
				return "Continue";
			case CampaignCreationStepEnum.Sequences:
				return "Continue";
			default:
				return "Continue";
		}
	};

	const getButtonDisabledState = useCallback(() => {
		if (isDirty || isSaving) {
			return true;
		}
		switch (activeCampaignStep) {
			case CampaignCreationStepEnum.Leads:
				return !importLeadsValidationRef.isValid;
			case CampaignCreationStepEnum.Sequences:
				return false;
			case CampaignCreationStepEnum.SelectEmails:
				return selectedAccountIds.length === 0;
			default:
				return false;
		}
	}, [activeCampaignStep, selectedAccountIds, isDirty, isSaving]);

	const getButtonTooltip = () => {
		switch (activeCampaignStep) {
			case CampaignCreationStepEnum.Leads:
				return "Associate a column as the Email to start importing your data";
			case CampaignCreationStepEnum.Sequences:
				return "Add at least one sequence to continue";
			case CampaignCreationStepEnum.SelectEmails:
				return "Select at least one email account to publish";
			default:
				return "";
		}
	};

	if (!selectedCampaign) {
		return <div>Campaign not found</div>;
	}

	/* Campaign editor header with navigation and controls */
	return (
		<header>
			{/* Editor control panel - manages campaign title, step navigation and actions */}
			<div className="w-full-form flex flex-row justify-between px-4 py-[13px]">
				{/* Left section containing title and step navigation */}
				<div className="inline-flex items-center gap-2">
					{/* Status indicator and editable campaign title */}
					<div className="flex items-center gap-2">
						<StatusIndicator status={selectedCampaign.status} showText={false} />
						{isEditing ? (
							<Input
								type="text"
								defaultValue={selectedCampaign?.title}
								onBlur={handleNameChange}
								onKeyDown={handleKeyDown}
								autoFocus
								className="h-8 border-0 px-3 text-sm"
								autoComplete="off"
								name="campaign-title-edit"
								data-1password-ignore="true"
							/>
						) : (
							<span
								onClick={() => setIsEditing(true)}
								onMouseMove={() => setIsHovered(true)}
								onMouseLeave={() => setIsHovered(false)}
								className={`text-(--label-label-muted) cursor-pointer text-[15px] font-medium leading-[22px] transition-opacity ${
									isHovered ? "opacity-50" : ""
								}`}
								style={{ color: "var(--label-label-muted, #666)" }}>
								{selectedCampaign?.title || "No name"}
							</span>
						)}
						{StepSeparatorIcon}
					</div>
					{/* Step progression indicators */}
					{Object.values(CampaignCreationStepEnum).map((step, i) => (
						<React.Fragment key={`${step}-${i}`}>
							<div
								className={`flex h-[28px] cursor-pointer items-center justify-center gap-[6px] rounded-[8px] px-[8px] ${
									activeCampaignStep === step
										? "bg-[color:var(--background-bg-base-hover,rgb(241_241_241))]"
										: isStepCompleted(step)
											? "border-[0.5px] border-[color:var(--background-bg-divider,rgb(227_227_227))] bg-[color:var(--background-bg-shade,rgb(249_249_249))]"
											: "border-[0.5px] border-[color:var(--background-bg-divider,rgb(227_227_227))]"
								}`}
								onClick={e => {
									e.preventDefault();
									handleStepChange(step);
								}}>
								{isStepCompleted(step) && (
									<div className="flex h-4 w-4 items-center justify-center rounded-[5px] bg-[color:rgb(34_197_93)] p-[2px]">
										<IconSVG path={completedPath} width={12} height={12} viewBox="0 0 12 12" fill="white" />
									</div>
								)}
								<span
									className={`text-[13px] font-medium leading-[18px] ${
										activeCampaignStep === step
											? "text-[color:var(--label-label-title,rgb(26_26_26))]"
											: isStepCompleted(step)
												? "text-[color:var(--label-label-base,rgb(41_41_41))]"
												: "text-[color:var(--label-label-muted,rgb(102_102_102))]"
									}`}>
									{step === CampaignCreationStepEnum.Leads
										? `Leads${selectedCampaign?.hasLeads ? ` (${selectedCampaign.hasLeads})` : ""}`
										: step}
								</span>
							</div>
						</React.Fragment>
					))}
				</div>
				{/* Primary action buttons */}
				<div className="flex flex-row items-center gap-2">
					{clients.length > 0 && (
						<>
							<Combobox
								value={selectedCampaign.client_id || ""}
								onChange={handleClientSelect}
								options={clientOptions}
								placeholder="Add client"
								searchPlaceholder="Search..."
								emptyMessage="No clients found"
								className="text-label-label-muted max-w-70 min-w-37 w-auto"
							/>

							<div className="my-auto h-4 w-0.5 shrink-0 rounded-[2px] bg-[color:var(--background-bg-divider,rgb(227_227_227))]" />
						</>
					)}

					{isImportPage && (
						<Button variant="exit" onClick={() => navigate(`/dashboard/campaigns`)}>
							Exit &amp; save
						</Button>
					)}
					{saveCallback && !isImportPage && (
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant={isDirty ? "default" : "ghost"}
									disabled={!isDirty}
									aria-label="Save changes"
									onClick={saveCallback}>
									{isSaving ? <Spinner /> : "Save"}
								</Button>
							</TooltipTrigger>
							<TooltipContent side="top" align="end">
								<span className="flex items-center gap-1 text-xs">
									<kbd className="rounded border px-1">
										{navigator.platform.toLowerCase().includes("mac") ? "⌘" : "Ctrl"}
									</kbd>
									<kbd className="rounded border px-1">S</kbd>
								</span>
							</TooltipContent>
						</Tooltip>
					)}
					{selectedCampaign?.status === "active" && (
						<Button
							variant="secondary"
							onClick={async () => {
								try {
									await pauseCampaign(selectedCampaign.id);
									navigate("/dashboard/campaigns");
								} catch (error) {
									console.error("Failed to pause campaign:", error);
									toast.error("Failed to pause campaign");
								}
							}}>
							Pause Campaign
						</Button>
					)}

					<Tooltip>
						<TooltipTrigger asChild>
							<span>
								<Button
									variant={!isDirty ? "default" : "ghost"}
									onClick={handleNextStep}
									disabled={getButtonDisabledState()}>
									{getButtonText()}
								</Button>
							</span>
						</TooltipTrigger>
						{getButtonDisabledState() && (
							<TooltipContent side="top" align="end">
								<p>{getButtonTooltip()}</p>
							</TooltipContent>
						)}
					</Tooltip>
				</div>
			</div>
			{/* Header bottom border */}
			<div
				className="bg-(--background-bg-border) h-[0.5px] border-0"
				style={{ background: "var(--background-bg-border, #D9D9D9)" }}
			/>
		</header>
	);
};

export default CampaignEditorHeader;
export const importLeadsValidationRef = { isValid: true };
