import { Input } from "@/components/ui/input";
import { CSVColumn, AvailableField } from "../types";
import { FieldMappingDropdown } from "./FieldMappingDropdown";
import useCustomFieldsStore from "@/store/useCustomFieldsStore";

interface Props {
	column: CSVColumn;
	currentColumnId: string;
	searchQuery: string;
	onSearchChange: (query: string) => void;
	filteredDefaultFields: AvailableField[];
	filteredCustomFields: AvailableField[];
	onFieldMapping: (columnId: string, fieldId: string | null) => void;
	onCustomFieldCreate: (type: "text" | "number" | "boolean", columnName: string) => void;
	onColumnSelect: (columnId: string) => void;
	allDefaultFields: AvailableField[];
}

export function ColumnMapping({
	column,
	currentColumnId,
	searchQuery,
	onSearchChange,
	filteredDefaultFields,
	filteredCustomFields,
	onFieldMapping,
	onCustomFieldCreate,
	onColumnSelect,
	allDefaultFields,
}: Props) {
	const { customFields } = useCustomFieldsStore();

	// Function to determine status indicator color
	const getStatusColor = (mappedTo: string | null): string => {
		if (mappedTo === null) return "#FF8E00"; // Orange for "do not import"
		if (mappedTo.startsWith("custom.")) {
			const customFieldId = mappedTo.replace("custom.", "");
			// Check if this is a pending custom field (not found in store)
			const isExistingCustomField = customFields.some(f => f.id === customFieldId);
			return isExistingCustomField ? "#397af1" : "#5FA97B"; // Green only for pending
		}
		return "#397af1"; // Blue for default fields
	};

	return (
		<div className="w-155 flex flex-col items-start gap-3 self-stretch rounded-lg bg-[#f9f9f9] px-3 py-3 pl-3">
			<div className="flex items-center gap-2">
				<div
					className="h-2 w-2 rounded-full transition-colors duration-300 ease-out"
					style={{ backgroundColor: getStatusColor(column.mappedTo) }}
				/>
				<span className="text-body-base-plus text-label-title">{column.customTitle}</span>
			</div>
			<div className="flex w-full items-center gap-2">
				<Input
					value={`${column.value}`}
					readOnly
					className="border-background-bg-border bg-background-bg-dim shadow-custom-low-inset flex h-8 flex-1 items-center gap-2 rounded-md border-[0.5px] p-2"
				/>
				<div className="flex flex-1">
					<FieldMappingDropdown
						currentColumnId={currentColumnId}
						mappedTo={column.mappedTo}
						searchQuery={searchQuery}
						onSearchChange={onSearchChange}
						filteredDefaultFields={filteredDefaultFields}
						filteredCustomFields={filteredCustomFields}
						onFieldMapping={onFieldMapping}
						onCustomFieldCreate={type => onCustomFieldCreate(type, column.customTitle)}
						onColumnSelect={() => onColumnSelect(column.id)}
						allDefaultFields={allDefaultFields}
						columnName={column.customTitle}
					/>
				</div>
			</div>
		</div>
	);
}
