import { mergeAttributes, Node, NodeViewProps } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";

// The main NodeView for the ElseBlock node
function ElseBlockNodeView(props: NodeViewProps) {
	return (
		<NodeViewWrapper as="span" data-node-type={props.node.type.name} className="inline" contentEditable={false}>
			<span className="inline">
				<span className="inline-flex h-6 min-w-6 items-center justify-center border-[0.5px] border-[#F4CDB6] bg-[#FFEFE6] px-1.5">
					<span className="inline text-[#F18039]">ELSE</span>
				</span>
			</span>
		</NodeViewWrapper>
	);
}

export const ElseBlock = Node.create({
	name: "elseBlock",
	inline: true,
	group: "inline",
	// isolating: true,
	atom: true,
	// content: "",
	parseHTML() {
		// If you want to load from HTML, parse data-condition (JSON) back into an object
		return [
			{
				tag: `span[data-node-type="${this.name}"]`,
			},
		];
	},

	renderHTML() {
		return ["span", mergeAttributes({ "data-node-type": this.name })];
	},

	addNodeView() {
		return ReactNodeViewRenderer(ElseBlockNodeView);
	},
});
