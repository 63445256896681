import { memo, useState, useRef, useEffect } from "react";
import { TableCell, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { TableCellToolbar } from "@/components/ui/table-toolbar";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
	DropdownMenuItem,
	DropdownMenuSub,
	DropdownMenuSubTrigger,
	DropdownMenuSubContent,
} from "@/components/ui/dropdown-menu";
import { CampaignProps, CampaignStatus } from "@/types/campaign";
import { StatusIndicator } from "@/components/status-indicator";
import { MetricCell } from "./MetricCell";
import ProgressCircle from "@/components/ProgressCircle/ProgressCircle";
import DotsVerticalIcon from "@/assets/icons/svgs/table-header/dots-vertical";
import ArchiveBoxIcon from "@/assets/icons/svgs/table-header/archive-box";
import UserIcon from "@/assets/icons/svgs/user.svg?react";
import PauseActionIcon from "@/assets/icons/svgs/table-header/pause-action";
import PlayActionIcon from "@/assets/icons/svgs/table-header/play-action";
import { toast } from "sonner";
import { cn } from "@/lib/utils";
import type { TClientRow } from "@za-zu/types";
import { Check } from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";

const CampaignRow = memo(
	({
		campaign,
		onNavigate,
		onArchive,
		onPause,
		onUnpause,
		onClientSelect,
		sentOption = "sent",
		deliverabilityOption = "deliverability",
		clients = [],
		selected = false,
		onSelect,
	}: {
		campaign: CampaignProps;
		onNavigate: (id: string) => void;
		onArchive: (campaign: CampaignProps) => void;
		onPause: (id: string) => Promise<void>;
		onUnpause: (id: string) => Promise<void>;
		onClientSelect?: (campaignId: string, clientId: string | null) => Promise<void>;
		sentOption?: "sent" | "contacted";
		deliverabilityOption?: "deliverability" | "bounce_rate";
		clients?: TClientRow[];
		selected?: boolean;
		onSelect?: (id: string, selected: boolean, shiftKey: boolean) => void;
	}) => {
		const [isHovered, setIsHovered] = useState(false);
		const [isDropdownOpen, setIsDropdownOpen] = useState(false);
		const [isClientAnimating, setIsClientAnimating] = useState(false);
		const [isClientDisappearing, setIsClientDisappearing] = useState(false);
		const [displayedClientName, setDisplayedClientName] = useState<string | null>(null);
		const [forceHideToolbar, setForceHideToolbar] = useState(false);
		const [, setClientSelectionInProgress] = useState(false);
		const prevClientIdRef = useRef<string | null>(campaign.client_id);

		// Track whether client action is in progress to fix toolbar visibility
		const isClientActionInProgress = isClientAnimating || isClientDisappearing;

		// Reset hover and force-hide when the mouse leaves
		const handleMouseLeave = () => {
			setIsHovered(false);
			// If any client animation happened, force-hide the toolbar until next hover
			if (isClientActionInProgress) {
				setForceHideToolbar(true);
			}
		};

		// Reset force-hide when the mouse enters
		const handleMouseEnter = () => {
			setIsHovered(true);
			setForceHideToolbar(false);
		};

		const clientName = campaign.client_id ? clients.find(c => c.id === campaign.client_id)?.name : null;

		// If the client ID changes from parent props (not during a client selection),
		// trigger animations when not already in progress
		if (campaign.client_id !== prevClientIdRef.current) {
			// Store the new value for next render comparison
			const oldClientId = prevClientIdRef.current;
			prevClientIdRef.current = campaign.client_id;

			// Only trigger animations if this is not the initial render
			if (oldClientId !== null || campaign.client_id !== null) {
				if (campaign.client_id) {
					// Client was added or changed
					setIsClientAnimating(true);
					setIsClientDisappearing(false);
					setDisplayedClientName(clientName || null);

					// Schedule reset of animation state
					setTimeout(() => {
						setIsClientAnimating(false);
					}, 300);
				} else if (oldClientId) {
					// Client was removed
					const oldClientName = clients.find(c => c.id === oldClientId)?.name || null;

					setIsClientDisappearing(true);
					setIsClientAnimating(false);
					setDisplayedClientName(oldClientName);

					// Schedule reset of animation and display states
					setTimeout(() => {
						setIsClientDisappearing(false);
						setDisplayedClientName(null);
					}, 250);
				}
			}
		}

		// Add effect to handle data-client-selection attribute changes
		useEffect(() => {
			const row = document.querySelector(`[data-campaign-id="${campaign.id}"]`);
			if (row?.hasAttribute("data-client-selection")) {
				setClientSelectionInProgress(true);
			} else {
				setClientSelectionInProgress(false);
			}
		}, [campaign.id]);

		const isDraft = campaign.status.toLowerCase() === "draft";

		// For the Sent/Contacted column
		const showContactedMetrics = sentOption === "contacted";

		// For the Deliverability/Bounce Rate column
		const showDeliverabilityMetrics = deliverabilityOption === "deliverability";

		const sentEmails = campaign.sent;
		const bouncedEmails = campaign.bounced;

		const totalLeads = campaign.hasLeads ?? 0;
		const totalEmails = totalLeads * (campaign.steps?.length ?? 0);

		const deliverability = sentEmails > 0 ? sentEmails - bouncedEmails : 0;
		const bounced = sentEmails > 0 ? bouncedEmails : 0;

		const contactedEmails = campaign.contacted;
		const openedEmails = campaign.opened;
		const replied = campaign.replied;
		const positiveReplies = campaign.positiveReplies ?? 0;

		const progressSent = sentEmails / totalEmails;
		const progressContacted = sentEmails > 0 ? contactedEmails / sentEmails : 0;

		const handleClientSelect = async (clientId: string | null) => {
			if (!onClientSelect) return;

			// Mark that a client selection is in progress to prevent double animation
			setClientSelectionInProgress(true);

			try {
				// Get the previous client name before we potentially update it
				const previousClientId = campaign.client_id;
				const previousClientName = previousClientId ? clients.find(c => c.id === previousClientId)?.name : null;

				// Trigger animation before the API call
				if (clientId) {
					// Client added or changed
					const selectedClientName = clients.find(c => c.id === clientId)?.name;
					if (selectedClientName) {
						// Prepare animation state before the API call
						setIsClientAnimating(true);
						setIsClientDisappearing(false);
						setDisplayedClientName(selectedClientName);
						// Update ref for future renders
						prevClientIdRef.current = clientId;

						// Make the API call to update the client association
						await onClientSelect(campaign.id, clientId);

						// Force hide the toolbar after interaction
						setForceHideToolbar(true);
						// Clean up animation state after it completes
						setTimeout(() => {
							setIsClientAnimating(false);
							// Reset the selection in progress flag
							setClientSelectionInProgress(false);
						}, 300);
					}
				} else {
					// Client removed
					if (previousClientName) {
						// Prepare animation state before the API call
						setIsClientDisappearing(true);
						setIsClientAnimating(false);
						setDisplayedClientName(previousClientName);
						// Update ref for future renders
						prevClientIdRef.current = null;

						// Make the API call to update the client association
						await onClientSelect(campaign.id, clientId);

						// Force hide the toolbar after interaction
						setForceHideToolbar(true);
						// Clean up animation and display state after it completes
						setTimeout(() => {
							setIsClientDisappearing(false);
							setDisplayedClientName(null);
							// Reset the selection in progress flag
							setClientSelectionInProgress(false);
						}, 250);
					} else {
						await onClientSelect(campaign.id, clientId);
						// Force hide the toolbar after interaction
						setForceHideToolbar(true);
						setClientSelectionInProgress(false);
					}
				}
			} catch (error) {
				console.error("Failed to update client association:", error);
				toast.error("Failed to update client association");
				setClientSelectionInProgress(false);
			}
		};

		// Handle checkbox selection
		const handleCheckboxChange = (e: React.MouseEvent) => {
			e.stopPropagation();
			if (onSelect) {
				// Pass the shift key state along with the campaign id and selected state
				onSelect(campaign.id, !selected, e.shiftKey);
			}
		};

		if (process.env.NODE_ENV === "development") {
			console.log({
				title: campaign.title,
				sentEmails,
				bouncedEmails,
				totalLeads,
				totalEmails,
				deliverability,
				bounced,
				showDeliverabilityMetrics,
			});
		}

		return (
			<TableRow
				key={campaign.id}
				className={`bg-background-bg-base border-background-bg-divider relative cursor-pointer border-b-[0.5px]`}
				onClick={() => onNavigate(campaign.id)}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}>
				<TableCell className="w-[20%]">
					<div className="flex items-center gap-3">
						<div onClick={handleCheckboxChange} className="shrink-0 cursor-pointer">
							<Checkbox checked={selected} />
						</div>
						<span className="grow truncate">
							{campaign.title}
							{(clientName || displayedClientName) && (
								<span
									className={cn(
										"text-label-label-muted inline-block",
										isClientAnimating && "animate-client-appear",
										isClientDisappearing && "animate-client-disappear",
									)}>
									{" "}
									— {displayedClientName || clientName}
								</span>
							)}
						</span>
					</div>
				</TableCell>
				<TableCell
					className="w-[10%]"
					icon={<StatusIndicator status={campaign.status.toLowerCase() as CampaignStatus} />}
				/>
				<TableCell className="w-[11.67%]">
					{showContactedMetrics ? (
						<div className="flex h-10 flex-1 items-center justify-between py-1">
							<div className="flex items-center gap-2">
								<ProgressCircle
									percentage={progressContacted * 100}
									size={14}
									status={campaign.status.toLowerCase() as CampaignStatus}
								/>
								<div className="flex items-center gap-1">
									<span className="text-mini text-label-title">
										{contactedEmails || "0"}/{totalLeads}
									</span>
								</div>
							</div>
							<span className="text-mini text-label-muted">{Math.round(progressContacted * 100)}%</span>
						</div>
					) : sentEmails === 0 ? (
						<div className="justify-left flex h-10 flex-1 items-center py-1">
							<span className="text-mini text-label-title">-</span>
						</div>
					) : (
						<div className="flex h-10 flex-1 items-center justify-between py-1">
							<div className="flex items-center gap-2">
								<ProgressCircle
									percentage={progressSent * 100}
									size={14}
									status={campaign.status.toLowerCase() as CampaignStatus}
								/>
								<div className="flex items-center gap-1">
									<span className="text-mini text-label-title">
										{sentEmails}/{totalEmails}
									</span>
								</div>
							</div>
							<span className="text-mini text-label-muted">{Math.round(progressSent * 100)}%</span>
						</div>
					)}
				</TableCell>
				{/* Opened */}
				<MetricCell value={openedEmails} total={sentEmails} noData={isDraft || openedEmails === 0} />
				{/* Replied */}
				<MetricCell value={replied} total={sentEmails} noData={isDraft || replied === 0} />
				{/* Positive Replies */}
				<MetricCell value={positiveReplies ?? 0} total={replied ?? 0} noData={isDraft || positiveReplies === 0} />
				{/* Deliverability / Bounce Rate */}
				<MetricCell
					value={showDeliverabilityMetrics ? deliverability : bounced}
					total={sentEmails}
					isPercentage
					noBorder
					noData={isDraft || sentEmails === 0}>
					<TableCellToolbar show={(!forceHideToolbar && isHovered && !isClientActionInProgress) || isDropdownOpen}>
						<DropdownMenu
							open={isDropdownOpen}
							onOpenChange={open => {
								setIsDropdownOpen(open);
								if (!open) {
									// Force hide the toolbar when menu is closed
									setForceHideToolbar(true);
								}
							}}>
							<DropdownMenuTrigger asChild onClick={e => e.stopPropagation()}>
								<Button
									variant="inboxAction"
									className={cn(
										"flex h-6 w-6 cursor-pointer items-center justify-center rounded-[5px] px-1 transition-all",
										isDropdownOpen && "bg-background-bg-base-hover",
									)}>
									<DotsVerticalIcon className="shrink-0 text-[#1A1A1A]" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end" variant="wide" onCloseAutoFocus={e => e.preventDefault()}>
								{campaign.status.toLowerCase() === "active" && (
									<DropdownMenuItem
										onClick={async e => {
											e.stopPropagation();
											try {
												await onPause(campaign.id);
												setIsDropdownOpen(false);
												setForceHideToolbar(true);
											} catch (error) {
												console.error("Failed to pause campaign:", error);
												toast.error("Failed to pause campaign");
											}
										}}>
										<PauseActionIcon className="text-label-title" />
										<span className="line-clamp-1 flex-1 overflow-hidden text-ellipsis">Pause</span>
									</DropdownMenuItem>
								)}
								{campaign.status.toLowerCase() === "paused" && (
									<DropdownMenuItem
										onClick={async e => {
											e.stopPropagation();
											try {
												await onUnpause(campaign.id);
												setIsDropdownOpen(false);
												setForceHideToolbar(true);
											} catch (error) {
												console.error("Failed to unpause campaign:", error);
												toast.error("Failed to unpause campaign");
											}
										}}>
										<PlayActionIcon className="text-label-title" />
										<span className="line-clamp-1 flex-1 overflow-hidden text-ellipsis">Unpause</span>
									</DropdownMenuItem>
								)}
								<DropdownMenuItem
									onClick={e => {
										e.stopPropagation();
										onArchive(campaign);
										setIsDropdownOpen(false);
										setForceHideToolbar(true);
									}}>
									<ArchiveBoxIcon className="" />
									<span className="line-clamp-1 flex-1 overflow-hidden text-ellipsis">Archive</span>
								</DropdownMenuItem>

								{clients.length > 0 && (
									<DropdownMenuSub>
										<DropdownMenuSubTrigger className="h-9 px-3 py-1">
											<UserIcon className="text-label-title h-4 w-4 shrink-0" />
											<span className="line-clamp-1 flex-1 overflow-hidden text-ellipsis">
												{clientName ? clientName : "Assign client"}
											</span>
										</DropdownMenuSubTrigger>
										<DropdownMenuSubContent className="max-h-[200px] w-[200px] overflow-y-auto">
											<DropdownMenuItem
												onClick={e => {
													e.stopPropagation();
													handleClientSelect(null);
													setIsDropdownOpen(false);
													setForceHideToolbar(true);
												}}>
												<span className="text-label-muted">None</span>
											</DropdownMenuItem>
											{clients.map(client => (
												<DropdownMenuItem
													key={client.id}
													onClick={e => {
														e.stopPropagation();
														handleClientSelect(client.id);
														setIsDropdownOpen(false);
														setForceHideToolbar(true);
													}}>
													<span
														className={cn(
															"line-clamp-1 flex-1 overflow-hidden text-ellipsis",
															campaign.client_id === client.id && "font-medium",
														)}>
														{client.name}
													</span>
													{campaign.client_id === client.id && <Check className="text-primary ml-2 h-4 w-4" />}
												</DropdownMenuItem>
											))}
										</DropdownMenuSubContent>
									</DropdownMenuSub>
								)}
							</DropdownMenuContent>
						</DropdownMenu>
					</TableCellToolbar>
				</MetricCell>
			</TableRow>
		);
	},
);

CampaignRow.displayName = "CampaignRow";

export default CampaignRow;
