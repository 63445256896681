import { Variable } from "@/components/TipTap/conditionalBuilder/core/nodes/types";
import { formatSenderVariableDisplayName } from "@/components/TipTap/slashCommand/utils/senderVariableCommands";
import { CustomField } from "@za-zu/types";

export function transformLiquidForTipTap(
	liquidText: string,
	customFieldsById: Record<string, CustomField.CustomField>,
	defaultVariablesById: Record<string, Variable>,
): string {
	// Find all {{custom.fieldId}} and {{fieldId}} tags and replace them with the appropriate span tags that will be
	// transformed into variable nodes in the slashSuggestions plugin. Since we're looking for {{custom.fieldId}} or
	// {{fieldId}}, we can work with the raw text and replace things.
	const regex = /{{([^}]+)}}/g;
	const textWithVariableNodes = liquidText.replace(regex, (match, ...parts) => {
		const variableIdRaw = parts[0];
		if (!variableIdRaw) {
			// The string {{}} was found, which means there is no variable id. This should not happen, but we'll return the
			// original match to avoid breaking the text.
			return match;
		}
		const isCustom = variableIdRaw.startsWith("custom.");
		const fieldId = isCustom ? variableIdRaw.replace("custom.", "") : variableIdRaw;
		const field = isCustom ? customFieldsById[fieldId] : defaultVariablesById[fieldId];
		const isSenderVariable = fieldId.startsWith("sender.");
		if (!field && !isSenderVariable) {
			// The field id was not found in the custom fields or default variables, so we'll return the original match to
			// avoid breaking the text.
			return match;
		}
		const displayName = isSenderVariable ? formatSenderVariableDisplayName(fieldId) : field.name;
		if (!displayName) {
			// The field id was not found in the custom fields or default variables, so we'll return the original match to
			// avoid breaking the text.
			return match;
		}
		return `<span data-node-type="variable" data-field-id="${fieldId}" data-field-display-name="${displayName}" data-is-custom="${isCustom}" data-is-pending="false">${displayName}</span>`;
	});
	// Make sure the slashes are not immediately preceded by a <, as that would mean it's a closing tag.
	const textWithSlashSpans = textWithVariableNodes.replace(
		/(?<!(<|href=[^>]*))\//g,
		"<span data-node-type='inactiveSlash'>/</span>",
	);
	return textWithSlashSpans;
}
