import { CampaignCreationStepEnum } from "@/app/enums/campaign.enum";
import CampaignEditorHeader from "@/components/CampaignEditor/CampaignEditorHeader";
import { FieldConfiguration } from "@/components/CSVUploader/components/FieldConfiguration";
import { CSVRow } from "@/components/CSVUploader/types";
import { ImportLeads } from "@/components/ImportLeads/ImportLeads";
import { useCampaignStore } from "@/store";
import { useState } from "react";
import { useParams } from "wouter";

export default function Page() {
	const params = useParams();
	const { campaigns, leadsByCampaignId } = useCampaignStore();
	const [importedData, setImportedData] = useState<CSVRow[] | null>(null);

	const campaignId = params.campaignId;
	if (!campaignId) {
		return (
			<div className="flex w-full flex-col">
				<div className="flex w-full flex-col">
					<div>Campaign not found</div>
				</div>
			</div>
		);
	}

	const selectedCampaign = campaigns.find(campaign => campaign.id === campaignId);
	if (!selectedCampaign) {
		return (
			<div className="flex w-full flex-col">
				<div className="flex w-full flex-col">
					<div>Campaign not found</div>
				</div>
			</div>
		);
	}
	const campaignLeads = leadsByCampaignId[selectedCampaign.id];

	if (campaignLeads === undefined) {
		return (
			<div className="flex w-full flex-col">
				<div className="flex w-full flex-col">
					<CampaignEditorHeader
						selectedCampaign={selectedCampaign}
						activeCampaignStep={CampaignCreationStepEnum.Leads}
					/>

					<div>Campaign not found</div>
				</div>
			</div>
		);
	}

	const showFieldConfiguration = importedData !== null;

	if (showFieldConfiguration) {
		return (
			<div className="flex w-full flex-col">
				<div className="flex w-full flex-col">
					<CampaignEditorHeader
						selectedCampaign={selectedCampaign}
						activeCampaignStep={CampaignCreationStepEnum.Leads}
					/>

					<FieldConfiguration selectedCampaign={selectedCampaign} importedData={importedData} />
				</div>
			</div>
		);
	}

	return (
		<div className="flex w-full flex-col">
			<div className="flex w-full flex-col">
				<CampaignEditorHeader selectedCampaign={selectedCampaign} activeCampaignStep={CampaignCreationStepEnum.Leads} />

				<ImportLeads selectedCampaign={selectedCampaign} setImportedData={setImportedData} />
			</div>
		</div>
	);
}
