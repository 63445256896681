import { useSidebar } from "./sidebar";
import { Tooltip, TooltipTrigger, TooltipContent } from "./tooltip";
import { SidebarCloseSVG, SidebarExpandSVG } from "@/assets/icons/svgs";
import { cn } from "@/lib/utils";

export const SidebarToggleButton = ({ variant = "collapsed" }: { variant?: "collapsed" | "expanded" }) => {
	const { toggleSidebar, state } = useSidebar();

	// Only render the button when the sidebar state matches the variant
	if (variant === "collapsed" && state !== "collapsed") {
		return null;
	}

	if (variant === "expanded" && state !== "expanded") {
		return null;
	}

	return (
		<Tooltip delayDuration={500}>
			<TooltipTrigger asChild>
				<button
					onClick={toggleSidebar}
					className={cn(
						"flex h-6 w-6 items-center justify-center rounded-sm",
						// Smoother hover transition with ease-out instead of default linear
						"transition-colors duration-150 ease-out",
						"hover:bg-gray-100",
						// Respect reduced motion preference
						"motion-reduce:transition-none",
					)}
					aria-label={variant === "expanded" ? "Collapse sidebar" : "Expand sidebar"}>
					<span
						className={cn(
							// Add transform transition to the icon for a subtle scale effect
							"inline-flex transform transition-transform duration-150 ease-out",
							"hover:scale-105 active:scale-95",
							"motion-reduce:transform-none motion-reduce:transition-none",
						)}>
						{variant === "expanded" ? <SidebarCloseSVG /> : <SidebarExpandSVG />}
					</span>
				</button>
			</TooltipTrigger>
			<TooltipContent side={variant === "expanded" ? "bottom" : "right"}>
				{variant === "expanded" ? "Collapse the sidebar (⌘B)" : "Expand the sidebar (⌘B)"}
			</TooltipContent>
		</Tooltip>
	);
};
