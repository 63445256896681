import * as React from "react";
const SvgText = (props) => /* @__PURE__ */ React.createElement("svg", { width: 16, height: 16, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { id: "text" }, /* @__PURE__ */ React.createElement("g", { id: "Vector" }, /* @__PURE__ */ React.createElement("path", { d: "M1.55 12.2832C2.01197 12.2832 2.27182 12.0522 2.43351 11.4978L2.87238 10.1986H5.8809L6.31977 11.5151C6.47568 12.058 6.7355 12.2832 7.22637 12.2832C7.7345 12.2832 8.0983 11.9424 8.0983 11.4632C8.0983 11.2899 8.06944 11.134 7.98864 10.9088L5.70189 4.70695C5.45359 4.02555 5.04937 3.72528 4.36798 3.72528C3.70968 3.72528 3.29969 4.03711 3.05716 4.71273L0.781997 10.9088C0.706924 11.1224 0.666504 11.3188 0.666504 11.4632C0.666504 11.9656 1.0072 12.2832 1.55 12.2832ZM3.25349 8.84729L4.33333 5.43454H4.37375L5.47668 8.84729H3.25349Z", fill: "#397AF1", style: {
  fill: "#397AF1",
  fill: "color(display-p3 0.2235 0.4784 0.9451)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M11.1094 12.2716C11.8832 12.2716 12.657 11.8847 13.0034 11.2264H13.0381V11.544C13.0727 12.0406 13.3903 12.2947 13.8465 12.2947C14.3258 12.2947 14.6665 12.006 14.6665 11.4458V7.91762C14.6665 6.67029 13.6328 5.85031 12.0448 5.85031C10.7629 5.85031 9.7697 6.30649 9.48097 7.04562C9.42324 7.17269 9.39437 7.29395 9.39437 7.42675C9.39437 7.80789 9.68884 8.07349 10.0988 8.07349C10.3702 8.07349 10.5839 7.96955 10.7687 7.76169C11.144 7.27662 11.4558 7.10915 11.9698 7.10915C12.605 7.10915 13.0092 7.44409 13.0092 8.02155V8.43155L11.4501 8.52389C9.9025 8.61629 9.03637 9.26882 9.03637 10.3949C9.03637 11.5094 9.93137 12.2716 11.1094 12.2716ZM11.6638 11.0532C11.0978 11.0532 10.7167 10.7644 10.7167 10.3025C10.7167 9.86362 11.0748 9.58062 11.7042 9.53449L13.0092 9.45362V9.90402C13.0092 10.5739 12.4029 11.0532 11.6638 11.0532Z", fill: "#397AF1", style: {
  fill: "#397AF1",
  fill: "color(display-p3 0.2235 0.4784 0.9451)",
  fillOpacity: 1
} }))));
export default SvgText;
