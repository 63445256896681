import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
	DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { OperatorDetails, OperatorDetailsMap, Operator, getOperatorCollection, VarType } from "../core/nodes/constants";

type OperatorSelectorProps = {
	showOperatorSearch: boolean;
	setShowOperatorSearch: (show: boolean) => void;
	selectedOperator?: Operator;
	variableType?: VarType;
	onOperatorSelect: (operator: Operator) => void;
};

export function OperatorSelector({
	showOperatorSearch,
	setShowOperatorSearch,
	selectedOperator,
	variableType,
	onOperatorSelect,
}: OperatorSelectorProps) {
	const operatorSearchResults: OperatorDetails[] = getOperatorCollection(variableType);

	return (
		<DropdownMenu open={showOperatorSearch} onOpenChange={setShowOperatorSearch}>
			<DropdownMenuTrigger asChild>
				<span className="border-background-bg-divider border-r-background-bg-divider-p3 bg-background-bg-dim-hover font-geist text-body-base leading-body-base inline-flex h-6 min-w-6 cursor-pointer items-center justify-center gap-1 border-r px-1.5 font-normal">
					{selectedOperator !== undefined ? OperatorDetailsMap[selectedOperator].name : "select condition"}
				</span>
			</DropdownMenuTrigger>
			<DropdownMenuContent variant="wide">
				{/* Group 1: Value presence operators */}
				<div className="border-background-bg-border w-full border-b">
					{operatorSearchResults
						.filter(op => [Operator.HAS_VALUE, Operator.HAS_NO_VALUE].includes(op.operator))
						.map(op => (
							<DropdownMenuItem
								key={op.operator}
								className="w-full justify-start"
								onClick={() => {
									onOperatorSelect(op.operator);
									setShowOperatorSearch(false);
								}}>
								<span className="w-full">{op.name}</span>
							</DropdownMenuItem>
						))}
				</div>
				{/* Group 2: Comparison operators */}
				<div className="w-full">
					{operatorSearchResults
						.filter(op =>
							[
								Operator.EQ,
								Operator.NE,
								Operator.LT,
								Operator.LTE,
								Operator.GT,
								Operator.GTE,
								Operator.CONTAINS,
							].includes(op.operator),
						)
						.map(op => (
							<DropdownMenuItem
								key={op.operator}
								className="w-full justify-start"
								onClick={() => {
									onOperatorSelect(op.operator);
									setShowOperatorSearch(false);
								}}>
								<span className="w-full">{op.name}</span>
							</DropdownMenuItem>
						))}
				</div>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
