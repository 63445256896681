import { CampaignCreationStepEnum } from "@/app/enums/campaign.enum";
import { CSVRow } from "@/components/CSVUploader/types";
import { RequestConflictError, RequestNotFoundError } from "@/hooks/useQuery";
import { generateGUID } from "@/lib/utils";
import useFetchStore from "@/store/fetchStore";
import { CampaignProps, calculateCampaignMetricsPercentages, getDefaultMetrics } from "@/types/campaign";
import { CampaignCreate, TLeadCustomFieldValue, TLeadWithCustomFieldValues, TUpdateLeadPayload } from "@za-zu/types";
import { toast } from "sonner";
import { create } from "zustand";
import { persist } from "zustand/middleware";
type CreateCampaignBody = {
	name: string;
	client_id: string | null;
};

export const ACCEPTABLE_TRUE_VALUES = ["true", "t", "1"];
export const ACCEPTABLE_FALSE_VALUES = ["false", "f", "0"];

type CampaignId = string;

type PendingCampaignChange =
	| {
			id: CampaignId;
			action: "delete";
			onSuccess?: () => void;
	  }
	| {
			id: CampaignId;
			action: "update";
			campaign: CampaignProps;
			onSuccess?: () => void;
	  }
	| {
			id: CampaignId;
			action: "create";
			campaign: CampaignCreate;
			onSuccess?: () => void;
	  };
type useCampaignStoreProps = {
	campaigns: CampaignProps[];
	campaignsById: Record<string, CampaignProps>;
	/** Leads are stored in pages of 1000 so we can paginate them and know what needs to still be fetched. Each key in the
	 * record is a page number.
	 */
	leadsByCampaignId: Record<string, Record<number, TLeadWithCustomFieldValues[]>>;
	selectedCampaign: CampaignProps | null;
	activeCampaignStep: CampaignCreationStepEnum;
	defaultTestEmail: string;
	isLoading: boolean;
	error: Error | null;
	wrongSizedLeads: CSVRow[] | null;
	fetchingAndInitializingLatestCampaigns: boolean;
	campaignsInitialized: boolean;
	pendingCampaignChanges: Record<CampaignId, PendingCampaignChange>;
	claimedPendingCampaignChanges: Set<CampaignId>;
	addPendingCampaignChange: (change: PendingCampaignChange) => void;
	/**
	 * Attempt to complete a pending campaign change. Use exponential backoff to retry the change until it is successful.
	 */
	attemptPendingCampaignChange: (campaignId: CampaignId) => Promise<void>;
	createCampaign: (input: CreateCampaignBody) => Promise<string>;
	setWrongSizedLeads: (value: CSVRow[] | null) => void;
	setSelectedCampaign: (value: CampaignProps | null) => void;
	setDefaultTestEmail: (value: string) => void;
	setActiveCampaignStep: (value: CampaignCreationStepEnum) => void;
	// updateHtmlInActiveStep: (newHtml: string) => void;
	setCampaigns: (campaigns: CampaignProps[]) => void;
	upsertCampaign: (campaign: CampaignProps) => void;
	fetchAndInitializeLatestCampaigns: () => void;
	fetchCampaignsWithoutClearing: () => Promise<void>; // with this we can refresh campaigns without clearing campaings list
	clearCampaignCache: () => void;
	saveCampaign: (campaign: CampaignProps, onSuccess?: () => void) => Promise<void>;
	deleteCampaign: (campaignId: string) => Promise<void>;
	publishCampaign: (campaignId: string) => Promise<void>;
	pauseCampaign: (campaignId: string) => Promise<void>;
	unpauseCampaign: (campaignId: string) => Promise<void>;
	fetchAndStorePageOfLeadsForCampaign: (campaignId: string, page: number) => Promise<void>;
	updateLead: (data: TUpdateLeadPayload) => Promise<void>;
	deleteLead: (leadEmail: string, campaignId: string) => Promise<void>;
};

const CAMPAIGN_ACTION_DEBOUNCE_MS = 500;
let lastPauseTimestamp = 0;
let lastUnpauseTimestamp = 0;

const useCampaignStore = create<useCampaignStoreProps>()(
	persist(
		(set, get) => ({
			campaigns: [],
			campaignsById: {},
			leadsByCampaignId: {},
			selectedCampaign: null,
			activeCampaignStep: CampaignCreationStepEnum.Leads,
			isLoading: false,
			error: null,
			wrongSizedLeads: null,
			fetchingAndInitializingLatestCampaigns: false,
			campaignsInitialized: false,
			defaultTestEmail: "",
			pendingCampaignChanges: {},
			claimedPendingCampaignChanges: new Set(),
			addPendingCampaignChange: change =>
				set(state => ({
					pendingCampaignChanges: {
						...state.pendingCampaignChanges,
						[change.id]: change,
					},
				})),
			createCampaign: async (input: CreateCampaignBody) => {
				const { authorizedFetch } = useFetchStore.getState();
				if (!authorizedFetch) throw new Error("Not authorized");
				const id = generateGUID("camp", 12);
				get().upsertCampaign({
					...input,
					id,
					status: "draft",
					hasLeads: 0,
					isLoadingLeads: false,
					activeStepId: "",
					campaignMetrics: getDefaultMetrics(),
					created_by_user_id: "",
					date_created: new Date().toISOString(),
					date_updated: new Date().toISOString(),
					title: input.name,
					client_id: input.client_id || "",
					steps: [],
					bounced: 0,
					complained: 0,
					contacted: 0,
					error: 0,
					failed: 0,
					missing: 0,
					queued: 0,
					replied: 0,
					sent: 0,
					clicked: 0,
					opened: 0,
					daily_stats: [],
				});
				set(state => ({
					leadsByCampaignId: {
						...state.leadsByCampaignId,
						[id]: {},
					},
				}));
				get().addPendingCampaignChange({
					id,
					action: "create",
					campaign: { ...input, id },
					onSuccess: () => {
						toast.success("Campaign created successfully", {
							duration: 2000,
						});
					},
				});
				return id;
			},
			setWrongSizedLeads: value => set({ wrongSizedLeads: value }),
			setSelectedCampaign: value => {
				set(_state => {
					if (!value) return { selectedCampaign: value };

					const sortedSequence = value.steps.sort((a, b) => a.step_index - b.step_index);
					const updatedValue = {
						...value,
						steps: sortedSequence,
						activeStepId: value.activeStepId || (sortedSequence.length > 0 ? sortedSequence[0].id : ""),
					};
					return { selectedCampaign: updatedValue };
				});
			},
			setDefaultTestEmail: value => set({ defaultTestEmail: value }),
			setActiveCampaignStep: value => set({ activeCampaignStep: value }),

			setCampaigns: campaigns => {
				const validCampaigns = Array.isArray(campaigns)
					? campaigns.map(campaign => ({
							...campaign,
							steps: campaign.steps?.sort((a, b) => a.step_index - b.step_index) || [],
						}))
					: [];

				set({
					campaigns: validCampaigns,
					campaignsById: validCampaigns.reduce(
						(acc, campaign) => {
							acc[campaign.id] = campaign;
							return acc;
						},
						{} as Record<string, CampaignProps>,
					),
					selectedCampaign: null,
				});
			},
			fetchAndInitializeLatestCampaigns: async () => {
				const { campaignsInitialized, fetchingAndInitializingLatestCampaigns } = get();
				if (campaignsInitialized || fetchingAndInitializingLatestCampaigns) return;
				const { apiClient } = useFetchStore.getState();
				if (!apiClient) {
					throw new Error("No API client found");
				}
				set({ fetchingAndInitializingLatestCampaigns: true });
				set({ isLoading: true });
				try {
					const { data: campaigns } = await apiClient.getCampaigns();
					const incomingCampaigns: CampaignProps[] = campaigns.map(campaign => {
						const calculatedMetrics = calculateCampaignMetricsPercentages(campaign);

						return {
							...campaign,
							activeStepId: campaign.steps.length > 0 ? campaign.steps[0].id : "",
							title: campaign.name,
							created_by_user_id: campaign.created_by,
							date_created: campaign.created_at,
							date_updated: campaign.updated_at,
							campaignMetrics: calculatedMetrics,
							client_id: campaign.client_id || "",
							isLoadingLeads: false,
							daily_stats: [],
						};
					});
					const byId = incomingCampaigns.reduce(
						(acc, campaign) => {
							acc[campaign.id] = campaign;
							return acc;
						},
						{} as Record<string, CampaignProps>,
					);
					set({
						campaignsById: byId,
						campaigns: Object.values(byId),
					});
					set({ isLoading: false, fetchingAndInitializingLatestCampaigns: false, campaignsInitialized: true });
				} catch (error) {
					// Release the lock so subsequent attempts can try again
					set({ fetchingAndInitializingLatestCampaigns: false, isLoading: false });

					if (error instanceof Error && error.message === "Organization not found") {
						// This is likely a race condition where the organization was just created
						// Set a timeout to try again after a short delay
						setTimeout(() => {
							// Reset the initialized flag so we can try again
							set({ campaignsInitialized: false });
							// Try again
							get().fetchAndInitializeLatestCampaigns();
						}, 2000);

						// Still set the error so the current render cycle can handle it appropriately
						set({ error: error as Error });
					} else if (error instanceof Error) {
						set({ error: error as Error });
					} else {
						set({ error: new Error("An unknown error occurred") });
					}
				}
			},
			upsertCampaign: campaign => {
				const latestCampaigns = { ...get().campaignsById, [campaign.id]: campaign };
				set({
					campaigns: Object.values(latestCampaigns),
					campaignsById: latestCampaigns,
				});
			},
			saveCampaign: async (campaign: CampaignProps, onSuccess?: () => void) => {
				get().upsertCampaign(campaign);
				get().addPendingCampaignChange({
					id: campaign.id,
					action: "update",
					campaign,
					onSuccess:
						onSuccess ??
						(() => {
							toast.success("Campaign saved successfully", {
								duration: 2000,
							});
						}),
				});
			},
			deleteCampaign: async (campaignId: string) => {
				// Update local state immediately
				set(state => ({
					campaigns: state.campaigns.filter(c => c.id !== campaignId),
					campaignsById: Object.fromEntries(Object.entries(state.campaignsById).filter(([id]) => id !== campaignId)),
				}));

				// Add to pending changes for backend sync
				get().addPendingCampaignChange({
					id: campaignId,
					action: "delete",
					onSuccess: () => {
						toast.success("Campaign deleted successfully", {
							duration: 2000,
						});
					},
				});
			},
			publishCampaign: async (campaignId: string) => {
				const selectedCampaign = get().campaignsById[campaignId];
				if (!selectedCampaign) return;

				const updatedCampaign: CampaignProps = {
					...selectedCampaign,
					status: "active",
				};
				get().saveCampaign(updatedCampaign, () => {
					toast.success("Campaign published successfully", {
						duration: 2000,
					});
				});
			},
			pauseCampaign: async (campaignId: string) => {
				const now = Date.now();
				if (now - lastPauseTimestamp < CAMPAIGN_ACTION_DEBOUNCE_MS) return;
				lastPauseTimestamp = now;

				const selectedCampaign = get().campaignsById[campaignId];
				if (!selectedCampaign) return;
				if (selectedCampaign.status.toLowerCase() !== "active") {
					console.warn("Attempted to pause a campaign that wasn't active");
					return;
				}

				const updatedCampaign: CampaignProps = {
					...selectedCampaign,
					status: "paused",
				};
				get().saveCampaign(updatedCampaign, () => {
					toast.success("Campaign paused successfully", {
						duration: 2000,
					});
				});
			},
			unpauseCampaign: async (campaignId: string) => {
				const now = Date.now();
				if (now - lastUnpauseTimestamp < CAMPAIGN_ACTION_DEBOUNCE_MS) return;
				lastUnpauseTimestamp = now;

				const selectedCampaign = get().campaignsById[campaignId];
				if (!selectedCampaign) return;
				if (selectedCampaign.status.toLowerCase() !== "paused") {
					console.warn("Attempted to unpause a campaign that wasn't paused");
					return;
				}

				const updatedCampaign: CampaignProps = {
					...selectedCampaign,
					status: "active",
				};
				get().saveCampaign(updatedCampaign, () => {
					toast.success("Campaign resumed successfully", {
						duration: 2000,
					});
				});
			},
			fetchAndStorePageOfLeadsForCampaign: async (campaignId: string, page: number) => {
				const selectedCampaign = get().campaignsById[campaignId];
				const { apiClient } = useFetchStore.getState();
				if (!apiClient) {
					throw new Error("No API client found");
				}
				if (!selectedCampaign) return;
				if (selectedCampaign.isLoadingLeads) return;
				get().upsertCampaign({
					...selectedCampaign,
					isLoadingLeads: true,
				});

				const { data: leads } = await apiClient.getPageOfLeads(campaignId, page);

				const currentCampaignLeads = get().leadsByCampaignId[campaignId] ?? {};
				currentCampaignLeads[page] = leads;
				set(state => ({
					leadsByCampaignId: {
						...state.leadsByCampaignId,
						[campaignId]: currentCampaignLeads,
					},
				}));
				// Get the latest version just in case something else was updated while we were waiting for this call to resolve
				const latestCampaign = get().campaignsById[campaignId];
				get().upsertCampaign({
					...latestCampaign,
					isLoadingLeads: false,
				});
			},
			updateLead: async (data: TUpdateLeadPayload) => {
				const { apiClient } = useFetchStore.getState();
				if (!apiClient) {
					throw new Error("No API client found");
				}
				const result = await apiClient.updateLead(data);
				// TODO: Find a more efficient way to update the lead.
				// For now, iterate over the pages of leads, and the leads in each page, looking for the lead that matches the
				// leadId and update it.
				const existingLeads = get().leadsByCampaignId;
				const leadsForCampaign = existingLeads[data.campaignId];
				if (!leadsForCampaign) return;
				for (const page of Object.values(leadsForCampaign)) {
					for (let i = 0; i < page.length; i++) {
						if (page[i].email === data.leadEmail) {
							// Update using the index and spread to preserve the rest of the page object so we can more easily
							// update the collection of lead pages
							const { custom, ...rest } = result;
							const customById = [...page[i].lead_custom_field_values, ...custom].reduce(
								(acc, curr) => ({
									...acc,
									[curr.custom_field_id]: curr,
								}),
								{} as Record<string, TLeadCustomFieldValue>,
							);

							page[i] = {
								...page[i],
								...rest,
								lead_custom_field_values: Object.values(customById),
							};
						}
					}
				}
				// Now update the leadsByCampaignId store with the new lead
				set(state => ({
					leadsByCampaignId: {
						...state.leadsByCampaignId,
						[data.campaignId]: leadsForCampaign,
					},
				}));
			},
			deleteLead: async (leadEmail: string, campaignId: string) => {
				const { apiClient } = useFetchStore.getState();
				if (!apiClient) {
					throw new Error("No API client found");
				}
				const campaign = get().campaignsById[campaignId];
				const result = await apiClient.deleteLead(leadEmail, campaignId);
				if (result.success === false) {
					toast.error("Failed to delete lead. Please try again later.", {
						duration: 2000,
					});
					throw new Error("Failed to delete lead");
				}
				// TODO: Find a more efficient way to update the lead.
				// For now, iterate over the pages of leads, and the leads in each page, looking for the lead that matches the
				// leadId and update it.
				const existingLeads = get().leadsByCampaignId;
				const leadsForCampaign = existingLeads[campaignId];
				if (!leadsForCampaign) return;
				for (const [pageNumber, page] of Object.entries(leadsForCampaign)) {
					leadsForCampaign[parseInt(`${pageNumber}`)] = page.filter(lead => lead.email !== leadEmail);
				}
				// Now update the leadsByCampaignId store with the new lead
				set(state => ({
					leadsByCampaignId: {
						...state.leadsByCampaignId,
						[campaignId]: leadsForCampaign,
					},
				}));
				get().upsertCampaign({ ...campaign, hasLeads: campaign.hasLeads - 1 });
				toast.success("Lead deleted successfully", {
					duration: 2000,
				});
			},
			attemptPendingCampaignChange: async (campaignId: CampaignId) => {
				let unresolved = true;
				let attempts = 0;
				const { claimedPendingCampaignChanges } = get();
				if (claimedPendingCampaignChanges.has(campaignId)) {
					// This change is already claimed by another function invocation so we don't need to do anything.
					return;
				}
				set(prev => ({
					claimedPendingCampaignChanges: prev.claimedPendingCampaignChanges.union(new Set([campaignId])),
				}));
				const attempt = async (): Promise<void> => {
					// Always use the latest of each of these values because they could be changing. For example, if a user
					// marked a membership to be removed but then the user marked it to be added again before we had a chance to
					// remove it, we would want to use the latest values, we would want to make sure we don't remove it.
					const { apiClient } = useFetchStore.getState();
					if (!apiClient) {
						throw new Error("No API client found");
					}
					const { pendingCampaignChanges } = get();

					const change = pendingCampaignChanges[campaignId];
					if (!change) {
						// This change is no longer in the state so we don't need to do anything. This likely means that the change
						// was completed already.
						return;
					}
					try {
						switch (change.action) {
							case "delete":
								await apiClient.deleteCampaign(change.id, change.onSuccess);
								break;
							case "update": {
								await apiClient.saveCampaign(change.campaign, change.onSuccess);
								break;
							}
							case "create": {
								await apiClient.createCampaign(change.campaign, change.onSuccess);
								break;
							}
						}
						// No error means the change was completed successfully
					} catch (error) {
						switch (change.action) {
							case "delete":
								if (error instanceof RequestNotFoundError) {
									// The account is not in the campaign so we don't need to do anything.
									return;
								}
								break;
							case "create":
								if (error instanceof RequestConflictError) {
									// The campaign already exists so we don't need to do anything.
									return;
								}
								break;
						}
						throw error;
					}
				};
				while (unresolved) {
					try {
						await attempt();
						// If we get here, we know that the change was completed successfully because the function would have
						// thrown an error if it had failed for one reason or another.
						unresolved = false;
					} catch {
						// Implement exponential backoff
						const backoff = 1000;
						// Ten minutes
						const maxBackoff = 600000;
						const jitter = Math.random() * 1000;
						const delay = Math.min((backoff + jitter) * Math.pow(2, attempts), maxBackoff);
						await new Promise(resolve => setTimeout(resolve, delay));
					}
					// Increase the number of attempts so each subsequent attempt is delayed longer
					attempts++;
				}
				// Remove the change from the state. This is safe to do even if this invocation is not the one that completed
				// the change because we only care that the change is completed, not which invocation completed it. There is no
				// concern for race conditions because we are only filtering out the change from the state, rather that deleting
				// it directly. Similarly, for the claims, we only need to do a difference operation on the set of claimed
				// changes instead of trying to remove it from the set of claimed changes directly.
				const changeSet = new Set([campaignId]);
				set(prev => ({
					pendingCampaignChanges: Object.fromEntries(
						Object.entries(prev.pendingCampaignChanges).filter(([key]) => key !== campaignId),
					),
					claimedPendingCampaignChanges: prev.claimedPendingCampaignChanges.difference(changeSet),
				}));
			},
			fetchCampaignsWithoutClearing: async () => {
				const { apiClient } = useFetchStore.getState();
				if (!apiClient) {
					throw new Error("No API client found");
				}

				set({ isLoading: true });

				try {
					const { data: campaigns } = await apiClient.getCampaigns();

					campaigns.forEach(campaign => {
						const calculatedMetrics = calculateCampaignMetricsPercentages(campaign);

						get().upsertCampaign({
							...campaign,
							activeStepId: campaign.steps.length > 0 ? campaign.steps[0].id : "",
							title: campaign.name,
							created_by_user_id: campaign.created_by,
							date_created: campaign.created_at,
							date_updated: campaign.updated_at,
							campaignMetrics: calculatedMetrics,
							client_id: campaign.client_id || "",
							isLoadingLeads: false,
							// TODO: Include these in response
							daily_stats: [],
						});
					});
				} catch (error) {
					if (error instanceof Error) {
						set({ error, isLoading: false });
					} else {
						set({ error: new Error("An unknown error occurred"), isLoading: false });
					}
				} finally {
					set({ isLoading: false });
				}
			},
			clearCampaignCache: () => {
				set({
					campaigns: [],
					campaignsById: {},
					leadsByCampaignId: {},
					selectedCampaign: null,
					activeCampaignStep: CampaignCreationStepEnum.Leads,
					isLoading: false,
					error: null,
					wrongSizedLeads: null,
					fetchingAndInitializingLatestCampaigns: false,
					campaignsInitialized: false,
					defaultTestEmail: "",
					pendingCampaignChanges: {},
					claimedPendingCampaignChanges: new Set(),
				});
			},
		}),
		{
			name: "campaigns-storage",
			version: 13,
			partialize: state =>
				Object.fromEntries(
					Object.entries(state).filter(
						([key]) =>
							![
								"error",
								"isLoading",
								"setSelectedCampaign",
								"setDefaultTestEmail",
								"setPreviewMode",
								"setActiveCampaignStep",
								"updateHtmlInActiveStep",
								"setCampaigns",
								"upsertCampaign",
								"fetchAndInitializeLatestCampaigns",
								"fetchAndStoreLeadsForCampaign",
								"saveCampaign",
								"deleteCampaign",
								"publishCampaign",
								"pauseCampaign",
								"claimedPendingCampaignChanges",
							].includes(key),
					),
				),
		},
	),
);

export default useCampaignStore;
