import { cn } from "@/lib/utils";

export interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
	/**
	 * Delay in seconds before the animation starts
	 * Useful for creating staggered animations
	 */
	delay?: number;

	/**
	 * How long the animation should take in seconds
	 * @default 1.5
	 */
	duration?: number;

	/**
	 * Whether to use ease-out animation
	 * @default true
	 */
	useEaseOut?: boolean;
}

function Skeleton({ className, delay, duration = 1.5, useEaseOut = true, style, ...props }: SkeletonProps) {
	// Create animation styles based on props
	const animationStyle: React.CSSProperties = {
		...(delay !== undefined && { animationDelay: `${delay}s` }),
		...(duration !== 1.5 && { animationDuration: `${duration}s` }),
		// Hardware accelerate the animation
		willChange: "opacity",
		...style,
	};

	return (
		<div
			className={cn(
				"bg-muted animate-pulse rounded-md",
				// Apply easing based on preference
				useEaseOut && "ease-out",
				// Respect reduced motion preferences
				"motion-reduce:animate-none motion-reduce:opacity-50",
				className,
			)}
			style={animationStyle}
			{...props}
		/>
	);
}

export { Skeleton };
