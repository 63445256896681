import { Button } from "../ui/button";
import googleLogo from "@/assets/images/google-logo.png";
import microsoftLogo from "@/assets/images/microsoft-logo.png";
import { useGenerateGoogleOauthUrl } from "@/hooks/accounts/generateGoogleOauthUrl";
import { useGenerateMicrosoftOauthUrl } from "@/hooks/accounts/generateMicrosoftOauthUrl";
import { useAuth } from "@clerk/clerk-react";
import { useCallback } from "react";
import { useLocation } from "wouter";

type Provider = "google" | "microsoft";

const providerConfig = {
	google: {
		label: "Connect Google",
		logo: googleLogo,
	},
	microsoft: {
		label: "Connect Microsoft",
		logo: microsoftLogo,
	},
} as const;

const ConnectAccountButton = ({ label, logo, onClick }: { label: string; logo: string; onClick: () => void }) => (
	<Button
		onClick={onClick}
		variant="outline"
		className="text-(--label-label-title) flex h-8 items-center justify-center gap-1.5 px-2 text-[13px] font-medium leading-[18px]">
		<img src={logo} alt={label} className="h-4 w-4 object-contain" />
		{label}
	</Button>
);

export const ConnectProviderButton = ({
	provider,
	onGoogleClick,
}: {
	provider: Provider;
	onGoogleClick?: () => Promise<void>;
}) => {
	const { getToken } = useAuth();
	const config = providerConfig[provider];
	const { generateMicrosoftOauthUrl } = useGenerateMicrosoftOauthUrl();

	const handleClick = useCallback(async () => {
		if (provider === "google") {
			await onGoogleClick?.();
		} else {
			const token = await getToken();
			await generateMicrosoftOauthUrl({ access_token: token ?? "" });
		}
	}, [getToken, generateMicrosoftOauthUrl, provider]);

	return <ConnectAccountButton label={config.label} logo={config.logo} onClick={handleClick} />;
};

type ConnectGoogleButtonProps = {
	mode: "dialog" | "oauth";
	onClick?: () => void;
	onClose?: () => void;
};

export const ConnectGoogleButton = ({ mode, onClick, onClose }: ConnectGoogleButtonProps) => {
	const { getToken } = useAuth();
	const { generateGoogleOauthUrl } = useGenerateGoogleOauthUrl();

	const handleClick = async () => {
		if (mode === "dialog") {
			onClick?.();
			return;
		}

		const token = await getToken();
		if (!token) return;
		onClose?.();
		await generateGoogleOauthUrl({ access_token: token });
	};

	return <ConnectProviderButton provider="google" onGoogleClick={handleClick} />;
};

export const ConnectMicrosoftButton = () => <ConnectProviderButton provider="microsoft" />;

// Updated ConnectSMTPButton component
export const ConnectSMTPButton = ({ onClick }: { onClick?: () => void }) => {
	const [, setLocation] = useLocation();

	const handleClick = () => {
		if (onClick) {
			onClick();
		} else {
			setLocation("/dashboard/email-accounts/smtp-setup");
		}
	};

	return (
		<Button onClick={handleClick} variant="exit" className="flex h-8 items-center justify-center gap-1.5 px-2">
			Connect SMTP
		</Button>
	);
};
