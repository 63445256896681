import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { customAlphabet } from "nanoid";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

const FULL_ID_ALPHABET = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const AMBIGUOUS_CHARACTERS = "1lLI0O";
const ID_ALPHABET = FULL_ID_ALPHABET.replace(new RegExp(`[${AMBIGUOUS_CHARACTERS}]`, "g"), "");

const nanoid = customAlphabet(ID_ALPHABET);

// Identical to the backend function
export const generateGUID = (prefix: string | null = null, length = 12): string =>
	[prefix ? prefix : "", nanoid(length)].join("_");

export async function sha256(input: string): Promise<string> {
	const encoder = new TextEncoder();
	const data = encoder.encode(input);
	const hashBuffer = await crypto.subtle.digest("SHA-256", data);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, "0")).join("");
	return hashHex;
}
