// Define all shared types here to avoid circular dependencies

export type CoachFeedback = {
	id: string;
	message: string;
	severity: "info" | "warning" | "error";
	position?: number; // cursor position if relevant
	elementId?: string; // DOM element ID if relevant
	description?: string; // Description text for additional context
	originalText?: string; // The original text that triggered this feedback
};

export type EmailCoachRule = {
	id: string;
	name: string;
	description: string;
	analyze: (content: string) => CoachFeedback[];
};

export type CoachOptions = {
	debounceMs?: number;
	maxToastsPerSession?: number;
	dismissDuration?: number;
};

// Default options
export const DEFAULT_COACH_OPTIONS: CoachOptions = {
	debounceMs: 500,
	maxToastsPerSession: 0, // 0 means unlimited toasts
	dismissDuration: 4000,
};
