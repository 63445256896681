/**
 * Shared utility functions for email coach
 */

// Import the default fields from the TipTap component
import { defaultFields } from "../../components/TipTap/slashCommand/utils/defaultCommands";
import useCustomFieldsStore from "../../store/useCustomFieldsStore";
import { CustomField } from "@za-zu/types";

/**
 * Extracts plain text from HTML content while preserving line breaks
 */
export const getPlainTextFromHtml = (html: string): string => {
	const div = document.createElement("div");
	div.innerHTML = html;

	// Get text content but preserve line breaks
	let text = div.textContent || div.innerText || "";

	// First, preserve line breaks by replacing <br> and </p> tags with newlines before getting text content
	// Convert <br> and paragraph endings to newlines in the original HTML
	html = html.replace(/<br\s*\/?>/gi, "\n").replace(/<\/p>/gi, "\n");

	// Re-extract the text with preserved line breaks
	div.innerHTML = html;
	text = div.textContent || div.innerText || "";

	// Normalize horizontal whitespace (spaces, tabs) but preserve line breaks
	text = text.replace(/[ \t]+/g, " ");

	// Remove leading/trailing whitespace on each line
	const lines = text.split("\n");
	const trimmedLines = lines.map(line => line.trim());

	// Rejoin with line breaks and trim the whole text
	return trimmedLines.join("\n").trim();
};

/**
 * Result type for extractSentences function
 */
export interface SentenceExtractionResult {
	sentences: string[]; // The extracted sentences
	isComplete: boolean[]; // Whether each sentence is complete (has proper ending punctuation)
}

/**
 * Extracts sentences from text while properly handling line breaks
 * and tracking which sentences are complete vs. in progress
 */
export const extractSentences = (text: string): SentenceExtractionResult => {
	// First normalize whitespace within lines, but preserve line breaks as potential sentence boundaries
	const normalizedText = text
		.replace(/[ \t]+/g, " ") // Normalize spaces and tabs within lines
		.trim();

	if (normalizedText.length === 0) {
		return { sentences: [], isComplete: [] };
	}

	// Split by line breaks first to handle cases where they're used as sentence boundaries without punctuation
	const lines = normalizedText.split(/\n+/);

	const sentences: string[] = [];
	const isComplete: boolean[] = [];

	// Process each line
	lines.forEach(line => {
		line = line.trim();
		if (line.length === 0) return;

		// Check if the line ends with sentence-ending punctuation
		const hasEndPunctuation = line.match(/[.!?]['"]?$/);

		// Use a sophisticated regex that handles various end-of-sentence cases
		// This matches sentence endings followed by space or end of text
		const sentenceEndingsRegex = /([.!?]+['"]?)(?:\s+|$)/g;

		// Store the original line before any modifications
		const originalLine = line;

		// For processing purposes, we'll handle the line differently based on whether it ends with punctuation
		const processedLine = line;

		// Split by sentence endings, but keep the endings in the result
		const parts = processedLine.split(sentenceEndingsRegex);

		// Combine parts back into complete sentences
		for (let i = 0; i < parts.length - 1; i += 2) {
			if (parts[i] && parts[i + 1]) {
				const completeSentence = (parts[i] + parts[i + 1]).trim();
				sentences.push(completeSentence);
				isComplete.push(true); // These sentences have punctuation, so they're complete
			} else if (parts[i]) {
				sentences.push(parts[i].trim());
				isComplete.push(true); // These sentences have punctuation, so they're complete
			}
		}

		// If there's a remaining part without punctuation, add it as an incomplete sentence
		if (parts.length % 2 === 1 && parts[parts.length - 1].trim().length > 0) {
			const remainingText = parts[parts.length - 1].trim();
			sentences.push(remainingText);
			// If the original line ended with punctuation but we still have remaining text,
			// this is unusual but we'll mark it as complete if the original line had punctuation
			isComplete.push(hasEndPunctuation !== null);
		}

		// If we didn't find any sentences in this line (no punctuation was present at all),
		// treat the entire line as one incomplete sentence
		if (parts.length <= 1 && !hasEndPunctuation) {
			sentences.push(originalLine);
			isComplete.push(false); // No punctuation, so incomplete
		}
	});

	// Remove any empty sentences
	const finalResult: SentenceExtractionResult = {
		sentences: [],
		isComplete: [],
	};

	sentences.forEach((sentence, index) => {
		if (sentence.trim().length > 0) {
			finalResult.sentences.push(sentence);
			finalResult.isComplete.push(isComplete[index]);
		}
	});

	return finalResult;
};

/**
 * Checks if a string ends with sentence-ending punctuation or contains a line break
 */
export const endsWithSentenceEnding = (text: string | null | undefined): boolean => {
	// Guard against null or undefined inputs
	if (text === null || text === undefined) {
		return false;
	}

	// Check for period, question mark, exclamation point at the end
	const endsWithPunctuation = /[.!?]['"]?$/.test(text.trim());

	// Check if the text contains a line break
	// This handles cases where the user presses Enter to start a new sentence
	const containsLineBreak = text.includes("\n");

	return endsWithPunctuation || containsLineBreak;
};

/**
 * Formats variable names in text with double curly braces
 * e.g., "First Name" becomes "{{First Name}}"
 */
export const formatVariableName = (text: string): string => {
	if (!text) return text;

	// Get variable names from the defaultFields array
	const variableNames = defaultFields.map(field => field.displayName);

	// Try to get custom fields if available
	try {
		// Access the custom fields store
		const customFieldsStore = useCustomFieldsStore.getState();

		if (customFieldsStore && customFieldsStore.customFields) {
			// Add custom field names to the variable names array
			const customVariableNames = customFieldsStore.customFields.map((field: CustomField.CustomField) => field.name);
			variableNames.push(...customVariableNames);
		}
	} catch (_error) {
		// If there's an error accessing the store (e.g., server-side rendering),
		// continue with just the default variables
	}

	// Replace all occurrences of known variables with {{variable}}
	let formattedText = text;
	variableNames.forEach(variable => {
		if (!variable) return; // Skip empty variable names
		const regex = new RegExp(`\\b${variable}\\b`, "g");
		formattedText = formattedText.replace(regex, `{{${variable}}}`);
	});

	return formattedText;
};
