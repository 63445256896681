import * as React from "react";

const PauseActionIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.66669 4C2.66669 2.89543 3.56212 2 4.66669 2C5.77126 2 6.66669 2.89543 6.66669 4V12C6.66669 13.1046 5.77125 14 4.66669 14C3.56212 14 2.66669 13.1046 2.66669 12V4Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.33335 4C9.33335 2.89544 10.2288 2 11.3334 2C12.438 2 13.3334 2.89544 13.3334 4V12C13.3334 13.1046 12.4379 14 11.3334 14C10.2288 14 9.33335 13.1046 9.33335 12V4Z"
			fill="currentColor"
		/>
	</svg>
);

export default PauseActionIcon;
