import * as React from "react";
const SvgDisconnectedPlug = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 15, height: 14, viewBox: "0 0 15 14", fill: "none", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M3.78713 6.5483C4.03659 6.29883 4.44106 6.29883 4.69052 6.5483L7.85242 9.71019C8.10188 9.95966 8.10188 10.3641 7.85242 10.6136L6.94902 11.517C6.20062 12.2654 4.98722 12.2654 4.23883 11.517L2.88373 10.1619C2.13533 9.41349 2.13533 8.2001 2.88373 7.4517L3.78713 6.5483Z", fill: "#FF9500", style: {
  fill: "#FF9500",
  fill: "color(display-p3 1.0000 0.5843 0.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.94902 3.3864C6.69955 3.63587 6.69955 4.04034 6.94902 4.2898L10.1109 7.45169C10.3604 7.70116 10.7648 7.70116 11.0143 7.45169L11.9177 6.5483C12.6661 5.7999 12.6661 4.5865 11.9177 3.8381L10.5626 2.48301C9.81421 1.73461 8.60081 1.73461 7.85242 2.48301L6.94902 3.3864Z", fill: "#FF9500", style: {
  fill: "#FF9500",
  fill: "color(display-p3 1.0000 0.5843 0.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.36807 6.32245C5.61754 6.07299 6.022 6.07299 6.27147 6.32245C6.52094 6.57192 6.52094 6.97639 6.27147 7.22585L5.81977 7.67755L4.91637 6.77415L5.36807 6.32245Z", fill: "#FF9500", style: {
  fill: "#FF9500",
  fill: "color(display-p3 1.0000 0.5843 0.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.17487 8.12924C7.42433 7.87978 7.8288 7.87978 8.07827 8.12924C8.32773 8.37871 8.32773 8.78317 8.07827 9.03264L7.62657 9.48434L6.72317 8.58094L7.17487 8.12924Z", fill: "#FF9500", style: {
  fill: "#FF9500",
  fill: "color(display-p3 1.0000 0.5843 0.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.10958 10.3877L4.01298 11.2911L2.65788 12.6462C2.40841 12.8957 2.00395 12.8957 1.75448 12.6462C1.50502 12.3968 1.50502 11.9923 1.75448 11.7428L3.10958 10.3877Z", fill: "#FF9500", style: {
  fill: "#FF9500",
  fill: "color(display-p3 1.0000 0.5843 0.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M10.7885 2.70886L11.6919 3.61226L13.047 2.25716C13.2964 2.0077 13.2964 1.60323 13.047 1.35376C12.7975 1.1043 12.393 1.1043 12.1436 1.35376L10.7885 2.70886Z", fill: "#FF9500", style: {
  fill: "#FF9500",
  fill: "color(display-p3 1.0000 0.5843 0.0000)",
  fillOpacity: 1
} }));
export default SvgDisconnectedPlug;
