import { toast } from "sonner";
import TipTap from "../TipTap/TipTap";
import { CustomField } from "@za-zu/types";
import { useEmailCoach } from "@/lib/emailCoach/useEmailCoach";
import { useEffect, useRef } from "react";

interface EmailEditorProps {
	subject: string;
	html: string;
	onSubjectChange: (subject: string) => void;
	onHtmlChange: (html: string) => void;
	isFollowUp: boolean;
	previewMode: boolean;
	previewContent?: {
		subject?: string;
		html: string;
	};
	onPreviewChange: (previewMode: boolean) => void;
	campaignId: string;
	customFieldsData: {
		customFields: CustomField.CustomField[];
		customFieldsById: Record<string, CustomField.CustomField>;
		pendingCustomFieldTrackingIdMap: Record<string, string>;
	};
}

export const EmailEditor: React.FC<EmailEditorProps> = ({
	subject,
	html,
	onSubjectChange,
	onHtmlChange,
	isFollowUp,
	previewMode,
	previewContent,
	onPreviewChange,
	campaignId,
	customFieldsData,
}) => {
	// Initialize the email coach
	const { analyzeContent, resetCoach } = useEmailCoach();

	// Reference for the analysis debounce timer
	const analysisTimerRef = useRef<NodeJS.Timeout | null>(null);

	// Analyze content when it changes
	useEffect(() => {
		if (!previewMode) {
			// Clear any existing timer
			if (analysisTimerRef.current) {
				clearTimeout(analysisTimerRef.current);
			}

			// Set a short debounce to batch rapid changes during typing
			analysisTimerRef.current = setTimeout(() => {
				analyzeContent(html);
				analysisTimerRef.current = null;
			}, 100); // Small delay to batch rapid changes
		}

		// Cleanup on unmount or when dependencies change
		return () => {
			if (analysisTimerRef.current) {
				clearTimeout(analysisTimerRef.current);
			}
		};
	}, [html, previewMode, analyzeContent]);

	// Reset coach when switching emails
	useEffect(() => {
		resetCoach();

		// Also clear any pending analysis
		if (analysisTimerRef.current) {
			clearTimeout(analysisTimerRef.current);
			analysisTimerRef.current = null;
		}
	}, [campaignId, resetCoach]);

	const handleLabelClick = (editorType: "subject" | "body") => {
		const selector =
			editorType === "subject" ? "[data-subject-editor] .ProseMirror" : "[data-body-editor] .ProseMirror";
		const editor = document.querySelector(selector);
		(editor as HTMLElement)?.focus();
	};

	return (
		<>
			<div
				className="mt-8 flex h-full flex-col gap-8 px-8"
				onClick={() => {
					if (previewMode) {
						toast.info(`Preview mode has been toggled off`, {});
						onPreviewChange(false);
					}
				}}>
				<div className="flex items-center">
					<span
						className="w-[80px] cursor-pointer text-[13px] font-medium leading-[18px] text-[#666666]"
						onClick={() => handleLabelClick("subject")}>
						Subject
					</span>
					<div className="flex flex-1" data-subject-editor>
						<TipTap
							content={previewMode ? previewContent?.subject || subject : subject}
							onUpdate={newContent => {
								if (!previewMode) onSubjectChange(newContent);
							}}
							previewMode={previewMode}
							padding={{ x: 8, top: 0 }}
							placeholder={
								!isFollowUp
									? "Keep it short and sweet"
									: "Leave blank for an in-thread follow up. A new subject line starts a new thread."
							}
							variant="subject"
							customFieldsData={customFieldsData}
							campaignId={campaignId}
						/>
					</div>
				</div>
				<div className="flex min-h-0 flex-1 flex-col gap-4">
					<span
						className="cursor-pointer text-[13px] font-medium leading-[18px] text-[#666666]"
						onClick={() => handleLabelClick("body")}>
						Email body
					</span>
					<div className="h-full flex-1 rounded-md" data-body-editor>
						<TipTap
							content={previewMode ? previewContent?.html || html : html}
							onUpdate={newContent => {
								if (!previewMode) onHtmlChange(newContent);
							}}
							previewMode={previewMode}
							placeholder={"Write your email here...\nPress / to use variables"}
							variant="body"
							customFieldsData={customFieldsData}
							campaignId={campaignId}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
