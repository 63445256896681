import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

interface Option {
	value: string;
	label: string;
}

interface ComboboxProps {
	value?: string;
	onChange?: (value: string) => void;
	options: Option[];
	placeholder?: string;
	searchPlaceholder?: string;
	emptyMessage?: string;
	className?: string;
}

export const Combobox = ({
	value,
	onChange,
	options,
	placeholder = "Select...",
	searchPlaceholder = "Search...",
	emptyMessage = "No options found.",
	className,
}: ComboboxProps) => {
	const [open, setOpen] = React.useState(false);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<button
					role="combobox"
					aria-expanded={open}
					className={cn(
						"focus:outline-hidden flex h-[32px] w-[260px] items-center justify-between gap-[12px] rounded-[12px] border border-[#E3E3E3] bg-white px-[14px] text-[13px] font-medium leading-[18px] text-[#292929] shadow-[0px_1px_1px_0px_rgba(0,0,0,0.06),0px_4px_4px_-1px_rgba(0,0,0,0.02)] hover:border-[#E3E3E3] focus:border-[#292929]",
						className,
					)}>
					<span className="truncate">
						{value ? options.find(option => option.value === value)?.label : placeholder}
					</span>
					<ChevronsUpDown className="h-3 w-3 text-[#666666]" />
				</button>
			</PopoverTrigger>
			<PopoverContent className="p-0" style={{ width: "var(--radix-popover-trigger-width)" }}>
				<Command>
					<CommandInput placeholder={searchPlaceholder} className="h-8 border-none text-[13px] focus:ring-0" />
					<CommandList>
						<CommandEmpty>{emptyMessage}</CommandEmpty>
						<CommandGroup>
							{options.map(option => (
								<CommandItem
									key={option.value}
									value={option.value}
									onSelect={currentValue => {
										onChange?.(currentValue === value ? "" : currentValue);
										setOpen(false);
									}}
									className="flex cursor-pointer items-center px-[14px] py-2 text-[13px] font-medium text-[#292929] hover:bg-[#F5F5F5]">
									<span className="truncate">{option.label}</span>
									<Check className={cn("ml-auto h-3 w-3", value === option.value ? "opacity-100" : "opacity-0")} />
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
};
