import { Combobox } from "@/components/ui/combobox";
import ToggleSwitch from "./ToggleSwitch";
import { AnimatePresence, motion, useReducedMotion } from "motion/react";
// commented out because we don't have a Use Variable button yet
// import { Button } from "@/components/ui/button";
// import BracketsIcon from "@/assets/icons/svgs/brackets.svg?react";
// import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface ComposerToolbarProps {
	previewMode: boolean;
	onPreviewChange: (value: boolean) => void;
	defaultTestEmail: string;
	onTestEmailChange: (email: string) => void;
	leads?: Array<{ email: string }>;
}

const ComposerToolbar: React.FC<ComposerToolbarProps> = ({
	previewMode,
	onPreviewChange,
	defaultTestEmail,
	onTestEmailChange,
	leads = [],
}) => {
	const shouldReduceMotion = useReducedMotion();

	return (
		<>
			<div className="flex h-[54px] items-center justify-between border-b-[0.5px] border-[#E3E3E3] pl-[32px] pr-[16px]">
				{/* Left side with Preview toggle */}
				<div className="flex items-center gap-[8px]">
					<ToggleSwitch isOn={previewMode} onToggle={() => onPreviewChange(!previewMode)} />
					<span>Preview</span>
				</div>

				{/* Right side content - changes based on preview mode */}
				<div className="flex items-center gap-[8px]">
					<AnimatePresence mode="wait">
						{previewMode ? (
							<motion.div
								key="combobox"
								className="relative"
								initial={{ opacity: 0, y: shouldReduceMotion ? 0 : -10 }}
								animate={{ opacity: 1, y: 0 }}
								exit={{ opacity: 0, y: shouldReduceMotion ? 0 : -10 }}
								transition={
									shouldReduceMotion
										? { duration: 0.1 }
										: {
												type: "spring",
												stiffness: 500,
												damping: 30,
												mass: 1,
											}
								}>
								<Combobox
									value={defaultTestEmail}
									onChange={onTestEmailChange}
									options={leads
										.filter(lead => lead.email)
										.map(lead => ({
											value: lead.email,
											label: lead.email,
										}))}
									placeholder="Select lead email..."
									searchPlaceholder="Search leads to preview..."
									emptyMessage="No leads found."
								/>
							</motion.div>
						) : null}
					</AnimatePresence>
				</div>
			</div>
		</>
	);
};

export default ComposerToolbar;
