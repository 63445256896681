import { Campaign, CampaignStep, TCampaignDailyStatsRow } from "@za-zu/types";
import { z } from "zod";

export type CampaignStatus = "active" | "draft" | "completed" | "paused" | "archived";

export const calculateCampaignMetricsPercentages = (campaign: Campaign) => {
	const sent = campaign.sent;
	const opened = campaign.opened;
	const clicked = campaign.clicked;
	const replied = campaign.replied;

	const positiveReplies = campaign.positive_replies ?? 0;

	return {
		sent,
		clicked,
		clickedPct: sent > 0 ? `${Math.round((clicked / sent) * 100)}%` : "0%",
		opened,
		openedPct: sent > 0 ? `${Math.round((opened / sent) * 100)}%` : "0%",
		replied,
		repliedPct: sent > 0 ? `${Math.round((replied / sent) * 100)}%` : "0%",
		positiveReplies,
		positiveRepliesPct: replied > 0 ? `${Math.round((positiveReplies / replied) * 100)}%` : "0%",
	};
};

export interface CampaignProps {
	id: string;
	title: string;
	status: CampaignStatus;
	activeStepId: string;
	steps: z.infer<typeof CampaignStep>[];
	client_id: string;
	created_by_user_id: string;
	date_created: string;
	date_updated: string;
	campaignMetrics: ReturnType<typeof calculateCampaignMetricsPercentages>;
	daily_stats: TCampaignDailyStatsRow[];
	/**
	The number of leads a campaign has associated with it.
	This is not the number of leads we have on the frontend for this campaign.
	It is the number of leads we have in the database.
	 */
	hasLeads: number;
	leadsAreSet?: boolean;
	isLoadingLeads: boolean;
	sortOrder?: number;
	bounced: number;
	complained: number;
	contacted: number;
	error: number;
	failed: number;
	missing: number;
	queued: number;
	replied: number;
	sent: number;
	clicked: number;
	opened: number;
	positiveReplies?: number;
}

export const getDefaultMetrics = () => ({
	sent: 0,
	clicked: 0,
	clickedPct: "0%",
	opened: 0,
	openedPct: "0%",
	replied: 0,
	repliedPct: "0%",
	positiveReplies: 0,
	positiveRepliesPct: "0%",
});
