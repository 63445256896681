import type { RawCommands } from "@tiptap/core";
import { Node } from "@tiptap/core";
import { Variable } from "../../conditionalBuilder/core/nodes/types";
import { CustomField } from "@za-zu/types";
declare module "@tiptap/core" {
	interface Commands<ReturnType> {
		variable: {
			setPendingCustomVariable: (fieldId: string, fieldDisplayName: string, trackingId: string) => ReturnType;
			setVariable: (fieldId: string, fieldDisplayName: string) => ReturnType;
			setCustomVariable: (fieldId: string) => ReturnType;
		};
	}
}

interface VariableNodeOptions {
	variables: Record<string, Variable>;
	defaultVariables: Variable[];
	customFieldsById: Record<string, CustomField.CustomField>;
}

export const VariableNode = Node.create<VariableNodeOptions>({
	name: "variable",
	group: "inline",
	inline: true,
	atom: true,

	addOptions() {
		return {
			variables: {},
			defaultVariables: [],
			customFieldsById: {},
		};
	},

	addAttributes() {
		return {
			fieldId: {
				default: "",
			},
			fieldDisplayName: {
				default: "",
			},
			isPending: {
				default: false,
			},
			isCustom: {
				default: false,
			},
			trackingId: {
				default: "",
			},
		};
	},

	parseHTML() {
		return [
			{
				tag: `span[data-node-type="${this.name}"]`,
				getAttrs: node => {
					return {
						fieldId: node.getAttribute("data-field-id"),
						fieldDisplayName: node.getAttribute("data-field-display-name"),
						isCustom: node.getAttribute("data-is-custom") === "true",
						isPending: node.getAttribute("data-is-pending") === "true",
						trackingId: node.getAttribute("data-tracking-id"),
					};
				},
			},
		];
	},

	renderHTML({ node }) {
		return [
			"span",
			{
				"data-node-type": this.name,
				"data-field-id": node.attrs.fieldId,
				"data-field-display-name": node.attrs.fieldDisplayName,
				"data-is-custom": node.attrs.isCustom,
				"data-is-pending": node.attrs.isPending ?? false,
				"data-tracking-id": node.attrs.trackingId,
				"class":
					"inline-flex h-[24px] items-center justify-center gap-[4px] rounded-[6px] px-[6px] text-[13px] font-normal leading-[18px] text-[#007EFF] bg-[rgba(19,124,249,0.12)]",
			},
			node.attrs.fieldDisplayName,
		];
	},

	addCommands(): Partial<RawCommands> {
		return {
			setVariable:
				(fieldId: string, fieldDisplayName: string) =>
				({ commands }) => {
					return commands.insertContent({
						type: this.name,
						attrs: { fieldId, fieldDisplayName },
					});
				},
			setPendingCustomVariable:
				(fieldId: string, fieldDisplayName: string, trackingId: string) =>
				({ commands }) => {
					return commands.insertContent({
						type: this.name,
						attrs: { fieldId, fieldDisplayName, trackingId, isPending: true, isCustom: true },
					});
				},
			setCustomVariable:
				(fieldId: string) =>
				({ commands }) => {
					const customField = this.options.customFieldsById[fieldId];
					if (!customField) {
						return false;
					}
					return commands.insertContent({
						type: this.name,
						attrs: { fieldId, fieldDisplayName: customField.name, isPending: false, isCustom: true },
					});
				},
		};
	},
});
