import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { AccountSlim } from "@za-zu/types";
import { Button } from "@/components/ui/button";
import { NEW_ACCOUNT_CONNECTED_EVENT } from "@/hooks/accounts/generateGoogleOauthUrl";
import DynamicWarmingSVG from "@/assets/icons/components/DynamicWarmingSVG";
import { ExclamationMarkSVG, StarFrameSVG, ClockSVG } from "@/assets/icons/svgs";

interface AccountConnectedDialogProps {
	isOpen: boolean;
	onClose: () => void;
	account: AccountSlim | null;
}

export const AccountConnectedDialog: React.FC<AccountConnectedDialogProps> = ({ isOpen, onClose, account }) => {
	if (!account) return null;

	return (
		<Dialog open={isOpen} onOpenChange={open => !open && onClose()}>
			<DialogContent
				variant="inbox"
				className="border-background-bg-border bg-background-bg-base flex w-[572px] flex-col items-center gap-[23px] rounded-[12px] border-[0.5px] p-6 shadow-[0px_1px_1px_0px_rgba(0,0,0,0.04),0px_2px_8px_0px_rgba(0,0,0,0.04),0px_3px_17px_0px_rgba(0,0,0,0.04),0px_4px_30px_0px_rgba(0,0,0,0.12)]">
				<DialogTitle className="text-label-title self-stretch text-center text-xl font-semibold leading-[27px]">
					Account connected
				</DialogTitle>
				<DialogDescription className="text-label-muted self-stretch text-center text-[15px] font-normal leading-[22px]">
					You successfully connected {account.email}
				</DialogDescription>

				<div className="bg-background-bg-border w-131 h-[0.5px]" />

				<DynamicWarmingSVG warmthScore={0.0} className="h-8 w-8" />

				<p className="text-label-title self-stretch text-center text-[15px] font-medium leading-[22px]">
					Your email inbox is now warming up.
				</p>

				<div className="flex flex-col items-center gap-3 self-stretch">
					<div className="flex items-start gap-3 self-stretch">
						<div className="flex h-[22px] w-[22px] items-center justify-center p-[3px]">
							<ExclamationMarkSVG className="h-4 w-4" />
						</div>
						<p className="text-label-title flex-1 text-[15px] font-normal leading-[22px]">
							You could start sending right away. But you shouldn&apos;t. Sending en masse without a built-up reputation
							may get your emails sent to spam.
						</p>
					</div>

					<div className="flex items-start gap-3 self-stretch">
						<div className="flex h-[22px] w-[22px] items-center justify-center p-[3px]">
							<StarFrameSVG className="h-4 w-4" />
						</div>
						<p className="text-label-title flex-1 text-[15px] font-normal leading-[22px]">
							Instead, we&apos;ll start warming your account by emailing other connected inboxes. They automatically
							mark your emails as important, reply on occasion, and rescue any messages that miss the inbox.
						</p>
					</div>

					<div className="flex items-start gap-3 self-stretch">
						<div className="flex h-[22px] w-[22px] items-center justify-center p-[3px]">
							<ClockSVG className="h-4 w-4" />
						</div>
						<p className="text-label-title flex-1 text-[15px] font-normal leading-[22px]">
							Building a healthy sender reputation takes ~3 weeks. Once you start sending cold messages, we&apos;ll
							continue our warming processes in parallel to protect your deliverability.
						</p>
					</div>
				</div>

				<div className="flex justify-center">
					<Button
						variant="outline"
						className="border-background-bg-border bg-background-bg-base rounded-[6px] border-[0.5px] shadow-[0px_1px_1px_0px_rgba(0,0,0,0.06),0px_4px_4px_-1px_rgba(0,0,0,0.02)]"
						onClick={onClose}>
						Got it
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

// Create a component that listens for the custom event and shows the dialog
export const AccountConnectedDialogListener: React.FC = () => {
	const [dialogState, setDialogState] = useState<{
		isOpen: boolean;
		account: AccountSlim | null;
	}>({
		isOpen: false,
		account: null,
	});

	useEffect(() => {
		const handleNewAccountConnected = (event: Event) => {
			const customEvent = event as CustomEvent;
			const account = customEvent.detail?.account;

			if (account) {
				setDialogState({
					isOpen: true,
					account,
				});
			}
		};

		// Add event listener
		window.addEventListener(NEW_ACCOUNT_CONNECTED_EVENT, handleNewAccountConnected);

		// Clean up
		return () => {
			window.removeEventListener(NEW_ACCOUNT_CONNECTED_EVENT, handleNewAccountConnected);
		};
	}, []);

	const handleClose = () => {
		setDialogState({
			isOpen: false,
			account: null,
		});
	};

	return <AccountConnectedDialog isOpen={dialogState.isOpen} onClose={handleClose} account={dialogState.account} />;
};
