import SelectEmailAccounts from "@/components/SelectEmailAccounts/SelectEmailAccounts";
import { useEffect, useCallback, useState } from "react";
import { useEmailAccountsStore } from "@/store";
import { InfoBubble } from "@/components/ui/info-bubble";
import {
	ConnectGoogleButton,
	ConnectMicrosoftButton,
	ConnectSMTPButton,
} from "@/components/ConnectAccountsButton/ConnectAccountButton";
import { GoogleAdminPermissionsDialog } from "@/components/GoogleAdminPermissionsDialog";
import { useSidebar } from "@/components/ui/sidebar";
import { AccountConnectedDialogListener } from "@/components/EmailAccountsTable/AccountConnectedDialog";

const EmailAccounts = () => {
	const { addPendingAccountChange, accounts, emailAccountsInitialized } = useEmailAccountsStore();
	const [isGoogleDialogOpen, setIsGoogleDialogOpen] = useState(false);
	const { state: sidebarState } = useSidebar();
	const isSidebarExpanded = sidebarState === "expanded";
	useEffect(() => {
		addPendingAccountChange({
			id: "latest-accounts",
			action: "fetch",
		});
	}, []);

	const handleOnGoogleClick = useCallback(async () => {
		setIsGoogleDialogOpen(true);
	}, []);

	const renderHeader = () => {
		// Don't wait for initialization to show connect buttons
		// Only hide them if we know there are no accounts after initialization
		const shouldHideButtons = emailAccountsInitialized && accounts.length === 0;

		return (
			<header>
				<div className="flex h-[52px] items-center justify-between">
					<div className="flex items-center gap-4 px-4">
						<h1 className="text-(--label-label-title) text-[15px] font-medium leading-[22px]">Email Accounts</h1>
						{!shouldHideButtons && (
							<div className={isSidebarExpanded ? "hidden lg:block" : "hidden md:block"}>
								<InfoBubble>Sending time: Weekdays · 9am-6pm (EST)</InfoBubble>
							</div>
						)}
					</div>
					{!shouldHideButtons && (
						<div className="flex items-center gap-2 pr-4">
							<ConnectSMTPButton />
							<ConnectGoogleButton mode="dialog" onClick={handleOnGoogleClick} />
							<ConnectMicrosoftButton />
						</div>
					)}
				</div>
				<div className="bg-background-bg-border h-[0.5px] w-full" />
			</header>
		);
	};

	return (
		<div className="flex h-full flex-col">
			{renderHeader()}
			<div className="flex-1 overflow-hidden">
				{isGoogleDialogOpen && (
					<GoogleAdminPermissionsDialog isOpen={isGoogleDialogOpen} onClose={() => setIsGoogleDialogOpen(false)} />
				)}
				<SelectEmailAccounts />

				{/* Account Connected Dialog Listener */}
				<AccountConnectedDialogListener />
			</div>
		</div>
	);
};

export default EmailAccounts;
