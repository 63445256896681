import { CampaignCreationStepEnum } from "@/app/enums/campaign.enum";
import CampaignEditorHeader from "@/components/CampaignEditor/CampaignEditorHeader";
import LeadsEditor from "@/components/CampaignEditor/LeadsEditor";
import { useCampaignStore } from "@/store";
import { Suspense, useEffect } from "react";
import { Redirect, useParams } from "wouter";

export default function Page() {
	const params = useParams();
	const { campaignsById, leadsByCampaignId, isLoading, fetchAndStorePageOfLeadsForCampaign } = useCampaignStore();

	useEffect(() => {
		const campaignId = params.campaignId;
		if (!campaignId) {
			return;
		}
		const selectedCampaign = campaignsById[campaignId];
		if (!selectedCampaign) {
			return;
		}
		const campaignLeads = leadsByCampaignId[selectedCampaign.id];
		if (campaignLeads === undefined && !isLoading && !selectedCampaign.isLoadingLeads) {
			fetchAndStorePageOfLeadsForCampaign(selectedCampaign.id, 1);
		}
	}, [campaignsById, leadsByCampaignId, isLoading]);

	const campaignId = params.campaignId;
	if (!campaignId) {
		return (
			<div className="flex w-full flex-col">
				<div className="flex w-full flex-col">
					<div>Campaign not found</div>
				</div>
			</div>
		);
	}

	const selectedCampaign = campaignsById[campaignId];

	if (!selectedCampaign) {
		return (
			<div className="flex w-full flex-col">
				<div className="flex w-full flex-col">
					<CampaignEditorHeader
						selectedCampaign={selectedCampaign}
						activeCampaignStep={CampaignCreationStepEnum.Leads}
					/>

					<div>Campaign not found</div>
				</div>
			</div>
		);
	}

	const campaignLeads = leadsByCampaignId[selectedCampaign.id];

	if (campaignLeads === undefined) {
		return (
			<div className="flex w-full flex-col">
				<div className="flex w-full flex-col">
					<CampaignEditorHeader
						selectedCampaign={selectedCampaign}
						activeCampaignStep={CampaignCreationStepEnum.Leads}
					/>

					<div>Loading...</div>
				</div>
			</div>
		);
	}
	if (Object.keys(campaignLeads).length === 0) {
		if (isLoading) {
			return (
				<div className="flex w-full flex-col">
					<div className="flex w-full flex-col">
						{/* Campaign editor header with navigation and controls */}
						<CampaignEditorHeader
							selectedCampaign={selectedCampaign}
							activeCampaignStep={CampaignCreationStepEnum.Leads}
						/>

						<div>Loading...</div>
					</div>
				</div>
			);
		}
	}
	if (selectedCampaign.hasLeads === 0) {
		// If there are no leads, redirect to the import page
		return <Redirect to={`/dashboard/campaigns/${campaignId}/leads/import`} />;
	}

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<LeadsEditor selectedCampaign={selectedCampaign} campaignLeads={campaignLeads} />
		</Suspense>
	);
}
