import { RequestConflictError, RequestNotFoundError } from "@/hooks/useQuery";
import { CustomField } from "@za-zu/types";
import { z } from "zod";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import useFetchStore from "./fetchStore";

type PendingCustomFieldChangeId = string;
type PendingCustomFieldCreationTrackingId = string;

type PendingCustomFieldChange =
	| {
			id: PendingCustomFieldChangeId;
			displayName: string;
			fieldData: CustomField.Create;
			action: "create";
			onSuccess?: (result: CustomField.CustomField) => void;
	  }
	| {
			id: PendingCustomFieldChangeId;
			action: "update";
			fieldData: CustomField.Update;
			onSuccess?: (result: CustomField.CustomField) => void;
	  }
	| {
			id: PendingCustomFieldChangeId;
			action: "delete";
			onSuccess?: () => void;
	  }
	| {
			id: "fetch-latest";
			action: "fetchLatest";
			onSuccess?: () => void;
	  };

type CustomFieldsStoreProps = {
	customFields: CustomField.CustomField[];
	customFieldsById: Record<string, CustomField.CustomField>;
	pendingCustomFieldChanges: Record<PendingCustomFieldChangeId, PendingCustomFieldChange>;
	claimedPendingCustomFieldChanges: Set<PendingCustomFieldChangeId>;
	customFieldsInitialized: boolean;
	fetchingAndInitializingCustomFields: boolean;
	pendingCustomFieldTrackingIdMap: Record<PendingCustomFieldCreationTrackingId, string>;
	lastSyncTimestamp: number;
	lastSyncCampaignId: string | undefined;
	resolveTrackingIdForPendingCustomField: (trackingId: PendingCustomFieldCreationTrackingId, fieldId: string) => void;
	fetchAndInitializeLatestCustomFields: () => Promise<void>;
	addPendingCustomFieldChange: (change: PendingCustomFieldChange) => void;
	updateCustomFields: (
		data: CustomField.CustomField[],
		trackingMapUpdates?: Record<PendingCustomFieldCreationTrackingId, string>,
	) => void;
	syncCustomFieldsWithLatest: (campaignId?: string) => Promise<void>;
	attemptPendingCustomFieldChange: (changeId: PendingCustomFieldChangeId) => Promise<void>;
	removeCustomField: (fieldId: string) => void;
};

const useCustomFieldsStore = create<CustomFieldsStoreProps>()(
	persist(
		(set, get) => ({
			customFields: [],
			customFieldsById: {},
			pendingCustomFieldChanges: {},
			claimedPendingCustomFieldChanges: new Set(),
			customFieldsInitialized: false,
			fetchingAndInitializingCustomFields: false,
			pendingCustomFieldTrackingIdMap: {},
			lastSyncTimestamp: 0,
			lastSyncCampaignId: undefined,
			resolveTrackingIdForPendingCustomField: (trackingId: PendingCustomFieldCreationTrackingId, fieldId: string) => {
				set(state => ({
					pendingCustomFieldTrackingIdMap: {
						...state.pendingCustomFieldTrackingIdMap,
						[trackingId]: fieldId,
					},
				}));
			},
			fetchAndInitializeLatestCustomFields: async () => {
				const { customFieldsInitialized, fetchingAndInitializingCustomFields } = get();

				if (customFieldsInitialized || fetchingAndInitializingCustomFields) return;
				set({ fetchingAndInitializingCustomFields: true });
				get().addPendingCustomFieldChange({
					id: "fetch-latest",
					action: "fetchLatest",
				});
				return;
			},
			addPendingCustomFieldChange: change => {
				set(state => ({
					pendingCustomFieldChanges: {
						...state.pendingCustomFieldChanges,
						[change.id]: change,
					},
				}));
				// Immediately attempt to process the change
				get().attemptPendingCustomFieldChange(change.id).catch(console.error);
			},
			removeCustomField: (fieldId: string) => {
				set(state => {
					const newFields = state.customFields.filter(field => field.id !== fieldId);
					const newCustomFieldsById = newFields.reduce(
						(acc, field) => {
							acc[field.id] = field;
							return acc;
						},
						{} as Record<string, CustomField.CustomField>,
					);
					return { customFieldsById: newCustomFieldsById };
				});
			},
			updateCustomFields: (
				data: CustomField.CustomField[],
				trackingMapUpdates?: Record<PendingCustomFieldCreationTrackingId, string>,
			) => {
				set(state => {
					const newCustomFieldsById = {
						...state.customFieldsById,
						...data.reduce(
							(acc, field) => {
								acc[field.id] = field;
								return acc;
							},
							{} as Record<string, CustomField.CustomField>,
						),
					};
					const result = {
						...state,
						customFieldsById: newCustomFieldsById,
						customFields: Object.values(newCustomFieldsById),
					};
					if (trackingMapUpdates) {
						result.pendingCustomFieldTrackingIdMap = {
							...state.pendingCustomFieldTrackingIdMap,
							...trackingMapUpdates,
						};
					}
					return result;
				});
			},
			syncCustomFieldsWithLatest: async (campaignId?: string) => {
				const { authorizedFetch } = useFetchStore.getState();
				if (!authorizedFetch) return;

				// Add a timestamp check to prevent multiple calls within 1 second
				const now = Date.now();
				const state = get();
				const lastSync = state.lastSyncTimestamp;
				const lastSyncCampaignId = state.lastSyncCampaignId;

				if (now - lastSync < 1000 && campaignId === lastSyncCampaignId) {
					return;
				}

				try {
					// First fetch the data
					const result = await authorizedFetch("/custom-fields", z.object({ data: z.array(CustomField.CustomField) }), {
						query: campaignId ? { campaign_id: campaignId } : undefined,
					});

					// Only update state after successful fetch
					set(state => ({
						...state,
						lastSyncTimestamp: now,
						lastSyncCampaignId: campaignId,
						// Clear and update in one atomic operation
						customFields: result.data,
						customFieldsById: result.data.reduce(
							(acc, field) => {
								acc[field.id] = field;
								return acc;
							},
							{} as Record<string, CustomField.CustomField>,
						),
					}));
				} catch (error) {
					console.error("Error fetching custom fields:", error);
					// Reset sync state on error
					set(state => ({
						...state,
						lastSyncTimestamp: 0,
						lastSyncCampaignId: undefined,
					}));
				}
			},
			attemptPendingCustomFieldChange: async (changeId: PendingCustomFieldChangeId) => {
				let unresolved = true;
				let attempts = 0;
				const { claimedPendingCustomFieldChanges } = get();
				if (claimedPendingCustomFieldChanges.has(changeId)) {
					// This change is already claimed by another function invocation so we don't need to do anything.
					return;
				}
				set(prev => ({
					claimedPendingCustomFieldChanges: prev.claimedPendingCustomFieldChanges.union(new Set([changeId])),
				}));
				const attempt = async (): Promise<void> => {
					// Always use the latest of each of these values because they could be changing. For example, if a user
					// marked a membership to be removed but then the user marked it to be added again before we had a chance to
					// remove it, we would want to use the latest values, we would want to make sure we don't remove it.
					const { apiClient } = useFetchStore.getState();
					if (!apiClient) {
						throw new Error("No API client found");
					}
					const { pendingCustomFieldChanges } = get();

					const change = pendingCustomFieldChanges[changeId];
					if (!change) {
						// This change is no longer in the state so we don't need to do anything. This likely means that the change
						// was completed already.
						return;
					}
					try {
						switch (change.action) {
							case "create": {
								const result = await apiClient.createCustomField(change.fieldData);
								get().updateCustomFields([result], { [change.id]: result.id });
								change.onSuccess?.(result);
								break;
							}
							case "update": {
								const result = await apiClient.updateCustomField(change.id, change.fieldData);
								get().updateCustomFields([result]);
								change.onSuccess?.(result);
								break;
							}
							case "delete":
								await apiClient.deleteCustomField(change.id);
								get().removeCustomField(change.id);
								change.onSuccess?.();
								break;
							case "fetchLatest": {
								const results = await apiClient.getLatestCustomFields();
								get().updateCustomFields(results);
								set({
									fetchingAndInitializingCustomFields: false,
									customFieldsInitialized: true,
								});
								change.onSuccess?.();
								break;
							}
						}
						// No error means the change was completed successfully
					} catch (error) {
						switch (change.action) {
							case "delete":
								if (error instanceof RequestNotFoundError) {
									// The account is not in the campaign so we don't need to do anything.
									get().removeCustomField(change.id);
									change.onSuccess?.();
									return;
								}
								break;
							case "create":
								if (error instanceof RequestConflictError) {
									// The account is not in the campaign so we don't need to do anything other than update the tracking
									// ID mapping so the variable nodes can be updated to the new ID.
									const {
										details: { existingField },
									} = z
										.object({
											details: z.object({
												existingField: CustomField.CustomField,
											}),
											message: z.string(),
											type: z.string(),
										})
										.parse(error.data);
									get().updateCustomFields([existingField]);
									change.onSuccess?.(existingField);
									console.log("create conflict resolved", existingField);
									return;
								}
								break;
						}
						throw error;
					}
				};
				while (unresolved) {
					try {
						await attempt();
						// If we get here, we know that the change was completed successfully because the function would have
						// thrown an error if it had failed for one reason or another.
						unresolved = false;
					} catch {
						// Implement exponential backoff
						const backoff = 1000;
						// Ten minutes
						const maxBackoff = 600000;
						const jitter = Math.random() * 1000;
						const delay = Math.min((backoff + jitter) * Math.pow(2, attempts), maxBackoff);
						await new Promise(resolve => setTimeout(resolve, delay));
					}
					// Increase the number of attempts so each subsequent attempt is delayed longer
					attempts++;
				}
				// Remove the change from the state. This is safe to do even if this invocation is not the one that completed
				// the change because we only care that the change is completed, not which invocation completed it. There is no
				// concern for race conditions because we are only filtering out the change from the state, rather that deleting
				// it directly. Similarly, for the claims, we only need to do a difference operation on the set of claimed
				// changes instead of trying to remove it from the set of claimed changes directly.
				const changeSet = new Set([changeId]);
				set(prev => ({
					pendingCustomFieldChanges: Object.fromEntries(
						Object.entries(prev.pendingCustomFieldChanges).filter(([key]) => key !== changeId),
					),
					claimedPendingCustomFieldChanges: prev.claimedPendingCustomFieldChanges.difference(changeSet),
				}));
			},
		}),
		{
			name: "CustomFields-storage",
			version: 10,
			partialize: state =>
				Object.fromEntries(
					Object.entries(state).filter(
						([key]) =>
							![
								"claimedPendingCustomFieldChanges",
								"fetchAndInitializeLatestCustomFields",
								"attemptPendingCustomFieldChange",
							].includes(key),
					),
				),
		},
	),
);

export default useCustomFieldsStore;
