import { useState, useMemo } from "react";
import { usePost } from "@/hooks/useQuery";
import { toast } from "sonner";
import { AvailableField, CSVColumn, CSVRow, CustomFieldModalState, PendingCustomField } from "../types";
import { autoMapColumns } from "../utils/autoMapping";
import { defaultFieldsAvailable } from "../constants";
import useCampaignStore from "@/store/useCampaignStore";
import useCustomFieldsStore from "@/store/useCustomFieldsStore";
import { CampaignProps } from "@/types/campaign";
import { CustomField, Leads } from "@za-zu/types";
import { useLocation } from "wouter";
import { parseCustomFieldValue } from "@/utils/parseCustomFieldValue";

const MAX_CUSTOM_FIELD_VALUE_SIZE = 1024 * 100; // 100 KB;

export function useFieldConfiguration(importedData: CSVRow[], selectedCampaign: CampaignProps) {
	const [, navigate] = useLocation();
	const { upsertCampaign, setWrongSizedLeads } = useCampaignStore();
	const { customFields: storeCustomFields, syncCustomFieldsWithLatest } = useCustomFieldsStore();
	const post = usePost<Leads.ImportResponse>();

	const [isImporting, setIsImporting] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [currentColumnId, setCurrentColumnId] = useState<string>("");
	const [pendingCustomFields, setPendingCustomFields] = useState<PendingCustomField[]>([]);
	const [customFieldModal, setCustomFieldModal] = useState<CustomFieldModalState>({
		isOpen: false,
		type: null,
		name: "",
	});

	// Convert and merge custom fields
	const customFieldsFromStore: AvailableField[] = useMemo(
		() =>
			storeCustomFields.map(cf => ({
				id: `custom.${cf.id}`,
				label: cf.name,
				type: "custom" as const,
			})),
		[storeCustomFields],
	);

	const mergedCustomFields: AvailableField[] = useMemo(
		() => [
			...customFieldsFromStore,
			...pendingCustomFields.map(field => ({
				id: field.id,
				label: field.name,
				type: "custom" as const,
			})),
		],
		[customFieldsFromStore, pendingCustomFields],
	);

	// Initialize columns with auto-mapping
	const [columns, setColumns] = useState<CSVColumn[]>(() => {
		if (!importedData?.length) return [];
		const headers = Object.keys(importedData[0]);
		return autoMapColumns(
			headers.map(header => ({
				id: header,
				value: importedData[0][header],
				mappedTo: null,
				customTitle: header,
			})),
			[...defaultFieldsAvailable, ...mergedCustomFields],
		);
	});

	// Filter fields based on search and current mapping
	const { filteredDefaultFields, filteredCustomFields } = useMemo(() => {
		// Get all currently mapped fields except the current column's mapping
		const mappedFields = new Set(
			columns.filter(col => col.mappedTo !== null && col.id !== currentColumnId).map(col => col.mappedTo),
		);

		return {
			filteredDefaultFields: defaultFieldsAvailable.filter(
				field => !mappedFields.has(field.id) && field.label.toLowerCase().includes(searchQuery.toLowerCase()),
			),
			filteredCustomFields: mergedCustomFields.filter(
				field => !mappedFields.has(field.id) && field.label.toLowerCase().includes(searchQuery.toLowerCase()),
			),
		};
	}, [searchQuery, mergedCustomFields, columns, currentColumnId]);

	// Handle field mapping
	const handleFieldMapping = (columnId: string, fieldId: string | null) => {
		setColumns(prev => prev.map(col => (col.id === columnId ? { ...col, mappedTo: fieldId } : col)));
	};

	// Handle custom field creation
	const handleCreateCustomField = () => {
		if (!customFieldModal.type || !customFieldModal.name.trim()) return;

		const trimmedName = customFieldModal.name.trim();
		const newFieldId = `custom.${trimmedName}`;

		setPendingCustomFields(prev => [
			...prev,
			{
				id: newFieldId,
				name: trimmedName,
				type: customFieldModal.type!,
				campaign_id: selectedCampaign.id,
			},
		]);

		handleFieldMapping(currentColumnId, newFieldId);
		setCustomFieldModal({ isOpen: false, type: null, name: "" });
		toast.success("Custom field added to mapping");
	};

	// Handle import
	const handleImport = async () => {
		const defaultMapping: Record<string, string> = {};
		const customMapping: Record<string, string> = {};
		const customFieldTypeRefsById: Record<string, CustomField.CustomFieldType> = [
			...storeCustomFields.map(cf => ({
				id: `custom.${cf.id}`,
				label: cf.name,
				type: cf.type,
			})),
			...pendingCustomFields,
		].reduce((acc, f) => ({ ...acc, [`custom.${f.id.replaceAll("custom.", "")}`]: f.type }), {});

		// Build mapping from columns
		columns.forEach(col => {
			if (!col.mappedTo) return;

			if (col.mappedTo.startsWith("custom.")) {
				customMapping[col.mappedTo] = col.id;
			} else {
				defaultMapping[col.mappedTo] = col.id;
			}
		});

		// Validate email field is mapped
		if (!defaultMapping.email) {
			toast.error("Email field mapping is required.");
			return;
		}

		setIsImporting(true);
		try {
			const leads: Leads.Create[] = [];
			const wrongSizedLeads: CSVRow[] = [];

			// Process each row in the imported data
			for (const row of importedData) {
				const rawEmail = row[defaultMapping.email];
				if (typeof rawEmail !== "string") continue;
				const email = rawEmail.trim();
				if (email.length === 0) continue;

				// Check field sizes
				let hasInvalidSize = false;
				for (const fieldId in defaultMapping) {
					const header = defaultMapping[fieldId];
					const value = row[header];
					if (value && String(value).length > MAX_CUSTOM_FIELD_VALUE_SIZE) {
						hasInvalidSize = true;
						break;
					}
				}
				if (!hasInvalidSize) {
					for (const fieldId in customMapping) {
						const header = customMapping[fieldId];
						const value = row[header];
						if (value && String(value).length > MAX_CUSTOM_FIELD_VALUE_SIZE) {
							hasInvalidSize = true;
							break;
						}
					}
				}
				if (hasInvalidSize) {
					wrongSizedLeads.push(row);
					continue;
				}

				// Format phone number if present
				let phoneNumber: string | null = null;
				if (defaultMapping.phone_number && row[defaultMapping.phone_number]) {
					const digits = String(row[defaultMapping.phone_number]).replace(/\D/g, "");
					phoneNumber = digits.length >= 10 && digits.length <= 15 ? `+${digits}` : null;
				}

				// Build lead object
				const lead: Leads.Create = {
					email,
					firstname: defaultMapping.first_name ? String(row[defaultMapping.first_name] || "") : "",
					lastname: defaultMapping.last_name ? String(row[defaultMapping.last_name] || "") : null,
					linkedin: defaultMapping.linkedin_url ? String(row[defaultMapping.linkedin_url] || "") : null,
					job_title: defaultMapping.job_title ? String(row[defaultMapping.job_title] || "") : null,
					company: defaultMapping.company_name ? String(row[defaultMapping.company_name] || "") : null,
					phone: phoneNumber,
				};

				// Add custom fields
				for (const fieldId in customMapping) {
					const header = customMapping[fieldId];
					const customFieldType = customFieldTypeRefsById[fieldId];
					const rawValue = row[header];
					const value = parseCustomFieldValue(rawValue, customFieldType);

					lead[fieldId] = value;
				}

				leads.push(lead);
			}

			setWrongSizedLeads(wrongSizedLeads);

			// Build new custom fields from pending fields
			const newCustomFields = pendingCustomFields.map(field => ({
				name: field.name,
				type: field.type,
				campaign_id: selectedCampaign.id,
			}));

			// Send to API
			const response = await post(
				"/leads/import",
				Leads.ImportResponse,
				{
					leads,
					new_custom_fields: newCustomFields,
				},
				{ query: { campaign_id: selectedCampaign.id } },
			);

			// Update campaign store
			upsertCampaign({
				...selectedCampaign,
				hasLeads: selectedCampaign.hasLeads + (response.stats?.created ?? 0),
			});
			await syncCustomFieldsWithLatest();

			// Show success/error toast
			const failedLeads = importedData.length - leads.length;
			if (failedLeads > 0) {
				toast.error("Upload error", {
					description: `${failedLeads} leads failed to import due to invalid field lengths.`,
				});
			} else {
				if (response?.partial_errors?.length) {
					toast.warning("Upload partially successful", {
						description: `${response.partial_errors.length} leads failed to import`,
					});
				} else {
					toast.success("Upload successful", {
						description: `You successfully upserted ${leads.length} leads.`,
					});
				}
			}
			navigate(`/dashboard/campaigns/${selectedCampaign.id}/leads`);
		} catch (error) {
			toast.error("Failed to upload leads", {
				description: "There was an error uploading your leads. Please try again.",
			});
			console.error("Failed to upload leads:", error);
		} finally {
			setIsImporting(false);
		}
	};

	return {
		isImporting,
		searchQuery,
		setSearchQuery,
		currentColumnId,
		setCurrentColumnId,
		columns,
		filteredDefaultFields,
		filteredCustomFields,
		customFieldModal,
		setCustomFieldModal,
		handleFieldMapping,
		handleCreateCustomField,
		handleImport,
		allDefaultFields: defaultFieldsAvailable,
	};
}
