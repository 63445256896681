import type { TLeadWithCustomFieldValues } from "@za-zu/types";
import { TableRow, TableCell } from "../ui/table";
import { useState, useRef, useEffect } from "react";
import { TableCellToolbar } from "../ui/table-toolbar";
import { cn } from "@/lib/utils";
import PencilSVG from "@/assets/icons/svgs/pencil.svg?react";
import TrashSVG from "@/assets/icons/svgs/trash.svg?react";
import { useCampaignStore } from "@/store";

interface LeadsTableRowsProps {
	headers: {
		key: string;
		label: string;
	}[];
	displayedLeads: TLeadWithCustomFieldValues[];
	handleRowClick: (lead: TLeadWithCustomFieldValues) => void;
}

export default function LeadsTableRows({ headers, displayedLeads, handleRowClick }: LeadsTableRowsProps) {
	const [hoveredRow, setHoveredRow] = useState<number | null>(null);
	const [visibleCells, setVisibleCells] = useState<Record<number, boolean[]>>({});
	const cellRefs = useRef<Array<Array<HTMLTableCellElement | null>>>([]);

	const { deleteLead } = useCampaignStore();

	// Initialize the cell refs array
	useEffect(() => {
		cellRefs.current = Array(displayedLeads.length)
			.fill(null)
			.map(() => Array(headers.length).fill(null));
	}, [displayedLeads.length, headers.length]);

	// Check which cells are visible when row is hovered
	useEffect(() => {
		if (hoveredRow === null) return;

		// Initialize new visibility state
		const newVisibleCells: Record<number, boolean[]> = {};

		// Check visibility of all cells in the hovered row
		const observer = new IntersectionObserver(
			entries => {
				entries.forEach(entry => {
					const dataset = (entry.target as HTMLElement).dataset;
					const rowIndex = dataset.rowIndex ? parseInt(dataset.rowIndex) : -1;
					const cellIndex = dataset.cellIndex ? parseInt(dataset.cellIndex) : -1;

					if (rowIndex >= 0 && cellIndex >= 0) {
						if (!newVisibleCells[rowIndex]) {
							newVisibleCells[rowIndex] = Array(headers.length).fill(false);
						}
						newVisibleCells[rowIndex][cellIndex] = entry.isIntersecting;
						setVisibleCells({ ...newVisibleCells });
					}
				});
			},
			{
				root: null, // Use the viewport
				threshold: 0.5, // At least 50% of the cell must be visible
			},
		);

		// Observe all cells in the hovered row
		const rowCells = cellRefs.current[hoveredRow];
		if (rowCells) {
			rowCells.forEach(cell => {
				if (cell) observer.observe(cell);
			});
		}

		return () => {
			if (rowCells) {
				rowCells.forEach(cell => {
					if (cell) observer.unobserve(cell);
				});
			}
		};
	}, [hoveredRow, headers.length]);

	const getCellContent = (lead: TLeadWithCustomFieldValues, key: string) => {
		if (key === "fullName") {
			return [lead.firstname, lead.lastname].filter(Boolean).join(" ");
		}
		if (key === "created_at") {
			return new Date(lead[key]).toLocaleDateString();
		}
		if (key.startsWith("custom.")) {
			const simpleFieldId = key.replace("custom.", "");

			const result = lead.lead_custom_field_values.find(v => v.custom_field_id === simpleFieldId);
			if (!result) {
				return undefined;
			}
			return result.text_value ?? result.boolean_value ?? result.number_value;
		}
		return lead[key as keyof TLeadWithCustomFieldValues];
	};

	// Find the rightmost visible cell in a row
	const findRightmostVisibleCellIndex = (rowIndex: number): number => {
		if (!visibleCells[rowIndex]) return -1;

		// Start from the right and find the first visible cell
		for (let i = headers.length - 1; i >= 0; i--) {
			if (visibleCells[rowIndex][i]) {
				return i;
			}
		}
		return -1; // No visible cells
	};

	return (
		<>
			{displayedLeads.map((lead: TLeadWithCustomFieldValues, rowIndex: number) => (
				<TableRow
					key={rowIndex}
					onClick={() => handleRowClick(lead)}
					onMouseEnter={() => setHoveredRow(rowIndex)}
					onMouseLeave={() => setHoveredRow(null)}
					className={cn(
						"relative cursor-pointer",
						hoveredRow === rowIndex ? "border-label-link/20 bg-background-bg-base-hover" : "",
					)}>
					{headers.map((header, cellIndex) => {
						const isRightmostVisible = hoveredRow === rowIndex && findRightmostVisibleCellIndex(rowIndex) === cellIndex;

						return (
							<TableCell
								key={header.key}
								ref={el => {
									if (cellRefs.current[rowIndex]) {
										cellRefs.current[rowIndex][cellIndex] = el;
									}
								}}
								data-row-index={rowIndex}
								data-cell-index={cellIndex}
								className={cn("max-w-[200px] whitespace-nowrap", isRightmostVisible ? "relative" : "")}
								noBorder={cellIndex === headers.length - 1}>
								<div className="overflow-hidden truncate text-ellipsis">
									{`${getCellContent(lead, header.key) ?? ""}`}
								</div>

								{isRightmostVisible && (
									<TableCellToolbar show={true} onClick={e => e.stopPropagation()}>
										<button
											className="hover:bg-background-bg-base-hover flex h-6 w-6 cursor-pointer items-center justify-center rounded-[5px] px-1 hover:shadow-[0px_1px_1px_0px_rgba(0,0,0,0.06),0px_4px_4px_-1px_rgba(0,0,0,0.02)]"
											onClick={e => {
												e.stopPropagation();
												handleRowClick(lead);
											}}>
											<PencilSVG className="text-label-muted flex-shrink-0" />
										</button>
										<button
											onClick={e => {
												e.stopPropagation();
												deleteLead(lead.email, lead.campaign_id);
											}}
											className="hover:bg-background-bg-base-hover flex h-6 w-6 cursor-pointer items-center justify-center rounded-[5px] px-1 hover:shadow-[0px_1px_1px_0px_rgba(0,0,0,0.06),0px_4px_4px_-1px_rgba(0,0,0,0.02)]">
											<TrashSVG className="text-label-muted flex-shrink-0" />
										</button>
									</TableCellToolbar>
								)}
							</TableCell>
						);
					})}
				</TableRow>
			))}
		</>
	);
}
