import * as React from "react";

const ArchiveBoxIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
		<path
			d="M1.99998 2C1.63179 2 1.33331 2.29848 1.33331 2.66667V4C1.33331 4.36819 1.63179 4.66667 1.99998 4.66667H14C14.3682 4.66667 14.6666 4.36819 14.6666 4V2.66667C14.6666 2.29848 14.3682 2 14 2H1.99998Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14 6H1.99998V10.1609C1.99997 10.6975 1.99997 11.1404 2.02944 11.5012C2.06006 11.8759 2.12577 12.2204 2.29063 12.544C2.54629 13.0457 2.95424 13.4537 3.45601 13.7093C3.77956 13.8742 4.12403 13.9399 4.49876 13.9705C4.85957 14 5.30247 14 5.83913 14H10.1608C10.6975 14 11.1404 14 11.5012 13.9705C11.8759 13.9399 12.2204 13.8742 12.544 13.7093C13.0457 13.4537 13.4536 13.0457 13.7093 12.544C13.8742 12.2204 13.9399 11.8759 13.9705 11.5012C14 11.1404 14 10.6975 14 10.1609V6ZM5.99998 8C5.99998 7.6318 6.29846 7.33333 6.66665 7.33333H9.33331C9.70151 7.33333 9.99998 7.6318 9.99998 8C9.99998 8.3682 9.70151 8.66667 9.33331 8.66667H6.66665C6.29846 8.66667 5.99998 8.3682 5.99998 8Z"
			fill="currentColor"
		/>
	</svg>
);

export default ArchiveBoxIcon;
