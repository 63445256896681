import { CoachFeedback, EmailCoachRule } from "../types";
import { getPlainTextFromHtml, extractSentences } from "../utils";

// List of common qualifier words that weaken writing
const QUALIFIERS = [
	"very",
	"really",
	"quite",
	"rather",
	"somewhat",
	"basically",
	"actually",
	"virtually",
	"fairly",
	"pretty",
	"just",
	"sort of",
	"kind of",
	"seems to",
	"appears to",
	"could be",
	"maybe",
	"perhaps",
	"in my opinion",
	"I think",
	"I believe",
	"I feel",
	"I suppose",
	"generally",
	"usually",
	"for the most part",
	"arguably",
	"relatively",
	// Additional qualifiers from Hemingway copycat
	"i consider",
	"i don't believe",
	"i don't consider",
	"i don't feel",
	"i don't suggest",
	"i don't think",
	"i hope to",
	"i might",
	"i suggest",
	"i was wondering",
	"i will try",
	"i wonder",
	"is kind of",
	"is sort of",
	"possibly",
	"we believe",
	"we consider",
	"we don't believe",
	"we don't consider",
	"we don't feel",
	"we don't suggest",
	"we don't think",
	"we feel",
	"we hope to",
	"we might",
	"we suggest",
	"we think",
	"we were wondering",
	"we will try",
	"we wonder",
];

// Analyze text for qualifier words that weaken writing
const analyzeQualifiers = (content: string): CoachFeedback[] => {
	// Extract plain text
	const plainText = getPlainTextFromHtml(content);

	// Get sentences using the existing extraction utility
	const sentenceResult = extractSentences(plainText);
	const sentences = sentenceResult.sentences;

	// Track feedback for each qualifier found
	const feedback: CoachFeedback[] = [];

	// Check each sentence for qualifiers
	sentences.forEach(sentence => {
		// Convert to lowercase for case-insensitive matching
		const lowerSentence = sentence.toLowerCase();

		// Check for each qualifier
		QUALIFIERS.forEach(qualifier => {
			// Use word boundary check for whole word matching
			const regex = new RegExp(`\\b${qualifier}\\b`, "gi");

			// Find all matches in the sentence
			const matches = [...lowerSentence.matchAll(regex)];

			// Add feedback for each match
			matches.forEach(match => {
				feedback.push({
					id: `qualifier-${match[0]}`,
					message: `Consider removing "${match[0]}" - qualifiers often weaken your point.`,
					severity: "info",
					description: sentence,
				});
			});
		});
	});

	return feedback;
};

// Export the rule
export const qualifiersRule: EmailCoachRule = {
	id: "qualifiers",
	name: "Qualifier Words",
	description: "Identifies qualifier words that may weaken your writing",
	analyze: (content: string) => analyzeQualifiers(content),
};
