import { cn } from "@/lib/utils";
import { HTMLAttributes } from "react";

export const VisuallyHidden = ({ className, ...props }: HTMLAttributes<HTMLSpanElement>) => {
	return (
		<span
			className={cn(
				"absolute -m-px h-px w-px overflow-hidden whitespace-nowrap border-0 p-0",
				"[clip:rect(0,0,0,0)]",
				className,
			)}
			{...props}
		/>
	);
};
