import { Operator, OperatorDetailsMap, OperatorLookupByLiquid } from "./constants";
import { Variable } from "./types";

type Continuation = {
	variant: "and" | "or";
	operation: LiquidOperationDefinitionTempStates;
};

export type LiquidOperationDefinitionFull = {
	variable: Variable;
	operator: Operator;
	rightOperand: string | boolean | number | null | Variable;
	continuation?: Continuation;
};

export type LiquidOperationDefinitionEmpty = Partial<Omit<LiquidOperationDefinitionFull, "variable">> & {
	variable: Variable | null;
};

export type LiquidOperationDefinitionWithVariable = Pick<LiquidOperationDefinitionFull, "variable" | "continuation"> &
	Partial<Omit<LiquidOperationDefinitionFull, "variable" | "continuation">>;
export type LiquidOperationDefinitionWithVariableAndOperator = Pick<
	LiquidOperationDefinitionFull,
	"variable" | "operator" | "continuation"
> &
	Partial<Pick<LiquidOperationDefinitionFull, "rightOperand">>;

export type LiquidOperationDefinitionTempStates =
	| LiquidOperationDefinitionEmpty
	| Partial<LiquidOperationDefinitionFull>;

export type LiquidOperationDefinition = LiquidOperationDefinitionEmpty | LiquidOperationDefinitionFull;

type LiquidOperationDefinitionAllVariations = LiquidOperationDefinition | LiquidOperationDefinitionTempStates;

export function isLiquidOperationDefinitionFull(
	operation: LiquidOperationDefinitionAllVariations,
): operation is LiquidOperationDefinitionFull {
	return operation.variable !== null && operation.operator !== undefined && operation.rightOperand !== undefined;
}

export function liquidConditionalBlockParser(
	liquidConditionAttribute: string,
	variables: Record<string, Variable>,
): LiquidOperationDefinition {
	const strippedLiquidConditionAttribute = liquidConditionAttribute
		.replace("{% if", "")
		.replace("{% elsif", "")
		.replace("{% else", "")
		.replace("%}", "")
		.trim();
	if (strippedLiquidConditionAttribute.length === 0 || strippedLiquidConditionAttribute === "false") {
		return {
			variable: null,
		};
	}
	// Can assume that if there are continuations, each operation will have three parts:
	// 1) variableId
	// 2) operator
	// 3) rightOperand
	// The next item will be the continuation if it exists. So if we split by spaces, we can get the pieces, and then
	// break them up into the necessary chunks.
	const pieces = strippedLiquidConditionAttribute.split(" ");
	// We need to break up the pieces into the necessary chunks. Start by grabbing the first chunk.
	const [firstVariableId, firstOperator, firstRightOperand] = pieces;
	const operation = getOperationDetailsFromChunks([firstVariableId, firstOperator, firstRightOperand], variables);
	let [, , , ...continuation] = pieces;
	// Pointer to the operation that is being continued
	let continuingOperation = operation;
	while (continuation.length > 0) {
		if (continuingOperation.variable === null) {
			// Something went wrong, so it's probably safe to ditch the continuation and allow the user to fix it
			break;
		}
		const [continuationOperator, nextVariableId, nextOperator, nextRightOperand] = continuation;
		if (continuationOperator !== "and" && continuationOperator !== "or") {
			// Something went wrong, so it's probably safe to ditch the continuation and allow the user to fix it
			break;
		}
		// Get the details of the next operation
		const nextOperation = getOperationDetailsFromChunks([nextVariableId, nextOperator, nextRightOperand], variables);
		if (nextOperation.variable === null) {
			// Something went wrong, so it's probably safe to ditch the continuation and allow the user to fix it
			break;
		}
		// Update the continuing operation with the new operation
		continuingOperation.continuation = {
			variant: continuationOperator,
			operation: nextOperation,
		};
		// Now switch which operation we're continuing to the one we just got
		continuingOperation = nextOperation;
		// Remove the first four items from the continuation array so we can continue parsing if there's more
		continuation = continuation.slice(4);
	}
	return operation;
}

function getOperationDetailsFromChunks(
	[variableId, liquidOperator, liquidRightOperand]: [string, string, string] | [string, string] | [string] | [],
	variables: Record<string, Variable>,
): LiquidOperationDefinition {
	if (!variableId) {
		return {
			variable: null,
		};
	}
	const variable = variables[variableId];
	if (!liquidOperator || liquidRightOperand === undefined) {
		// Default to checking the variable exists if the operator or right operand aren't present
		return {
			variable,
			operator: Operator.NE,
			rightOperand: null,
		};
	}
	const operator = OperatorLookupByLiquid[liquidOperator];
	let rightOperand: string | boolean | number | null | Variable = null;
	if (liquidRightOperand === "true" || liquidRightOperand === "false") {
		// Boolean values
		rightOperand = rightOperand === "true";
	} else if (liquidRightOperand === "nil") {
		// Nil values
		rightOperand = null;
	} else if (
		(liquidRightOperand.startsWith(`"`) && liquidRightOperand.endsWith(`"`)) ||
		(liquidRightOperand.startsWith(`'`) && liquidRightOperand.endsWith(`'`))
	) {
		// String values
		rightOperand = liquidRightOperand.slice(1, -1);
	} else if (!isNaN(Number(liquidRightOperand))) {
		// Number values
		rightOperand = Number(liquidRightOperand);
	} else {
		// Is likely a variable
		rightOperand = variables[liquidRightOperand] ?? null;
	}

	return {
		variable,
		operator,
		rightOperand,
	};
}

function getLiquidOperand(operand: string | boolean | number | null | Variable): string {
	if (operand === null) {
		return "nil";
	}
	if (typeof operand === "object") {
		// Variable
		return operand.id;
	}
	if (typeof operand === "boolean") {
		// Hardcode responses just in case
		return operand ? "true" : "false";
	}
	if (typeof operand === "string") {
		// Must surround with quotes
		return `"${operand}"`;
	}
	// Only remaining option is a number
	return operand.toString();
}

export function liquidConditionalBlockParserToAttribute(operation: LiquidOperationDefinitionFull): string[] {
	const pieces: string[] = [
		operation.variable.id,
		OperatorDetailsMap[operation.operator].liquid,
		getLiquidOperand(operation.rightOperand),
	];
	if (operation.continuation) {
		if (operation.continuation.operation) {
			if (isLiquidOperationDefinitionFull(operation.continuation.operation)) {
				// Can add continuation. Otherwise we'd be adding incomplete operations, which we don't want to persist.
				pieces.push(operation.continuation.variant);
				pieces.push(...liquidConditionalBlockParserToAttribute(operation.continuation.operation));
			}
		}
	}
	return pieces;
}
