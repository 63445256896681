import * as React from "react";
import { createContext, useContext } from "react";

import { cn } from "@/lib/utils";

// Create a context to pass the noBorders prop down to all table components
type TableContextType = {
	noBorders?: boolean;
};

const TableContext = createContext<TableContextType>({ noBorders: false });

// Custom hook to monitor scroll position and container dimensions
const useScrollGradients = (ref: React.RefObject<HTMLElement>) => {
	const [showLeftGradient, setShowLeftGradient] = React.useState(false);
	const [showRightGradient, setShowRightGradient] = React.useState(false);
	const [showTopGradient, setShowTopGradient] = React.useState(false);
	const [showBottomGradient, setShowBottomGradient] = React.useState(false);

	const checkScroll = React.useCallback(() => {
		if (ref.current) {
			const { scrollLeft, scrollTop, scrollWidth, scrollHeight, clientWidth, clientHeight } = ref.current;
			// Add a small buffer (1px) to account for rounding errors
			const maxHorizontalScroll = scrollWidth - clientWidth;
			const maxVerticalScroll = scrollHeight - clientHeight;

			// Horizontal scrolling
			setShowLeftGradient(scrollLeft > 0);
			setShowRightGradient(scrollLeft < maxHorizontalScroll - 1 && maxHorizontalScroll > 1);

			// Vertical scrolling
			setShowTopGradient(scrollTop > 0);
			setShowBottomGradient(scrollTop < maxVerticalScroll - 1 && maxVerticalScroll > 1);
		}
	}, [ref]);

	React.useEffect(() => {
		const container = ref.current;
		if (container) {
			const resizeObserver = new ResizeObserver(() => {
				requestAnimationFrame(checkScroll);
			});

			checkScroll();
			container.addEventListener("scroll", checkScroll, { passive: true });
			resizeObserver.observe(container);

			return () => {
				container.removeEventListener("scroll", checkScroll);
				resizeObserver.disconnect();
			};
		}
	}, [ref, checkScroll]);

	return { showLeftGradient, showRightGradient, showTopGradient, showBottomGradient };
};

const Table = React.forwardRef<
	HTMLTableElement,
	React.HTMLAttributes<HTMLTableElement> & { noBorders?: boolean; noScroll?: boolean }
>(({ className, noBorders = false, noScroll = false, ...props }, ref) => {
	const tableRef = React.useRef<HTMLDivElement>(null);
	const { showLeftGradient, showRightGradient, showTopGradient, showBottomGradient } = useScrollGradients(tableRef);

	return (
		<TableContext.Provider value={{ noBorders }}>
			<div className="relative w-full">
				<div
					ref={tableRef}
					className={cn("w-full", noScroll ? "overflow-visible" : "overflow-auto")}
					style={{ willChange: "scroll-position" }}>
					{/* Left gradient with smooth transition */}
					<div
						className={cn(
							"from-background-bg-base via-background-bg-base/50 pointer-events-none absolute left-0 top-0 z-10 h-full w-16 bg-gradient-to-r to-transparent transition-opacity duration-150 ease-out",
							showLeftGradient ? "opacity-100" : "opacity-0",
						)}
						aria-hidden="true"
					/>
					{/* Right gradient with smooth transition */}
					<div
						className={cn(
							"from-background-bg-base via-background-bg-base/50 pointer-events-none absolute right-0 top-0 z-10 h-full w-16 bg-gradient-to-l to-transparent transition-opacity duration-150 ease-out motion-reduce:transition-none",
							showRightGradient ? "opacity-100" : "opacity-0",
						)}
						aria-hidden="true"
					/>
					{/* Top gradient with smooth transition */}
					<div
						className={cn(
							"from-background-bg-base via-background-bg-base/50 pointer-events-none absolute left-0 top-0 z-10 h-16 w-full bg-gradient-to-b to-transparent transition-opacity duration-150 ease-out",
							showTopGradient ? "opacity-100" : "opacity-0",
						)}
						aria-hidden="true"
					/>
					{/* Bottom gradient with smooth transition */}
					<div
						className={cn(
							"from-background-bg-base via-background-bg-base/50 pointer-events-none absolute bottom-0 left-0 z-10 h-16 w-full bg-gradient-to-t to-transparent transition-opacity duration-150 ease-out motion-reduce:transition-none",
							showBottomGradient ? "opacity-100" : "opacity-0",
						)}
						aria-hidden="true"
					/>
					<table
						ref={ref}
						className={cn("w-full caption-bottom border-collapse text-sm", className)}
						cellSpacing="0"
						cellPadding="0"
						{...props}
					/>
				</div>
			</div>
		</TableContext.Provider>
	);
});
Table.displayName = "Table";

const TableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
	({ className, ...props }, ref) => <thead ref={ref} className={cn(className)} {...props} />,
);
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
	({ className, ...props }, ref) => <tbody ref={ref} className={cn(className)} {...props} />,
);
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
	({ className, ...props }, ref) => (
		<tfoot ref={ref} className={cn("bg-muted/50 border-t font-medium last:[&>tr]:border-b-0", className)} {...props} />
	),
);
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<
	HTMLTableRowElement,
	React.HTMLAttributes<HTMLTableRowElement> & { noBorder?: boolean }
>(({ className, noBorder = false, ...props }, ref) => {
	const { noBorders } = useContext(TableContext);
	const shouldRemoveBorder = noBorder || noBorders;

	return (
		<tr
			ref={ref}
			className={cn(
				"data-[state=selected]:bg-muted m-0 p-0 transition-colors hover:border-[color(display-p3_0.098_0.4863_0.9569/0.20)] hover:bg-[color(display-p3_0.9373_0.9647_0.9961)]",
				className,
			)}
			style={
				shouldRemoveBorder
					? { padding: 0, margin: 0 }
					: { borderBottom: "0.5px solid var(--background-bg-divider, #E3E3E3)", padding: 0, margin: 0 }
			}
			{...props}
		/>
	);
});
TableRow.displayName = "TableRow";

const TableHeaderRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
	({ className, ...props }, ref) => (
		<tr
			ref={ref}
			className={cn("bg-background-bg-base-dim transition-colors", className)}
			style={{
				borderBottom: "1px solid var(--background-bg-border, #E3E3E3)",
				borderTop: "1px solid var(--background-bg-border, #E3E3E3)",
			}}
			{...props}
		/>
	),
);
TableHeaderRow.displayName = "TableHeaderRow";

interface TableHeadProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
	icon?: React.ReactNode;
	label?: React.ReactNode;
	iconPosition?: "left" | "right";
	align?: "left" | "center" | "right";
	noBorder?: boolean;
	noSidePadding?: boolean;
}

const TableHead = React.forwardRef<HTMLTableCellElement, TableHeadProps>(
	(
		{
			className,
			icon,
			label,
			children,
			iconPosition = "left",
			align = "left",
			noBorder = false,
			noSidePadding = false,
			...props
		},
		ref,
	) => {
		const { noBorders } = useContext(TableContext);
		const shouldRemoveBorder = noBorder || noBorders;

		return (
			<th
				ref={ref}
				className={cn(
					"text-label-label-title body-mini-plus m-0 !p-0 text-left align-middle [&:has([role=checkbox])]:pr-0",
					{
						"text-center": align === "center",
						"text-right": align === "right",
						"border-r-[0.5px] border-r-[#E3E3E3]": !shouldRemoveBorder,
					},
					className,
				)}
				style={{ padding: 0, margin: 0 }}
				{...props}>
				{icon || label ? (
					<div
						className={cn(
							"m-0 flex h-10 items-center gap-[12px] py-1",
							!noSidePadding ? "px-0 pl-[16px] pr-[12px]" : "px-0",
						)}>
						{iconPosition === "left" && icon}
						{label && (
							<span
								className={cn("flex-1 whitespace-nowrap", {
									"text-center": align === "center",
									"text-right": align === "right",
								})}>
								{label}
							</span>
						)}
						{iconPosition === "right" && icon}
					</div>
				) : (
					children
				)}
			</th>
		);
	},
);
TableHead.displayName = "TableHead";

interface TableCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
	icon?: React.ReactNode;
	iconPosition?: "left" | "right";
	align?: "left" | "center" | "right";
	noBorder?: boolean;
	noSidePadding?: boolean;
}

const TableCell = React.forwardRef<HTMLTableCellElement, TableCellProps>(
	(
		{
			className,
			children,
			icon,
			iconPosition = "left",
			align = "left",
			noBorder = false,
			noSidePadding = false,
			...props
		},
		ref,
	) => {
		const { noBorders } = useContext(TableContext);
		const shouldRemoveBorder = noBorder || noBorders;

		return (
			<td
				ref={ref}
				className={cn(
					"m-0 !p-0 align-middle [&:has([role=checkbox])]:pr-0",
					{
						"text-center": align === "center",
						"text-right": align === "right",
						"border-r-[0.5px] border-r-[#E3E3E3]": !shouldRemoveBorder,
					},
					className,
				)}
				style={{ padding: 0, margin: 0 }}
				{...props}>
				{icon ? (
					<div
						className={cn(
							"m-0 flex h-10 items-center gap-[12px] py-1",
							!noSidePadding ? "px-0 pl-[16px] pr-[12px]" : "px-0",
						)}>
						{iconPosition === "left" && icon}
						<div className="flex-1">{children}</div>
						{iconPosition === "right" && icon}
					</div>
				) : (
					<div
						className={cn(
							"m-0 flex h-10 flex-1 items-center gap-1 py-1",
							!noSidePadding && "px-4",
							noSidePadding && "px-0",
							className?.includes("rightmost-cell") &&
								!noSidePadding &&
								"relative h-[40px] flex-[1_0_0] gap-[4px] p-[4px_16px]",
							className?.includes("rightmost-cell") &&
								noSidePadding &&
								"relative h-[40px] flex-[1_0_0] gap-[4px] py-[4px]",
						)}>
						{children}
					</div>
				)}
			</td>
		);
	},
);
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<HTMLTableCaptionElement, React.HTMLAttributes<HTMLTableCaptionElement>>(
	({ className, ...props }, ref) => (
		<caption ref={ref} className={cn("text-muted-foreground mt-4 text-sm", className)} {...props} />
	),
);
TableCaption.displayName = "TableCaption";

export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableHeaderRow, TableCell, TableCaption };
