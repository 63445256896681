import * as React from "react";
import { cn } from "@/lib/utils";
import { CloseSignSVG } from "@/assets/icons/svgs";

export interface CalloutProps extends React.HTMLAttributes<HTMLDivElement> {
	onClose?: () => void;
	showCloseButton?: boolean;
	primaryText?: string;
	secondaryText?: string;
}

const Callout = React.forwardRef<HTMLDivElement, CalloutProps>(
	({ className, children, onClose, showCloseButton = true, primaryText, secondaryText, ...props }, ref) => {
		return (
			<div
				className={cn(
					"relative inline-flex w-full flex-col items-start gap-3 rounded-[10px] bg-[rgba(19,124,249,0.12)] p-3",
					className,
				)}
				ref={ref}
				{...props}>
				{showCloseButton && (
					<button onClick={onClose} className="absolute right-4 top-3.5 cursor-pointer" aria-label="Close">
						<CloseSignSVG />
					</button>
				)}
				{primaryText && (
					<p className="text-body-base-plus leading-body-base-plus text-label-label-link w-full self-stretch text-left font-medium">
						{primaryText}
					</p>
				)}
				{secondaryText && (
					<p className="text-body-base leading-body-base text-label-label-link w-[462px] max-w-full text-left">
						{secondaryText}
					</p>
				)}
				{children}
			</div>
		);
	},
);

Callout.displayName = "Callout";

export { Callout };
