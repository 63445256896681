import { ProgressiveBlur } from "@/components/ui/progressive-blur";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Table, TableBody, TableHead, TableHeader, TableHeaderRow } from "@/components/ui/table";
import { useCampaignStore, useGlobalFilterStore } from "@/store";
import useCampaignListPreferencesStore from "@/store/useCampaignListPreferencesStore";
import { CampaignProps } from "@/types/campaign";
import { filterData } from "@/utils/filterData";
import { useEffect, useRef, useState, useCallback } from "react";
import { useLocation } from "wouter";
import openedIcon from "../../assets/images/opened-icon.png";
import repliedIcon from "../../assets/images/replied-icon.png";
import FilterModalContent from "../FilterModalContent/FilterModalContent";
import { Button } from "../ui/button";
import { EmptyCampaigns } from "./EmptyCampaigns";
import { toast } from "sonner";
import CampaignRow from "./CampaignRow";
import { TABLE_HEADERS, TableHeaderConfig } from "./constants";
import CampaignListSkeleton from "./CampaignListSkeleton";
import { NameCampaignDialog } from "./NameCampaignDialog";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu";
import ChevronBigDownSVG from "@/assets/icons/svgs/chevron-big-down.svg?react";
import useFetchStore from "@/store/fetchStore";
import type { TClientRow } from "@za-zu/types";
import { SelectionToolbar } from "@/components/ui/selection-toolbar";
import ArchiveBoxIcon from "@/assets/icons/svgs/table-header/archive-box";
import PauseActionIcon from "@/assets/icons/svgs/table-header/pause-action";
import PlayActionIcon from "@/assets/icons/svgs/table-header/play-action";
import UserIcon from "@/assets/icons/svgs/user.svg?react";
import ArchiveConfirmationModal from "./ArchiveConfirmationModal";

const CampaignTableHeader = ({ tableHeader }: { tableHeader: TableHeaderConfig[] }) => {
	// Using persistent store for header options across sessions
	const { sentOption, setSentOption, deliverabilityOption, setDeliverabilityOption } =
		useCampaignListPreferencesStore();

	return (
		<TableHeaderRow>
			{tableHeader.map((header, index) => (
				<TableHead
					key={header.label}
					className={`w-[${header.width}]`}
					noBorder={index === tableHeader.length - 1}
					icon={
						header.isImage && !header.hasDropdown ? (
							<img
								src={header.icon === "openedIcon" ? openedIcon : repliedIcon}
								alt={`${header.label} icon`}
								width={14}
								height={14}
							/>
						) : header.icon !== "openedIcon" &&
						  header.icon !== "repliedIcon" &&
						  header.icon !== "positiveRepliesIcon" &&
						  !header.hasDropdown ? (
							<header.icon className={`h-3.5 w-3.5 ${header.iconColor}`} />
						) : null
					}
					label={
						header.hasDropdown ? (
							<DropdownMenu>
								<DropdownMenuTrigger className="hover:bg-background-bg-base-hover data-[state=open]:bg-background-bg-base-hover -ml-2 inline-flex items-center gap-1 rounded-[5px] px-2 py-1 transition-colors focus:outline-none">
									{(() => {
										// Determine which option to use based on dropdown type
										let selectedValue: string;
										if (header.label === "Sent") {
											selectedValue = sentOption;
										} else if (header.label === "Bounce rate") {
											selectedValue = deliverabilityOption;
										} else {
											selectedValue = ""; // Fallback
										}

										const selectedOpt = header.dropdownOptions?.find(opt => opt.value === selectedValue);
										if (selectedOpt?.isImage || (header.isImage && !selectedOpt)) {
											return (
												<img
													src={header.icon === "openedIcon" ? openedIcon : repliedIcon}
													alt={`${header.label} icon`}
													width={14}
													height={14}
												/>
											);
										} else if (selectedOpt) {
											const IconComponent = selectedOpt.icon as React.FC<React.SVGProps<SVGSVGElement>>;
											return <IconComponent className={`h-3.5 w-3.5 ${selectedOpt.iconColor}`} />;
										} else {
											const IconComponent = header.icon as React.FC<React.SVGProps<SVGSVGElement>>;
											return <IconComponent className={`h-3.5 w-3.5 ${header.iconColor}`} />;
										}
									})()}
									<span className="whitespace-nowrap">
										{(() => {
											// Determine which option to display based on dropdown type
											let selectedValue: string;
											if (header.label === "Sent") {
												selectedValue = sentOption;
											} else if (header.label === "Bounce rate") {
												selectedValue = deliverabilityOption;
											} else {
												return header.label; // Fallback to header label
											}

											return header.dropdownOptions?.find(opt => opt.value === selectedValue)?.label || header.label;
										})()}
									</span>
									<ChevronBigDownSVG className="text-[#AAAAAA]" width={16} height={16} />
								</DropdownMenuTrigger>
								<DropdownMenuContent align="start">
									{header.dropdownOptions?.map(option => {
										const IconComponent = option.icon as React.FC<React.SVGProps<SVGSVGElement>>;
										return (
											<DropdownMenuItem
												key={option.value}
												onClick={() => {
													// Set the appropriate state based on dropdown type
													if (header.label === "Sent") {
														setSentOption(option.value as "sent" | "contacted");
													} else if (header.label === "Bounce rate") {
														setDeliverabilityOption(option.value as "deliverability" | "bounce_rate");
													}
												}}
												className="flex cursor-pointer items-center gap-2">
												{option.isImage ? (
													<img
														src={option.icon === "openedIcon" ? openedIcon : repliedIcon}
														alt={`${option.label} icon`}
														width={14}
														height={14}
													/>
												) : (
													<IconComponent className={`h-3.5 w-3.5 ${option.iconColor || header.iconColor}`} />
												)}
												<span className="whitespace-nowrap">{option.label}</span>
											</DropdownMenuItem>
										);
									})}
								</DropdownMenuContent>
							</DropdownMenu>
						) : (
							<span className="whitespace-nowrap">{header.label}</span>
						)
					}
				/>
			))}
		</TableHeaderRow>
	);
};

interface CampaignsListProps {
	renderHeader: (hasArchivedCampaigns: boolean, campaignsLength: number, onNewCampaign: () => void) => React.ReactNode;
	activeFilter: "open" | "archived";
	isFilterModalOpen: boolean;
	onFilterClose: () => void;
}

const CampaignsList = ({ renderHeader, activeFilter, isFilterModalOpen, onFilterClose }: CampaignsListProps) => {
	const [, navigate] = useLocation();
	const { campaigns, isLoading, error, createCampaign, pauseCampaign, unpauseCampaign, saveCampaign } =
		useCampaignStore();
	// Using persistent store for metric view preferences
	const { sentOption, deliverabilityOption } = useCampaignListPreferencesStore();
	const { searchText } = useGlobalFilterStore();
	const [selectedStatuses, setSelectedStatuses] = useState<
		("active" | "draft" | "completed" | "paused" | "archived")[] | null
	>([]);
	const [showNameDialog, setShowNameDialog] = useState(false);
	const [archiveModalState, setArchiveModalState] = useState<{
		isOpen: boolean;
		campaign: CampaignProps | null;
		selectedIds: string[];
	}>({
		isOpen: false,
		campaign: null,
		selectedIds: [],
	});
	const [clients, setClients] = useState<TClientRow[]>([]);
	const { apiClient } = useFetchStore();
	// Add state to track selected campaigns
	const [selectedCampaignIds, setSelectedCampaignIds] = useState<string[]>([]);
	// Track the last selected campaign for shift-click functionality
	const [lastSelectedCampaignId, setLastSelectedCampaignId] = useState<string | null>(null);

	useEffect(() => {
		const fetchClients = async () => {
			if (!apiClient) return;
			try {
				const fetchedClients = await apiClient.getClients();
				setClients(fetchedClients);
			} catch (error) {
				console.error("Error fetching clients:", error);
			}
		};

		fetchClients();
	}, [apiClient]);

	const handleNewCampaignClick = useCallback(() => {
		setShowNameDialog(true);
	}, []);

	const handleArchive = (campaign: CampaignProps) => {
		setArchiveModalState({
			isOpen: true,
			campaign,
			selectedIds: [],
		});
	};

	const handleBulkArchive = (ids: string[]) => {
		setArchiveModalState({
			isOpen: true,
			campaign: null,
			selectedIds: ids,
		});
	};

	const handleConfirmArchive = async () => {
		// Handle single campaign archive
		if (archiveModalState.campaign) {
			try {
				const updatedCampaign = {
					...archiveModalState.campaign,
					status: "archived" as const,
				};
				await saveCampaign(updatedCampaign, () => {
					toast.success("Campaign archived successfully", {
						duration: 2000,
					});
				});
			} catch (error) {
				console.error("Error archiving campaign:", error);
				toast.error("Failed to archive campaign. Please try again.");
			}
		}
		// Handle bulk campaign archive
		else if (archiveModalState.selectedIds.length > 0) {
			try {
				await Promise.all(
					archiveModalState.selectedIds.map(id => {
						const campaign = filteredCampaigns.find(c => c.id === id);
						if (campaign) {
							const updatedCampaign = {
								...campaign,
								status: "archived" as const,
							};
							return saveCampaign(updatedCampaign);
						}
						return Promise.resolve();
					}),
				);

				toast.success(`Archived ${archiveModalState.selectedIds.length} campaigns`);
				setSelectedCampaignIds([]);
				setLastSelectedCampaignId(null);
			} catch (error) {
				console.error("Failed to archive campaigns:", error);
				toast.error("Failed to archive some campaigns");
			}
		}

		// Reset modal state
		setArchiveModalState({ isOpen: false, campaign: null, selectedIds: [] });
	};

	const handleClientSelect = async (campaignId: string, clientId: string | null) => {
		try {
			const campaign = campaigns.find(c => c.id === campaignId);
			if (!campaign) {
				throw new Error("Campaign not found");
			}

			const updatedCampaign = {
				...campaign,
				client_id: clientId || "",
			};

			await saveCampaign(updatedCampaign);
			return Promise.resolve();
		} catch (error) {
			console.error("Failed to update client:", error);
			return Promise.reject(error);
		}
	};

	// Calculate filtered campaigns directly during rendering instead of using useEffect
	const filteredCampaigns = (() => {
		// Check if there are any archived campaigns
		const hasArchivedCampaigns = campaigns.some(campaign => campaign.status.toLowerCase() === "archived");

		// If there are no archived campaigns, force the filter to be "open"
		const effectiveFilter = hasArchivedCampaigns ? activeFilter : "open";

		// Filter based on active filter (open or archived)
		let filteredResults = [...campaigns];

		if (effectiveFilter === "open") {
			filteredResults = filteredResults.filter(campaign => campaign.status.toLowerCase() !== "archived");
		} else {
			filteredResults = filteredResults.filter(campaign => campaign.status.toLowerCase() === "archived");
		}

		// Apply additional filtering based on search text and selected statuses
		return filterData(filteredResults, searchText, (campaign, searchText) => {
			const title = campaign.title?.toLowerCase() || "";
			const status = campaign.status?.toLowerCase() || "";
			const client = clients.find(c => c.id === campaign.client_id);
			const clientName = client?.name?.toLowerCase() || "";

			const searchTerms = searchText.toLowerCase();
			const matchesSearch =
				title.includes(searchTerms) || status.includes(searchTerms) || clientName.includes(searchTerms);

			const matchesStatus =
				!selectedStatuses?.length ||
				selectedStatuses.includes(campaign.status as "active" | "draft" | "completed" | "paused" | "archived");

			return matchesSearch && matchesStatus;
		});
	})();

	const debounceTextSearch = useRef<NodeJS.Timeout | null>(null);

	// New useEffect for debounced search tracking
	useEffect(() => {
		if (debounceTextSearch.current) {
			clearTimeout(debounceTextSearch.current);
		}

		debounceTextSearch.current = setTimeout(() => {
			if (searchText.trim().length > 1) {
				// Analytics tracking removed
			}
		}, 2000);

		return () => {
			if (debounceTextSearch.current) {
				clearTimeout(debounceTextSearch.current);
			}
		};
	}, [searchText]);

	// Add keyboard shortcut handler
	useEffect(() => {
		const handleKeyPress = (e: KeyboardEvent) => {
			// Only trigger if not typing in an input/textarea
			if (e.key.toLowerCase() === "n" && !["INPUT", "TEXTAREA"].includes((e.target as HTMLElement).tagName)) {
				e.preventDefault(); // Prevent the 'n' from being typed
				e.stopPropagation(); // Stop event from bubbling up
				handleNewCampaignClick();
			}
		};

		document.addEventListener("keydown", handleKeyPress);
		return () => document.removeEventListener("keydown", handleKeyPress);
	}, []);

	// Add keyboard shortcut for select all (Cmd+A / Ctrl+A)
	useEffect(() => {
		const handleSelectAll = (e: KeyboardEvent) => {
			// Check if it's Cmd+A (Mac) or Ctrl+A (Windows/Linux) and not inside an input field
			if (
				(e.metaKey || e.ctrlKey) &&
				e.key === "a" &&
				!["INPUT", "TEXTAREA"].includes((e.target as HTMLElement).tagName)
			) {
				e.preventDefault(); // Prevent the default "select all" behavior

				// If we already have all filtered campaigns selected, deselect all
				if (
					selectedCampaignIds.length === filteredCampaigns.length &&
					filteredCampaigns.every(campaign => selectedCampaignIds.includes(campaign.id))
				) {
					setSelectedCampaignIds([]);
				} else {
					// Otherwise select all filtered campaigns
					setSelectedCampaignIds(filteredCampaigns.map(campaign => campaign.id));
				}
			}
		};

		document.addEventListener("keydown", handleSelectAll);
		return () => document.removeEventListener("keydown", handleSelectAll);
	}, [filteredCampaigns, selectedCampaignIds]);

	const handleCreateCampaign = async (name: string, clientId: string | null) => {
		try {
			const campaignId = await createCampaign({
				name,
				client_id: clientId,
			});
			navigate(`/dashboard/campaigns/${campaignId}/leads/import`);
			setShowNameDialog(false);
		} catch (error) {
			console.error("Error creating campaign:", error);
			toast.error("Failed to create campaign");
		}
	};

	// Update handler for campaign selection to support shift-click
	const handleCampaignSelection = (id: string, selected: boolean, shiftKey: boolean) => {
		// Handle single selection without shift key
		if (!shiftKey) {
			setSelectedCampaignIds(prev => {
				if (selected) {
					return [...prev, id];
				} else {
					return prev.filter(campaignId => campaignId !== id);
				}
			});
			setLastSelectedCampaignId(id);
			return;
		}

		// Handle range selection with shift key
		if (shiftKey && lastSelectedCampaignId) {
			// Find the indexes of the last selected and current rows
			const currentIndex = filteredCampaigns.findIndex(campaign => campaign.id === id);
			const lastIndex = filteredCampaigns.findIndex(campaign => campaign.id === lastSelectedCampaignId);

			if (currentIndex === -1 || lastIndex === -1) return;

			// Determine the range to select (inclusive of both ends)
			const startIndex = Math.min(currentIndex, lastIndex);
			const endIndex = Math.max(currentIndex, lastIndex);

			// Get all campaign IDs in the range
			const campaignsInRange = filteredCampaigns.slice(startIndex, endIndex + 1).map(campaign => campaign.id);

			setSelectedCampaignIds(prev => {
				// If the clicked item is selected, select all in range
				if (selected) {
					// Create a new set to avoid duplicates
					const uniqueIds = new Set([...prev, ...campaignsInRange]);
					return Array.from(uniqueIds);
				} else {
					// If the clicked item is being deselected, deselect all in range
					return prev.filter(id => !campaignsInRange.includes(id));
				}
			});

			// Update the last selected ID
			setLastSelectedCampaignId(id);
		}
	};

	if (isLoading) {
		return (
			<div className="campaigns-list-wrapper">
				{renderHeader(false, 0, handleNewCampaignClick)}
				<CampaignListSkeleton />
			</div>
		);
	}

	if (error) {
		console.error("Error loading campaigns:", error);

		// Handle the Organization not found error more gracefully
		if (error.message === "Organization not found") {
			return (
				<div className="flex h-full w-full flex-col items-center justify-center p-8 text-center">
					<h3 className="text-body-head-plus text-label-title mb-4 font-medium">Initializing your account</h3>
					<p className="text-body-base text-label-muted mb-6 max-w-md">
						Your organization is still being set up. This usually takes just a few seconds.
					</p>
					<p className="body-mini-plus text-label-faint">We&apos;ll automatically retry...</p>
				</div>
			);
		}

		return <div>Please refresh the page. Error loading campaigns: {error.message}</div>;
	}

	// Check if there are any archived campaigns for the header
	const hasArchivedCampaigns = campaigns.some(campaign => campaign.status.toLowerCase() === "archived");
	const hasNonArchivedCampaigns = campaigns.some(campaign => campaign.status.toLowerCase() !== "archived");

	return (
		<div className="campaigns-list-wrapper">
			{renderHeader(hasArchivedCampaigns && hasNonArchivedCampaigns, campaigns.length, handleNewCampaignClick)}
			<div className="bg-background-bg-border h-[0.5px] w-full" />
			<ArchiveConfirmationModal
				isOpen={archiveModalState.isOpen}
				onClose={() => setArchiveModalState({ isOpen: false, campaign: null, selectedIds: [] })}
				onConfirm={handleConfirmArchive}
				campaignName={archiveModalState.campaign?.title ?? ""}
				selectedCount={archiveModalState.selectedIds.length}
			/>
			<NameCampaignDialog isOpen={showNameDialog} onOpenChange={setShowNameDialog} onSubmit={handleCreateCampaign} />
			{!hasNonArchivedCampaigns ? (
				<EmptyCampaigns onCreateCampaign={handleNewCampaignClick} />
			) : (
				<div className="relative">
					<ScrollArea className="h-[calc(100vh-100px)]">
						<div className="campaigns-list-table w-full overflow-hidden">
							<Table>
								<TableHeader>
									<CampaignTableHeader tableHeader={TABLE_HEADERS} />
								</TableHeader>
								<TableBody>
									{filteredCampaigns.map(campaign => (
										<CampaignRow
											key={campaign.id}
											campaign={campaign}
											onNavigate={id => navigate(`/dashboard/campaigns/${id}`)}
											onArchive={handleArchive}
											onPause={pauseCampaign}
											onUnpause={unpauseCampaign}
											onClientSelect={handleClientSelect}
											sentOption={sentOption}
											deliverabilityOption={deliverabilityOption}
											clients={clients}
											selected={selectedCampaignIds.includes(campaign.id)}
											onSelect={handleCampaignSelection}
										/>
									))}
								</TableBody>
							</Table>
							<div style={{ borderBottom: "0.5px solid var(--background-bg-divider, #E3E3E3)" }} />
						</div>
					</ScrollArea>
					<ProgressiveBlur
						orientation="vertical"
						className="pointer-events-none absolute bottom-0 h-16 w-full"
						style={{ background: "linear-gradient(to top, white, transparent)" }}
					/>
				</div>
			)}
			{isFilterModalOpen && (
				<FilterModalContent
					onClose={onFilterClose}
					selectedStatuses={selectedStatuses}
					setSelectedStatuses={setSelectedStatuses}
				/>
			)}
			{/* Selection Toolbar */}
			<SelectionToolbar
				selectedCount={selectedCampaignIds.length}
				entityName="campaigns"
				entityNameSingular="campaign"
				showToolbar={selectedCampaignIds.length > 0}>
				{selectedCampaignIds.length > 0 &&
					filteredCampaigns.some(
						campaign => selectedCampaignIds.includes(campaign.id) && campaign.status.toLowerCase() === "active",
					) && (
						<Button
							variant="ghost"
							size="sm"
							className="text-label-muted h-8 gap-2 rounded-md px-3 text-sm"
							onClick={() => {
								if (selectedCampaignIds.length > 0) {
									const selectedCampaigns = filteredCampaigns.filter(campaign =>
										selectedCampaignIds.includes(campaign.id),
									);
									const hasActiveCampaigns = selectedCampaigns.some(
										campaign => campaign.status.toLowerCase() === "active",
									);

									if (hasActiveCampaigns) {
										// Handle pausing selected campaigns
										Promise.all(
											selectedCampaigns
												.filter(campaign => campaign.status.toLowerCase() === "active")
												.map(campaign => pauseCampaign(campaign.id)),
										)
											.then(() => {
												toast.success(
													`Paused ${selectedCampaigns.filter(c => c.status.toLowerCase() === "active").length} campaigns`,
												);
											})
											.catch(error => {
												console.error("Failed to pause campaigns:", error);
												toast.error("Failed to pause some campaigns");
											});
									}
								}
							}}>
							<PauseActionIcon className="text-label-label-muted h-4 w-4" />
							<span>Pause</span>
						</Button>
					)}

				{selectedCampaignIds.length > 0 &&
					filteredCampaigns.some(
						campaign => selectedCampaignIds.includes(campaign.id) && campaign.status.toLowerCase() === "paused",
					) && (
						<Button
							variant="ghost"
							size="sm"
							className="text-label-muted h-8 gap-2 rounded-md px-3 text-sm"
							onClick={() => {
								if (selectedCampaignIds.length > 0) {
									const selectedCampaigns = filteredCampaigns.filter(campaign =>
										selectedCampaignIds.includes(campaign.id),
									);
									const hasPausedCampaigns = selectedCampaigns.some(
										campaign => campaign.status.toLowerCase() === "paused",
									);

									if (hasPausedCampaigns) {
										// Handle unpausing selected campaigns
										Promise.all(
											selectedCampaigns
												.filter(campaign => campaign.status.toLowerCase() === "paused")
												.map(campaign => unpauseCampaign(campaign.id)),
										)
											.then(() => {
												toast.success(
													`Resumed ${selectedCampaigns.filter(c => c.status.toLowerCase() === "paused").length} campaigns`,
												);
											})
											.catch(error => {
												console.error("Failed to resume campaigns:", error);
												toast.error("Failed to resume some campaigns");
											});
									}
								}
							}}>
							<PlayActionIcon className="text-label-label-muted h-4 w-4" />
							<span>Resume</span>
						</Button>
					)}

				{clients.length > 0 && (
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="ghost" size="sm" className="text-label-muted h-8 gap-2 rounded-md px-3 text-sm">
								<UserIcon className="text-label-label-muted h-4 w-4 shrink-0" />
								<span>Assign client</span>
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="start">
							<DropdownMenuItem
								onClick={() => {
									// First update the campaigns with empty client_id to trigger animations
									const updatedCampaigns = campaigns.map(campaign => {
										if (selectedCampaignIds.includes(campaign.id)) {
											return { ...campaign, client_id: "" };
										}
										return campaign;
									});

									// Update the store with modified campaigns
									useCampaignStore.setState({ campaigns: updatedCampaigns });

									Promise.all(selectedCampaignIds.map(id => handleClientSelect(id, null)))
										.then(() => {
											toast.success(`Removed client from ${selectedCampaignIds.length} campaigns`);
										})
										.catch(error => {
											console.error("Failed to remove clients:", error);
											toast.error("Failed to remove clients from some campaigns");
										});
								}}>
								<span className="text-label-muted">None</span>
							</DropdownMenuItem>
							{clients.map(client => (
								<DropdownMenuItem
									key={client.id}
									onClick={() => {
										// First update the campaigns with new client_id to trigger animations
										const updatedCampaigns = campaigns.map(campaign => {
											if (selectedCampaignIds.includes(campaign.id)) {
												return { ...campaign, client_id: client.id };
											}
											return campaign;
										});

										// Update the store with modified campaigns
										useCampaignStore.setState({ campaigns: updatedCampaigns });

										Promise.all(selectedCampaignIds.map(id => handleClientSelect(id, client.id)))
											.then(() => {
												toast.success(`Assigned ${client.name} to ${selectedCampaignIds.length} campaigns`);
											})
											.catch(error => {
												console.error("Failed to assign clients:", error);
												toast.error("Failed to assign clients to some campaigns");
											});
									}}>
									<span className="line-clamp-1 flex-1 overflow-hidden text-ellipsis">{client.name}</span>
								</DropdownMenuItem>
							))}
						</DropdownMenuContent>
					</DropdownMenu>
				)}

				<Button
					variant="ghost"
					size="sm"
					className="text-label-muted h-8 gap-2 rounded-md px-3 text-sm"
					onClick={() => {
						// Archive workflow for single or multiple campaigns
						if (selectedCampaignIds.length === 1) {
							const campaign = filteredCampaigns.find(c => c.id === selectedCampaignIds[0]);
							if (campaign) {
								handleArchive(campaign);
							}
						} else if (selectedCampaignIds.length > 1) {
							handleBulkArchive(selectedCampaignIds);
						}
					}}>
					<ArchiveBoxIcon className="text-label-label-muted h-4 w-4" />
					<span>Archive</span>
				</Button>
			</SelectionToolbar>
		</div>
	);
};

export default CampaignsList;
