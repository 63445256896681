import { Editor, Extension, Range } from "@tiptap/core";
import { Plugin } from "@tiptap/pm/state";
import Fuse from "fuse.js";
import { VarType } from "./nodes/constants";
import { ElseBlock } from "./nodes/elseBlock";
import { EndIfNode } from "./nodes/endIf";
import { ElseIfBlock, IfBlock } from "./nodes/ifBlock";
import { Variable } from "./nodes/types";
import { RightOperandNode } from "./nodes/RightOperandNode";

declare module "@tiptap/core" {
	interface Commands<ReturnType> {
		conditionalLogic: {
			other: (editor: Editor, range: Range) => ReturnType;
			triggerConditionalLogicExtension: (editor: Editor, range: Range) => ReturnType;
		};
	}
}

interface ConditionalLogicOptions {
	variables?: Record<string, Variable>;
	defaultVariables?: Variable[];
}

export const ConditionalLogicExtension = Extension.create<ConditionalLogicOptions>({
	name: "conditionalLogic",
	exit: {
		backspace: true,
		enter: false,
		space: false,
		escape: true,
	},

	addOptions() {
		return {
			variables: {},
			defaultVariables: [],
		};
	},

	addExtensions() {
		const defaultVariables = this.options.defaultVariables || [];
		const variables = this.options.variables || {};
		const variableFuse = new Fuse(Object.values(variables), { keys: ["name"] });
		const stringVariableFuse = new Fuse(
			Object.values(variables).filter(variable => variable.type === VarType.String),
			{
				keys: ["name"],
			},
		);
		const numberVariableFuse = new Fuse(
			Object.values(variables).filter(variable => variable.type === VarType.Number),
			{
				keys: ["name"],
			},
		);
		const booleanVariableFuse = new Fuse(
			Object.values(variables).filter(variable => variable.type === VarType.Boolean),
			{
				keys: ["name"],
			},
		);
		// Must be configured as they are returned. Cannot use a map function to configure them.
		return [
			// InlineConditionalNode.configure({
			// 	defaultVariables,
			// 	variables,
			// 	variableFuse,
			// }),
			IfBlock.configure({
				defaultVariables,
				variables,
				variableFuse,
				stringVariableFuse,
				numberVariableFuse,
				booleanVariableFuse,
			}),
			ElseIfBlock.configure({
				defaultVariables,
				variables,
				variableFuse,
				stringVariableFuse,
				numberVariableFuse,
				booleanVariableFuse,
			}),
			ElseBlock.configure({
				defaultVariables,
				variables,
				variableFuse,
				stringVariableFuse,
				numberVariableFuse,
				booleanVariableFuse,
			}),
			EndIfNode.configure({
				defaultVariables,
				variables,
				variableFuse,
				stringVariableFuse,
				numberVariableFuse,
				booleanVariableFuse,
			}),
			RightOperandNode.configure({
				defaultVariables,
				variables,
			}),
		];
	},
	addCommands() {
		return {
			triggerConditionalLogicExtension:
				(_editor, range) =>
				({ chain }) => {
					return chain()
						.focus()
						.deleteRange(range)
						.insertContent({
							type: "ifBlock",
							content: [],
						})
						.insertContent({
							type: "text",
							text: "if",
						})
						.insertContent({
							type: "elseBlock",
							content: [],
						})
						.insertContent({
							type: "text",
							text: "else",
						})
						.insertContent({
							type: "endIfNode",
							content: [],
						})
						.run();
				},
		};
	},
	addProseMirrorPlugins() {
		return [
			new Plugin({
				// This plugin is used to prevent the user from deleting a conditional logic block that is not completely
				// covered by the transaction.
				// filterTransaction(transaction, state) {
				// 	let result = true; // true for keep, false for stop transaction
				// 	if (!state.doc) return result;
				// 	const replaceSteps: number[] = [];
				// 	transaction.steps.forEach((step, index) => {
				// 		const jsonStep = step.toJSON();
				// 		// Typing information is not available in the transaction, so we will have to do some duck typing to
				// 		// determine if the step is a replace step.
				// 		try {
				// 			if (!jsonStep.slice) return;
				// 			for (const content of jsonStep.slice.content) {
				// 				if (content.type === "ifBlock" || content.type === "elseIfBlock") {
				// 					// Likely just an update of the attributes, so we will allow the transaction.
				// 					return;
				// 				}
				// 			}
				// 		} catch (e) {
				// 			console.error("error while trying to determine if the step is a replace step for conditional logic", e);
				// 		}
				// 		if (jsonStep.stepType === "replace") {
				// 			replaceSteps.push(index);
				// 		}
				// 	});
				// 	replaceSteps.forEach(index => {
				// 		const map = transaction.mapping.maps[index];
				// 		// Track the start and end of the ifBlock and endIfNode. Keep in mind, they can be nested. We only want to
				// 		// allow deleting entire conditional logic blocks, and only if they are completely covered by the
				// 		// transaction. This algorithm will work much like how determining if a point is inside a polygon works. It
				// 		// should resolve to 0 if the point is inside entire conditional logic block, and anything else if it isn't
				// 		// by incrementing for every ifBlock and decrementing for every endIfNode. If it comes across an
				// 		// elseIfBlock or elseBlock while the count is 0, then then it is not a complete conditional logic block and
				// 		// so we should not allow the transaction.
				// 		let numOfIntersections = 0;
				// 		let foundOutOfSync = false;
				// 		map.forEach((oldStart, oldEnd, _newStart, _newEnd) => {
				// 			// The end of the range is the end of the document, or the end of the range, whichever is smaller.
				// 			const adjustedOldEnd = Math.min(oldEnd, state.doc.content.size);
				// 			state.doc.nodesBetween(oldStart, adjustedOldEnd, node => {
				// 				if (node.type.name === "ifBlock") {
				// 					numOfIntersections++;
				// 				} else if (node.type.name === "endIfNode") {
				// 					numOfIntersections--;
				// 				} else if (node.type.name === "elseIfBlock" || node.type.name === "elseBlock") {
				// 					if (numOfIntersections === 0) {
				// 						foundOutOfSync = true;
				// 					}
				// 				}
				// 			});
				// 		});
				// 		if (foundOutOfSync || numOfIntersections !== 0) {
				// 			result = false;
				// 		}
				// 	});
				// 	return result;
				// },
			}),
		];
	},
});
