import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { CSVRow } from "../types";
import { CampaignProps } from "@/types/campaign";
import { useFieldConfiguration } from "../hooks/useFieldConfiguration";
import { ColumnMapping } from "./ColumnMapping";
import { CustomFieldModal } from "./CustomFieldModal";
import { ColumnMappingHeader } from "./ColumnMappingHeader";

interface Props {
	selectedCampaign: CampaignProps;
	importedData: CSVRow[];
}

export function FieldConfiguration({ selectedCampaign, importedData }: Props) {
	const {
		isImporting,
		searchQuery,
		setSearchQuery,
		currentColumnId,
		setCurrentColumnId,
		columns,
		filteredDefaultFields,
		filteredCustomFields,
		customFieldModal,
		setCustomFieldModal,
		handleFieldMapping,
		handleCreateCustomField,
		handleImport,
		allDefaultFields,
	} = useFieldConfiguration(importedData, selectedCampaign);

	return (
		<div className="flex h-screen flex-col bg-white">
			<div className="flex-none p-6">
				<Card className="w-155 mx-auto border-none bg-transparent shadow-none">
					<CardHeader>
						<ColumnMappingHeader isImporting={isImporting} onImport={handleImport} />
					</CardHeader>
				</Card>
			</div>

			<div className="flex-1 overflow-auto px-6 pb-6">
				<Card className="w-155 mx-auto border-none bg-transparent shadow-none">
					<CardContent className="mt-2 space-y-4">
						<div className="sticky top-0 z-10 flex items-center justify-between bg-white py-2">
							<div className="text-body-base leading-body-base text-label-label-muted grow font-medium">
								CSV columns
							</div>
							<div className="text-body-base leading-body-base text-label-label-muted grow font-medium">Variables</div>
						</div>
						<div className="space-y-4 pb-6">
							{columns.map(column => (
								<ColumnMapping
									key={column.id}
									column={column}
									currentColumnId={currentColumnId}
									searchQuery={searchQuery}
									onSearchChange={setSearchQuery}
									filteredDefaultFields={filteredDefaultFields}
									filteredCustomFields={filteredCustomFields}
									onFieldMapping={handleFieldMapping}
									onCustomFieldCreate={(type, columnName) =>
										setCustomFieldModal({ isOpen: true, type, name: columnName })
									}
									onColumnSelect={setCurrentColumnId}
									allDefaultFields={allDefaultFields}
								/>
							))}
						</div>
					</CardContent>
				</Card>
			</div>

			<CustomFieldModal
				state={customFieldModal}
				onStateChange={setCustomFieldModal}
				onSubmit={handleCreateCustomField}
			/>
		</div>
	);
}
