import { CoachFeedback, EmailCoachRule } from "../types";

// Categorize readability levels based on Hemingway app
export enum ReadabilityLevel {
	Normal = "normal",
	Hard = "hard",
	VeryHard = "veryHard",
}

// Constants for readability calculation
const LETTER_WEIGHT = 4.71;
const SENTENCE_WEIGHT = 0.5;
const SCORE_ADJUSTMENT = 21.43;

// Word count thresholds for sentence difficulty
const HARD_MIN_WORD_THRESHOLD = 10;
const HARD_MAX_WORD_THRESHOLD = 14;
const VERY_HARD_WORD_THRESHOLD = 14;

// Helper to extract plain text from HTML
const getPlainTextFromHtml = (html: string): string => {
	const div = document.createElement("div");
	div.innerHTML = html;
	return div.textContent || div.innerText || "";
};

// Regular expression to split text into sentences
const SENTENCE_REGEX = /[.!?]+\s+/;

// Count letters excluding spaces and punctuation
const countLetters = (text: string): number => {
	return text.replace(/[^a-zA-Z]/g, "").length;
};

// Count words in a string
const countWords = (text: string): number => {
	const words = text
		.trim()
		.split(/\s+/)
		.filter(word => word.length > 0);
	return words.length;
};

// Analyze entire email content for readability
const analyzeReadability = (content: string): CoachFeedback[] => {
	const plainText = getPlainTextFromHtml(content);

	// Skip empty content
	if (!plainText.trim()) {
		return [];
	}

	// Split into sentences
	const sentences = plainText.split(SENTENCE_REGEX).filter(sentence => sentence.trim().length > 0);

	// Skip if no complete sentences
	if (sentences.length === 0) {
		return [];
	}

	// Calculate overall metrics
	const totalLetters = countLetters(plainText);
	const totalWords = countWords(plainText);
	const totalSentences = sentences.length;

	// Calculate reading level using Hemingway formula
	const readabilityScore = calculateReadingLevel(totalLetters, totalWords, totalSentences);

	// Check each sentence for complexity
	const hardSentences = [];
	const veryHardSentences = [];

	sentences.forEach(sentence => {
		const wordCount = countWords(sentence);

		if (wordCount >= VERY_HARD_WORD_THRESHOLD) {
			veryHardSentences.push(sentence);
		} else if (wordCount >= HARD_MIN_WORD_THRESHOLD && wordCount < HARD_MAX_WORD_THRESHOLD) {
			hardSentences.push(sentence);
		}
	});

	// Create appropriate feedback based on overall score
	const feedback: CoachFeedback[] = [];

	// Always add the overall readability feedback
	feedback.push({
		id: "overall-readability",
		message: getReadabilityMessage(readabilityScore, veryHardSentences.length, hardSentences.length),
		severity: getReadabilitySeverity(readabilityScore),
	});

	return feedback;
};

// Calculate reading level using Hemingway formula
const calculateReadingLevel = (letters: number, words: number, sentences: number): number => {
	if (words === 0 || sentences === 0) return 0;

	const score = LETTER_WEIGHT * (letters / words) + SENTENCE_WEIGHT * (words / sentences) - SCORE_ADJUSTMENT;
	return score <= 0 ? 0 : Math.round(score);
};

// Get severity based on score
const getReadabilitySeverity = (score: number): CoachFeedback["severity"] => {
	if (score >= 14) return "error";
	if (score >= 10) return "warning";
	return "info";
};

// Get appropriate message based on score and sentence counts
const getReadabilityMessage = (score: number, veryHardCount: number, hardCount: number): string => {
	let readabilityLevel = "";

	if (score >= 14) {
		readabilityLevel = "Post-graduate";
	} else if (score >= 10) {
		readabilityLevel = "College";
	} else if (score >= 8) {
		readabilityLevel = "10th Grade";
	} else {
		readabilityLevel = "Elementary";
	}

	let message = `Readability Score: ${score} (${readabilityLevel} level).`;

	if (veryHardCount > 0 || hardCount > 0) {
		message += ` Found ${veryHardCount} very complex and ${hardCount} complex sentences.`;
		message += " Consider simplifying for better readability.";
	} else if (score < 8) {
		message += " Your email is very readable. Great job!";
	}

	return message;
};

// Export the rule
export const readabilityRule: EmailCoachRule = {
	id: "readability",
	name: "Readability Analysis",
	description: "Analyzes the overall readability of your email using the Hemingway readability formula",
	analyze: (content: string) => analyzeReadability(content),
};
