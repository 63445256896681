import { create } from "zustand";
import { persist } from "zustand/middleware";

type CampaignListPreferencesStore = {
	sentOption: "sent" | "contacted";
	deliverabilityOption: "deliverability" | "bounce_rate";
	setSentOption: (option: "sent" | "contacted") => void;
	setDeliverabilityOption: (option: "deliverability" | "bounce_rate") => void;
};

const useCampaignListPreferencesStore = create<CampaignListPreferencesStore>()(
	persist(
		set => ({
			sentOption: "sent",
			deliverabilityOption: "deliverability",
			setSentOption: option => set({ sentOption: option }),
			setDeliverabilityOption: option => set({ deliverabilityOption: option }),
		}),
		{
			name: "campaign-list-preferences-storage",
			version: 1,
		},
	),
);

export default useCampaignListPreferencesStore;
