import { CommandItem } from "../types";
import { capitalize } from "lodash";
import SenderVariablesPlane from "@/assets/icons/svgs/sender-variables-plane.svg?react";
import SenderName from "@/assets/icons/svgs/sender-name.svg?react";
import SenderFirstName from "@/assets/icons/svgs/sender-first-name.svg?react";
import SenderLastName from "@/assets/icons/svgs/sender-last-name.svg?react";
import SenderDomain from "@/assets/icons/svgs/sender-domain.svg?react";
import SenderEmail from "@/assets/icons/svgs/sender-email.svg?react";

export const senderVariableCommands: CommandItem[] = [
	{
		title: "Sender name",
		icon: <SenderName className="h-4 w-4 text-[#397af1]" />,
		command: ({ editor, range }) => {
			editor.commands.deleteRange(range);
			editor.commands.setVariable("sender.name", "Sender name");
		},
	},
	{
		title: "Sender first name",
		icon: <SenderFirstName className="h-4 w-4 text-[#397af1]" />,
		command: ({ editor, range }) => {
			editor.commands.deleteRange(range);
			editor.commands.setVariable("sender.firstname", "Sender first name");
		},
	},
	{
		title: "Sender last name",
		icon: <SenderLastName className="h-4 w-4 text-[#397af1]" />,
		command: ({ editor, range }) => {
			editor.commands.deleteRange(range);
			editor.commands.setVariable("sender.lastname", "Sender last name");
		},
	},
	{
		title: "Sender domain",
		icon: <SenderDomain className="h-4 w-4 text-[#397af1]" />,
		command: ({ editor, range }) => {
			editor.commands.deleteRange(range);
			editor.commands.setVariable("sender.domain", "Sender domain");
		},
	},
	{
		title: "Sender email",
		icon: <SenderEmail className="h-4 w-4 text-[#397af1]" />,
		command: ({ editor, range }) => {
			editor.commands.deleteRange(range);
			editor.commands.setVariable("sender.email", "Sender email");
		},
	},
];

// Keep the submenu version for when there's no search query
export const senderVariablesSubmenu: CommandItem = {
	title: "Sender variables",
	icon: <SenderVariablesPlane className="h-4 w-4 text-[#F18039]" />,
	subMenuClassName: "min-w-60",
	children: senderVariableCommands,
};

export const formatSenderVariableDisplayName = (fieldId: string) => {
	if (fieldId === "sender.firstname") {
		return "Sender first name";
	} else if (fieldId === "sender.lastname") {
		return "Sender last name";
	}
	return capitalize(fieldId).replace(".", " ");
};
