import SelectEmailsEditor from "@/components/CampaignEditor/SelectEmailsEditor";
import { useCampaignStore } from "@/store";
import { Suspense } from "react";
import { useParams } from "wouter";

export default function Page() {
	const params = useParams();
	const { campaigns } = useCampaignStore();

	const campaignId = params.campaignId;
	if (!campaignId) {
		return <div>Campaign not found</div>;
	}

	const selectedCampaign = campaigns.find(campaign => campaign.id === campaignId);
	if (!selectedCampaign) {
		return <div>Campaign not found</div>;
	}

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<SelectEmailsEditor selectedCampaign={selectedCampaign} />
		</Suspense>
	);
}
