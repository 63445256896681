import Fuse from "fuse.js";

export enum VarType {
	String,
	Number,
	Boolean,
}

export enum Operator {
	EQ,
	GT,
	GTE,
	LT,
	LTE,
	NE,
	HAS_VALUE,
	HAS_NO_VALUE,
	CONTAINS,
}

export enum ContinuationBlockType {
	AND,
	OR,
}

export type OperatorDetails = {
	operator: Operator;
	label: string;
	name: string;
	description: string;
	liquid: string;
};

export const OperatorDetailsMap: Record<Operator, OperatorDetails> = {
	[Operator.EQ]: {
		operator: Operator.EQ,
		label: "EQ",
		name: "equals",
		description: "The value equals the variable",
		liquid: "==",
	},
	[Operator.GT]: {
		operator: Operator.GT,
		label: "GT",
		name: "is greater than",
		description: "The value is greater than the variable",
		liquid: ">",
	},
	[Operator.GTE]: {
		operator: Operator.GTE,
		label: "GTE",
		name: "is greater than or equal to",
		description: "The value is greater than or equal to the variable",
		liquid: ">=",
	},
	[Operator.LT]: {
		operator: Operator.LT,
		label: "LT",
		name: "is less than",
		description: "The value is less than the variable",
		liquid: "<",
	},
	[Operator.LTE]: {
		operator: Operator.LTE,
		label: "LTE",
		name: "is less than or equal to",
		description: "The value is less than or equal to the variable",
		liquid: "<=",
	},
	[Operator.NE]: {
		operator: Operator.NE,
		label: "NE",
		name: "does not equal",
		description: "The value does not equal the variable",
		liquid: "!=",
	},
	[Operator.HAS_VALUE]: {
		operator: Operator.HAS_VALUE,
		label: "HAS_VALUE",
		name: "has any value",
		description: "The variable has any value",
		liquid: "blank != true",
	},
	[Operator.HAS_NO_VALUE]: {
		operator: Operator.HAS_NO_VALUE,
		label: "HAS_NO_VALUE",
		name: "has no value",
		description: "The variable has no value",
		liquid: "blank == true",
	},
	[Operator.CONTAINS]: {
		operator: Operator.CONTAINS,
		label: "CONTAINS",
		name: "contains",
		description: "The value contains the variable",
		liquid: "contains",
	},
	// [Operator.TRUE]: {
	// 	operator: Operator.TRUE,
	// 	label: "TRUE",
	// 	name: "true",
	// 	description: "The value is true",
	// 	liquid: "true",
	// },
	// [Operator.FALSE]: {
	// 	operator: Operator.FALSE,
	// 	label: "FALSE",
	// 	name: "false",
	// 	description: "The value is false",
	// 	liquid: "false",
	// },
};

export const OperatorLookupByValue: Record<number, Operator> = {
	[Operator.EQ]: Operator.EQ,
	[Operator.GT]: Operator.GT,
	[Operator.GTE]: Operator.GTE,
	[Operator.LT]: Operator.LT,
	[Operator.LTE]: Operator.LTE,
	[Operator.NE]: Operator.NE,
	[Operator.HAS_VALUE]: Operator.HAS_VALUE,
	[Operator.HAS_NO_VALUE]: Operator.HAS_NO_VALUE,
	[Operator.CONTAINS]: Operator.CONTAINS,
};

export function getOperatorByValue(value: number): Operator {
	const op = OperatorLookupByValue[value];
	if (op === undefined) {
		throw new Error(`Operator not found for value: ${value}`);
	}
	return op;
}

export const OperatorLookupByLiquid: Record<string, Operator> = {
	"==": Operator.EQ,
	">": Operator.GT,
	">=": Operator.GTE,
	"<": Operator.LT,
	"<=": Operator.LTE,
	"!=": Operator.NE,
	"blank != true": Operator.HAS_VALUE,
	"blank == true": Operator.HAS_NO_VALUE,
	"contains": Operator.CONTAINS,
};

// Now we need to create a list of operators for each variable type
export const TextOperators = [Operator.EQ, Operator.NE, Operator.HAS_VALUE, Operator.HAS_NO_VALUE, Operator.CONTAINS];

export const BooleanOperators = [Operator.EQ, Operator.NE, Operator.HAS_VALUE, Operator.HAS_NO_VALUE];

export const NumberOperators = [
	Operator.EQ,
	Operator.NE,
	Operator.GT,
	Operator.GTE,
	Operator.LT,
	Operator.LTE,
	Operator.HAS_VALUE,
	Operator.HAS_NO_VALUE,
];

export const OPERATORS_FUSE = new Fuse(Object.values(OperatorDetailsMap), {
	keys: ["name", "label", "description", "liquid"],
});

export const TextOperatorDetails = TextOperators.map(operator => OperatorDetailsMap[operator]);
export const BooleanOperatorDetails = BooleanOperators.map(operator => OperatorDetailsMap[operator]);
export const NumberOperatorDetails = NumberOperators.map(operator => OperatorDetailsMap[operator]);

export const TextOperatorsFUSE = new Fuse(TextOperatorDetails, {
	keys: ["name", "label", "description", "liquid"],
});

export const BooleanOperatorsFUSE = new Fuse(BooleanOperatorDetails, {
	keys: ["name", "label", "description", "liquid"],
});

export const NumberOperatorsFUSE = new Fuse(NumberOperatorDetails, {
	keys: ["name", "label", "description", "liquid"],
});

export const getOperatorCollection = (variableType?: VarType) => {
	if (variableType === undefined) return Object.values(OperatorDetailsMap);
	switch (variableType) {
		case VarType.String:
			return TextOperatorDetails;
		case VarType.Boolean:
			return BooleanOperatorDetails;
		case VarType.Number:
			return NumberOperatorDetails;
	}
};

export const getOperatorFuse = (variableType?: VarType) => {
	if (variableType === undefined) return OPERATORS_FUSE;
	switch (variableType) {
		case VarType.String:
			return TextOperatorsFUSE;
		case VarType.Boolean:
			return BooleanOperatorsFUSE;
		case VarType.Number:
			return NumberOperatorsFUSE;
	}
};
