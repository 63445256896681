import { ReactRenderer } from "@tiptap/react";
import { SuggestionKeyDownProps } from "@tiptap/suggestion";
import Tippy, { Instance as TippyInstance } from "tippy.js";
import { CustomSuggestionKeyDownProps, CustomSuggestionProps } from "../core/suggestion";
import { CommandItem, ItemCollection } from "../types";
import CommandsList from "./CommandsList";
import "../../../../app/globals.css";

// Define interface for the ref of CommandsList component
interface CommandsListRef {
	onKeyDown: (props: CustomSuggestionKeyDownProps) => boolean;
}

export default function RenderSuggestions() {
	// Specify that ReactRenderer will handle a component with CommandsListRef
	let reactRenderer: ReactRenderer<CommandsListRef>;
	let popup: TippyInstance[];

	return {
		onStart: (props: CustomSuggestionProps<ItemCollection, CommandItem>) => {
			reactRenderer = new ReactRenderer(CommandsList, {
				props,
				editor: props.editor,
			});

			if (!props.clientRect) return;

			popup = Tippy("body", {
				theme: "customtippy",
				getReferenceClientRect: () => {
					if (props.clientRect) {
						return props.clientRect() ?? document.body.getBoundingClientRect();
					}
					return document.body.getBoundingClientRect();
				},
				appendTo: () => document.body,
				content: reactRenderer.element,
				showOnCreate: true,
				interactive: true,
				trigger: "manual",
				arrow: false,
				placement: "bottom-start",
			});
		},
		onUpdate(props: CustomSuggestionProps<ItemCollection, CommandItem>) {
			reactRenderer.updateProps(props);

			if (!props.clientRect) return;

			popup[0].setProps({
				getReferenceClientRect: () => {
					if (props.clientRect) {
						return props.clientRect() ?? document.body.getBoundingClientRect();
					}
					return document.body.getBoundingClientRect();
				},
			});
		},
		onKeyDown(props: SuggestionKeyDownProps): boolean {
			if (props.event.key === "Escape") {
				popup[0].hide();
				return true;
			}
			if (reactRenderer.ref) {
				return reactRenderer.ref.onKeyDown(props);
			}
			return false;
		},
		onExit() {
			if (popup) {
				popup[0].destroy();
				reactRenderer.destroy();
			}
		},
	};
}
