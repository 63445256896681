import { LiquidOperationDefinitionTempStates } from "../core/nodes/liquidParsing";

type ContinuationOperatorProps = {
	continuation?: {
		variant: "and" | "or";
		operation: LiquidOperationDefinitionTempStates;
	};
	onVariantToggle: () => void;
	onRemove: () => void;
};

export function ContinuationOperator({ continuation, onVariantToggle, onRemove }: ContinuationOperatorProps) {
	if (!continuation) return null;

	return (
		<span className="cursor-pointer px-2 py-1 text-gray-500 hover:text-gray-700" onClick={onVariantToggle}>
			<span
				className={`relative left-0 top-1/2 mr-2 -translate-x-full -translate-y-1/2 bg-red-500 px-2 py-1 text-gray-500 opacity-20 transition-opacity duration-200 hover:text-gray-700 hover:opacity-100`}
				onClick={e => {
					e.stopPropagation();
					onRemove();
				}}>
				×
			</span>
			{continuation.variant.toUpperCase()}
		</span>
	);
}
