import CompanyCardIcon from "@/assets/icons/svgs/table-header/company-card.svg?react";
import SendPaperAirplaneIcon from "@/assets/icons/svgs/table-header/send-paper-airplane.svg?react";
// import ClickedIcon from "@/assets/icons/svgs/table-header/clicked.svg?react";
import StatusInfoIcon from "@/assets/icons/svgs/table-header/status-info.svg?react";
import PhoneBookIcon from "@/assets/icons/svgs/phone-book.svg?react";
import BounceRateIcon from "@/assets/icons/svgs/table-header/bounce-rate.svg?react";
import DoubleCheckmarkIcon from "@/assets/icons/svgs/double-checkmark.svg?react";
import PositiveRepliesIcon from "@/assets/icons/svgs/table-header/positive-replies.svg?react";
import React from "react";

// Type definition for table header icons
export type TableHeaderIcon =
	| React.FunctionComponent<React.SVGProps<SVGSVGElement>>
	| "openedIcon"
	| "repliedIcon"
	| "positiveRepliesIcon";

// Interface for table header configuration
export interface TableHeaderConfig {
	width: string;
	icon: TableHeaderIcon;
	label: string;
	iconColor?: string;
	isImage?: boolean;
	hasDropdown?: boolean;
	dropdownOptions?: {
		value: string;
		label: string;
		icon: TableHeaderIcon;
		iconColor?: string;
		isImage?: boolean;
	}[];
}

// Table headers configuration
export const TABLE_HEADERS: TableHeaderConfig[] = [
	{
		width: "20%",
		icon: CompanyCardIcon,
		label: "Name",
		iconColor: "text-[#1A1A1A]",
	},
	{
		width: "10%",
		icon: StatusInfoIcon,
		label: "Status",
		iconColor: "text-[#AAAAAA]",
	},
	{
		width: "11.67%",
		icon: SendPaperAirplaneIcon,
		label: "Sent",
		iconColor: "text-[#58C172]",
		hasDropdown: true,
		dropdownOptions: [
			{ value: "sent", label: "Sent", icon: SendPaperAirplaneIcon, iconColor: "text-[#58C172]" },
			{ value: "contacted", label: "Contacted", icon: PhoneBookIcon, iconColor: "text-[#30B0C7]" },
		],
	},
	{
		width: "11.67%",
		icon: "openedIcon",
		label: "Opened",
		isImage: true,
	},
	{
		width: "11.67%",
		icon: "repliedIcon",
		label: "Replied",
		isImage: true,
	},
	{
		width: "11.67%",
		icon: PositiveRepliesIcon,
		label: "Positive replies",
		iconColor: "text-[#34C759]",
	},
	{
		width: "11.67%",
		icon: BounceRateIcon,
		label: "Bounce rate",
		iconColor: "text-red",
		hasDropdown: true,
		dropdownOptions: [
			{ value: "deliverability", label: "Deliverability", icon: DoubleCheckmarkIcon, iconColor: "text-[#58C172]" },
			{ value: "bounce_rate", label: "Bounce rate", icon: BounceRateIcon, iconColor: "text-red" },
		],
	},
];
