"use client";

import { useSidebar } from "@/components/ui/sidebar";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useEffect, useRef } from "react";

interface UnderConstructionProps {
	title?: string;
	description?: string;
}

const UnderConstruction = ({
	title = "Coming Soon",
	description = "This feature is currently under development.",
}: UnderConstructionProps) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const { state, toggleSidebar } = useSidebar();

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const ctx = canvas.getContext("2d");
		if (!ctx) return;

		canvas.width = canvas.offsetWidth;
		canvas.height = canvas.offsetHeight;

		const drawLine = (startX: number, startY: number, endX: number, endY: number, color: string) => {
			ctx.beginPath();
			ctx.moveTo(startX, startY);
			ctx.lineTo(endX, endY);
			ctx.strokeStyle = color;
			ctx.lineWidth = 2;
			ctx.stroke();
		};

		const animate = () => {
			ctx.clearRect(0, 0, canvas.width, canvas.height);

			const time = Date.now() * 0.001;
			const lines = 5;
			const points = 8;

			for (let i = 0; i < lines; i++) {
				const yOffset = (canvas.height / lines) * i;
				let prevX = 0;
				let prevY = yOffset + Math.sin(time + i) * 20;

				for (let j = 0; j < points; j++) {
					const x = (canvas.width / (points - 1)) * j;
					const y = yOffset + Math.sin(time + i + j * 0.5) * 20;

					drawLine(prevX, prevY, x, y, `hsl(${180 + i * 30}, 80%, 50%)`);

					prevX = x;
					prevY = y;
				}
			}

			requestAnimationFrame(animate);
		};

		animate();

		return () => {
			// Cleanup
		};
	}, []);

	return (
		<div className="w-full px-4">
			<div className="mx-auto w-full max-w-7xl space-y-2">
				<header>
					<div className="flex h-[52px] items-center">
						<div className="flex items-center gap-2">
							<Tooltip delayDuration={500}>
								<TooltipTrigger asChild>
									<button
										onClick={toggleSidebar}
										className="flex h-6 w-6 items-center justify-center rounded-sm hover:bg-gray-100"
										aria-label="Toggle sidebar"></button>
								</TooltipTrigger>
								<TooltipContent side="right">
									{state === "expanded" ? "Collapse the sidebar (⌘B)" : "Expand the sidebar (⌘B)"}
								</TooltipContent>
							</Tooltip>
							<h1 className="text-[15px] font-medium leading-[22px] text-[#1A1A1A]">{title}</h1>
						</div>
					</div>
				</header>
				<div className="relative h-96 overflow-hidden rounded-lg bg-black/90">
					<canvas ref={canvasRef} className="h-full w-full" />
					<div className="absolute inset-0 flex items-center justify-center">
						<p className="text-2xl font-semibold text-white">Coming Soon</p>
					</div>
				</div>
				<p className="text-muted-foreground text-center text-lg">{description}</p>
			</div>
		</div>
	);
};

export default UnderConstruction;
