import { InboxTabs } from "@/app/enums/inbox.enum";
import {
	EmailParticipant,
	Row,
	SendEmailPayload,
	TAccountThreadRow,
	TMessageNewRow,
	TThreadWithMessagesAndAccounts,
} from "@za-zu/types";
import { toast } from "sonner";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import useFetchStore from "./fetchStore";
import useEmailAccountsStore from "./useEmailAccountsStore";

type TempEmailSendId = string;

type PendingInboxChange = {
	id: TempEmailSendId;
	action: "send";
	email: SendEmailPayload;
};

type InboxStoreProps = {
	allThreads: Record<string, TThreadWithMessagesAndAccounts>;
	threadsLoading: boolean;
	selectedCampaignId: string | null;
	pendingInboxChanges: Record<TempEmailSendId, PendingInboxChange>;
	claimedPendingInboxChanges: Set<TempEmailSendId>;
	outboundMessageTrackingById: Record<
		TempEmailSendId,
		Row<"reply_message_tracking"> & { outbound: Row<"outbound_message_tracking_new"> | null }
	>;
	addPendingInboxChange: (change: PendingInboxChange) => void;
	trackOutbound: (
		info: Row<"reply_message_tracking"> & { outbound: Row<"outbound_message_tracking_new"> | null },
	) => Promise<void>;
	setSelectedCampaignId: (id: string | null) => void;
	getPrimary: () => TThreadWithMessagesAndAccounts[];
	getOther: () => TThreadWithMessagesAndAccounts[];
	getSent: () => TThreadWithMessagesAndAccounts[];
	getUntracked: () => TThreadWithMessagesAndAccounts[];
	getDone: () => TThreadWithMessagesAndAccounts[];
	updateThreadsWithData: (data: TThreadWithMessagesAndAccounts[]) => void;
	syncWithLatest: (tab: InboxTabs) => Promise<void>;
	archiveThread: (thread: TThreadWithMessagesAndAccounts) => void;
	archiveThreadById: (threadId: string) => void;
	unarchiveThread: (thread: TThreadWithMessagesAndAccounts) => void;
	unarchiveThreadById: (threadId: string) => void;
	updateMessageInThread: (threadId: string, tempId: string, confirmedMessageId: string) => void;
	updateThreadSeenStatus: ({ threadId, seen }: { threadId: string; seen: boolean }) => void;
	sortIncomingMessage: (message: TMessageNewRow) => void;
	updateThread: (thread: TThreadWithMessagesAndAccounts) => void;
	sendEmail: ({
		thread,
		messageText,
		markAsDone,
		referenceMessageId,
	}: {
		thread: TThreadWithMessagesAndAccounts;
		messageText: string;
		markAsDone: boolean;
		referenceMessageId: string;
	}) => string;
	attemptPendingInboxChange: (changeId: TempEmailSendId) => Promise<void>;
};

const sortMessagesByDate = (messages: TMessageNewRow[]) => {
	return [...messages].sort((a, b) => new Date(a.sent_at ?? "").getTime() - new Date(b.sent_at ?? "").getTime());
};

const sortThreadsByDate = (threads: TThreadWithMessagesAndAccounts[], ascending = false) => {
	return [...threads].sort((a, b) => {
		const timeA = new Date(a.last_sent_at ?? "").getTime();
		const timeB = new Date(b.last_sent_at ?? "").getTime();
		return ascending ? timeA - timeB : timeB - timeA;
	});
};

const useInboxStore = create<InboxStoreProps>()(
	persist(
		(set, get) => ({
			allThreads: {},
			threadsLoading: false,
			selectedCampaignId: null,
			pendingInboxChanges: {},
			claimedPendingInboxChanges: new Set(),
			outboundMessageTrackingById: {},
			addPendingInboxChange: change =>
				set(state => ({
					pendingInboxChanges: {
						...state.pendingInboxChanges,
						[change.id]: change,
					},
				})),

			trackOutbound: async info => {
				const outbound = info.outbound;
				if (!outbound) return;
				const { confirmed_email_engine_message_id, message_e_id, thread_e_id } = outbound;
				if (!confirmed_email_engine_message_id || !message_e_id || !thread_e_id) return;
				const thread = get().allThreads[thread_e_id];
				if (!thread) {
					console.error("Unable to locate thread");
					throw new Error("Unable to locate thread");
				}

				const confirmedMatchIndex = thread.messages.findIndex(
					msg => msg.message_id === confirmed_email_engine_message_id,
				);
				if (confirmedMatchIndex !== -1) {
					return;
				}

				const { apiClient } = useFetchStore.getState();
				if (!apiClient) {
					throw new Error("No API client found");
				}

				const result = await apiClient.getThreadMessage(info.email_account_id, confirmed_email_engine_message_id);

				set(state => {
					if (!info.outbound || !info.outbound.thread_e_id || !info.outbound.message_e_id) return state;
					const thread = state.allThreads[info.outbound?.thread_e_id];
					if (!thread) return state;

					// Filter out the temporary message and add the confirmed one
					const updatedThreadMessages = thread.messages.filter(msg => msg.e_id !== message_e_id);
					updatedThreadMessages.push(result);

					// Sort messages by sent date
					const sortedMessages = sortMessagesByDate(updatedThreadMessages);

					const updatedThread: TThreadWithMessagesAndAccounts = {
						...thread,
						messages: sortedMessages,
					};

					return {
						...state,
						allThreads: {
							...state.allThreads,
							[thread_e_id]: updatedThread,
						},
					};
				});

				// Always update the tracking info
				set(prev => ({
					outboundMessageTrackingById: {
						...prev.outboundMessageTrackingById,
						[info.tracking_id]: info,
					},
				}));
			},

			setSelectedCampaignId: id => set({ selectedCampaignId: id }),
			getPrimary: () => {
				const allThreads = Object.values(get().allThreads);
				return sortThreadsByDate(
					allThreads.filter(
						thread =>
							thread.accounts.some(acc => acc.campaign_id !== null) &&
							thread.accounts.some(acc => acc.status === "active") &&
							thread.messages.some(msg => {
								const isInbound = msg.from.address !== thread.accounts[0]?.email_account_id;
								return isInbound;
							}) &&
							(thread.lead_thread_category === null ||
								thread.lead_thread_category === "interested" ||
								thread.lead_thread_category === "meeting_requested" ||
								thread.lead_thread_category === "meeting_booked"),
					),
				);
			},

			getOther: () => {
				const allThreads = Object.values(get().allThreads);
				return sortThreadsByDate(
					allThreads.filter(
						thread =>
							thread.accounts.some(acc => acc.campaign_id !== null) &&
							thread.accounts.some(acc => acc.status === "active") &&
							thread.messages.some(msg => {
								const isInbound = msg.from.address !== thread.accounts[0]?.email_account_id;
								return isInbound;
							}) &&
							(thread.lead_thread_category === "not_interested" ||
								thread.lead_thread_category === "do_not_contact" ||
								thread.lead_thread_category === "out_of_office" ||
								thread.lead_thread_category === "wrong_person"),
					),
				);
			},

			getSent: () => {
				const allThreads = Object.values(get().allThreads);
				return sortThreadsByDate(
					allThreads.filter(thread =>
						thread.accounts.some(acc => acc.status === "active" && acc.latest_direction === "outbound"),
					),
				);
			},

			getUntracked: () => {
				const allThreads = Object.values(get().allThreads);
				return sortThreadsByDate(
					allThreads.filter(thread =>
						thread.accounts.some(
							acc => acc.campaign_id === null && acc.status === "active" && acc.latest_direction === "inbound",
						),
					),
				);
			},

			getDone: () => {
				const allThreads = Object.values(get().allThreads);
				return sortThreadsByDate(allThreads.filter(thread => thread.accounts.every(acc => acc.status === "archived")));
			},

			updateThreadsWithData: data => {
				set(state => ({
					allThreads: {
						...state.allThreads,
						...data.reduce(
							(acc, thread) => ({
								...acc,
								[thread.e_id]: thread,
							}),
							{} satisfies Record<string, TThreadWithMessagesAndAccounts>,
						),
					},
				}));
			},

			syncWithLatest: async (tab: InboxTabs) => {
				const { apiClient } = useFetchStore.getState();
				if (!apiClient) {
					throw new Error("No API client found");
				}
				set({ threadsLoading: true });
				try {
					const result = await apiClient.getThreadsPage(tab);
					get().updateThreadsWithData(result);
				} catch (error) {
					console.error("Error syncing threads:", error);
				} finally {
					set({ threadsLoading: false });
				}
			},

			archiveThread: thread => {
				if (!thread) return;
				const updatedThread: TThreadWithMessagesAndAccounts = {
					...thread,
					accounts: thread.accounts.map(acc => ({ ...acc, status: "archived", seen: true })),
					lead_thread_category: thread.lead_thread_category,
				};
				set(state => ({
					allThreads: {
						...state.allThreads,
						[thread.e_id]: updatedThread,
					},
				}));
			},

			archiveThreadById: threadId => {
				const thread = get().allThreads[threadId];
				if (!thread) return;
				get().archiveThread(thread);
			},

			unarchiveThread: thread => {
				if (!thread) return;
				const updatedThread: TThreadWithMessagesAndAccounts = {
					...thread,
					accounts: thread.accounts.map(acc => ({ ...acc, status: "active" })),
					lead_thread_category: thread.lead_thread_category,
				};
				set(state => ({
					allThreads: {
						...state.allThreads,
						[thread.e_id]: updatedThread,
					},
				}));
			},

			unarchiveThreadById: threadId => {
				const thread = get().allThreads[threadId];
				if (!thread) return;
				get().unarchiveThread(thread);
			},

			updateThreadSeenStatus: ({ threadId, seen }) => {
				set(state => {
					const thread = state.allThreads[threadId];
					if (!thread) return state;
					const updatedThread: TThreadWithMessagesAndAccounts = {
						...thread,
						accounts: thread.accounts.map(acc => ({ ...acc, seen })),
						lead_thread_category: thread.lead_thread_category,
					};
					return {
						...state,
						allThreads: {
							...state.allThreads,
							[threadId]: updatedThread,
						},
					};
				});
			},

			updateMessageInThread: (threadId, tempId, confirmedMessageId) => {
				set(state => {
					const thread = state.allThreads[threadId];
					if (!thread) return state;

					const messageIndex = thread.messages.findIndex(m => m.e_id === tempId);
					if (messageIndex === -1) return state;

					const updatedMessages = [...thread.messages];
					updatedMessages[messageIndex] = {
						...updatedMessages[messageIndex],
						e_id: confirmedMessageId,
						message_id: confirmedMessageId,
					};

					return {
						...state,
						allThreads: {
							...state.allThreads,
							[threadId]: {
								...thread,
								messages: updatedMessages,
							},
						},
					};
				});
			},
			updateThread: thread => {
				set(state => {
					const existingThread = state.allThreads[thread.e_id];
					if (!existingThread) {
						return {
							...state,
							allThreads: {
								...state.allThreads,
								[thread.e_id]: {
									...thread,
									lead_thread_category: thread.lead_thread_category ?? null,
								},
							},
						};
					}
					// Find the latest versions of each message
					const allMessages: TMessageNewRow[] = [...existingThread.messages, ...thread.messages];
					// Sort them by newest first. This will allow us to simultaneously sort the messages in a way that can be
					// reversed to get the desired end result, while also making sure we can conveniently grab the latest version
					// of each. Be sure to use updated_at instead of sent_at so that any changes will impact the sorting order
					// properly.
					allMessages.sort((a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime());
					const accountedMsgIds = new Set<string>();
					const latestMessagesSorted: TMessageNewRow[] = [];
					for (const msg of allMessages) {
						if (accountedMsgIds.has(msg.e_id)) {
							continue;
						}
						latestMessagesSorted.push(msg);
						accountedMsgIds.add(msg.e_id);
					}
					// Now reverse them to get the desired order (old to new).
					latestMessagesSorted.reverse();
					// The order for the account refs isn't important, so we can use a simpler method
					const accounts: TAccountThreadRow[] = Object.values(
						[...thread.accounts, ...existingThread.accounts]
							.sort((a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime())
							.reduce<
								Record<string, TAccountThreadRow>
							>((acc, curr) => ({ ...acc, [curr.email_account_id]: curr }), {}),
					);

					return {
						...state,
						allThreads: {
							...state.allThreads,
							[thread.e_id]: {
								...thread,
								accounts,
								messages: latestMessagesSorted,
								lead_thread_category: thread.lead_thread_category ?? existingThread.lead_thread_category ?? null,
							},
						},
					};
				});
			},

			sortIncomingMessage: message => {
				const thread = get().allThreads[message.thread_e_id];
				if (!thread) return;

				const updatedThreadMessages: TThreadWithMessagesAndAccounts["messages"] = [];
				let existsAlready = false;

				// Check if message already exists and use the most recent version
				for (const msg of thread.messages) {
					if (msg.e_id !== message.e_id) {
						updatedThreadMessages.push(msg);
						continue;
					}

					existsAlready = true;
					const existingSentAt = new Date(msg.sent_at || "");
					const incomingSentAt = new Date(message.sent_at || "");

					if (existingSentAt.getTime() > incomingSentAt.getTime()) {
						updatedThreadMessages.push(msg);
					} else {
						updatedThreadMessages.push(message);
					}
				}

				if (!existsAlready) {
					updatedThreadMessages.push(message);
				}
				const sortedMessages = sortMessagesByDate(updatedThreadMessages);

				get().updateThreadsWithData([
					{
						...thread,
						messages: sortedMessages,
					},
				]);
			},

			sendEmail: ({ thread, messageText, markAsDone, referenceMessageId }) => {
				const { accountsById } = useEmailAccountsStore.getState();
				if (!messageText.trim()) {
					throw new Error("Message must have text");
				}

				const { apiClient } = useFetchStore.getState();
				if (!apiClient) {
					throw new Error("No API client found");
				}

				const firstMessageInThread = thread.messages[0];
				if (!firstMessageInThread) {
					throw new Error(`Somehow have thread without any messages (ID: ${thread.e_id})`);
				}

				const tempId = String(Date.now());
				const lastMessageInThread = thread.messages[thread.messages.length - 1];
				if (!lastMessageInThread) {
					// This should not be possible as we don't have anything to send unprompted emails from our UI currently. So
					// throw an error.
					throw new Error(`No last message for thread (EID: ${thread.e_id})`);
				}
				// TODO: Have some way to pick which email account to send from if more than one are involved. For now, just use
				// the first.
				const sendingEmailAccountRef = thread.accounts[0];
				if (!sendingEmailAccountRef) {
					// This should not be possible as all should have at least one account involved (otherwise we wouldn't be able
					// see them), so throw an error.
					throw new Error(`No account found for thread (EID: ${thread.e_id})`);
				}
				const sendingEmailAccount = accountsById[sendingEmailAccountRef.email_account_id];
				if (!sendingEmailAccount) {
					throw new Error(`No account found with account ID (ID: ${sendingEmailAccountRef.email_account_id})`);
				}
				const referencedEmail = thread.messages.find(msg => msg.e_id === referenceMessageId);
				if (!referencedEmail) {
					throw new Error(`Message not found(EID: ${referenceMessageId})`);
				}
				let otherEmail: EmailParticipant;
				let thisEmail: EmailParticipant;
				if (referencedEmail.from.address === sendingEmailAccount.email) {
					thisEmail = referencedEmail.from;
					otherEmail = referencedEmail.to;
				} else {
					thisEmail = referencedEmail.to;
					otherEmail = referencedEmail.from;
				}

				const newEmail: TMessageNewRow = {
					e_id: tempId,
					thread_e_id: thread.e_id,
					created_at: new Date().toISOString(),
					updated_at: new Date().toISOString(),
					message_id: `<${crypto.randomUUID()}>`,
					subject: null,
					from: thisEmail,
					to: otherEmail,
					text_content: messageText,
					sent_at: new Date().toISOString(),
					preview: messageText?.substring(0, 100),
					account_email_address: thread.account_email_address,
					is_deleted: false,
				};

				const payload: SendEmailPayload = {
					temporaryId: tempId,
					from: thisEmail,
					to: otherEmail,
					text: messageText,
					html: messageText.replace(/\n/g, "<br>"),
					accountId: sendingEmailAccountRef.email_account_id,
					threadId: thread.e_id,
					type: "reply",
					markAsDone: markAsDone,
					referenceMessageId,
				};
				get().sortIncomingMessage({
					...newEmail,
					thread_e_id: thread.e_id,
				});
				get().addPendingInboxChange({
					action: "send",
					id: tempId,
					email: payload,
				});
				if (markAsDone) {
					get().archiveThread(thread);
				}
				return tempId;
			},

			attemptPendingInboxChange: async changeId => {
				const { claimedPendingInboxChanges } = get();
				if (claimedPendingInboxChanges.has(changeId)) {
					return;
				}

				// Claim this change to prevent duplicate processing
				set(prev => {
					const newSet = new Set(prev.claimedPendingInboxChanges);
					newSet.add(changeId);
					return { claimedPendingInboxChanges: newSet };
				});

				let unresolved = true;
				let attempts = 0;

				const attempt = async (): Promise<void> => {
					const { apiClient } = useFetchStore.getState();
					if (!apiClient) {
						throw new Error("No API client found");
					}

					const { pendingInboxChanges } = get();
					const change = pendingInboxChanges[changeId];
					if (!change) return;

					switch (change.action) {
						case "send": {
							const result = await apiClient.sendEmail(change.email);
							get().trackOutbound(result);
							toast.success("Email sent successfully");
							break;
						}
					}
				};
				while (unresolved) {
					try {
						await attempt();
						unresolved = false;
					} catch (_error) {
						const backoff = 1000;
						const maxBackoff = 600000;
						const jitter = Math.random() * 1000;
						const delay = Math.min((backoff + jitter) * Math.pow(2, attempts), maxBackoff);
						await new Promise(resolve => setTimeout(resolve, delay));
					}
					attempts++;
				}
				set(prev => {
					const newClaimedSet = new Set(prev.claimedPendingInboxChanges);
					newClaimedSet.delete(changeId);
					return {
						pendingInboxChanges: Object.fromEntries(
							Object.entries(prev.pendingInboxChanges).filter(([key]) => key !== changeId),
						),
						claimedPendingInboxChanges: newClaimedSet,
					};
				});
			},
		}),
		{
			name: "inbox-storage",
			version: 12,
			partialize: state =>
				Object.fromEntries(Object.entries(state).filter(([key]) => !["claimedPendingInboxChanges"].includes(key))),
		},
	),
);

export default useInboxStore;
