import { CampaignCreationStepEnum } from "@/app/enums/campaign.enum";
import { useCampaignStore, useEmailAccountsStore } from "@/store";
import { CampaignProps } from "@/types/campaign";
import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { useLocation } from "wouter";
import SelectEmailAccounts from "../SelectEmailAccounts/SelectEmailAccounts";
import { Spinner } from "../ui/spinner";
import CampaignEditorHeader from "./CampaignEditorHeader";
import useDirtyStore from "@/store/useDirtyStore";

const SelectEmailsEditor = ({ selectedCampaign }: { selectedCampaign: CampaignProps }) => {
	const [_location, navigate] = useLocation();
	const activeCampaignStep = CampaignCreationStepEnum.SelectEmails;
	const {
		accountsByCampaignId,
		fetchAndStoreAccountsByCampaignId,
		updateEmailAccountMembershipsForCampaign,
		emailAccountsInitialized,
	} = useEmailAccountsStore();
	const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([]);
	const { setIsDirty, setIsSaving } = useDirtyStore();
	const { publishCampaign } = useCampaignStore();
	const [isLoading, setIsLoading] = useState(!accountsByCampaignId[selectedCampaign.id]);
	// Add a ref to track if a manual save was recently performed
	const manualSaveTimestampRef = useRef<number>(0);

	const handleNextStep = async () => {
		if (selectedCampaign && selectedAccountIds.length > 0) {
			if (saveTimeoutRef.current) {
				// Something else requested a save before this one went through, so cancel that one since this one is being
				// executed immediately.
				clearTimeout(saveTimeoutRef.current);
			}
			// Set timestamp of manual save to prevent auto-save from firing too soon after
			manualSaveTimestampRef.current = Date.now();
			const wasSuccessful = await updateEmailAccountMembershipsForCampaign(selectedCampaign.id, selectedAccountIds);
			if (!wasSuccessful) {
				return;
			}
			try {
				await publishCampaign(selectedCampaign.id);
				navigate("/dashboard/campaigns");
			} catch (error) {
				console.error("Failed to publish:", error);
				toast.error(error instanceof Error ? error.message : "Failed to set campaign as active");
				return;
			}
		}
	};

	useEffect(() => {
		if (!emailAccountsInitialized) {
			setIsLoading(true);
			return;
		}
		const accs = accountsByCampaignId[selectedCampaign.id];
		if (accs === undefined) {
			// Need to fetch accounts because they are not yet fetched
			fetchAndStoreAccountsByCampaignId(selectedCampaign.id);
			setIsLoading(true);
		} else if (accs === null) {
			// Currently fetching accounts
			setIsLoading(true);
		} else {
			// Accounts have been fetched
			const newIds = accs.map(a => a.email_account_id).sort();
			setSelectedAccountIds(newIds);
			setIsLoading(false);
		}
	}, [JSON.stringify(accountsByCampaignId[selectedCampaign.id]), emailAccountsInitialized]);

	const saveTimeoutRef = useRef<NodeJS.Timeout>();

	useEffect(() => {
		return () => {
			// Cleanup timeout on unmount
			if (saveTimeoutRef.current) {
				clearTimeout(saveTimeoutRef.current);
			}
		};
	}, []);

	useEffect(() => {
		if (!emailAccountsInitialized) {
			return;
		}
		const accs = accountsByCampaignId[selectedCampaign.id];
		if (accs === undefined) {
			return;
		} else if (accs === null) {
			// Currently fetching accounts
			return;
		}
		// Accounts have been fetched
		const newIds = accs.map(a => a.email_account_id).sort();
		setIsDirty(JSON.stringify(newIds) !== JSON.stringify(selectedAccountIds.sort()));
	}, [JSON.stringify(accountsByCampaignId[selectedCampaign.id]), emailAccountsInitialized, selectedAccountIds]);

	return (
		<div className="flex w-full flex-col">
			<div className="flex w-full flex-col">
				{/* Campaign editor header with navigation and controls */}
				<CampaignEditorHeader
					selectedCampaign={selectedCampaign}
					activeCampaignStep={activeCampaignStep}
					selectedAccountIds={selectedAccountIds}
					onStepChange={handleNextStep}
					saveCallback={async () => {
						setIsSaving(true);
						// Clear any pending auto-save to prevent duplicate saving
						if (saveTimeoutRef.current) {
							clearTimeout(saveTimeoutRef.current);
							saveTimeoutRef.current = undefined;
						}
						// Set timestamp of manual save to prevent auto-save from firing too soon after
						manualSaveTimestampRef.current = Date.now();
						// Manual save should show notification (non-silent)
						await updateEmailAccountMembershipsForCampaign(selectedCampaign.id, selectedAccountIds, false);
						setIsSaving(false);
						setIsDirty(false);
					}}
				/>

				{!emailAccountsInitialized ? (
					<div className="flex h-full items-center justify-center">
						<Spinner />
					</div>
				) : (
					<SelectEmailAccounts
						selectedCampaign={selectedCampaign}
						selectedAccountIds={isLoading ? null : selectedAccountIds}
						onSelectedAccountsChange={setSelectedAccountIds}
					/>
				)}
			</div>
		</div>
	);
};

export default SelectEmailsEditor;
