"use client";

import ActiveIcon from "@/assets/icons/svgs/status/active.svg?react";
import DraftIcon from "@/assets/icons/svgs/status/draft.svg?react";
import PausedIcon from "@/assets/icons/svgs/status/paused.svg?react";
import PlaneCheckIcon from "@/assets/icons/svgs/status/plane-check.svg?react";
import { CampaignStatus } from "@/types/campaign";

interface StatusIndicatorProps {
	status: CampaignStatus;
	className?: string;
	showText?: boolean;
}

export function StatusIndicator({ status, className, showText = true }: StatusIndicatorProps) {
	const getStatusIcon = () => {
		switch (status) {
			case "active":
				return <ActiveIcon className="h-3.5 w-3.5 text-[#34C759]" />;
			case "draft":
				return <DraftIcon className="h-3.5 w-3.5 text-[#666666]" />;
			case "paused":
				return <PausedIcon className="h-3.5 w-3.5 text-[#FF9500]" />;
			case "completed":
				return <PlaneCheckIcon className="h-3.5 w-3.5 text-[#666666]" />;

			default:
				return null;
		}
	};

	return (
		<div className={`flex-1-0-0 flex items-center gap-2 ${className}`}>
			<div className="h-[14px] w-[14px]">{getStatusIcon()}</div>
			{showText && (
				<span
					className={`text-body-base leading-body-base ${
						status === "draft" ? "text-(--label-label-muted) font-normal" : "text-(--label-label-title) font-medium"
					}`}>
					{status.charAt(0).toUpperCase() + status.slice(1)}
				</span>
			)}
		</div>
	);
}
