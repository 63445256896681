import { TThreadWithMessagesAndAccounts } from "@za-zu/types";
import { useEffect, useRef, useState, useCallback } from "react";
import { EmailHandlers, EmailState, NavigationDirection } from "../components/EmailModal/types";

export const useEmailState = (
	thread: TThreadWithMessagesAndAccounts | null,
	onClose: () => void,
	onNavigateThread: (direction: NavigationDirection) => void,
) => {
	const isInitialLoad = useRef(true);
	const hasSetInitialState = useRef(false);
	const [emailState, setEmailState] = useState<EmailState>({
		expandedIds: new Set(),
		activeId: null,
		replyingToId: null,
		messageText: "",
		isLoading: false,
		hoveredId: null,
		sendingMessages: new Set<string>(),
	});

	const updateEmailState = useCallback(
		(updates: Partial<EmailState> | ((current: EmailState) => Partial<EmailState>)) => {
			if (typeof updates === "function") {
				setEmailState(current => ({
					...current,
					...updates(current),
				}));
			} else {
				setEmailState(current => ({ ...current, ...updates }));
			}
		},
		[],
	);

	const handlers: EmailHandlers = {
		onClose,
		navigateThread: direction => {
			onNavigateThread(direction);
		},
		handleStartReply: id => {
			if (emailState.replyingToId === id) return;

			if (!emailState.expandedIds.has(id)) {
				const newExpandedIds = new Set(emailState.expandedIds);
				newExpandedIds.add(id);
				updateEmailState({
					expandedIds: newExpandedIds,
					activeId: id,
					replyingToId: id,
				});
			} else {
				updateEmailState({ replyingToId: id });
			}
		},
		handleEmailClick: id => {
			setEmailState(current => ({
				...current,
				expandedIds: new Set([id]),
				activeId: id,
			}));
		},
		handleNavigateToAdjacentThread: direction => {
			onNavigateThread(direction);
		},
	};

	// Effect for setting initial state
	useEffect(() => {
		if (!thread || hasSetInitialState.current) return;
		const lastMessage = thread.messages[thread.messages.length - 1];
		if (!lastMessage) {
			throw new Error(`Thread has no messages (EID: ${thread.e_id})`);
		}
		updateEmailState({
			activeId: lastMessage.e_id,
			hoveredId: lastMessage.e_id,
			expandedIds: new Set([lastMessage.e_id]),
		});
		hasSetInitialState.current = true;
	}, [thread, updateEmailState]);

	// Reset hasSetInitialState when thread changes
	useEffect(() => {
		hasSetInitialState.current = false;
	}, [thread?.e_id]);

	return {
		emailState,
		updateEmailState,
		handlers,
		isInitialLoad,
	};
};
