import { Range } from "@tiptap/core";
import { ResolvedPos } from "@tiptap/pm/model";

export interface Trigger {
	char: string;
	$position: ResolvedPos;
}

export type SuggestionMatch = {
	range: Range;
	query: string;
	text: string;
} | null;

export function findSuggestionMatch(config: Trigger): SuggestionMatch {
	const { char, $position } = config;

	const text = $position.nodeBefore?.isText && $position.nodeBefore.text;
	if (!text) {
		return null;
	}
	const pNode = $position.parent;
	/**
	 * This is the position of the cursor relative to the parent node (the p tag)
	 */
	const relativeEnd = $position.parentOffset;
	// Find the last slash that is before our current position, but also not an inactive slash node.
	let query = "";
	/** Use this to track the starting position of the current descendant node. It is essentially equivalent to the sum of
	 * the sizes of all the nodes that have been processed so far.
	 */
	let baseIndex = 0;
	let lastNodeSize = 0;
	let firstSlashIndex: number | null = null;

	pNode.descendants(node => {
		baseIndex += lastNodeSize;
		lastNodeSize = node.nodeSize;
		if (baseIndex > relativeEnd) {
			// Done, so finish
			return false;
		}
		if (node.type.name === "inactiveSlash") {
			// This is an inactive slash node, so we need to skip it, but we need to add it to the query so it would show up
			// in the suggestion list as needed.
			return false;
		}
		if (!node.text) {
			// No text in this node, so we can just skip it.
			return true;
		}
		// Grab the first index of a slash in the current node's text.
		const nextRelativeIndex = node.text.indexOf(char);
		if (nextRelativeIndex === -1) {
			// No slash in this node, so we don't need to process it extensively. We just need to grab whatever is before the
			// cursor to add it to the query. It's ok to do this even if we aren't in the node, because once we do find the
			// next valid slash, the query will be reset to an empty string.
			query += node.text.slice(0, relativeEnd - baseIndex);
			return true;
		}
		// Must have found a slash, so we need to update the first slash index.
		firstSlashIndex = nextRelativeIndex + baseIndex;
		query = node.text.slice(nextRelativeIndex + char.length, relativeEnd - baseIndex);
		return true;
	});
	if (firstSlashIndex === null) {
		return null;
	}
	if (firstSlashIndex >= relativeEnd) {
		return null;
	}
	const from = $position.pos - (query.length + char.length);
	const to = $position.pos;
	// If the $position is located within the matched substring, return that range
	if (from < $position.pos) {
		return {
			range: {
				from,
				to,
			},
			query,
			text: `${char}${query}`,
		};
	}

	return null;
}
