import { Button } from "@/components/ui/button";
import { CardTitle } from "@/components/ui/card";

interface Props {
	isImporting: boolean;
	onImport: () => void;
}

export function ColumnMappingHeader({ isImporting, onImport }: Props) {
	return (
		<div className="flex flex-col items-start gap-2 self-stretch">
			<div className="border-background-bg-border w-155 border-b pb-8">
				<div className="flex items-start justify-between">
					<div className="flex flex-col gap-2">
						<CardTitle className="text-label-title text-xl font-semibold">Map your CSV columns</CardTitle>
						<p className="font-geist text-body-base leading-body-base text-label-label-muted font-normal">
							Fine tune your fields and ensure your data is correctly imported.
						</p>
						<div className="flex items-center gap-2">
							<div className="bg-label-label-link h-2 w-2 rounded-full" />
							<span className="font-geist text-mini leading-mini text-label-label-link font-medium">
								Auto-mapped columns
							</span>
						</div>
					</div>
					<Button
						className="bg-background-bg-brand text-label-label-control hover:bg-background-bg-brand/90"
						onClick={onImport}
						disabled={isImporting}>
						{isImporting ? "Importing..." : "Import leads"}
					</Button>
				</div>
			</div>
		</div>
	);
}
