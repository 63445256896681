import React from "react";
import { Table, TableBody, TableHeader, TableRow, TableCell, TableHeaderRow, TableHead } from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ProgressiveBlur } from "@/components/ui/progressive-blur";
import { TABLE_HEADERS } from "./constants";

/**
 * Skeleton loader for campaign rows with staggered animation
 */
const CampaignRowSkeleton: React.FC<{ index: number }> = ({ index }) => {
	// Stagger animation by 50ms per row
	const staggerDelay = index * 0.05;

	return (
		<TableRow className="border-background-bg-divider border-b-[0.5px]">
			{/* Campaign title */}
			<TableCell className="w-[20%]">
				<Skeleton className="h-5 w-40" delay={staggerDelay} duration={0.8} />
			</TableCell>

			{/* Status indicator */}
			<TableCell className="w-[10%]">
				<div className="flex items-center gap-2">
					<Skeleton className="h-2 w-2 rounded-full" delay={staggerDelay} duration={0.8} />
					<Skeleton className="h-5 w-16" delay={staggerDelay} duration={0.8} />
				</div>
			</TableCell>

			{/* Sent/Contacted metrics */}
			<TableCell className="w-[11.67%]">
				<div className="flex h-10 flex-1 items-center justify-between py-1">
					<div className="flex items-center gap-2">
						<Skeleton className="h-3.5 w-3.5 rounded-full" delay={staggerDelay} duration={0.8} />
						<Skeleton className="h-5 w-16" delay={staggerDelay} duration={0.8} />
					</div>
					<Skeleton className="h-4 w-8" delay={staggerDelay} duration={0.8} />
				</div>
			</TableCell>

			{/* Opened metrics */}
			<TableCell className="w-[11.67%]">
				<div className="flex items-center justify-between gap-1">
					<Skeleton className="h-5 w-12" delay={staggerDelay} duration={0.8} />
					<Skeleton className="h-4 w-12" delay={staggerDelay} duration={0.8} />
				</div>
			</TableCell>

			{/* Replied/Clicked metrics */}
			<TableCell className="w-[11.67%]">
				<div className="flex items-center justify-between gap-1">
					<Skeleton className="h-5 w-12" delay={staggerDelay} duration={0.8} />
					<Skeleton className="h-4 w-12" delay={staggerDelay} duration={0.8} />
				</div>
			</TableCell>

			{/* Deliverability metrics */}
			<TableCell className="rightmost-cell w-[11.67%]" noBorder>
				<div className="flex items-center justify-between">
					<Skeleton className="h-5 w-16" delay={staggerDelay} duration={0.8} />
					<Skeleton className="h-6 w-6 rounded-[5px]" delay={staggerDelay} duration={0.8} />
				</div>
			</TableCell>
		</TableRow>
	);
};

/**
 * Table header component for the skeleton
 */
const CampaignTableHeaderSkeleton = () => (
	<TableHeaderRow>
		{TABLE_HEADERS.map((header, index) => (
			<TableHead
				key={header.label}
				className={`w-[${header.width}]`}
				noBorder={index === TABLE_HEADERS.length - 1}
				icon={
					header.hasDropdown ? (
						<div className="flex items-center gap-1">
							<Skeleton className="h-3.5 w-3.5 rounded-sm" />
							<Skeleton className="h-4 w-16" />
							<Skeleton className="h-4 w-4" />
						</div>
					) : header.isImage ? (
						<Skeleton className="h-3.5 w-3.5" />
					) : (
						<Skeleton className="h-3.5 w-3.5" />
					)
				}
				label={
					header.hasDropdown ? null : (
						<div className="flex items-center gap-1">
							<Skeleton className="h-4 w-16" />
						</div>
					)
				}
			/>
		))}
	</TableHeaderRow>
);

interface CampaignListSkeletonProps {
	numRows?: number;
}

/**
 * Full skeleton component for the CampaignsList that can be used during loading
 */
export const CampaignListSkeleton: React.FC<CampaignListSkeletonProps> = ({ numRows = 5 }) => {
	return (
		<div className="relative">
			<ScrollArea className="h-[calc(100vh-100px)]">
				<div className="campaigns-list-table w-full overflow-hidden">
					<Table>
						<TableHeader>
							<CampaignTableHeaderSkeleton />
						</TableHeader>
						<TableBody>
							{/* Show skeleton rows while loading */}
							{[...Array(numRows)].map((_, index) => (
								<CampaignRowSkeleton key={`skeleton-${index}`} index={index} />
							))}
						</TableBody>
					</Table>
					<div style={{ borderBottom: "0.5px solid var(--background-bg-divider, #E3E3E3)" }} />
				</div>
			</ScrollArea>
			<ProgressiveBlur
				orientation="vertical"
				className="pointer-events-none absolute bottom-0 h-16 w-full"
				style={{ background: "linear-gradient(to top, white, transparent)" }}
			/>
		</div>
	);
};

export default CampaignListSkeleton;
