import { z } from "zod";
import { LeadThreadCategory } from "@za-zu/types";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { CheckmarkSVG } from "@/assets/icons/svgs";

type BaseColor = {
	bg: string;
	text: string;
	border: string;
};

type CategoryStyle = {
	default: {
		bgColor: string;
		textColor: string;
		border?: string;
	};
	rowActive?: {
		bgColor?: string;
		textColor?: string;
		border?: string;
	};
	hover?: {
		bgColor?: string;
		textColor?: string;
		border?: string;
	};
};

type Category = z.infer<typeof LeadThreadCategory>;

/**
 * Creates complete styles for a category based on base colors and opacities
 * @param bgColor - Background color in display-p3 format
 * @param textColor - Text color in display-p3 format
 * @param borderColor - Border color in display-p3 format
 * @param options - Optional configuration for opacities
 * @returns Complete CategoryStyle object
 */
const createCategoryStyle = (
	bgColor: string,
	textColor: string,
	borderColor: string,
	options?: {
		bgOpacity?: number;
		defaultBorderOpacity?: number;
		activeBorderOpacity?: number;
		useTransparentBg?: boolean;
	},
): CategoryStyle => {
	const {
		bgOpacity = 1,
		defaultBorderOpacity = 0.08,
		activeBorderOpacity = 0.2,
		useTransparentBg = false,
	} = options || {};

	return {
		default: {
			bgColor: useTransparentBg ? "bg-transparent" : `bg-[color(display-p3_${bgColor}/${bgOpacity})]`,
			textColor: `text-[color(display-p3_${textColor})]`,
			border: `border border-[color(display-p3_${borderColor}/${defaultBorderOpacity})]`,
		},
		rowActive: {
			border: `border border-[color(display-p3_${borderColor}/${activeBorderOpacity})]`,
		},
		hover: {
			border: `border-2 border-[color(display-p3_${borderColor}/${activeBorderOpacity})]`,
		},
	};
};

// Define base colors for each category
const CATEGORY_COLORS: Record<Category, BaseColor> = {
	interested: {
		bg: "0.7804_0.9412_0.8745",
		text: "0.1786_0.6032_0.4272",
		border: "0.1804_0.6039_0.4275",
	},
	not_interested: {
		bg: "1_0.893_0.9091",
		text: "0.8196_0.3627_0.4236",
		border: "0.8196_0.3608_0.4235",
	},
	do_not_contact: {
		bg: "0.9686_0.8072_0.8234",
		text: "0.8153_0.3117_0.3788",
		border: "0.8157_0.3098_0.3804",
	},
	out_of_office: {
		bg: "0_0_0", // Not used as we set transparent background
		text: "0.51_0.5417_0.5779",
		border: "0_0_0",
	},
	wrong_person: {
		bg: "0.9544_0.9341_0.7108",
		text: "0.7409_0.3625_0.3426",
		border: "0.7412_0.3608_0.3412",
	},
	meeting_requested: {
		bg: "0.8667_0.8275_0.9725",
		text: "0.4672_0.4024_0.6318",
		border: "0.4672_0.4024_0.6318",
	},
	info_request: {
		bg: "0.9294_0.9098_0.9843",
		text: "0.4672_0.4024_0.6318",
		border: "0.4672_0.4024_0.6318",
	},
	meeting_booked: {
		bg: "0.8159_0.911_1",
		text: "0.2549_0.5255_0.7765",
		border: "0.2549_0.5255_0.7765",
	},
};

// Generate all styles based on base colors
const CATEGORY_STYLES: Record<Category, CategoryStyle> = {
	interested: createCategoryStyle(
		CATEGORY_COLORS.interested.bg,
		CATEGORY_COLORS.interested.text,
		CATEGORY_COLORS.interested.border,
	),
	not_interested: createCategoryStyle(
		CATEGORY_COLORS.not_interested.bg,
		CATEGORY_COLORS.not_interested.text,
		CATEGORY_COLORS.not_interested.border,
	),
	do_not_contact: createCategoryStyle(
		CATEGORY_COLORS.do_not_contact.bg,
		CATEGORY_COLORS.do_not_contact.text,
		CATEGORY_COLORS.do_not_contact.border,
	),
	out_of_office: createCategoryStyle(
		CATEGORY_COLORS.out_of_office.bg,
		CATEGORY_COLORS.out_of_office.text,
		CATEGORY_COLORS.out_of_office.border,
		{ useTransparentBg: true, activeBorderOpacity: 0.13 },
	),
	wrong_person: createCategoryStyle(
		CATEGORY_COLORS.wrong_person.bg,
		CATEGORY_COLORS.wrong_person.text,
		CATEGORY_COLORS.wrong_person.border,
	),
	meeting_requested: createCategoryStyle(
		CATEGORY_COLORS.meeting_requested.bg,
		CATEGORY_COLORS.meeting_requested.text,
		CATEGORY_COLORS.meeting_requested.border,
	),
	info_request: createCategoryStyle(
		CATEGORY_COLORS.info_request.bg,
		CATEGORY_COLORS.info_request.text,
		CATEGORY_COLORS.info_request.border,
	),
	meeting_booked: createCategoryStyle(
		CATEGORY_COLORS.meeting_booked.bg,
		CATEGORY_COLORS.meeting_booked.text,
		CATEGORY_COLORS.meeting_booked.border,
	),
} as const;

interface ThreadCategoryBadgeProps {
	category: Category;
	className?: string;
	isRowActive?: boolean;
}

export const ThreadCategoryBadge: React.FC<ThreadCategoryBadgeProps> = ({ category, className, isRowActive }) => {
	const [isHovered, setIsHovered] = useState(false);
	const style = CATEGORY_STYLES[category];

	// Get the appropriate style based on state
	// Direct hover takes precedence over row active state
	const currentStyle = isHovered
		? { ...style.default, ...style.hover }
		: isRowActive && !isHovered // Only apply rowActive if not directly hovered
			? { ...style.default, ...style.rowActive }
			: style.default;

	const isMeetingBooked = category === "meeting_booked";

	return (
		<div
			className={cn(
				"flex items-center justify-center rounded-lg px-3 py-1.5",
				// Set gap to 4px for meeting_booked, default to 8px for others
				isMeetingBooked ? "gap-1" : "gap-2",
				// Use ease timing function and 150ms duration as per animation guidelines
				"ease transition-all duration-150",
				currentStyle.bgColor,
				currentStyle.textColor,
				currentStyle.border,
				className,
			)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}>
			{isMeetingBooked && <CheckmarkSVG className="h-3 w-3 flex-shrink-0" />}
			<span>
				{category
					.split("_")
					.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
					.join(" ")}
			</span>
		</div>
	);
};

export default ThreadCategoryBadge;
