import { CoachFeedback, EmailCoachRule } from "../types";
import { getPlainTextFromHtml, extractSentences } from "../utils";

// Hemingway thresholds for sentence complexity
const HARD_MIN_WORDS = 10;
const HARD_MAX_WORDS = 14;
const VERY_HARD_WORDS = 14;

// Helper to count words in a string more accurately
const countWords = (text: string): number => {
	// Strip HTML tags
	const plainText = text.replace(/<[^>]*>?/gm, "");
	// Trim and split by whitespace, count non-empty words
	return plainText
		.trim()
		.split(/\s+/)
		.filter(word => word.length > 0).length;
};

// Analyze text for sentence complexity using Hemingway's guidelines
const analyzeSentenceLength = (content: string): CoachFeedback[] => {
	// Extract plain text
	const plainText = getPlainTextFromHtml(content);

	// Use our improved sentence extraction
	const sentenceResult = extractSentences(plainText);
	const sentences = sentenceResult.sentences;
	const isComplete = sentenceResult.isComplete;

	// Check each sentence
	const feedback: CoachFeedback[] = [];

	sentences.forEach((sentence, index) => {
		// Only analyze complete sentences
		if (!isComplete[index]) {
			return;
		}

		const wordCount = countWords(sentence);

		// Only flag sentences that are truly too complex
		if (wordCount >= VERY_HARD_WORDS) {
			feedback.push({
				id: `very-hard-sentence-${index}`,
				message: `The sentence is very complex — consider breaking it into smaller sentences.`,
				severity: "info",
				description: sentence, // Use description field to store the sentence instead of elementId
			});
		} else if (wordCount >= HARD_MIN_WORDS && wordCount < HARD_MAX_WORDS) {
			feedback.push({
				id: `hard-sentence-${index}`,
				message: `The sentence is somewhat complex — consider simplifying.`,
				severity: "info",
				description: sentence, // Use description field to store the sentence instead of elementId
			});
		}
	});

	return feedback;
};

// Export the rule
export const sentenceLengthRule: EmailCoachRule = {
	id: "sentence-length",
	name: "Sentence Length",
	description: "Identifies sentences that may be too complex for easy comprehension (using Hemingway standards)",
	analyze: (content: string) => analyzeSentenceLength(content),
};
