import React, { useEffect } from "react";
import { Link } from "wouter";
import { MenuItem } from "@/types/globals";
import { useGlobalFilterStore, useInboxStore } from "@/store";
import { OrganizationSwitcher, useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import { usePathname } from "wouter/use-browser-location";
import SearchBar from "@/components/SearchBar";
import IconSVG from "@/components/IconSVG/IconSVG";
import CampaignIcon from "@/assets/icons/svgs/campaign.svg?react";
import EmailIcon from "@/assets/icons/svgs/email.svg?react";
import InboxRegularIcon from "@/assets/icons/svgs/inbox.svg?react";
import InboxUnreadIcon from "@/assets/icons/svgs/inbox-unread.svg?react";
import CanaryBirdIcon from "@/assets/icons/svgs/canary-bird.svg?react";
import SupportIcon from "@/assets/icons/svgs/support.svg?react";
import UserIcon from "@/assets/icons/svgs/user.svg?react";
import { clearAllAppCaches } from "@/utils/cacheManagement";
import {
	Sidebar,
	SidebarContent,
	SidebarHeader,
	SidebarMenu,
	SidebarMenuButton,
	SidebarSeparator,
	SidebarFooter,
	useSidebar,
} from "@/components/ui/sidebar";
import { SidebarToggleButton } from "@/components/ui/sidebar-toggle-button";
import { cn } from "@/lib/utils";
import { Tooltip, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { flaskPath } from "@/assets/icons/paths";
import UserProfile from "@/components/User/user-profile";
import { Spinner } from "../ui/spinner";
import { Input } from "../ui/input";
import clsx from "clsx";
import { show } from "@intercom/messenger-js-sdk";
import { ClientsPage } from "@/components/Clients";

const DevToolsPopover = () => {
	const { getToken } = useAuth();

	const [loadingToken, setLoadingToken] = React.useState(false);
	const [copiedToken, setCopiedToken] = React.useState(false);
	const [cachedToken, setToken] = React.useState("");

	const { user } = useUser();
	const [copiedUserId, setCopiedUserId] = React.useState(false);

	const { organization } = useOrganization();
	const [copiedOrgId, setCopiedOrgId] = React.useState(false);

	const [clearingCache, setClearingCache] = React.useState(false);

	return (
		<div className="space-y-4">
			<h1>Dev utils</h1>
			<div className="flex flex-col gap-2 rounded-md bg-gray-100 p-2">
				<h2 className="text-label-muted cursor-default">Current User ID</h2>
				<Input
					value={user?.id}
					className="text-muted-foreground"
					aria-disabled
					onChange={e => e.preventDefault()}
					onClick={e => (e.target as HTMLInputElement).select()}
				/>
				<Button
					size="sm"
					onClick={async () => {
						if (user?.id) navigator.clipboard.writeText(user.id);
						setCopiedUserId(true);
						setTimeout(() => setCopiedUserId(false), 1500);
					}}
					className="bg-brand-primary hover:bg-brand-primary/90">
					{!user ? <Spinner size="sm" /> : copiedUserId ? "Copied!" : "Copy"}
				</Button>
			</div>
			<div className="flex flex-col gap-2 rounded-md bg-gray-100 p-2">
				<h2 className="text-label-muted cursor-default">Current Organization ID</h2>
				<Input
					value={organization?.id}
					className="text-muted-foreground"
					aria-disabled
					onChange={e => e.preventDefault()}
					onClick={e => (e.target as HTMLInputElement).select()}
				/>
				<Button
					size="sm"
					onClick={async () => {
						if (organization?.id) navigator.clipboard.writeText(organization.id);
						setCopiedOrgId(true);
						setTimeout(() => setCopiedOrgId(false), 1500);
					}}
					className="bg-brand-primary hover:bg-brand-primary/90">
					{!organization ? <Spinner size="sm" /> : copiedOrgId ? "Copied!" : "Copy"}
				</Button>
			</div>
			<div className="flex flex-col gap-2 rounded-md bg-gray-100 p-2">
				<h2 className="text-label-muted cursor-default">Generate a long-lived Clerk Authorization token (7 days)</h2>
				<Button
					size="sm"
					onClick={async () => {
						setLoadingToken(true);
						const token = cachedToken ? cachedToken : await getToken({ template: "api-testing" });
						if (token) {
							navigator.clipboard.writeText(token);
							setCopiedToken(true);
							setToken(token);
						}
						setLoadingToken(false);
						setTimeout(() => setCopiedToken(false), 1500);
					}}
					disabled={loadingToken}
					className={clsx("bg-brand-primary hover:bg-brand-primary/90", loadingToken && "opacity-50")}>
					{loadingToken ? <Spinner size="sm" /> : copiedToken ? "Copied!" : "Generate and copy"}
				</Button>
			</div>
			<div className="border-border mt-4 border-t pt-4">
				<h4 className="text-medium mb-1 font-medium">Clear application cache</h4>
				<p className="text-muted-foreground mb-2 text-sm">
					<strong>IMPORTANT:</strong> Use this after a database reset. The page will automatically reload to ensure a
					complete reset of all components.
				</p>

				<button
					className={cn("bg-destructive text-destructive-foreground w-full rounded-md px-3 py-2 text-sm")}
					onClick={() => {
						setClearingCache(true);
						setTimeout(() => {
							clearAllAppCaches();
						}, 300);
					}}>
					{clearingCache ? "Clearing cache & reloading..." : "Clear and reload"}
				</button>
			</div>
		</div>
	);
};

const CampaignIconWithState = ({ isActive }: { isActive?: boolean }) => (
	<CampaignIcon className={cn("h-4 w-4 shrink-0", isActive ? "text-[#1A1A1A]" : "text-label-faint")} />
);

const EmailIconWithState = ({ isActive }: { isActive?: boolean }) => (
	<EmailIcon className={cn("h-4 w-4 shrink-0", isActive ? "text-[#1A1A1A]" : "text-label-faint")} />
);

const InboxIconWithState = ({ isActive, showBadge }: { isActive?: boolean; showBadge?: boolean }) => {
	const Icon = showBadge ? InboxUnreadIcon : InboxRegularIcon;
	return <Icon className={cn("h-4 w-4 shrink-0", isActive ? "text-[#1A1A1A]" : "text-label-faint")} />;
};

const CanaryBirdIconWithState = ({ isActive }: { isActive?: boolean }) => (
	<CanaryBirdIcon className={cn("h-4 w-4 shrink-0", isActive ? "text-[#1A1A1A]" : "text-label-faint")} />
);

const SupportIconWithState = ({ isActive }: { isActive?: boolean }) => (
	<SupportIcon className={cn("h-4 w-4 shrink-0", isActive ? "text-[#1A1A1A]" : "text-label-faint")} />
);

// Rename this to CustomSidebarMenuItem to avoid conflict with imported component
const CustomSidebarMenuItem = React.forwardRef<HTMLLIElement, React.ComponentProps<"li">>(
	({ className, ...props }, ref) => (
		<li ref={ref} data-sidebar="menu-item" className={cn("group/menu-item relative", className)} {...props} />
	),
);
CustomSidebarMenuItem.displayName = "CustomSidebarMenuItem";

// Enhance the animated menu item for better animation when menu items appear/disappear
// This can be used for items that need entrance/exit animations
const AnimatedSidebarMenuItem = React.forwardRef<HTMLLIElement, React.ComponentProps<"li">>(
	({ className, ...props }, ref) => (
		<li
			ref={ref}
			data-sidebar="menu-item"
			className={cn(
				"group/menu-item relative",
				// Add enter animation with staggered delay
				"animate-in fade-in slide-in-from-left-2 duration-300 ease-out",
				// For reduced motion, only use fade without movement
				"motion-reduce:slide-in-from-left-0",
				className,
			)}
			{...props}
		/>
	),
);
AnimatedSidebarMenuItem.displayName = "AnimatedSidebarMenuItem";

const InboxMenuItem = ({ currentPath, state }: { currentPath: string; state: string }) => {
	const getPrimary = useInboxStore(state => state.getPrimary);
	const primaryThreads = getPrimary();
	const inboxCount = primaryThreads.length;

	const isActive = currentPath === "/dashboard/inbox" || currentPath.startsWith("/dashboard/inbox/");
	const showBadge = inboxCount > 0;

	return (
		<AnimatedSidebarMenuItem>
			<Tooltip>
				<TooltipTrigger asChild>
					<SidebarMenuButton asChild>
						<Link
							href="/dashboard/inbox"
							className={cn(
								"relative flex h-8 items-center gap-2 rounded-[6px] px-2",
								"group-data-[collapsible=icon]:w-8 group-data-[collapsible=icon]:justify-center",
								isActive
									? "bg-[#FFFFFF]! isolate border-[0.5px] border-[color(display-p3_0.851_0.851_0.851)] shadow-[0px_1px_1px_0px_color(display-p3_0_0_0/0.06),0px_4px_4px_-1px_color(display-p3_0_0_0/0.02)]"
									: "",
								// Add subtle hover transition
								"transition-colors duration-200 ease-out motion-reduce:transition-none",
							)}>
							<InboxIconWithState isActive={isActive} showBadge={showBadge} />
							<span className="group-data-[collapsible=icon]:hidden">
								{`Inbox${inboxCount > 0 ? ` (${inboxCount})` : ""}`}
							</span>
						</Link>
					</SidebarMenuButton>
				</TooltipTrigger>
				<TooltipContent side="right" className={cn("hidden", state === "collapsed" && "block")}>
					Inbox
				</TooltipContent>
			</Tooltip>
		</AnimatedSidebarMenuItem>
	);
};

const NewSidebar: React.FC = () => {
	const { searchText, setSearchText } = useGlobalFilterStore();
	const currentPath = usePathname();
	const { state } = useSidebar();
	const [devUtilsOpen, setDevUtilsOpen] = React.useState(false);

	useEffect(() => {
		setSearchText("");
	}, [currentPath, setSearchText]);

	const sidebarMenuItems: MenuItem[] = [
		{
			icon: <CampaignIconWithState />,
			label: "Campaigns",
			value: "campaigns",
		},
		{
			icon: <EmailIconWithState />,
			label: "Email Accounts",
			value: "email-accounts",
		},
		// Inbox is handled separately in the InboxMenuItem component for more efficient updates
	];

	return (
		<Sidebar collapsible="icon">
			<div className={cn("flex h-full flex-col", "space-y-6", "px-5", "group-data-[collapsible=icon]:px-2")}>
				<SidebarHeader className={cn("flex-col gap-4", "group-data-[collapsible=icon]:items-center")}>
					<div className={cn("flex items-center justify-between", "group-data-[collapsible=icon]:pt-[16px]")}>
						<div className={cn("flex-1", "group-data-[collapsible=icon]:hidden")}>
							<OrganizationSwitcher
								hidePersonal
								appearance={{
									elements: {
										rootBox: "flex-1",
										organizationSwitcherTrigger:
											"justify-start gap-2 py-1 pl-0 text-[#1A1A1A] text-[13px] font-medium leading-[18px] max-w-[210px]",
										organizationPreview: "gap-2 truncate",
										organizationPreviewTextContainer: "truncate",
										organizationPreviewMainIdentifier: "truncate",
										organizationPreviewAvatarBox: "w-7 h-7",
										organizationSwitcherTriggerIcon: "m-0",
									},
									layout: {
										shimmer: false,
									},
									variables: {
										colorPrimary: "#333",
									},
								}}>
								<OrganizationSwitcher.OrganizationProfilePage
									label="Clients"
									url="clients"
									labelIcon={<UserIcon className="h-4 w-4 shrink-0 text-current" />}>
									<ClientsPage />
								</OrganizationSwitcher.OrganizationProfilePage>
							</OrganizationSwitcher>
						</div>
						<SidebarToggleButton variant={state === "expanded" ? "expanded" : "collapsed"} />
					</div>
					<div className="w-full max-w-[234px] group-data-[collapsible=icon]:hidden">
						<SearchBar searchText={searchText} setSearchText={setSearchText} />
					</div>
				</SidebarHeader>

				<SidebarContent className="flex-1">
					<div className="space-y-4">
						<SidebarMenu>
							{sidebarMenuItems.map((item, index) => {
								if (item.value === "spacer") {
									return <SidebarSeparator key={`spacer-${index}`} />;
								}

								const isActive =
									currentPath === `/dashboard/${item.value}` || currentPath.startsWith(`/dashboard/${item.value}/`);

								const iconWithActiveState = React.cloneElement(item.icon as React.ReactElement, {
									isActive,
								});

								return (
									<AnimatedSidebarMenuItem key={item.value} style={{ animationDelay: `${index * 50}ms` }}>
										<Tooltip>
											<TooltipTrigger asChild>
												<SidebarMenuButton asChild>
													<Link
														href={`/dashboard/${item.value}`}
														className={cn(
															"relative flex h-8 items-center gap-2 rounded-[6px] px-2",
															"group-data-[collapsible=icon]:w-8 group-data-[collapsible=icon]:justify-center",
															isActive
																? "bg-[#FFFFFF]! isolate border-[0.5px] border-[color(display-p3_0.851_0.851_0.851)] shadow-[0px_1px_1px_0px_color(display-p3_0_0_0/0.06),0px_4px_4px_-1px_color(display-p3_0_0_0/0.02)]"
																: "",
															// Add subtle hover transition
															"transition-colors duration-200 ease-out motion-reduce:transition-none",
														)}>
														{iconWithActiveState}
														<span className="group-data-[collapsible=icon]:hidden">{item.label}</span>
													</Link>
												</SidebarMenuButton>
											</TooltipTrigger>
											<TooltipContent side="right" className={cn("hidden", state === "collapsed" && "block")}>
												{item.label}
											</TooltipContent>
										</Tooltip>
									</AnimatedSidebarMenuItem>
								);
							})}

							{/* Use the specialized InboxMenuItem for reactive updates */}
							<InboxMenuItem currentPath={currentPath} state={state} />
						</SidebarMenu>
					</div>
				</SidebarContent>

				<div className="list-none space-y-1">
					{import.meta.env.VITE_ENV === "development" && (
						<AnimatedSidebarMenuItem style={{ animationDelay: "300ms" }}>
							<Popover open={devUtilsOpen} onOpenChange={setDevUtilsOpen}>
								<Tooltip>
									<TooltipTrigger asChild>
										<PopoverTrigger asChild>
											<SidebarMenuButton asChild size="md">
												<div
													role="button"
													tabIndex={0}
													onClick={() => {
														setDevUtilsOpen(true);
													}}
													onKeyDown={e => {
														if (e.key === "Enter" || e.key === " ") {
															e.preventDefault();
															setDevUtilsOpen(true);
														}
													}}
													className={cn(
														"relative flex h-8 items-center gap-2 rounded-[6px] px-2",
														"group-data-[collapsible=icon]:w-8 group-data-[collapsible=icon]:justify-center",
														"cursor-pointer",
														"transition-colors duration-200 ease-out motion-reduce:transition-none",
													)}>
													<IconSVG
														path={flaskPath}
														width={16}
														height={16}
														viewBox="0 0 256 256"
														useStroke={false}
														className="text-label-faint shrink-0 stroke-current"
													/>
													<span className="group-data-[collapsible=icon]:hidden">Dev utils</span>
												</div>
											</SidebarMenuButton>
										</PopoverTrigger>
									</TooltipTrigger>
									<TooltipContent side="right" className={cn("hidden", state === "collapsed" && "block")}>
										Dev utils
									</TooltipContent>
								</Tooltip>
								<PopoverContent className="w-[274px] p-4" side="top">
									<DevToolsPopover />
								</PopoverContent>
							</Popover>
						</AnimatedSidebarMenuItem>
					)}

					<AnimatedSidebarMenuItem style={{ animationDelay: "350ms" }}>
						<Tooltip>
							<TooltipTrigger asChild>
								<SidebarMenuButton asChild size="md">
									<div
										role="button"
										tabIndex={0}
										onClick={() => {
											if (import.meta.env.VITE_INTERCOM_APP_ID) {
												show();
											}
										}}
										onKeyDown={e => {
											if (e.key === "Enter" || e.key === " ") {
												e.preventDefault();
												if (window.Intercom) {
													window.Intercom("update", {
														hide_default_launcher: false,
													});
													window.Intercom?.("show");
												}
											}
										}}
										className={cn(
											"relative flex h-8 items-center gap-2 rounded-[6px] px-2",
											"group-data-[collapsible=icon]:w-8 group-data-[collapsible=icon]:justify-center",
											"cursor-pointer",
											"transition-colors duration-200 ease-out motion-reduce:transition-none",
										)}>
										<SupportIconWithState />
										<span className="group-data-[collapsible=icon]:hidden">Support</span>
									</div>
								</SidebarMenuButton>
							</TooltipTrigger>
							<TooltipContent side="right" className={cn("hidden", state === "collapsed" && "block")}>
								Support
							</TooltipContent>
						</Tooltip>
					</AnimatedSidebarMenuItem>

					<AnimatedSidebarMenuItem style={{ animationDelay: "400ms" }}>
						<Tooltip>
							<TooltipTrigger asChild>
								<SidebarMenuButton asChild size="md">
									<a
										href="https://forum.za-zu.com"
										target="_blank"
										rel="noopener noreferrer"
										className={cn(
											"relative flex h-8 items-center gap-2 rounded-[6px] px-2",
											"group-data-[collapsible=icon]:w-8 group-data-[collapsible=icon]:justify-center",
											"transition-colors duration-200 ease-out motion-reduce:transition-none",
										)}>
										<CanaryBirdIconWithState />
										<span className="group-data-[collapsible=icon]:hidden">Community forum</span>
									</a>
								</SidebarMenuButton>
							</TooltipTrigger>
							<TooltipContent side="right" className="group-data-[state=expanded]:hidden">
								Join our forum where you can hang out with fellow za-zu users and ask them questions. It&apos;s like an
								AI but with people.
							</TooltipContent>
						</Tooltip>
					</AnimatedSidebarMenuItem>
				</div>

				<SidebarFooter className={cn("mt-auto", "group-data-[collapsible=icon]:items-center")}>
					<UserProfile />
				</SidebarFooter>
			</div>
		</Sidebar>
	);
};

export default NewSidebar;
