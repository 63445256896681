import { useCampaignStore, useEmailAccountsStore, useInboxStore } from "@/store";
import { CampaignProps } from "@/types/campaign";
import { TMessageNewRow, TThreadWithMessagesAndAccounts } from "@za-zu/types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useEmailState } from "../../hooks/useEmailState";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "../ui/dialog";
import { TooltipProvider } from "../ui/tooltip";
import { EmailModalHeader } from "./EmailModalHeader";
import { EmailThreadItem } from "./EmailThreadItem";
import { NavigationDirection } from "./types";
import { useEmailKeyboardShortcuts } from "./useEmailKeyboardShortcuts";

// Thread modal props
export type ThreadModalProps = {
	isOpen: boolean;
	onClose: () => void;
	thread: TThreadWithMessagesAndAccounts;
	onNavigateToAdjacentThread: (direction: "previous" | "next") => boolean;
	onUnarchive: () => void;
	onArchive: () => void;
	archivingThreadId: string | null;
	toggleThreadSeenStatus?: (thread: TThreadWithMessagesAndAccounts) => Promise<void>;
};

const EmailModal: React.FC<ThreadModalProps> = ({
	isOpen,
	onClose,
	thread,
	onNavigateToAdjacentThread,
	onUnarchive,
	onArchive,
	archivingThreadId,
	toggleThreadSeenStatus,
}) => {
	// Refs for DOM elements
	const textareaRef = useRef<HTMLTextAreaElement | null>(null);
	const contentRef = useRef<HTMLDivElement>(null);
	const emailRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
	const messageHeaderExpandedRef = useRef<(() => void) | null>(null);

	// UI state
	const [isHeaderBlurred, setIsHeaderBlurred] = useState(false);
	// Track local state for seen status to ensure immediate UI updates
	const [isThreadSeen, setIsThreadSeen] = useState(thread.accounts.every(acc => acc.seen));

	// Effect to synchronize the component's state with the thread's seen status
	useEffect(() => {
		setIsThreadSeen(thread.accounts.every(acc => acc.seen));
	}, [thread.accounts.every(acc => acc.seen)]);

	// Handle navigation attempts
	const handleNavigationAttempt = (direction: NavigationDirection) => {
		const success = onNavigateToAdjacentThread(direction);
		if (!success) {
			onClose();
		}
	};

	// Thread state management
	const { emailState, updateEmailState, handlers, isInitialLoad } = useEmailState(thread, onClose, direction =>
		handleNavigationAttempt(direction),
	);
	const { sendEmail } = useInboxStore();

	// Updated handler for toggling read/unread status with local state update
	const handleToggleReadStatus = async () => {
		if (!thread || !toggleThreadSeenStatus) return;

		// Optimistically update local state
		setIsThreadSeen(!isThreadSeen);

		// Call the actual API update
		await toggleThreadSeenStatus(thread);
	};

	// Thread messages sorting
	const sortedThreadEmails = useMemo(
		() =>
			thread.messages?.slice().sort((a: TMessageNewRow, b: TMessageNewRow) => {
				return new Date(a.sent_at).getTime() - new Date(b.sent_at).getTime();
			}) ?? [],
		[thread.messages],
	);

	// Keyboard shortcuts
	useEmailKeyboardShortcuts(
		emailState,
		handlers,
		textareaRef,
		updateEmailState,
		thread.accounts.every(acc => acc.status === "archived") ? onUnarchive : onArchive,
		sortedThreadEmails,
		() => messageHeaderExpandedRef.current?.(),
		handleToggleReadStatus,
	);

	// Email interaction handlers
	const handleEmailHover = useMemo(
		() => (id: string) => {
			updateEmailState({ hoveredId: id });
		},
		[updateEmailState],
	);

	const handleEmailHoverEnd = useMemo(
		() => () => {
			updateEmailState({ hoveredId: null });
		},
		[updateEmailState],
	);

	// Effects
	useEffect(() => {
		if (!isOpen) {
			isInitialLoad.current = true;
			updateEmailState({
				expandedIds: new Set(),
				activeId: null,
				replyingToId: null,
				messageText: "",
				isLoading: false,
				hoveredId: null,
				sendingMessages: new Set(),
			});
		}
	}, [isOpen, updateEmailState]);

	useEffect(() => {
		if (!isOpen || !sortedThreadEmails?.length || !isInitialLoad.current) return;

		const lastEmailId = sortedThreadEmails[sortedThreadEmails.length - 1].e_id;
		updateEmailState({
			activeId: lastEmailId,
			hoveredId: lastEmailId,
			expandedIds: new Set([lastEmailId]),
		});
		isInitialLoad.current = false;
	}, [isOpen, sortedThreadEmails, updateEmailState]);

	useEffect(() => {
		if (!emailState.activeId) return;

		const activeRef = emailRefs.current[emailState.activeId];
		if (activeRef?.current) {
			activeRef.current.focus();
			activeRef.current.scrollIntoView({
				behavior: isInitialLoad.current ? undefined : "smooth",
				block: "center",
			});
			isInitialLoad.current = false;
		}
	}, [emailState.activeId]);

	// Scroll handling
	useEffect(() => {
		const el = contentRef.current;
		if (el) {
			const onScroll = () => {
				setIsHeaderBlurred(el.scrollTop > 0);
			};
			el.addEventListener("scroll", onScroll);
			setIsHeaderBlurred(el.scrollTop > 0);

			return () => {
				el.removeEventListener("scroll", onScroll);
			};
		}
	}, [thread]);

	useEffect(() => {
		if (emailState.replyingToId && contentRef.current) {
			const emailElement = emailRefs.current[emailState.replyingToId]?.current;
			if (emailElement) {
				const emailRect = emailElement.getBoundingClientRect();
				const containerRect = contentRef.current.getBoundingClientRect();
				const scrollTop = contentRef.current.scrollTop;

				const targetPosition = scrollTop + emailRect.top - containerRect.top - containerRect.height / 10;

				setTimeout(() => {
					contentRef.current?.scrollTo({
						top: targetPosition,
						behavior: "smooth",
					});
				}, 50);
			}
		}
	}, [emailState.replyingToId]);

	// Email refs management
	useEffect(() => {
		if (sortedThreadEmails) {
			sortedThreadEmails.forEach(threadEmail => {
				if (!emailRefs.current[threadEmail.e_id]) {
					emailRefs.current[threadEmail.e_id] = React.createRef<HTMLDivElement>();
				}
			});
		}
	}, [sortedThreadEmails]);
	const { accountsById } = useEmailAccountsStore();
	const { campaignsById } = useCampaignStore();
	const firstAccountRef = thread.accounts[0];
	if (!firstAccountRef) {
		throw new Error(`Unable to locate account ref for thread (EID: ${thread.e_id})`);
	}
	const firstAccount = accountsById[firstAccountRef.email_account_id];
	if (!firstAccount) {
		throw new Error(`Unable to locate account for thread (EID: ${thread.e_id})`);
	}

	const lastMessage = useMemo(() => sortedThreadEmails[sortedThreadEmails.length - 1], [sortedThreadEmails]);

	if (!lastMessage) {
		throw new Error(`Unable to locate messages for thread (EID: ${thread.e_id})`);
	}
	const firstMessage = useMemo(() => sortedThreadEmails[0], [sortedThreadEmails]);

	if (!firstMessage) {
		throw new Error(`Unable to locate messages for thread (EID: ${thread.e_id})`);
	}
	const campaign: CampaignProps | undefined =
		firstAccountRef.campaign_id !== null ? campaignsById[firstAccountRef.campaign_id] : undefined;

	// Render thread items
	const renderedThreadItems = sortedThreadEmails?.map((threadEmail: TMessageNewRow, index: number) => (
		<EmailThreadItem
			thread={thread}
			key={threadEmail.e_id}
			ref={emailRefs.current[threadEmail.e_id]}
			threadEmail={threadEmail}
			index={index}
			isExpanded={emailState.expandedIds.has(threadEmail.e_id)}
			isActive={emailState.activeId === threadEmail.e_id}
			isHovered={emailState.hoveredId === threadEmail.e_id}
			isReplying={emailState.replyingToId === threadEmail.e_id}
			messageText={emailState.messageText}
			fullMessage={threadEmail.text_content ?? ""}
			onSelect={() => handlers.handleEmailClick(threadEmail.e_id)}
			onReply={() => handlers.handleStartReply(threadEmail.e_id)}
			onMessageChange={text => updateEmailState({ messageText: text })}
			onSend={(thread, messageText, markAsDone) => {
				const referenceMessageId = thread.messages[thread.messages.length - 1]?.e_id;
				const tempId = sendEmail({
					thread,
					messageText,
					markAsDone,
					referenceMessageId,
				});
				updateEmailState({
					messageText: "",
					replyingToId: null,
					expandedIds: new Set([...emailState.expandedIds, tempId]),
					activeId: tempId,
				});
				return tempId;
			}}
			onDiscard={() =>
				updateEmailState({
					messageText: "",
					replyingToId: null,
				})
			}
			onMouseEnter={() => handleEmailHover(threadEmail.e_id)}
			onMouseLeave={handleEmailHoverEnd}
			textareaRef={textareaRef}
			isArchived={thread.accounts.every(acc => acc.status === "archived")}
			onUnarchive={onUnarchive}
			isSending={emailState.sendingMessages.has(threadEmail.e_id)}
			onMessageHeaderToggleRef={isActive => (isActive ? messageHeaderExpandedRef : null)}
		/>
	));

	return (
		<TooltipProvider>
			<Dialog open={isOpen} onOpenChange={onClose}>
				<DialogContent variant="inbox" className="max-w-800 flex h-[90vh] w-full flex-col p-0">
					<DialogTitle className="sr-only">{thread.messages[0]?.subject || "Email Thread"}</DialogTitle>
					<DialogDescription className="sr-only">
						Email thread with subject: {firstMessage.subject || "Email Thread"}.
						{campaign ? ` From campaign: ${campaign.title}.` : ""}
					</DialogDescription>
					<EmailModalHeader
						onCloseModal={onClose}
						onNavigateToAdjacentThread={onNavigateToAdjacentThread}
						isUnread={!isThreadSeen}
						toggleReadStatus={handleToggleReadStatus}
						isHeaderBlurred={isHeaderBlurred}
						subject={firstMessage.subject || ""}
						campaignName={campaign?.title}
						onArchive={thread.accounts.every(acc => acc.status === "archived") ? onUnarchive : onArchive}
						isArchived={thread.accounts.every(acc => acc.status === "archived")}
						archivingThreadId={archivingThreadId}
						threadId={thread.e_id}
					/>
					<div ref={contentRef} className="custom-scrollbar grow overflow-auto px-4">
						<div className="space-y-1 pb-4">{renderedThreadItems}</div>
					</div>
				</DialogContent>
			</Dialog>
		</TooltipProvider>
	);
};

export default EmailModal;
