import * as React from "react";
const SvgBranchSplit = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 14, height: 14, viewBox: "0 0 14 14", fill: "none", ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.99992 1.16675C7.32209 1.16675 7.58325 1.42792 7.58325 1.75008V3.88897C7.58325 5.28502 6.45153 6.41675 5.05547 6.41675C4.30375 6.41675 3.69436 7.02614 3.69436 7.77786L3.69436 10.8517L4.64507 9.91054C4.87402 9.68388 5.24336 9.68573 5.47002 9.91468C5.69668 10.1436 5.69482 10.513 5.46588 10.7396L3.52143 12.6646C3.29411 12.8897 2.92795 12.8897 2.70063 12.6646L0.756184 10.7396C0.527237 10.513 0.525381 10.1436 0.752039 9.91468C0.978697 9.68573 1.34804 9.68388 1.57699 9.91054L2.5277 10.8517L2.5277 7.77786C2.5277 6.38181 3.65942 5.25008 5.05547 5.25008C5.8072 5.25008 6.41659 4.64069 6.41659 3.88897V1.75008C6.41659 1.42792 6.67775 1.16675 6.99992 1.16675Z", fill: "#F18039", style: {
  fill: "#F18039",
  fill: "color(display-p3 0.9451 0.5001 0.2235)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.99992 1.75008C6.67775 1.75008 6.41659 2.01125 6.41659 2.33341V3.88897C6.41659 5.28502 7.54831 6.41675 8.94436 6.41675C9.69608 6.41675 10.3055 7.02614 10.3055 7.77786V10.8517L9.35476 9.91054C9.12582 9.68388 8.75648 9.68573 8.52982 9.91468C8.30316 10.1436 8.30501 10.513 8.53396 10.7396L10.4784 12.6646C10.7057 12.8897 11.0719 12.8897 11.2992 12.6646L13.2437 10.7396C13.4726 10.513 13.4745 10.1436 13.2478 9.91468C13.0211 9.68573 12.6518 9.68388 12.4229 9.91054L11.4721 10.8517V7.77786C11.4721 6.38181 10.3404 5.25008 8.94436 5.25008C8.19264 5.25008 7.58325 4.64069 7.58325 3.88897V2.33341C7.58325 2.01125 7.32209 1.75008 6.99992 1.75008Z", fill: "#F18039", style: {
  fill: "#F18039",
  fill: "color(display-p3 0.9451 0.5001 0.2235)",
  fillOpacity: 1
} }));
export default SvgBranchSplit;
