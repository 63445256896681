import { Button } from "../ui/button";
import CampaignFolderIcon from "@/assets/images/empty-states/campaign-folder-icon.png";

interface EmptyCampaignsProps {
	onCreateCampaign: () => void;
}

export const EmptyCampaigns = ({ onCreateCampaign }: EmptyCampaignsProps) => {
	return (
		<div className="flex h-full w-full flex-col items-center pt-32">
			<div className="h-32 w-32 overflow-hidden">
				<img src={CampaignFolderIcon} alt="Campaign Folder" className="h-full w-full object-contain" />
			</div>
			<div className="mt-[20px] flex flex-col items-center gap-[10px] self-stretch">
				<h2 className="text-label-title font-geist text-center text-[20px] font-semibold leading-[27px]">
					Start building your first campaign
				</h2>
				<p className="text-label-label-muted font-geist text-body-head leading-body-head max-w-[426px] text-center font-normal">
					The first step to sending outbound with Za-zu is to create a campaign. It&apos;s easy to start—just click
					below.
				</p>
			</div>
			<Button variant="default" className="mt-6" onClick={onCreateCampaign}>
				Create campaign
			</Button>
		</div>
	);
};
