import { useAuth, useOrganization } from "@clerk/clerk-react";
import { z } from "zod";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";

// Get base URL from environment variable
export const API_URL = import.meta.env.VITE_API_URL || "http://localhost:3004";

export const WS_URL = `${API_URL.replace("http", "ws")}/ws`;

export interface RequestConfig extends RequestInit {
	params?: Record<string, string>;
	query?: Record<string, string>;
}

export type PutFunction = (url: string, data?: Record<string, unknown>, config?: RequestConfig) => Promise<unknown>;

export class RequestError extends Error {
	status?: number;
	data?: unknown;
	constructor(message: string, status?: number, data?: unknown) {
		super(message);
		this.status = status;
		this.data = data;
	}
}

export class RequestConflictError extends RequestError {
	constructor(message: string, data?: unknown) {
		super(message, 409, data);
	}
}
export class RequestNotFoundError extends RequestError {
	constructor(message: string, data?: unknown) {
		super(message, 404, data);
	}
}

async function createError(res: Response): Promise<RequestError> {
	let data;
	try {
		data = await res.json();
	} catch {
		data = await res.text();
	}

	switch (res.status) {
		case 409:
			return new RequestConflictError(res.statusText || "An error occurred", data);
		case 404:
			return new RequestNotFoundError(res.statusText || "An error occurred", data);
		default:
			return new RequestError(res.statusText || "An error occurred", res.status, data);
	}
}

function buildUrl(path: string, config?: RequestConfig): string {
	const cleanPath = path.startsWith("/") ? path.slice(1) : path;
	const baseUrl = `${API_URL}/${cleanPath}`;
	const url = new URL(baseUrl);

	if (config?.query) {
		Object.entries(config.query).forEach(([key, value]) => {
			if (value !== undefined && value !== null) {
				url.searchParams.append(key, String(value));
			}
		});
	}

	let finalUrl = url.toString();
	if (config?.params) {
		Object.entries(config.params).forEach(([key, value]) => {
			if (value !== undefined && value !== null) {
				finalUrl = finalUrl.replace(`:${key}`, String(value));
			}
		});
	}

	return finalUrl;
}

export function useFetch(): <T>(url: string, zodSchema: z.ZodSchema<T>, config?: RequestConfig) => Promise<T> {
	const { getToken } = useAuth();
	const { organization } = useOrganization();
	const organization_id = organization?.id;

	return async function authedFetch<T>(url: string, zodSchema: z.ZodSchema<T>, config?: RequestConfig): Promise<T> {
		if (!organization_id) throw new Error("Organization not found");

		const token = await getToken();
		if (!token) throw new Error("No authentication token found");

		// Create headers with the three required values
		const contentHeader: HeadersInit = {};
		if (config) {
			if (config.body !== undefined) {
				contentHeader["Content-Type"] = "application/json";
			}
		}
		const headers = new Headers({
			...contentHeader,
			Authorization: `Bearer ${token}`,
			...config?.headers, // Allow additional headers from config to override defaults if needed
		});

		const finalUrl = buildUrl(url, config);
		const res = await fetch(finalUrl, {
			...config,
			headers,
			body: config?.body && typeof config.body === "object" ? JSON.stringify(config.body) : config?.body,
		});

		if (!res.ok) throw await createError(res);

		const contentType = res.headers.get("content-type");
		if (!contentType?.includes("application/json")) {
			throw new Error("Response is not JSON");
		}
		const data: unknown = await res.json();
		const parsedData = zodSchema.parse(data);
		return parsedData;
	};
}

interface UseQueryOptions<T> extends SWRConfiguration<T> {
	config?: RequestConfig;
}

export function useQuery<T>(
	url: string | null,
	zodSchema: z.ZodSchema<T>,
	options?: UseQueryOptions<T>,
): SWRResponse<T, RequestError> {
	const fetcher = useFetch();
	return useSWR<T, RequestError>(url, url => fetcher<T>(url, zodSchema, options?.config), options);
}

export function usePost<T>(): (
	url: string,
	zodSchema: z.ZodSchema<T>,
	data?: Record<string, unknown> | Record<string, unknown>[],
	config?: RequestConfig,
) => Promise<T> {
	const fetcher = useFetch();
	return (
		url: string,
		zodSchema: z.ZodSchema<T>,
		data?: Record<string, unknown> | Record<string, unknown>[],
		config?: RequestConfig,
	) =>
		fetcher<T>(url, zodSchema, {
			...config,
			method: "POST",
			body: data ? JSON.stringify(data) : undefined,
		});
}

export function usePut<T>(): (
	url: string,
	zodSchema: z.ZodSchema<T>,
	data?: Record<string, unknown>,
	config?: RequestConfig,
) => Promise<T> {
	const fetcher = useFetch();
	return (url: string, zodSchema: z.ZodSchema<T>, data?: Record<string, unknown>, config?: RequestConfig) =>
		fetcher<T>(url, zodSchema, {
			...config,
			method: "PUT",
			body: data ? JSON.stringify(data) : undefined,
		});
}

export function useDelete<T>(): (url: string, zodSchema: z.ZodSchema<T>, config?: RequestConfig) => Promise<T> {
	const fetcher = useFetch();
	return (url: string, zodSchema: z.ZodSchema<T>, config?: RequestConfig) =>
		fetcher<T>(url, zodSchema, { ...config, method: "DELETE" });
}

export type QueryResponse<T> = SWRResponse<T, RequestError>;
