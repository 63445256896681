import { InboxTabs } from "@/app/enums/inbox.enum";
import { useEffect, useRef } from "react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";

interface UnifiedInboxHeaderProps {
	activeTab: InboxTabs;
	setActiveTab: (tab: InboxTabs) => void;
	primaryCount: number;
	isModalOpen: boolean;
}

const UnifiedInboxHeader = ({ activeTab, setActiveTab, primaryCount, isModalOpen }: UnifiedInboxHeaderProps) => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const tabRefs = useRef<Record<string, HTMLDivElement | null>>({});

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === "Tab" && !isModalOpen) {
				e.preventDefault();
				// Cycle through tabs in order
				const tabOrder = [InboxTabs.PRIMARY, InboxTabs.OTHER, InboxTabs.SENT, InboxTabs.UNTRACKED, InboxTabs.DONE];
				const currentIndex = tabOrder.indexOf(activeTab);
				const nextIndex = (currentIndex + 1) % tabOrder.length;
				setActiveTab(tabOrder[nextIndex]);
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [activeTab, setActiveTab, isModalOpen]);

	// Update clip-path when active tab changes
	useEffect(() => {
		const container = containerRef.current;
		const activeTabElement = tabRefs.current[activeTab];

		if (container && activeTabElement) {
			const { offsetLeft, offsetWidth } = activeTabElement;
			const containerWidth = container.offsetWidth;

			// Calculate clip-path values
			const clipLeft = (offsetLeft / containerWidth) * 100;
			const clipRight = 100 - ((offsetLeft + offsetWidth) / containerWidth) * 100;

			// Apply clip-path with transition
			container.style.clipPath = `inset(0 ${clipRight.toFixed(2)}% 0 ${clipLeft.toFixed(2)}%)`;
		}
	}, [activeTab]);

	return (
		<TooltipProvider>
			<div className="border-background-bg-divider relative flex items-center gap-6 self-stretch border-b py-4 pl-6 pr-4">
				<div className="flex flex-1 items-center gap-6">
					{/* Regular tab list with inactive styling */}
					<div className="relative flex items-center gap-6">
						<Tooltip>
							<TooltipTrigger asChild>
								<div
									ref={el => (tabRefs.current[InboxTabs.PRIMARY] = el)}
									className="flex cursor-pointer items-center gap-2"
									onClick={() => setActiveTab(InboxTabs.PRIMARY)}>
									<label className="flex text-xl font-semibold leading-[27px] text-[color:var(--label-label-faint)]">
										Primary
									</label>
									<div className="bg-background-bg-dim flex h-[19px] items-center justify-center rounded-md px-1.5">
										<label className="font-geist text-body-base text-label-faint flex font-semibold tracking-[-0.13px]">
											{primaryCount}
										</label>
									</div>
								</div>
							</TooltipTrigger>
							<TooltipContent>
								People who want to talk <kbd>tab</kbd>
							</TooltipContent>
						</Tooltip>
						<Tooltip>
							<TooltipTrigger asChild>
								<div
									ref={el => (tabRefs.current[InboxTabs.OTHER] = el)}
									className="cursor-pointer text-xl font-semibold leading-[27px] text-[color:var(--label-label-faint)]"
									onClick={() => setActiveTab(InboxTabs.OTHER)}>
									Other
								</div>
							</TooltipTrigger>
							<TooltipContent>
								Other responses <kbd>tab</kbd>
							</TooltipContent>
						</Tooltip>
						<Tooltip>
							<TooltipTrigger asChild>
								<div
									ref={el => (tabRefs.current[InboxTabs.SENT] = el)}
									className="cursor-pointer text-xl font-semibold leading-[27px] text-[color:var(--label-label-faint)]"
									onClick={() => setActiveTab(InboxTabs.SENT)}>
									Sent
								</div>
							</TooltipTrigger>
							<TooltipContent>
								People you&apos;ve replied to <kbd>tab</kbd>
							</TooltipContent>
						</Tooltip>
						<Tooltip>
							<TooltipTrigger asChild>
								<div
									ref={el => (tabRefs.current[InboxTabs.UNTRACKED] = el)}
									className="cursor-pointer text-xl font-semibold leading-[27px] text-[color:var(--label-label-faint)]"
									onClick={() => setActiveTab(InboxTabs.UNTRACKED)}>
									Untracked
								</div>
							</TooltipTrigger>
							<TooltipContent>
								Emails not linked to any campaign <kbd>tab</kbd>
							</TooltipContent>
						</Tooltip>
						<Tooltip>
							<TooltipTrigger asChild>
								<div
									ref={el => (tabRefs.current[InboxTabs.DONE] = el)}
									className="cursor-pointer text-xl font-semibold leading-[27px] text-[color:var(--label-label-faint)]"
									onClick={() => setActiveTab(InboxTabs.DONE)}>
									Done
								</div>
							</TooltipTrigger>
							<TooltipContent>
								Emails you&apos;ve finished with <kbd>tab</kbd>
							</TooltipContent>
						</Tooltip>
					</div>

					{/* Clip-path container with active styled tabs */}
					<div
						ref={containerRef}
						aria-hidden="true"
						className="absolute left-6 top-4 flex items-center gap-6 transition-[clip-path] duration-200 ease-out"
						style={{
							// Start with a clip-path that shows nothing
							clipPath: "inset(0 100% 0 0%)",
						}}>
						<div className="flex cursor-pointer items-center gap-2" onClick={() => setActiveTab(InboxTabs.PRIMARY)}>
							<label className="flex text-xl font-semibold leading-[27px] text-[color:var(--label-label-title)]">
								Primary
							</label>
							<div className="bg-background-bg-dim flex h-[19px] items-center justify-center rounded-md px-1.5">
								<label className="font-geist text-body-base text-label-faint flex font-semibold tracking-[-0.13px]">
									{primaryCount}
								</label>
							</div>
						</div>
						<div
							className="cursor-pointer text-xl font-semibold leading-[27px] text-[color:var(--label-label-title)]"
							onClick={() => setActiveTab(InboxTabs.OTHER)}>
							Other
						</div>
						<div
							className="cursor-pointer text-xl font-semibold leading-[27px] text-[color:var(--label-label-title)]"
							onClick={() => setActiveTab(InboxTabs.SENT)}>
							Sent
						</div>
						<div
							className="cursor-pointer text-xl font-semibold leading-[27px] text-[color:var(--label-label-title)]"
							onClick={() => setActiveTab(InboxTabs.UNTRACKED)}>
							Untracked
						</div>
						<div
							className="cursor-pointer text-xl font-semibold leading-[27px] text-[color:var(--label-label-title)]"
							onClick={() => setActiveTab(InboxTabs.DONE)}>
							Done
						</div>
					</div>

					<div className="ml-auto flex items-center gap-4"></div>
				</div>
			</div>
		</TooltipProvider>
	);
};
export default UnifiedInboxHeader;
