import { sentenceLengthRule } from "./sentenceLengthRule";
import { readabilityRule } from "./readabilityRule";
import { qualifiersRule } from "./qualifiersRule";
import { complexWordsRule } from "./complexWordsRule";
import { spamWordsRule } from "./spamWordsRule";

// Export all rules
export const emailCoachRules = [sentenceLengthRule, readabilityRule, qualifiersRule, complexWordsRule, spamWordsRule];

// Export individual rules
export * from "./sentenceLengthRule";
export * from "./readabilityRule";
export * from "./qualifiersRule";
export * from "./complexWordsRule";
export * from "./spamWordsRule";
