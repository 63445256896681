import { useMutation } from "../../hooks/useMutation";
import { useEmailAccountsStore } from "@/store";
import { toast } from "sonner";
import { ZazuAPI } from "@/utils/zazu-api";
import { AccountSlim } from "@za-zu/types";

// Define the payload interface expected by your SMTP endpoint.
export interface SMTPConnectionPayload {
	email: string;
	name: string;
	account?: string;
	imapIndexer?: string; // will be "fast" by default
	imap: {
		host: string;
		port: number;
		auth: {
			user: string;
			pass: string;
		};
	};
	smtp: {
		host: string;
		port: number;
		auth: {
			user: string;
			pass: string;
		};
	};
}

// Function to call the SMTP endpoint.
const connectSMTPAccount = async ({ input }: { input: SMTPConnectionPayload }) => {
	const uri = `/accounts/imap-smtp`;
	const response = await ZazuAPI.post(uri, input);
	return response.data.data;
};

type ParsableError = {
	response: {
		data: {
			message: string;
		};
	};
};

function isParsableError(err: unknown): err is ParsableError {
	return (
		typeof err === "object" &&
		err !== null &&
		"response" in err &&
		err.response !== null &&
		typeof err.response === "object" &&
		"data" in err.response &&
		err.response.data !== null &&
		typeof err.response.data === "object" &&
		"message" in err.response.data &&
		typeof err.response.data.message === "string"
	);
}

// Custom event for a new account connection.
export const NEW_ACCOUNT_CONNECTED_EVENT = "new-account-connected";

export interface NewAccountConnectedEvent extends CustomEvent {
	detail: {
		account: AccountSlim;
	};
}

// Helper to dispatch the custom event.
export function dispatchNewAccountConnectedEvent(account: AccountSlim) {
	const event = new CustomEvent(NEW_ACCOUNT_CONNECTED_EVENT, {
		detail: { account },
		bubbles: true,
	});
	window.dispatchEvent(event);
}

// Hook for connecting an SMTP (IMAP/SMTP) account.
export function useConnectSMTP(): {
	isConnecting: boolean;
	connectSMTP: (input: SMTPConnectionPayload) => Promise<{ account?: string }>;
	connectionError: unknown;
} {
	const { addPendingAccountChange, accounts } = useEmailAccountsStore.getState();

	const { isMutating, mutate, error } = useMutation<{ account?: string }, SMTPConnectionPayload, void>({
		update: connectSMTPAccount,
		onSuccess: (data, _key, _args) => {
			// Capture the current accounts to compare later.
			const currentAccounts = [...accounts];
			// If no account was created, show an error toast.
			if (!data?.account) {
				toast.error("No SMTP account was created. Please try again.");
				return;
			}

			// Add a pending change so that the store refetches accounts.
			const fetchAndCheckForNewAccounts = async () => {
				addPendingAccountChange({
					id: "latest-accounts",
					action: "fetch",
				});

				// Wait a bit for the store to update.
				setTimeout(() => {
					const { accounts: updatedAccounts } = useEmailAccountsStore.getState();
					// Identify newly added accounts.
					const newAccounts = updatedAccounts.filter(
						account => !currentAccounts.some(existing => existing.id === account.id),
					);
					if (newAccounts.length > 0) {
						dispatchNewAccountConnectedEvent(newAccounts[0]);
					}
				}, 1000);
			};

			fetchAndCheckForNewAccounts();
			toast.success("SMTP account connected successfully");
		},
		onError: (err: unknown) => {
			let errorMessage = "Error connecting SMTP account.";
			if (isParsableError(err)) {
				errorMessage = err.response.data.message;
			}
			toast.error(errorMessage);
			console.error(err);
		},
	});

	return {
		isConnecting: isMutating,
		connectSMTP: mutate,
		connectionError: error,
	};
}
