import "./globals.css";

import { ClerkProvider, RedirectToSignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import { Toaster } from "sonner";
import { App } from "./_app";

export default function RootLayout({ children }: { children: React.ReactNode }) {
	return (
		<ClerkProvider publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}>
			<div style={{ fontFamily: "var(--font-geist)" }}>
				<SignedIn>
					<App>{children}</App>
				</SignedIn>
				<SignedOut>
					<RedirectToSignIn />
				</SignedOut>

				<Toaster richColors position="bottom-right" expand={true} gap={8} visibleToasts={5} />
			</div>
		</ClerkProvider>
	);
}
