import { useCampaignStore } from "@/store";
import { CampaignProps } from "@/types/campaign";
import Papa from "papaparse";
import { useRef, useState, useEffect } from "react";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogClose, DialogTitle, DialogDescription } from "../ui/dialog";
import { X } from "lucide-react";
import CSVIcon from "@/assets/icons/svgs/csv.svg?react";
import ExcelIcon from "@/assets/icons/svgs/excel.svg?react";
import ArrowUpIcon from "@/assets/icons/svgs/arrow-up.svg?react";
import { VisuallyHidden } from "../ui/visually-hidden";
import { z } from "zod";

interface CSVUploaderProps {
	open: boolean;
	onClose: () => void;
	onUpload: (data: { [key: string]: string | number | boolean | null }[]) => void;
	selectedCampaign: CampaignProps;
	initialFile?: File | null;
}

type UploadState = "idle" | "dragging" | "uploading" | "complete";

export const CSVUploader = ({ open, onClose, onUpload, selectedCampaign, initialFile }: CSVUploaderProps) => {
	const { leadsByCampaignId } = useCampaignStore();
	const leads = leadsByCampaignId[selectedCampaign.id] ?? {};
	const [uploadState, setUploadState] = useState<UploadState>("idle");
	const [uploadProgress, setUploadProgress] = useState(0);

	const fileInputRef = useRef<HTMLInputElement>(null);

	// Process initial file if provided
	useEffect(() => {
		if (open && initialFile) {
			handleFileUpload(initialFile);
		}
	}, [open, initialFile]);

	const handleDragOver = (e: React.DragEvent) => {
		e.preventDefault();
	};

	const handleDragEnter = (e: React.DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setUploadState("dragging");
	};

	const handleDragLeave = (e: React.DragEvent) => {
		e.preventDefault();
		if (e.currentTarget.contains(e.relatedTarget as Node)) {
			return;
		}
		setUploadState("idle");
	};

	const handleDrop = async (e: React.DragEvent) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		if (file && file.type === "text/csv") {
			await handleFileUpload(file);
		} else {
			setUploadState("idle");
		}
	};

	const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			await handleFileUpload(file);
		}
	};

	const handleFileUpload = async (file: File) => {
		setUploadState("uploading");
		setUploadProgress(0);

		const reader = new FileReader();
		reader.onload = e => {
			const csvContent = e.target?.result as string;
			Papa.parse(csvContent, {
				header: true,
				skipEmptyLines: "greedy",
				dynamicTyping: true,
				complete: results => {
					if (results.errors.length > 0) {
						console.error("Parse errors:", results.errors);
						setUploadState("idle");
						return;
					}

					setUploadState("complete");
					onUpload(
						z
							.record(z.string(), z.union([z.string(), z.number(), z.boolean(), z.null()]))
							.array()
							.parse(results.data),
					);
				},
			});
		};
		reader.readAsText(file);
	};

	const handleDownloadTemplate = () => {
		const defaultHeaders = ["email", "firstname", "lastname", "linkedin", "jobtitle", "company", "phone"];
		const sampleData = [
			"john@example.com",
			"John",
			"Doe",
			"https://linkedin.com/in/johndoe",
			"Sales Manager",
			"Acme Inc.",
			"+1234567890",
		];

		const headers = defaultHeaders;

		// Get sample data from first lead or use default
		const data = Object.values(leads).flat()[0]
			? headers.map(header => {
					const lead = Object.values(leads).flat()[0] as Record<string, unknown>;
					const value = lead[header];
					return Array.isArray(value) ? value[0] : (value as string) || "";
				})
			: sampleData;

		const csvContent = Papa.unparse({
			fields: headers,
			data: [data],
		});

		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = "leads_template.csv";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	};

	const renderDropzoneContent = () => {
		switch (uploadState) {
			case "dragging":
				return (
					<div className="text-center">
						<ArrowUpIcon className="text-label-label-link mx-auto mb-4 h-4 w-4 shrink-0" />
						<p className="text-label-label-title text-[13px]">Release to upload your file</p>
					</div>
				);

			case "uploading":
				return (
					<div className="text-center">
						<div className="mx-auto mb-4 h-12 w-12 animate-spin rounded-full border-4 border-[#D9D9D9] border-t-[#0066FF]" />
						<p className="text-[13px] text-[#666]">Uploading file, please wait...</p>
						<p className="mt-2 text-[13px] font-medium text-[#0066FF]">{uploadProgress}%</p>
					</div>
				);

			case "complete":
				return (
					<div className="text-center">
						<svg className="mx-auto h-12 w-12 text-green-500" viewBox="0 0 24 24" fill="none">
							<path
								d="M20 6L9 17L4 12"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<p className="mt-2 text-[13px] text-[#666]">Upload complete!</p>
					</div>
				);

			default:
				return (
					<div className="text-center">
						<CSVIcon className="text-label-label-link mx-auto h-4 w-4 shrink-0" />
						<p className="text-label-label-title mt-3 text-[13px]">
							Drag and drop or{" "}
							<button onClick={() => fileInputRef.current?.click()} className="text-[#0066FF] hover:text-[#0052CC]">
								choose file(s)
							</button>{" "}
							to upload.
						</p>
					</div>
				);
		}
	};

	return (
		<Dialog open={open} onOpenChange={isOpen => !isOpen && onClose()}>
			<DialogContent
				variant="inbox"
				className="flex w-[430px] flex-col items-center gap-6 px-1 py-6"
				onInteractOutside={e => e.preventDefault()}>
				<VisuallyHidden>
					<DialogTitle>Upload CSV File</DialogTitle>
					<DialogDescription>Upload a CSV file to import leads into your campaign</DialogDescription>
				</VisuallyHidden>
				<DialogClose className="focus:outline-hidden absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none">
					<X className="h-4 w-4" />
					<span className="sr-only">Close</span>
				</DialogClose>
				<div className="mx-auto flex w-[362px] flex-col items-center gap-3">
					<h2 className="text-[15px] font-medium leading-[22px] text-[#1A1A1A]">Upload a CSV file</h2>
					<p className="text-[13px] leading-[18px] text-[#666]">Drag and drop a .csv file to import your leads</p>
				</div>

				<div
					className={`flex h-[235px] flex-col items-center justify-center gap-3 self-stretch rounded-xl border border-dashed p-6 transition-colors ${
						uploadState === "dragging"
							? "border-[#0066FF] bg-[#F5F9FF]"
							: "border-background-bg-border bg-background-bg-shade"
					}`}
					onDragOver={handleDragOver}
					onDragEnter={handleDragEnter}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}>
					{renderDropzoneContent()}
				</div>

				<input type="file" ref={fileInputRef} onChange={handleFileSelect} accept=".csv" className="hidden" />

				<div className="flex justify-center">
					<Button variant="outline" onClick={handleDownloadTemplate}>
						<ExcelIcon className="text-label-label-muted h-4 w-4 shrink-0" />
						Download template file
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};
