import { SidebarToggleButton } from "@/components/ui/sidebar-toggle-button";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useLocation } from "wouter";
import { useForm } from "@tanstack/react-form";
import { z } from "zod";
import { ChevronDown } from "lucide-react";
import { useState } from "react";
import { useConnectSMTP } from "@/hooks/accounts/useConnectSMTP";

// Define keyframe animations
const typingAnimation = `
.typing-cursor {
  position: relative;
}

.typing-cursor::after {
  content: '';
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 1px;
  background-color: currentColor;
  animation: blink 0.8s step-end infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@media (prefers-reduced-motion: reduce) {
  .typing-cursor::after {
    animation: none;
  }
}
`;

// Define the form schema with Zod - used for type inference and validation
const formSchema = z.object({
	emailAddress: z.string().email("Please enter a valid email"),
	senderName: z.string().min(1, "Sender name is required"),
	imapServer: z.string().min(1, "IMAP server is required"),
	imapPort: z.string().min(1, "IMAP port is required"),
	imapUsername: z.string().min(1, "IMAP username is required"),
	imapPassword: z.string().min(1, "IMAP password is required"),
	connectionType: z.enum(["secure", "unencrypted"]),
	smtpServer: z.string().min(1, "SMTP server is required"),
	smtpPort: z.string().min(1, "SMTP port is required"),
	smtpUsername: z.string().min(1, "SMTP username is required"),
	smtpPassword: z.string().min(1, "SMTP password is required"),
});

const SMTPSetup = () => {
	const [, setLocation] = useLocation();
	const [animatingFields, setAnimatingFields] = useState<string[]>([]);
	const [typingInProgress, setTypingInProgress] = useState(false);
	const [formError, setFormError] = useState<string | null>(null);
	const [copyError, setCopyError] = useState<string | null>(null);
	const { connectSMTP } = useConnectSMTP();

	// Initialize form with TanStack Form
	const form = useForm({
		defaultValues: {
			emailAddress: "",
			senderName: "",
			imapServer: "",
			imapPort: "993",
			imapUsername: "",
			imapPassword: "",
			connectionType: "secure" as "secure" | "unencrypted",
			smtpServer: "",
			smtpPort: "465",
			smtpUsername: "",
			smtpPassword: "",
		},
		onSubmit: async ({ value }) => {
			try {
				// Validate the entire form with Zod.
				formSchema.parse(value);

				// Clear any previous form errors.
				setFormError(null);

				// Build the payload for your SMTP endpoint.
				const payload = {
					email: value.emailAddress,
					name: value.senderName,
					imapIndexer: "fast", // default value as per the endpoint requirements.
					imap: {
						host: value.imapServer,
						port: Number(value.imapPort),
						auth: {
							user: value.imapUsername,
							pass: value.imapPassword,
						},
					},
					smtp: {
						host: value.smtpServer,
						port: Number(value.smtpPort),
						auth: {
							user: value.smtpUsername,
							pass: value.smtpPassword,
						},
					},
				};

				// Call the connectSMTP function from your custom hook.
				const result = await connectSMTP(payload);

				// If an account was created (account id exists), navigate away.
				if (result && result.account) {
					setLocation("/dashboard/email-accounts");
					return { status: "success" };
				} else {
					// Otherwise, do not navigate. The hook's onError should show an error toast.
					return { status: "error", message: "No account was created" };
				}
			} catch (error) {
				console.error("Form validation error:", error);
				if (error instanceof z.ZodError) {
					setFormError(error.errors[0]?.message || "Validation failed");
				} else {
					setFormError("An unexpected error occurred");
				}
				return { status: "error", message: "Form validation failed" };
			}
		},
	});

	const handleCancel = () => {
		setLocation("/dashboard/email-accounts");
	};

	const copySmtpCredentials = () => {
		if (typingInProgress) return; // Prevent multiple clicks during animation

		// Clear any previous copy errors
		setCopyError(null);

		// Get the values to be typed
		const smtpUsername = form.state.values.smtpUsername;
		const smtpPassword = form.state.values.smtpPassword;

		// Check if SMTP credentials have values
		const hasUsername = smtpUsername && smtpUsername.trim() !== "";
		const hasPassword = smtpPassword && smtpPassword.trim() !== "";

		if (!hasUsername && !hasPassword) {
			setCopyError("SMTP credentials are empty. Please enter them first.");
			return;
		}

		if (!hasUsername) {
			setCopyError("SMTP Username is empty. Please enter that first.");
			return;
		}

		if (!hasPassword) {
			setCopyError("SMTP Password is empty. Please enter that first.");
			return;
		}

		setTypingInProgress(true);
		setAnimatingFields(["imapUsername", "imapPassword"]);

		// Clear the fields and their errors
		form.setFieldValue("imapUsername", "");
		form.setFieldValue("imapPassword", "");

		// Type the username character by character
		let usernameIndex = 0;
		let passwordIndex = 0;

		const getRandomTypingDelay = () => {
			// Randomize typing speed slightly to make it feel more natural
			// Base speed of 40ms with a random variation of +/- 15ms
			return Math.floor(Math.random() * 30) + 30;
		};

		const typeUsername = () => {
			if (usernameIndex <= smtpUsername.length) {
				form.setFieldValue("imapUsername", smtpUsername.substring(0, usernameIndex));
				usernameIndex++;
				setTimeout(typeUsername, getRandomTypingDelay());
			} else {
				// Small pause before starting to type password
				setTimeout(typePassword, 300);
			}
		};

		const typePassword = () => {
			if (passwordIndex <= smtpPassword.length) {
				form.setFieldValue("imapPassword", smtpPassword.substring(0, passwordIndex));
				passwordIndex++;
				setTimeout(typePassword, getRandomTypingDelay());
			} else {
				// Finish animation
				setTimeout(() => {
					setAnimatingFields([]);
					setTypingInProgress(false);
				}, 500);
			}
		};

		// Start the typing animation
		typeUsername();
	};

	const renderHeader = () => (
		<header>
			<div className="flex h-[52px] items-center justify-between">
				<div className="flex items-center gap-2">
					<SidebarToggleButton />
					<h1 className="text-(--label-label-title) text-[15px] font-medium leading-[22px]">Connect SMTP</h1>
				</div>
				<div className="flex gap-2">
					<Button variant="exit" type="button" onClick={handleCancel}>
						Cancel
					</Button>
					<Button variant="default" type="submit" form="smtp-form">
						Connect account
					</Button>
				</div>
			</div>
		</header>
	);

	// Helper to render field error state
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getFieldErrorClass = (field: any) => {
		return field.state.meta.errors && field.state.meta.errors.length > 0 ? "border-red-500" : "";
	};

	return (
		<div className="flex h-full flex-col overflow-hidden">
			<style dangerouslySetInnerHTML={{ __html: typingAnimation }} />
			<div className="px-4">{renderHeader()}</div>
			<div className="bg-(--background-bg-border) h-[0.5px]" />

			<div className="flex-1 overflow-auto">
				<form
					id="smtp-form"
					onSubmit={e => {
						e.preventDefault();
						form.handleSubmit();
					}}>
					<div className="mx-auto flex w-[640px] flex-col items-start gap-[56px] px-4 py-[56px]">
						<div className="flex w-full items-center justify-between">
							<h2 className="text-xl font-semibold">Setup your email account with SMTP</h2>
						</div>

						{formError && (
							<div className="w-full rounded-md bg-red-50 p-4 text-red-700">
								<p>{formError}</p>
							</div>
						)}

						<div className="w-full">
							<div className="grid grid-cols-1 gap-4 md:grid-cols-2">
								<form.Field
									name="emailAddress"
									validators={{
										onBlur: ({ value }) => {
											try {
												z.string().email("Please enter a valid email").parse(value);
												return undefined;
											} catch (error) {
												return error instanceof z.ZodError ? error.errors[0]?.message : "Invalid email";
											}
										},
									}}>
									{field => (
										<div>
											<Label htmlFor="email" className="mb-2 block">
												Email address
											</Label>
											<Input
												id="email"
												type="email"
												className={getFieldErrorClass(field)}
												placeholder="example@example.com"
												value={field.state.value}
												onChange={e => field.handleChange(e.target.value)}
												onBlur={field.handleBlur}
											/>
											{field.state.meta.errors && field.state.meta.errors.length > 0 && (
												<p className="mt-1 text-xs text-red-500">{field.state.meta.errors[0]}</p>
											)}
										</div>
									)}
								</form.Field>

								<form.Field
									name="senderName"
									validators={{
										onBlur: ({ value }) => {
											try {
												z.string().min(1, "Sender name is required").parse(value);
												return undefined;
											} catch (error) {
												return error instanceof z.ZodError ? error.errors[0]?.message : "Sender name is required";
											}
										},
									}}>
									{field => (
										<div>
											<Label htmlFor="sender" className="mb-2 block">
												Sender name
											</Label>
											<Input
												id="sender"
												type="text"
												className={getFieldErrorClass(field)}
												placeholder="John Doe"
												value={field.state.value}
												onChange={e => field.handleChange(e.target.value)}
												onBlur={field.handleBlur}
											/>
											{field.state.meta.errors && field.state.meta.errors.length > 0 && (
												<p className="mt-1 text-xs text-red-500">{field.state.meta.errors[0]}</p>
											)}
										</div>
									)}
								</form.Field>
							</div>
						</div>

						<div className="w-full">
							<h3 className="mb-2 text-base font-semibold">SMTP Settings</h3>
							<p className="text-label-label-muted mb-6 text-sm">Sending emails relies on the SMTP protocol.</p>

							<div className="mb-8 flex items-end gap-3">
								<div className="flex flex-col gap-0">
									<div className="flex gap-4">
										<div className="flex flex-1 flex-col items-start gap-2">
											<form.Field
												name="smtpServer"
												validators={{
													onBlur: ({ value }) => {
														try {
															z.string().min(1, "SMTP server is required").parse(value);
															return undefined;
														} catch (error) {
															return error instanceof z.ZodError ? error.errors[0]?.message : "SMTP server is required";
														}
													},
												}}>
												{field => (
													<div className="w-full">
														<Label htmlFor="smtpServer" className="mb-2 block whitespace-nowrap">
															SMTP Server Hostname
														</Label>
														<Input
															id="smtpServer"
															type="text"
															className={getFieldErrorClass(field)}
															placeholder="smtp.example.com"
															value={field.state.value}
															onChange={e => field.handleChange(e.target.value)}
															onBlur={field.handleBlur}
														/>
														{field.state.meta.errors && field.state.meta.errors.length > 0 && (
															<p className="mt-1 text-xs text-red-500">{field.state.meta.errors[0]}</p>
														)}
													</div>
												)}
											</form.Field>
										</div>

										<div className="flex w-[100px] flex-col items-start gap-2">
											<form.Field
												name="smtpPort"
												validators={{
													onBlur: ({ value }) => {
														try {
															z.string().min(1, "SMTP port is required").parse(value);
															return undefined;
														} catch (error) {
															return error instanceof z.ZodError ? error.errors[0]?.message : "SMTP port is required";
														}
													},
												}}>
												{field => (
													<div className="w-full">
														<Label htmlFor="smtpPort" className="mb-2 block whitespace-nowrap">
															SMTP Port
														</Label>
														<Input
															id="smtpPort"
															type="text"
															className={getFieldErrorClass(field)}
															value={field.state.value}
															onChange={e => field.handleChange(e.target.value)}
															onBlur={field.handleBlur}
														/>
														{field.state.meta.errors && field.state.meta.errors.length > 0 && (
															<p className="mt-1 text-xs text-red-500">{field.state.meta.errors[0]}</p>
														)}
													</div>
												)}
											</form.Field>
										</div>
									</div>
								</div>
								<p className="text-label-faint w-65 self-end text-xs">
									Most SMTP servers default to using ports 465 (most common), 587, or 25 (least common).
								</p>
							</div>

							<div className="mb-6">
								<div className="mb-4 self-stretch text-base font-semibold">SMTP Credentials</div>
								<div className="grid grid-cols-1 gap-4 md:grid-cols-2">
									<form.Field
										name="smtpUsername"
										validators={{
											onBlur: ({ value }) => {
												try {
													z.string().min(1, "SMTP username is required").parse(value);
													return undefined;
												} catch (error) {
													return error instanceof z.ZodError ? error.errors[0]?.message : "SMTP username is required";
												}
											},
										}}>
										{field => (
											<div>
												<Label htmlFor="smtpUsername" className="mb-2 block">
													Username
												</Label>
												<Input
													id="smtpUsername"
													type="text"
													className={getFieldErrorClass(field)}
													value={field.state.value}
													onChange={e => field.handleChange(e.target.value)}
													onBlur={field.handleBlur}
												/>
												{field.state.meta.errors && field.state.meta.errors.length > 0 && (
													<p className="mt-1 text-xs text-red-500">{field.state.meta.errors[0]}</p>
												)}
											</div>
										)}
									</form.Field>

									<form.Field
										name="smtpPassword"
										validators={{
											onBlur: ({ value }) => {
												try {
													z.string().min(1, "SMTP password is required").parse(value);
													return undefined;
												} catch (error) {
													return error instanceof z.ZodError ? error.errors[0]?.message : "SMTP password is required";
												}
											},
										}}>
										{field => (
											<div>
												<Label htmlFor="smtpPassword" className="mb-2 block">
													Password
												</Label>
												<Input
													id="smtpPassword"
													type="password"
													className={getFieldErrorClass(field)}
													value={field.state.value}
													onChange={e => field.handleChange(e.target.value)}
													onBlur={field.handleBlur}
												/>
												{field.state.meta.errors && field.state.meta.errors.length > 0 && (
													<p className="mt-1 text-xs text-red-500">{field.state.meta.errors[0]}</p>
												)}
											</div>
										)}
									</form.Field>
								</div>
							</div>
						</div>

						<div className="w-full">
							<div className="flex items-center justify-between">
								<h3 className="mb-2 text-base font-semibold">IMAP Settings</h3>
							</div>
							<p className="text-label-label-muted mb-6 text-sm">
								Syncing incoming responses to the Inbox relies on the IMAP protocol.
							</p>

							<div className="mb-6 grid grid-cols-1 gap-4 md:grid-cols-2">
								<div className="flex flex-col items-start gap-2">
									<form.Field
										name="imapServer"
										validators={{
											onBlur: ({ value }) => {
												try {
													z.string().min(1, "IMAP server is required").parse(value);
													return undefined;
												} catch (error) {
													return error instanceof z.ZodError ? error.errors[0]?.message : "IMAP server is required";
												}
											},
										}}>
										{field => (
											<div className="w-full">
												<Label htmlFor="imapServer" className="mb-2 block">
													IMAP Server Hostname
												</Label>
												<Input
													id="imapServer"
													type="text"
													className={getFieldErrorClass(field)}
													placeholder="imap.example.com"
													value={field.state.value}
													onChange={e => field.handleChange(e.target.value)}
													onBlur={field.handleBlur}
												/>
												{field.state.meta.errors && field.state.meta.errors.length > 0 && (
													<p className="mt-1 text-xs text-red-500">{field.state.meta.errors[0]}</p>
												)}
											</div>
										)}
									</form.Field>
								</div>
							</div>

							<div className="mb-8 flex items-end gap-3">
								<div className="flex flex-col gap-0">
									<div className="flex gap-4">
										<div className="flex flex-1 flex-col items-start gap-2">
											<form.Field name="connectionType">
												{field => (
													<div className="w-full">
														<Label className="w-47 mb-2 block whitespace-nowrap">Connection type</Label>
														<div className="relative w-full">
															<DropdownMenu>
																<DropdownMenuTrigger className="border-background-bg-border bg-background-bg-base shadow-low text-label-title text-body-base leading-body-base flex h-8 w-full items-center gap-2 self-stretch rounded-[6px] border-[0.5px] px-2 py-0">
																	<span className="flex-[1_0_0] text-left">
																		{field.state.value === "secure" ? "Secure (encrypted)" : "Unencrypted"}
																	</span>
																	<ChevronDown className="text-label-label-muted h-4 w-4" />
																</DropdownMenuTrigger>
																<DropdownMenuContent variant="full-width" align="start" sideOffset={4}>
																	<DropdownMenuItem onClick={() => field.handleChange("secure")}>
																		Secure (encrypted)
																	</DropdownMenuItem>
																	<DropdownMenuItem onClick={() => field.handleChange("unencrypted")}>
																		Unencrypted
																	</DropdownMenuItem>
																</DropdownMenuContent>
															</DropdownMenu>
														</div>
													</div>
												)}
											</form.Field>
										</div>

										<div className="flex w-[100px] flex-col items-start gap-2">
											<form.Field
												name="imapPort"
												validators={{
													onBlur: ({ value }) => {
														try {
															z.string().min(1, "IMAP port is required").parse(value);
															return undefined;
														} catch (error) {
															return error instanceof z.ZodError ? error.errors[0]?.message : "IMAP port is required";
														}
													},
												}}>
												{field => (
													<div className="w-full">
														<Label htmlFor="imapPort" className="mb-2 block w-[100px] whitespace-nowrap">
															IMAP Port
														</Label>
														<Input
															id="imapPort"
															type="text"
															className={getFieldErrorClass(field)}
															value={field.state.value}
															onChange={e => field.handleChange(e.target.value)}
															onBlur={field.handleBlur}
														/>
														{field.state.meta.errors && field.state.meta.errors.length > 0 && (
															<p className="mt-1 text-xs text-red-500">{field.state.meta.errors[0]}</p>
														)}
													</div>
												)}
											</form.Field>
										</div>
									</div>
								</div>
							</div>

							<div className="mb-4 flex items-center justify-between">
								<div className="text-base font-semibold">IMAP Credentials</div>
								<div className="flex items-center">
									<Button variant="exit" type="button" onClick={copySmtpCredentials} disabled={typingInProgress}>
										{typingInProgress ? "Copying..." : "Use SMTP credentials"}
									</Button>
								</div>
							</div>

							{copyError && (
								<div className="mb-4 rounded-md bg-yellow-50 p-3 text-yellow-800">
									<p>{copyError}</p>
								</div>
							)}

							<div className="mb-6">
								<div className="grid grid-cols-1 gap-4 md:grid-cols-2">
									<form.Field
										name="imapUsername"
										validators={{
											onBlur: ({ value }) => {
												try {
													z.string().min(1, "IMAP username is required").parse(value);
													return undefined;
												} catch (error) {
													return error instanceof z.ZodError ? error.errors[0]?.message : "IMAP username is required";
												}
											},
										}}>
										{field => (
											<div>
												<Label htmlFor="imapUsername" className="mb-2 block">
													Username
												</Label>
												<Input
													id="imapUsername"
													type="text"
													className={`${getFieldErrorClass(field)} ${animatingFields.includes("imapUsername") ? "typing-cursor" : ""}`}
													value={field.state.value}
													onChange={e => field.handleChange(e.target.value)}
													onBlur={field.handleBlur}
													disabled={typingInProgress}
												/>
												{field.state.meta.errors && field.state.meta.errors.length > 0 && (
													<p className="mt-1 text-xs text-red-500">{field.state.meta.errors[0]}</p>
												)}
											</div>
										)}
									</form.Field>

									<form.Field
										name="imapPassword"
										validators={{
											onBlur: ({ value }) => {
												try {
													z.string().min(1, "IMAP password is required").parse(value);
													return undefined;
												} catch (error) {
													return error instanceof z.ZodError ? error.errors[0]?.message : "IMAP password is required";
												}
											},
										}}>
										{field => (
											<div>
												<Label htmlFor="imapPassword" className="mb-2 block">
													Password
												</Label>
												<Input
													id="imapPassword"
													type="password"
													className={`${getFieldErrorClass(field)} ${animatingFields.includes("imapPassword") ? "typing-cursor" : ""}`}
													value={field.state.value}
													onChange={e => field.handleChange(e.target.value)}
													onBlur={field.handleBlur}
													disabled={typingInProgress}
												/>
												{field.state.meta.errors && field.state.meta.errors.length > 0 && (
													<p className="mt-1 text-xs text-red-500">{field.state.meta.errors[0]}</p>
												)}
											</div>
										)}
									</form.Field>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SMTPSetup;
