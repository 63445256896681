import React from "react";
interface ProgressCircleProps {
	percentage: number;
	size?: number;
	status?: "active" | "draft" | "completed" | "paused" | "archived";
}

const colors = {
	green: "#58C172",
	yellow: "#ffa600",
};

const getProgressColor = (status?: "active" | "draft" | "completed" | "paused" | "archived") => {
	if (status === "active") return colors.green;
	if (status === "paused") return colors.yellow;
	return colors.green; // Default to green for other statuses
};

// Add CSS for the transition
const progressPathStyle = {
	transition: "stroke 150ms ease", // Using a quick 150ms transition with ease timing for a responsive feel
};

const ProgressCircle: React.FC<ProgressCircleProps> = ({ percentage, size = 14, status }) => {
	const clampedPercentage = Math.max(0, Math.min(1, percentage / 100));

	const angle = clampedPercentage * 360;

	const backgroundCircleColor = "#D9D9D9";

	const getProgressPath = () => {
		if (clampedPercentage === 0) return "";
		if (clampedPercentage === 1) return "";

		const angleRad = (angle - 90) * (Math.PI / 180);
		const radius = size / 2 - size / 7;
		const centerX = size / 2;
		const centerY = size / 2;

		const endX = centerX + radius * Math.cos(angleRad);
		const endY = centerY + radius * Math.sin(angleRad);

		const largeArcFlag = angle > 180 ? 1 : 0;

		return `M ${centerX} ${size / 7} 
				A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`;
	};

	const getCheckmarkPath = () => {
		const scale = size / 14; // Scale factor based on default size of 14
		const path = `M${7 * scale} 0C${3.13401 * scale} 0 0 ${3.13401 * scale} 0 ${7 * scale}C0 ${10.866 * scale} ${3.13401 * scale} ${14 * scale} ${7 * scale} ${14 * scale}C${10.866 * scale} ${14 * scale} ${14 * scale} ${10.866 * scale} ${14 * scale} ${7 * scale}C${14 * scale} ${3.13401 * scale} ${10.866 * scale} 0 ${7 * scale} 0ZM${9.6418 * scale} ${5.69331 * scale}C${9.88659 * scale} ${5.39407 * scale} ${9.84249 * scale} ${4.95306 * scale} ${9.54324 * scale} ${4.70825 * scale}C${9.24406 * scale} ${4.46345 * scale} ${8.80306 * scale} ${4.50754 * scale} ${8.5582 * scale} ${4.80675 * scale}L${5.89806 * scale} ${8.05812 * scale}L${5.04498 * scale} ${7.20503 * scale}C${4.77161 * scale} ${6.93168 * scale} ${4.32839 * scale} ${6.93168 * scale} ${4.05502 * scale} ${7.20503 * scale}C${3.78166 * scale} ${7.47838 * scale} ${3.78166 * scale} ${7.92162 * scale} ${4.05502 * scale} ${8.19497 * scale}L${5.45502 * scale} ${9.59497 * scale}C${5.59487 * scale} ${9.73483 * scale} ${5.78732 * scale} ${9.80903 * scale} ${5.98486 * scale} ${9.79916 * scale}C${6.1824 * scale} ${9.78929 * scale} ${6.3665 * scale} ${9.69633 * scale} ${6.4918 * scale} ${9.54331 * scale}L${9.6418 * scale} ${5.69331 * scale}Z`;
		return path;
	};

	return (
		<svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
			{clampedPercentage === 1 ? (
				<g>
					<path fillRule="evenodd" clipRule="evenodd" d={getCheckmarkPath()} fill={colors.green} />
				</g>
			) : (
				<>
					<circle
						cx={size / 2}
						cy={size / 2}
						r={size / 2 - size / 7}
						stroke={backgroundCircleColor}
						strokeWidth={size / 5}
					/>

					{clampedPercentage > 0 && (
						<path
							d={getProgressPath()}
							stroke={getProgressColor(status)}
							strokeWidth={size / 5}
							strokeLinecap="round"
							fill="none"
							style={progressPathStyle}
						/>
					)}
				</>
			)}
		</svg>
	);
};

export default ProgressCircle;
