import React, { useEffect, useState } from "react";
import { Button } from "../ui/button";
import { Textarea } from "../ui/textarea";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import { TThreadWithMessagesAndAccounts } from "@za-zu/types";
import DoubleCheckmarkIcon from "@/assets/icons/svgs/double-checkmark.svg?react";

interface EmailReplyFormProps {
	thread: TThreadWithMessagesAndAccounts;
	recipientName: string;
	messageText: string;
	onMessageChange: (text: string) => void;
	onSend: (thread: TThreadWithMessagesAndAccounts, messageText: string, markAsDone: boolean) => string;
	onDiscard: () => void;
	textareaRef?: React.RefObject<HTMLTextAreaElement>;
	onFocusChange: (isFocused: boolean) => void;
	isArchived: boolean;
	onUnarchive: () => void;
}

export const EmailReplyForm = ({
	thread,
	recipientName,
	messageText,
	onMessageChange,
	onSend,
	onDiscard,
	textareaRef,
	onFocusChange,
	isArchived,
	onUnarchive,
}: EmailReplyFormProps) => {
	const [isMac, setIsMac] = useState(false);

	useEffect(() => {
		setIsMac(navigator.platform.toUpperCase().indexOf("MAC") >= 0);
	}, []);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key === ".") {
				event.preventDefault();
				onDiscard();
			}

			// Add command/ctrl + enter handler
			if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
				event.preventDefault();
				onSend(thread, messageText, true);
			}
		};

		window.addEventListener("keydown", handleKeyDown);

		if (textareaRef?.current) {
			textareaRef.current.focus();
		}

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [onDiscard, textareaRef, onSend, thread, messageText]);

	return (
		<div className="bg-background-bg-base flex w-full flex-col items-start gap-3 overflow-hidden rounded-[10px] p-4">
			<div className="flex w-full items-center gap-1">
				<div className="flex h-6 items-center gap-1.5 rounded-full bg-[#DFF3E8] px-2 text-[#5FA97B]">Draft</div>
				<div className="text-label-title text-campaign leading-preview line-clamp-1 flex-1 overflow-hidden text-ellipsis font-medium">
					to {recipientName}
				</div>
			</div>

			<Textarea
				ref={textareaRef}
				className="text-label-title text-body-head leading-body-head w-full resize-none border-none px-0 py-0 font-normal focus-visible:ring-0 focus-visible:ring-offset-0"
				value={messageText}
				onChange={e => onMessageChange(e.target.value)}
				onFocus={() => onFocusChange(true)}
				onBlur={() => onFocusChange(false)}
				onInput={e => {
					const target = e.target as HTMLTextAreaElement;
					target.style.height = "auto";
					target.style.height = `${target.scrollHeight}px`;
				}}
				placeholder="Type your reply"
			/>

			<div className="flex w-full justify-end">
				<div className="flex items-center gap-3">
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								variant="ghost"
								className="bg-background-bg-shade text-label-label-muted flex h-8 items-center justify-center gap-1.5 rounded-full px-3"
								onClick={onDiscard}>
								<span className="text-[13px] leading-[18px]">Discard</span>
							</Button>
						</TooltipTrigger>
						<TooltipContent>
							<kbd>{isMac ? "⌘" : "ctrl"}</kbd>
							<kbd>shift</kbd>
							<kbd>.</kbd>
						</TooltipContent>
					</Tooltip>
					{!isArchived ? (
						<>
							<Button
								variant="outline"
								className="border-background-bg-divider h-8 rounded-full border px-3"
								onClick={() => onSend(thread, messageText, false)}>
								Send
							</Button>

							<Tooltip>
								<TooltipTrigger asChild>
									<Button
										className="bg-background-bg-brand text-label-label-contrast inline-flex h-8 shrink-0 items-center justify-center gap-1.5 rounded-full px-3 text-[13px] font-medium leading-[18px]"
										onClick={() => onSend(thread, messageText, true)}>
										Send +
										<DoubleCheckmarkIcon className="text-label-label-contrast h-4 w-4" />
									</Button>
								</TooltipTrigger>
								<TooltipContent>
									Send & Mark Done <kbd>{isMac ? "⌘" : "ctrl"}</kbd>
									<kbd>enter</kbd>
								</TooltipContent>
							</Tooltip>
						</>
					) : (
						<Button
							variant="outline"
							className="h-8 rounded-full px-3"
							onClick={() => {
								onUnarchive();
							}}>
							Unarchive
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

EmailReplyForm.displayName = "EmailReplyForm";
