import { useEffect } from "react";
import type { EmailState, EmailHandlers } from "@/components/EmailModal/types";
import { TMessageNewRow } from "@za-zu/types";

export const useEmailKeyboardShortcuts = (
	emailState: EmailState,
	handlers: EmailHandlers,
	textareaRef: React.RefObject<HTMLTextAreaElement>,
	updateEmailState: (updates: Partial<EmailState>) => void,
	onArchive: () => void,
	sortedThreadEmails?: TMessageNewRow[],
	onToggleMessageHeader?: () => void,
	onToggleReadStatus?: () => void,
) => {
	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			try {
				// Skip if we're in a textarea and not using a special command
				if (
					document.activeElement === textareaRef.current ||
					document.activeElement?.tagName === "TEXTAREA" ||
					document.activeElement?.tagName === "INPUT"
				) {
					// Handle command/ctrl + enter for sending when in reply mode
					if ((e.metaKey || e.ctrlKey) && e.key === "Enter" && emailState.replyingToId) {
						e.preventDefault();
						// Let the EmailReplyForm handle this since it has the message text and thread
						return;
					}
					return;
				}

				if (e.key === "ArrowUp" || e.key === "ArrowDown") {
					e.preventDefault();
					if (!sortedThreadEmails?.length) return;

					const currentIndex = sortedThreadEmails.findIndex(email => email.e_id === emailState.activeId);
					let nextIndex: number;

					if (currentIndex === -1) {
						// If no email is selected, select the first or last one depending on direction
						nextIndex = e.key === "ArrowUp" ? sortedThreadEmails.length - 1 : 0;
					} else {
						// Move up or down, but stop at boundaries
						if (e.key === "ArrowUp") {
							if (currentIndex === 0) return; // Stop at top
							nextIndex = currentIndex - 1;
						} else {
							if (currentIndex === sortedThreadEmails.length - 1) return; // Stop at bottom
							nextIndex = currentIndex + 1;
						}
					}

					const nextEmailId = sortedThreadEmails[nextIndex].e_id;
					updateEmailState({
						activeId: nextEmailId,
						hoveredId: nextEmailId,
					});
				} else if (e.key === "Escape") {
					e.preventDefault();
					// If replying, cancel reply mode first
					if (emailState.replyingToId) {
						updateEmailState({
							replyingToId: null,
							messageText: "",
						});
					} else {
						// Otherwise, close modal
						handlers.onClose();
					}
				} else if (e.key === "r" && !e.metaKey && !e.ctrlKey && !e.altKey && !e.shiftKey) {
					if (!emailState.activeId) {
						return;
					}
					e.preventDefault();
					const expandedIds = new Set(emailState.expandedIds);

					// If this email is already in reply mode, focus its textarea
					if (emailState.replyingToId === emailState.activeId && textareaRef.current) {
						textareaRef.current.focus();
						return;
					}

					if (expandedIds.has(emailState.activeId)) {
						// If email is already expanded, start replying
						handlers.handleStartReply(emailState.activeId);
					} else {
						// Expand the active email without collapsing others
						expandedIds.add(emailState.activeId);
						updateEmailState({
							expandedIds,
							activeId: emailState.activeId,
						});
					}
				} else if (e.key === "Enter") {
					if (!emailState.activeId) {
						return;
					}
					e.preventDefault();
					const expandedIds = new Set(emailState.expandedIds);

					// If this email is already in reply mode, focus its textarea
					if (emailState.replyingToId === emailState.activeId && textareaRef.current) {
						textareaRef.current.focus();
						return;
					}

					if (expandedIds.has(emailState.activeId)) {
						// If email is already expanded, start replying
						handlers.handleStartReply(emailState.activeId);
					} else {
						// Expand the active email without collapsing others
						expandedIds.add(emailState.activeId);
						updateEmailState({
							expandedIds,
							activeId: emailState.activeId,
						});
					}
				} else if (e.key.toLowerCase() === "o") {
					if (!emailState.activeId) {
						return;
					}

					e.preventDefault();
					const expandedIds = new Set(emailState.expandedIds);
					if (expandedIds.has(emailState.activeId)) {
						// If email is expanded, collapse it
						expandedIds.delete(emailState.activeId);
					} else {
						// If email is expanded, expand it
						expandedIds.add(emailState.activeId);
					}
					updateEmailState({
						expandedIds,
						activeId: emailState.activeId,
					});
				} else if (e.key.toLowerCase() === "e") {
					e.preventDefault();
					onArchive();
				} else if (e.key.toLowerCase() === "u" && onToggleReadStatus) {
					e.preventDefault();
					onToggleReadStatus();
				} else if (e.key.toLowerCase() === "h" && e.shiftKey && onToggleMessageHeader) {
					e.preventDefault();
					onToggleMessageHeader();
				} else if (e.key.toLowerCase() === "j") {
					e.preventDefault();
					handlers.handleNavigateToAdjacentThread("previous");
				} else if (e.key.toLowerCase() === "k") {
					e.preventDefault();
					handlers.handleNavigateToAdjacentThread("next");
				}
			} catch (error) {
				console.error("Error handling keyboard shortcut:", error);
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [
		emailState.activeId,
		emailState.expandedIds,
		emailState.replyingToId,
		handlers,
		onArchive,
		onToggleMessageHeader,
		onToggleReadStatus,
		sortedThreadEmails,
		textareaRef,
		updateEmailState,
	]);
};
