import { NumberHashSVG, TextSVG, ToggleBooleanSVG } from "@/assets/icons/svgs";
import PlusNoCircle from "@/assets/icons/svgs/plus-no-circle.svg?react";
import Info from "@/assets/icons/svgs/info.svg?react";
import { SuggestionPluginState } from "../core/suggestion";
import { CommandItem, ItemCollection } from "../types";
import { senderVariableCommands, senderVariablesSubmenu } from "./senderVariableCommands";

const conditionCommand: CommandItem = {
	title: "Add condition",
	icon: <PlusNoCircle className="h-4 w-4 text-[#F18039]" />,
	command: ({ editor, range }) => {
		return editor.commands.triggerConditionalLogicExtension(editor, range);
	},
};

export const filterCommandItems = (
	query: string | undefined,
	commandItems: ItemCollection,
	maxSuggestionsVisible: number,
	onCreateVariable: (query: string, type: "text" | "number" | "boolean") => string,
	_pluginState: SuggestionPluginState,
): ItemCollection => {
	const normalizedQuery = query?.toLowerCase().trim() ?? "";

	// Clean up the query for display
	const displayQuery = query
		?.trim()
		.split(/\s+/) // Split on any number of whitespace characters
		.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter
		.join(" ");

	// Check if a variable with this name already exists
	const variableExists =
		Boolean(displayQuery) &&
		(commandItems.defaultItems.some(item => item.title.toLowerCase() === displayQuery?.toLowerCase()) ||
			commandItems.customItems.some(item => item.title.toLowerCase() === displayQuery?.toLowerCase()));

	// Create dynamic "Create New" item
	const createCustomFieldItem: CommandItem = {
		title: displayQuery ? `Create "${displayQuery}"` : "Type to create a new variable",
		icon: displayQuery ? (
			<PlusNoCircle className={variableExists ? "text-label-label-title" : "text-label-label-table h-4 w-4"} />
		) : (
			<Info className="text-(--label-label-muted) h-4 w-4" />
		),
		disabled: !displayQuery || variableExists,
		className: displayQuery
			? variableExists
				? "text-label-label-muted"
				: "text-label-label-base"
			: "text-(--label-label-muted)",
		subMenuClassName: "min-w-0",
		children:
			displayQuery && !variableExists
				? [
						{
							title: "Text",
							icon: <TextSVG className="text-label-label-link" />,
							command: async ({ editor, range }) => {
								if (!displayQuery) return;
								// Kick off the creation process and get the tracking ID
								const trackingId = onCreateVariable(displayQuery, "text");
								// Then delete the slash command text
								editor.commands.deleteRange(range);
								// Set the pending variable
								editor.commands.setPendingCustomVariable("", displayQuery, trackingId);
							},
						},
						{
							title: "Number",
							icon: <NumberHashSVG className="text-label-label-link" />,
							command: async ({ editor, range }) => {
								if (!displayQuery) return;
								// Kick off the creation process and get the tracking ID
								const trackingId = onCreateVariable(displayQuery, "number");
								// Then delete the slash command text
								editor.commands.deleteRange(range);
								// Set the pending variable
								editor.commands.setPendingCustomVariable("", displayQuery, trackingId);
							},
						},
						{
							title: "True / False",
							icon: <ToggleBooleanSVG className="text-label-label-link" />,
							command: async ({ editor, range }) => {
								if (!displayQuery) return;
								// Kick off the creation process and get the tracking ID
								const trackingId = onCreateVariable(displayQuery, "boolean");
								// Then delete the slash command text
								editor.commands.deleteRange(range);
								// Set the pending variable
								editor.commands.setPendingCustomVariable("", displayQuery, trackingId);
							},
						},
					]
				: undefined,
	};

	// If there's a search query, include sender variables in the main results
	// If no query, keep them in the submenu
	const senderItems = normalizedQuery ? senderVariableCommands : [];

	let availableSlotsLeft = maxSuggestionsVisible;
	const matchingDefaultItems = [...commandItems.defaultItems, ...senderItems]
		.sort((a, b) => {
			const titleA = a.title.toLowerCase();
			const titleB = b.title.toLowerCase();
			// Prioritize items that start with the query
			const aStartsWith = titleA.startsWith(normalizedQuery);
			const bStartsWith = titleB.startsWith(normalizedQuery);
			if (aStartsWith !== bStartsWith) {
				return aStartsWith ? -1 : 1;
			}
			// Preserve original order for default items
			return 0;
		})
		.filter(item => {
			const title = item.title.toLowerCase();
			const titleWords = title.split(" ");

			// Check if any word starts with the query
			const startsWithMatch = titleWords.some(word => word.startsWith(normalizedQuery));

			// Only do word matching if query is longer than 1 character
			const wordMatch =
				normalizedQuery.length > 1 &&
				normalizedQuery.split(" ").every(word => {
					const anyWordMatches = titleWords.some(titleWord => {
						const doesMatch = titleWord.startsWith(word);
						return doesMatch;
					});
					return anyWordMatches;
				});

			return startsWithMatch || wordMatch;
		})
		.slice(0, availableSlotsLeft);
	availableSlotsLeft -= matchingDefaultItems.length;

	const matchingCustomItems = commandItems.customItems
		.sort((a, b) => {
			const titleA = a.title.toLowerCase();
			const titleB = b.title.toLowerCase();
			// Prioritize items that start with the query
			const aStartsWith = titleA.startsWith(normalizedQuery);
			const bStartsWith = titleB.startsWith(normalizedQuery);
			if (aStartsWith !== bStartsWith) {
				return aStartsWith ? -1 : 1;
			}
			// Apply alphabetical sorting for custom items
			return titleA.localeCompare(titleB);
		})
		.filter(item => {
			const title = item.title.toLowerCase();
			const titleWords = title.split(" ");

			// Check if any word starts with the query
			const startsWithMatch = titleWords.some(word => word.startsWith(normalizedQuery));

			// Only do word matching if query is longer than 1 character
			const wordMatch =
				normalizedQuery.length > 1 &&
				normalizedQuery.split(" ").every(word => {
					const anyWordMatches = titleWords.some(titleWord => {
						const doesMatch = titleWord.startsWith(word);
						return doesMatch;
					});
					return anyWordMatches;
				});

			return startsWithMatch || wordMatch;
		})
		.slice(0, availableSlotsLeft);

	const otherCommands: CommandItem[] = [];

	if (import.meta.env.VITE_ENABLE_CONDITIONS === "true") {
		const conditionTitle = conditionCommand.title.toLowerCase();
		const conditionWords = conditionTitle.split(" ");

		// Check if any word starts with the query (same as other items)
		const startsWithMatch = conditionWords.some(word => word.startsWith(normalizedQuery));

		// Word matching for multi-character queries (same as other items)
		const wordMatch =
			normalizedQuery.length > 1 &&
			normalizedQuery.split(" ").every(word => {
				return conditionWords.some(titleWord => titleWord.startsWith(word));
			});

		if (!displayQuery || startsWithMatch || wordMatch) {
			otherCommands.push(conditionCommand);
		}
	}

	return {
		defaultItems: matchingDefaultItems,
		customItems: matchingCustomItems,
		belowDividerItems: [
			...otherCommands,
			createCustomFieldItem,
			...(normalizedQuery ? [] : [senderVariablesSubmenu]), // Only show submenu when no search
		],
	};
};

export default filterCommandItems;
