import forward from "@/assets/icons/images/forward.png";
import reply from "@/assets/icons/images/reply.png";
import chevronCollapse from "@/assets/icons/svgs/chevron-collapse.svg";
import chevronExpand from "@/assets/icons/svgs/chevron-expand.svg";
import ThreeDots from "@/assets/icons/svgs/three-dots.svg?react";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useEmailAccountsStore } from "@/store";
import { formatTimestamp } from "@/utils/helpers";
import { EmailParticipant, TMessageNewRow, TThreadWithMessagesAndAccounts } from "@za-zu/types";
import React, { forwardRef, memo, useEffect, useState } from "react";
import { z } from "zod";
import { EmailReplyForm } from "./EmailReplyForm";

interface EmailThreadItemProps {
	thread: TThreadWithMessagesAndAccounts;
	threadEmail: TMessageNewRow;
	index: number;
	isExpanded: boolean;
	isActive: boolean;
	isReplying: boolean;
	messageText: string;
	fullMessage?: string;
	onSelect: () => void;
	onReply: () => void;
	onMessageChange: (text: string) => void;
	onSend: (thread: TThreadWithMessagesAndAccounts, messageText: string, markAsDone: boolean) => string;
	onDiscard: () => void;
	isHovered: boolean;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
	textareaRef?: React.RefObject<HTMLTextAreaElement>;
	isArchived: boolean;
	onUnarchive: () => void;
	isSending?: boolean;
	onMessageHeaderToggleRef?: (isActive: boolean) => React.MutableRefObject<(() => void) | null> | null;
}

/**
 * Removes reply history from email text by finding common separator patterns
 * and keeping only the content before the first separator
 */
const removeReplyHistory = (text: string): string => {
	if (!text) return "";

	// Common email reply separators
	const separators = [
		/On.*wrote:/, // Standard "On ... wrote:"
		/^>+/m, // Quoted text with '>'
		/---Original Message---/, // Original Message separator
		/---Forwarded Message---/, // Forwarded Message separator
		/^From:.*Sent:/m, // Outlook style headers
		/^________________________________$/m, // Underline separator
		/<blockquote.*?>/g, // HTML blockquotes
		/<div name="messageReplySection".*?>/g, // Email reply sections
		/<div class="gmail_quote">/g, // Gmail signature
	];

	// Find the first occurrence of any separator
	let cleanedText = text;
	for (const separator of separators) {
		const parts = cleanedText.split(separator);
		if (parts.length > 1) {
			cleanedText = parts[0].trim();
		}
	}

	return cleanedText;
};

/**
 * Cleans email text by removing image tags, trailing quote indicators,
 * and other unwanted elements
 */
const cleanEmailText = (text: string): string => {
	if (!text) return "";

	let cleanedText = text;

	const bodyMatch = /<body[^>]*>([\s\S]*?)<\/body>/i.exec(text);
	if (bodyMatch && bodyMatch[1]) {
		cleanedText = bodyMatch[1];
	}

	const blockquoteIndex = cleanedText.indexOf("<blockquote");
	const replyIndex = cleanedText.indexOf('<div name="messageReplySection"');

	let cutoffIndex = -1;
	if (blockquoteIndex !== -1 && replyIndex !== -1) {
		cutoffIndex = Math.min(blockquoteIndex, replyIndex);
	} else if (blockquoteIndex !== -1) {
		cutoffIndex = blockquoteIndex;
	} else if (replyIndex !== -1) {
		cutoffIndex = replyIndex;
	}

	if (cutoffIndex !== -1) {
		cleanedText = cleanedText.substring(0, cutoffIndex);
	}

	// Clean up any remaining style attributes and script tags
	cleanedText = cleanedText
		.replace(/ style="[^"]*"/g, "")
		.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "")
		// Remove image tags like [image: description]
		.replace(/\[image:.*?\]\s*/g, "")
		// Remove trailing quote indicators
		.replace(/\s*>\s*$/, "")
		// Remove trailing empty divs
		.replace(/<div>\s*<br\s*\/?>\s*<\/div>\s*$/g, "")
		.trim();

	return cleanedText;
};

/**
 * Prepares clean email content by removing both unwanted elements and reply history
 * Used for both the collapsed view preview and the expanded view (before showing full history)
 */
const prepareCleanEmailContent = (text: string): string => {
	if (!text) return "";

	// First clean the text of unwanted elements
	const cleanedText = cleanEmailText(text);
	// Then remove any reply history
	return removeReplyHistory(cleanedText);
};

const formatContextualDate = (date: Date): string => {
	const now = new Date();
	const isToday = date.toDateString() === now.toDateString();

	if (isToday) {
		return date.toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit" });
	}

	return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
};

const formatFullDateTime = (date: Date): string => {
	return date.toLocaleDateString("en-US", {
		weekday: "short",
		month: "short",
		day: "numeric",
		year: "numeric",
		hour: "numeric",
		minute: "2-digit",
		timeZoneName: "short",
	});
};

export const EmailThreadItem = memo(
	forwardRef<HTMLDivElement, EmailThreadItemProps>(
		(
			{
				thread,
				threadEmail,
				isExpanded,
				isActive,
				isReplying,
				messageText,
				fullMessage,
				onSelect,
				onReply,
				onMessageChange,
				onSend,
				onDiscard,
				isHovered,
				onMouseEnter,
				onMouseLeave,
				textareaRef,
				isArchived,
				onUnarchive,
				isSending,
				onMessageHeaderToggleRef,
			},
			ref,
		) => {
			const [isReplyFocused, setIsReplyFocused] = useState(false);
			const [isMessageHeaderExpanded, setIsMessageHeaderExpanded] = useState(false);
			const [isHistoryExpanded, setIsHistoryExpanded] = useState(false);
			const [isHeaderHovered, setIsHeaderHovered] = useState(false);
			const { accounts } = useEmailAccountsStore();

			if (!threadEmail) {
				return <div className="p-4">Email not found</div>;
			}

			const getFirstName = (contact: z.infer<typeof EmailParticipant>): string => contact.name?.split(" ")[0] ?? "";

			const isFromUser = accounts.some(account => account.email === threadEmail.from.address);

			useEffect(() => {
				if (isActive && ref && "current" in ref && ref.current) {
					ref.current.focus();
				}
			}, [isActive, ref]);

			// Update the ref when active state changes
			useEffect(() => {
				if (onMessageHeaderToggleRef) {
					const ref = onMessageHeaderToggleRef(isActive);
					if (ref) {
						ref.current = () => setIsMessageHeaderExpanded(prev => !prev);
					}
				}
			}, [isActive, onMessageHeaderToggleRef]);
			// Collapsed view
			if (!isExpanded) {
				return (
					<div
						ref={ref}
						tabIndex={0}
						className={`outline-hidden relative flex h-12 max-w-[768px] shrink-0 cursor-pointer items-center gap-3 rounded-[10px] ${
							isActive ? "bg-background-bg-dim pl-4 pr-4" : ""
						} ${!isActive && isHovered ? "bg-background-bg-base-hover px-4" : ""} ${
							!isActive && !isHovered ? "px-4" : ""
						} ${isSending ? "bg-yellow-50" : ""}`}
						onClick={onSelect}
						onMouseMove={onMouseEnter}
						onMouseLeave={onMouseLeave}>
						{isActive && <div className="bg-label-link absolute left-0 top-4 h-4 w-0.5 rounded-r-[2px]" />}
						<div className="flex w-[80px] shrink-0 items-center gap-[2px]">
							<span className="font-inter max-w-[124px] shrink-0 text-[13px] font-medium leading-[18px] text-[#292929]">
								{isFromUser ? "Me" : getFirstName(threadEmail.from)}
							</span>
						</div>
						<span className="font-inter flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-[13px] font-normal leading-[18px] text-[#292929]">
							{cleanEmailText(threadEmail.preview || "")}
						</span>
						<time
							className="text-label-label-muted shrink-0 whitespace-nowrap text-[13px]"
							dateTime={new Date(threadEmail.sent_at).toISOString()}>
							{formatTimestamp(threadEmail.sent_at, false)}
						</time>
					</div>
				);
			}
			// Expanded view
			return (
				<div
					ref={ref}
					tabIndex={0}
					className={`group/actions outline-hidden relative flex w-[768px] flex-col items-start rounded-[10px] bg-white shadow-[0px_1px_1px_0px_rgba(0,0,0,0.04),0px_2px_8px_0px_rgba(0,0,0,0.04),0px_3px_17px_0px_rgba(0,0,0,0.04),0px_4px_30px_0px_rgba(0,0,0,0.12)] transition-all ${!isActive ? "border-transparent hover:border-gray-200" : ""} ${isSending ? "bg-yellow-50" : ""}`}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onDoubleClick={() => setIsHistoryExpanded(!isHistoryExpanded)}>
					<div className="flex w-full flex-col items-start p-4">
						<div className="relative w-full">
							{isActive && (!isReplying || !isReplyFocused) && (
								<div className="bg-label-link absolute -left-4 h-full w-0.5 rounded-r-[2px]" />
							)}
							<div className="flex w-full flex-col gap-3">
								<div
									className="relative flex w-full cursor-pointer items-start gap-3 self-stretch"
									onMouseEnter={() => setIsHeaderHovered(true)}
									onMouseLeave={() => setIsHeaderHovered(false)}>
									<div className="flex flex-col gap-1">
										<div className="flex items-center">
											{!isMessageHeaderExpanded ? (
												<>
													<div
														className="hover:bg-background-bg-base-hover -ml-2 flex cursor-pointer items-center gap-1 rounded-md px-2 py-1"
														onMouseEnter={() => setIsHeaderHovered(true)}
														onMouseLeave={() => setIsHeaderHovered(false)}
														onClick={e => {
															e.stopPropagation();
															setIsMessageHeaderExpanded(!isMessageHeaderExpanded);
														}}>
														<span className="text-label-label-base text-preview leading-preview font-medium">
															{isFromUser
																? `Me to ${getFirstName(threadEmail.to)}`
																: `${getFirstName(threadEmail.from)} to Me`}
														</span>
														{isHeaderHovered && (
															<Tooltip>
																<TooltipTrigger asChild>
																	<img src={chevronExpand} alt="Expand" className="h-4 w-4" />
																</TooltipTrigger>
																<TooltipContent side="right">
																	Expand message header{" "}
																	<kbd className="ml-1 rounded border px-1 py-0.5 text-xs">shift</kbd>
																	<kbd className="ml-1 rounded border px-1 py-0.5 text-xs">H</kbd>
																</TooltipContent>
															</Tooltip>
														)}
													</div>
												</>
											) : (
												<div className="flex w-full flex-col items-start gap-1 self-stretch text-xs">
													<div className="flex flex-col gap-1">
														<div className="flex items-start gap-1">
															<span className="text-label-label-base">From:</span>
															<span>
																{threadEmail.from.name}{" "}
																<span className="text-label-link">&lt;{threadEmail.from.address}&gt;</span>
															</span>
														</div>
														<div className="flex items-start gap-1">
															<span className="text-label-label-base">To:</span>
															<span>
																{threadEmail.to.name}{" "}
																<span className="text-label-link">&lt;{threadEmail.to.address}&gt;</span>
															</span>
														</div>
														{/* {(threadEmail.cc?.length ?? 0) > 0 && (
															<div className="flex items-start gap-1">
																<span className="text-label-label-base">Cc:</span>
																<span>
																	{threadEmail.cc?.map((recipient, index) => (
																		<React.Fragment key={recipient.address}>
																			{recipient.name}{" "}
																			<span className="text-label-link">&lt;{recipient.address}&gt;</span>
																			{index < (threadEmail.cc?.length ?? 0) - 1 ? ", " : ""}
																		</React.Fragment>
																	))}
																</span>
															</div>
														)}
														{(threadEmail.bcc?.length ?? 0) > 0 && (
															<div className="flex items-start gap-1">
																<span className="text-label-label-base">Bcc:</span>
																<span>
																	{threadEmail.bcc?.map((recipient, index) => (
																		<React.Fragment key={recipient.address}>
																			{recipient.name}{" "}
																			<span className="text-label-link">&lt;{recipient.address}&gt;</span>
																			{index < (threadEmail.bcc?.length ?? 0) - 1 ? ", " : ""}
																		</React.Fragment>
																	))}
																</span>
															</div>
														)} */}
														<div className="flex items-center gap-1">
															<time
																className="font-geist text-label-label-muted overflow-hidden text-ellipsis text-[11px] font-medium leading-[16px] [-webkit-box-orient:vertical] [-webkit-line-clamp:1] [display:-webkit-box]"
																dateTime={new Date(threadEmail.sent_at).toISOString()}>
																{new Date(threadEmail.sent_at).toLocaleDateString("en-US", {
																	weekday: "long",
																	day: "numeric",
																	month: "long",
																	year: "numeric",
																	hour: "numeric",
																	minute: "numeric",
																	hour12: true,
																})}
															</time>
															<Tooltip>
																<TooltipTrigger asChild>
																	<button
																		onClick={e => {
																			e.stopPropagation();
																			setIsMessageHeaderExpanded(!isMessageHeaderExpanded);
																		}}
																		className="flex items-center justify-center">
																		<img src={chevronCollapse} alt="Collapse" className="h-4 w-4" />
																	</button>
																</TooltipTrigger>
																<TooltipContent side="right">
																	Collapse message header{" "}
																	<kbd className="ml-1 rounded border px-1 py-0.5 text-xs">shift</kbd>
																	<kbd className="ml-1 rounded border px-1 py-0.5 text-xs">H</kbd>
																</TooltipContent>
															</Tooltip>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
									<div className="absolute right-0 top-[-1px] flex items-center gap-2">
										<div className="invisible flex items-center gap-[2px] opacity-0 transition-all duration-500 ease-in-out group-hover/actions:visible group-hover/actions:opacity-100">
											<Tooltip>
												<TooltipTrigger asChild>
													<button
														className="hover:bg-background-bg-dim flex h-5 w-5 items-center justify-center gap-1 rounded-[5px] p-0 px-1"
														onClick={e => {
															e.stopPropagation();
															onReply();
														}}>
														<img src={reply} alt="Reply" className="h-3.5 w-3.5 shrink-0" />
													</button>
												</TooltipTrigger>
												<TooltipContent>
													Reply <kbd className="ml-1 rounded border px-1 py-0.5 text-xs">R</kbd>
												</TooltipContent>
											</Tooltip>

											<Tooltip>
												<TooltipTrigger asChild>
													<button
														className="hover:bg-background-bg-dim flex h-5 w-5 items-center justify-center gap-1 rounded-[5px] p-0 px-1"
														onClick={e => e.stopPropagation()}>
														<img src={forward} alt="Forward" className="h-3.5 w-3.5 shrink-0" />
													</button>
												</TooltipTrigger>
												<TooltipContent>
													Forward <kbd className="ml-1 rounded border px-1 py-0.5 text-xs">F</kbd>
												</TooltipContent>
											</Tooltip>
										</div>
										{!isMessageHeaderExpanded && (
											<Tooltip>
												<TooltipTrigger asChild>
													<time
														className="text-body-base text-label-label-muted overflow-hidden truncate text-right"
														dateTime={new Date(threadEmail.sent_at).toISOString()}>
														{formatContextualDate(new Date(threadEmail.sent_at))}
													</time>
												</TooltipTrigger>
												<TooltipContent side="left">{formatFullDateTime(new Date(threadEmail.sent_at))}</TooltipContent>
											</Tooltip>
										)}
									</div>
								</div>

								<div className="flex flex-col gap-2">
									{/* Always show the clean message content */}
									<div
										className="text-label-label-base text-body-head leading-body-head [&_a]:text-label-link self-stretch"
										dangerouslySetInnerHTML={{
											__html: prepareCleanEmailContent(fullMessage || threadEmail.preview || ""),
										}}
									/>

									{/* Show three dots between clean message and history */}
									{thread.messages && thread.messages[0]?.e_id !== threadEmail.e_id && (
										<button
											onClick={() => setIsHistoryExpanded(!isHistoryExpanded)}
											className="border-background-bg-divider flex h-4 items-center justify-center gap-1 self-start rounded-[32px] border-[0.5px] px-[6px]">
											<ThreeDots
												className={`h-[4px] w-5 ${isHistoryExpanded ? "text-label-link" : "text-label-label-muted"}`}
											/>
										</button>
									)}

									{/* Show history content only when expanded */}
									{isHistoryExpanded && thread.messages && thread.messages[0]?.e_id !== threadEmail.e_id && (
										<div
											className="text-label-label-muted text-body-head leading-body-head border-background-bg-divider mt-1 self-stretch border-l-2 pl-3"
											dangerouslySetInnerHTML={{
												__html: fullMessage ? fullMessage.replace(prepareCleanEmailContent(fullMessage), "") : "",
											}}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="border-background-bg-border w-full border-t-[0.5px]">
						{isReplying ? (
							<EmailReplyForm
								thread={thread}
								recipientName={getFirstName(threadEmail.to)}
								messageText={messageText}
								onMessageChange={onMessageChange}
								onSend={onSend}
								onDiscard={onDiscard}
								textareaRef={textareaRef}
								onFocusChange={setIsReplyFocused}
								isArchived={isArchived}
								onUnarchive={onUnarchive}
							/>
						) : (
							<div className="bg-background-bg-base flex w-full items-center gap-3" />
						)}
					</div>
				</div>
			);
		},
	),
	(prevProps, nextProps) => {
		const arePropsEqual =
			prevProps.threadEmail.e_id === nextProps.threadEmail.e_id &&
			prevProps.isExpanded === nextProps.isExpanded &&
			prevProps.isActive === nextProps.isActive &&
			prevProps.isReplying === nextProps.isReplying &&
			prevProps.messageText === nextProps.messageText &&
			prevProps.isHovered === nextProps.isHovered &&
			prevProps.fullMessage === nextProps.fullMessage;
		return arePropsEqual;
	},
);

EmailThreadItem.displayName = "EmailThreadItem";
