import { findBestMatch } from "string-similarity";
import { CSVColumn, AvailableField } from "../types";

/**
 * Normalizes a string by applying Unicode normalization, converting to lowercase,
 * and removing non-alphanumeric characters.
 */
export const normalize = (str: string) =>
	str
		.normalize("NFC")
		.toLocaleLowerCase()
		.replace(/[^a-z0-9]/gi, "");

/**
 * Checks if the CSV column (normalized) should force an override mapping.
 */
function getOverrideMapping(csvColumnNormalized: string, normalizedFields: string[]): { index: number } | null {
	if (csvColumnNormalized.includes("validatedworkemail")) {
		const emailIndex = normalizedFields.findIndex(label => label === "email");
		if (emailIndex !== -1) {
			return { index: emailIndex };
		}
	}
	if (csvColumnNormalized.includes("organizationname")) {
		const companyIndex = normalizedFields.findIndex(label => label === "companyname");
		if (companyIndex !== -1) {
			return { index: companyIndex };
		}
	}
	return null;
}

/**
 * Auto-maps CSV columns to existing fields.
 *
 * This function first checks if the CSV header directly matches a custom field id
 * (e.g. if the header is "custom.abc123" and there is an available custom field with that id).
 * If a direct match is found, the mapping is applied immediately. Otherwise, it falls back to
 * fuzzy matching on field labels (with additional handling for custom fields).
 */
export function autoMapColumns(columns: CSVColumn[], existingFields: AvailableField[]): CSVColumn[] {
	const threshold = 0.8;
	// Copy the available fields into a pool so that once a field is mapped it’s removed.
	const pool = [...existingFields];

	return columns.map(csvColumn => {
		const rawHeader = csvColumn.customTitle || csvColumn.id;
		const csvNormalizedOriginal = normalize(rawHeader);
		// Also create a stripped version if the header starts with "custom"
		const csvNormalizedStripped = csvNormalizedOriginal.startsWith("custom")
			? csvNormalizedOriginal.replace(/^custom/, "")
			: csvNormalizedOriginal;

		let selectedField: AvailableField | null = null;

		if (pool.length > 0) {
			// --- Direct Matching for Custom Fields ---
			// If the CSV header explicitly looks like a custom field id (e.g. "custom.abc123")
			// then check if that directly matches one of the custom fields.
			if (rawHeader.toLowerCase().startsWith("custom.")) {
				const directMatchIndex = pool.findIndex(
					field => field.type === "custom" && normalize(field.id) === csvNormalizedOriginal,
				);
				if (directMatchIndex !== -1) {
					selectedField = pool[directMatchIndex];
					pool.splice(directMatchIndex, 1);
					return { ...csvColumn, mappedTo: selectedField.id };
				}
			}

			// --- Fuzzy Matching ---
			// Normalize the labels of the remaining fields.
			const normalizedFields = pool.map(field => normalize(field.label));

			// Run fuzzy matching with both the original and stripped header values.
			const fuzzyResultsOriginal = findBestMatch(csvNormalizedOriginal, normalizedFields);
			const fuzzyResultsStripped = findBestMatch(csvNormalizedStripped, normalizedFields);

			const boostedRatings = fuzzyResultsOriginal.ratings.map((result, i) => {
				// For custom fields, pick the higher rating between the two variants.
				let baseRating = result.rating;
				if (pool[i].type === "custom") {
					baseRating = Math.max(baseRating, fuzzyResultsStripped.ratings[i].rating);
				}
				// Use the stripped version for boost calculations if this is a custom field.
				const boostCandidate = pool[i].type === "custom" ? csvNormalizedStripped : csvNormalizedOriginal;
				let boost = 0;
				if (boostCandidate.startsWith(normalizedFields[i])) {
					boost = 0.5;
				} else if (boostCandidate.includes(normalizedFields[i])) {
					boost = 0.1;
				}
				return { ...result, rating: baseRating + boost, index: i };
			});

			// Find the best match from the boosted ratings.
			let bestMatch = { target: "", rating: 0, index: -1 };
			boostedRatings.forEach(result => {
				if (result.rating > bestMatch.rating) {
					bestMatch = result;
				}
			});

			// Check for any override mapping conditions.
			const overrideMapping = getOverrideMapping(csvNormalizedOriginal, normalizedFields);
			if (overrideMapping) {
				const overrideRating = boostedRatings[overrideMapping.index].rating;
				if (bestMatch.index === -1 || bestMatch.rating < threshold || bestMatch.rating < overrideRating) {
					bestMatch = boostedRatings[overrideMapping.index];
				}
			}

			const isOverride = overrideMapping && bestMatch.index === overrideMapping.index;
			if (bestMatch.index !== -1 && (isOverride || bestMatch.rating >= threshold)) {
				selectedField = pool[bestMatch.index];
				pool.splice(bestMatch.index, 1);
			}
		}

		return { ...csvColumn, mappedTo: selectedField ? selectedField.id : null };
	});
}
