import * as React from "react";
import { cn } from "@/lib/utils";
import InfoBubbleSVG from "@/assets/icons/svgs/info-bubble.svg?react";

export interface InfoBubbleProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode;
}

const InfoBubble = React.forwardRef<HTMLDivElement, InfoBubbleProps>(({ className, children, ...props }, ref) => {
	return (
		<div
			className={cn(
				"inline-flex h-6 flex-shrink-0 items-center gap-2 px-3 py-2",
				"rounded-[32px]",
				"bg-[color(display-p3_0.2235_0.4784_0.9451_/_0.12)]",
				className,
			)}
			ref={ref}
			{...props}>
			<InfoBubbleSVG className="h-3.5 w-3.5" />
			<span className="text-label-label-link text-mini-plus leading-mini-plus font-normal">{children}</span>
		</div>
	);
});

InfoBubble.displayName = "InfoBubble";

export { InfoBubble };

/**
 * Usage example:
 *
 * ```tsx
 * import { InfoBubble } from "@/components/ui/info-bubble";
 *
 * export default function MyComponent() {
 *   return (
 *     <div>
 *       <h2>Account Status</h2>
 *       <InfoBubble>Active until May 2023</InfoBubble>
 *     </div>
 *   );
 * }
 * ```
 */
