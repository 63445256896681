import React from "react";
import { Table, TableBody, TableHeader, TableRow, TableCell, TableHeaderRow, TableHead } from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";
import { MotionConfig } from "motion/react";

/**
 * Skeleton loader for email account rows with staggered animation
 */
const EmailAccountRowSkeleton: React.FC<{ index: number; showCheckbox?: boolean }> = ({
	index,
	showCheckbox = true,
}) => {
	// Stagger animation by 50ms per row
	const staggerDelay = index * 0.05;

	return (
		<TableRow className="border-background-bg-divider relative border-b">
			{showCheckbox && (
				<TableCell className="w-[56px]">
					<Skeleton className="h-4 w-4 rounded-sm" delay={staggerDelay} duration={0.8} />
				</TableCell>
			)}

			{/* Email */}
			<TableCell>
				<Skeleton className="h-5 w-44" delay={staggerDelay} duration={0.8} />
			</TableCell>

			{/* Name */}
			<TableCell>
				<Skeleton className="h-5 w-32" delay={staggerDelay} duration={0.8} />
			</TableCell>

			{/* Daily limit */}
			<TableCell>
				<Skeleton className="h-5 w-24" delay={staggerDelay} duration={0.8} />
			</TableCell>

			{/* Wait between sends */}
			<TableCell>
				<Skeleton className="h-5 w-20" delay={staggerDelay} duration={0.8} />
			</TableCell>

			{/* Status */}
			<TableCell>
				<div className="flex flex-1 items-center gap-[8px]">
					<Skeleton className="h-1.5 w-1.5 rounded-full" delay={staggerDelay} duration={0.8} />
					<Skeleton className="h-5 w-24" delay={staggerDelay} duration={0.8} />
				</div>
			</TableCell>

			{/* Connected */}
			<TableCell className="relative" noBorder>
				<div className="flex justify-between">
					<Skeleton className="h-5 w-28" delay={staggerDelay} duration={0.8} />
					<Skeleton className="h-8 w-8 rounded-full" delay={staggerDelay} duration={0.8} />
				</div>
			</TableCell>
		</TableRow>
	);
};

/**
 * Table header component for the skeleton
 */
const EmailAccountsTableHeaderSkeleton: React.FC<{ showCheckbox?: boolean }> = ({ showCheckbox = true }) => (
	<TableHeaderRow>
		{showCheckbox && <TableHead className="w-[56px]" />}
		<TableHead label="Email" />
		<TableHead label="Name" />
		<TableHead label="Daily limit" />
		<TableHead label="Wait between sends" />
		<TableHead label="Status" />
		<TableHead label="Connected" noBorder />
	</TableHeaderRow>
);

interface EmailAccountsTableSkeletonProps {
	numRows?: number;
	showCheckbox?: boolean;
}

/**
 * Full skeleton component for the EmailAccountsTable that can be used during loading
 */
export const EmailAccountsTableSkeleton: React.FC<EmailAccountsTableSkeletonProps> = ({
	numRows = 5,
	showCheckbox = true,
}) => {
	return (
		<div className="bg-background-bg-base flex h-full w-full flex-col rounded-lg pb-2">
			<div className="flex-1">
				<MotionConfig transition={{ type: "spring", bounce: 0, duration: 0.25 }}>
					<div className="h-full w-full overflow-auto pb-4">
						<Table>
							<TableHeader>
								<EmailAccountsTableHeaderSkeleton showCheckbox={showCheckbox} />
							</TableHeader>
							<TableBody>
								{/* Show skeleton rows while loading */}
								{[...Array(numRows)].map((_, index) => (
									<EmailAccountRowSkeleton key={`skeleton-${index}`} index={index} showCheckbox={showCheckbox} />
								))}
							</TableBody>
						</Table>
					</div>
				</MotionConfig>
			</div>
		</div>
	);
};

export default EmailAccountsTableSkeleton;
