import { useEffect, useState, useCallback } from "react";
import { EventSource } from "eventsource";
import { API_URL } from "./useQuery";
import { useAuth } from "@clerk/clerk-react";

interface EventData {
	[key: string]: string;
}

const useSse = () => {
	const [connectionState, setConnectionState] = useState<"CONNECTING" | "OPEN" | "CLOSED">("CONNECTING");
	const [connectionError, setConnectionError] = useState<Event | null>(null);
	const [eventSource, setEventSource] = useState<EventSource | null>(null);
	const [eventData, setEventData] = useState<EventData>({});
	const [token, setToken] = useState<string | null>(null);
	const { getToken } = useAuth();

	useEffect(() => {
		const f = async () => {
			setToken(await getToken());
		};
		f();
	}, [getToken]);

	useEffect(() => {
		if (!token) return;
		const es = new EventSource(`${API_URL}/sse`, {
			fetch: (input, init) =>
				fetch(input, {
					...init,
					headers: {
						...((init ?? {}).headers ?? {}),
						Authorization: `Bearer ${token}`,
					},
				}),
		});
		setEventSource(es);

		es.onopen = () => setConnectionState("OPEN");
		es.onerror = (error: Event) => {
			setConnectionState("CLOSED");
			setConnectionError(error);
		};

		return () => es.close();
	}, [token]);

	const addListener = useCallback(
		(eventName: string, eventHandler: (data: string) => void) => {
			if (eventSource) {
				eventSource.addEventListener(eventName, (event: MessageEvent) => {
					setEventData(prevData => ({
						...prevData,
						[eventName]: event.data,
					}));
					eventHandler(event.data);
				});
			}
		},
		[eventSource],
	);

	const getEventData = useCallback(
		(eventName: string) => {
			const [data, setData] = useState<string | undefined>(eventData[eventName]);
			useEffect(() => {
				setData(eventData[eventName]);
			}, [eventData[eventName]]);
			return [data, setData];
		},
		[eventData],
	);

	const closeConnection = useCallback(() => eventSource?.close(), [eventSource]);

	return { connectionState, connectionError, addListener, getEventData, closeConnection };
};

export default useSse;
