import { generateGUID } from "@/lib/utils";
import { useCampaignStore } from "@/store";
import useCustomFieldsStore from "@/store/useCustomFieldsStore";
import { CampaignProps } from "@/types/campaign";
import { useOrganization } from "@clerk/clerk-react";
import { CampaignStep } from "@za-zu/types";
import { AnimatePresence, motion, Reorder, LayoutGroup } from "motion/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "sonner";
import ComposerToolbar from "./ComposerToolbar";
import { EmailEditor } from "./EmailEditor";
import SequenceStepComponent from "./SequenceStep";
import { parseCustomFieldsDictFromLeadCustomFieldValues } from "@/lib/customFields";
import { z } from "zod";
import { PlusCircleSVG } from "@/assets/icons/svgs";
import { formatSenderVariableDisplayName } from "../TipTap/slashCommand/utils/senderVariableCommands";

const Sequences = ({
	selectedCampaign,
	sequences,
	setSequences,
}: {
	selectedCampaign: CampaignProps;
	sequences: z.infer<typeof CampaignStep>[];
	setSequences: (sequences: z.infer<typeof CampaignStep>[]) => void;
}) => {
	const { organization } = useOrganization();
	const { defaultTestEmail, setDefaultTestEmail, leadsByCampaignId } = useCampaignStore();
	const [previewMode, setPreviewMode] = useState(false);
	const [activeSequenceStep, setActiveSequenceStep] = useState<z.infer<typeof CampaignStep>>(selectedCampaign.steps[0]);
	const { pendingCustomFieldTrackingIdMap, customFields, customFieldsById, syncCustomFieldsWithLatest } =
		useCustomFieldsStore();

	const [liquidPreview, setLiquidPreview] = useState<{ html: string; subject?: string } | undefined>(undefined);

	// Get the lead that matches the selected test email
	const selectedLead = useMemo(
		() =>
			Object.values(leadsByCampaignId[selectedCampaign.id] || {})
				.flat()
				.find(lead => lead.email === defaultTestEmail),
		[leadsByCampaignId, selectedCampaign.id, defaultTestEmail],
	);

	// Fetch campaign-specific custom fields when campaign changes
	useEffect(() => {
		if (!selectedCampaign?.id) return;
		syncCustomFieldsWithLatest(selectedCampaign.id);
	}, [selectedCampaign?.id]); // Only depend on the ID

	// Create a memoized object with all custom fields related props
	const customFieldsData = useMemo(() => {
		const filteredCustomFields = customFields.filter(
			field => field.campaign_id === null || field.campaign_id === selectedCampaign.id,
		);
		// Manage these seperately just in case they are processed differently.
		const filteredCustomFieldsById = Object.fromEntries(
			Object.entries(customFieldsById).filter(
				([_id, field]) => field.campaign_id === null || field.campaign_id === selectedCampaign.id,
			),
		);
		return {
			customFields: filteredCustomFields,
			customFieldsById: filteredCustomFieldsById,
			pendingCustomFieldTrackingIdMap,
		};
	}, [customFields, customFieldsById, pendingCustomFieldTrackingIdMap]);

	useEffect(() => {
		if (previewMode && selectedLead) {
			try {
				const customFieldsForLead = parseCustomFieldsDictFromLeadCustomFieldValues(
					selectedLead.lead_custom_field_values,
				);
				const div = document.createElement("div");
				div.innerHTML = activeSequenceStep.body || "";

				div.querySelectorAll(`span[data-node-type="variable"]`).forEach(span => {
					if (previewMode) {
						span.className = [
							"inline-flex h-[24px] items-center justify-center gap-[4px]",
							"rounded-[6px] px-[6px]",
							"text-[13px] font-normal leading-[18px]",
							"text-label-label-link",
							"bg-background-bg-sub",
						].join(" ");
					}

					let fieldId = span.getAttribute("data-field-id");
					const isCustom = span.getAttribute("data-is-custom") === "true";
					const trackingId = span.getAttribute("data-tracking-id");
					if (isCustom) {
						if (!fieldId && trackingId) {
							fieldId = pendingCustomFieldTrackingIdMap[trackingId];
						}
						if (!fieldId) {
							// This is a straggler from something going wrong in the past, so we can just remove it.
							span.replaceWith("");
							return;
						}
						if (!customFieldsForLead) {
							// This is a straggler from something going wrong in the past, so we can just remove it.
							span.replaceWith("");
							return;
						}
						// Replace the inner content of the span with the context value
						const customFieldKey = fieldId.startsWith("custom.") ? fieldId : `custom.${fieldId}`;
						span.textContent = `${customFieldsForLead[customFieldKey] || "undefined"}`;
					} else if (fieldId?.startsWith("sender.")) {
						span.textContent = formatSenderVariableDisplayName(fieldId);
					} else if (fieldId) {
						// Replace the inner content of the span with the context value
						span.textContent = `${selectedLead[fieldId as keyof typeof selectedLead]}`;
					} else {
						// This is a straggler from something going wrong in the past, so we can just remove it.
						span.replaceWith("");
					}
				});
				// Now do the same for the subject
				const subjectDiv = document.createElement("div");
				subjectDiv.innerHTML = activeSequenceStep.subject || "";
				subjectDiv.querySelectorAll(`span[data-node-type="variable"]`).forEach(span => {
					if (previewMode) {
						span.className = [
							"inline-flex h-[24px] items-center justify-center gap-[4px]",
							"rounded-[6px] px-[6px]",
							"text-[13px] font-normal leading-[18px]",
							"text-label-label-link",
							"bg-background-bg-sub",
						].join(" ");
					}

					let fieldId = span.getAttribute("data-field-id");
					const isCustom = span.getAttribute("data-is-custom") === "true";
					const trackingId = span.getAttribute("data-tracking-id");
					if (isCustom) {
						if (!fieldId && trackingId) {
							fieldId = pendingCustomFieldTrackingIdMap[trackingId];
						}
						if (!fieldId) {
							// This is a straggler from something going wrong in the past, so we can just remove it.
							span.replaceWith("");
							return;
						}
						if (!customFieldsForLead) {
							// This is a straggler from something going wrong in the past, so we can just remove it.
							span.replaceWith("");
							return;
						}
						// Replace the inner content of the span with the context value
						const customFieldKey = fieldId.startsWith("custom.") ? fieldId : `custom.${fieldId}`;
						span.textContent = `${customFieldsForLead[customFieldKey] || "undefined"}`;
					} else if (fieldId?.startsWith("sender.")) {
						span.textContent = formatSenderVariableDisplayName(fieldId);
					} else if (fieldId) {
						// Replace the inner content of the span with the context value
						span.textContent = `${selectedLead[fieldId as keyof typeof selectedLead]}`;
					} else {
						// This is a straggler from something going wrong in the past, so we can just remove it.
						span.replaceWith("");
					}
				});
				setLiquidPreview({
					html: div.innerHTML,
					subject: subjectDiv.innerHTML,
				});
			} catch (error) {
				console.error(error);
				toast.error("Error rendering template");
			}
		}
	}, [selectedLead, previewMode, activeSequenceStep]);

	// Set a default test email if none is selected and there are leads
	useEffect(() => {
		const campaignLeads = Object.values(leadsByCampaignId[selectedCampaign.id] || {}).flat();
		if (defaultTestEmail === "" && campaignLeads.length > 0) {
			const firstLeadWithEmail = campaignLeads.find(lead => lead?.email);
			if (firstLeadWithEmail?.email) {
				setDefaultTestEmail(firstLeadWithEmail.email);
			}
		}
	}, [selectedCampaign.id, defaultTestEmail, setDefaultTestEmail, leadsByCampaignId]);

	// Function to add a new step to the sequence
	const addStep = useCallback(() => {
		// Turn off preview mode when adding a new step
		if (previewMode) {
			setPreviewMode(false);
		}

		const newSequenceStep: z.infer<typeof CampaignStep> = {
			campaign_id: selectedCampaign.id,
			organization_id: (organization ?? {}).id || "",
			id: generateGUID("step", 12),
			step_index: sequences.length,
			subject: null,
			body: "<p></p>",
			delay_in_hours: 72,
			created_at: "",
			updated_at: "",
		};

		const updatedSequences = [...sequences, newSequenceStep];
		setSequences(updatedSequences);

		// Set the newly added step as the active step
		setActiveSequenceStep(newSequenceStep);

		// Scroll to the newly added step after it renders
		setTimeout(() => {
			const newStepElement = document.getElementById(`sequence-step-${newSequenceStep.id}`);
			if (newStepElement) {
				newStepElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
			}
		}, 100);
	}, [selectedCampaign, sequences, previewMode, setPreviewMode]);

	// Handle reordering of sequences
	const handleReorder = useCallback(
		(reorderedSequences: z.infer<typeof CampaignStep>[]) => {
			const updatedSequences: z.infer<typeof CampaignStep>[] = reorderedSequences.map(
				(seq, index) =>
					({
						...seq,
						step_index: index,
					}) satisfies z.infer<typeof CampaignStep>,
			);

			setSequences(updatedSequences);
		},
		[selectedCampaign],
	);

	const saveTimeoutRef = useRef<NodeJS.Timeout>();

	const handleContentUpdate = useCallback(
		(newContent: string, field: "subject" | "body") => {
			if (!activeSequenceStep) return;
			const updatedSequence = sequences.map(step => {
				const updatedStep = { ...step };
				if (step.id === activeSequenceStep.id) {
					if (field === "subject") {
						const div = document.createElement("div");
						div.innerHTML = newContent;
						updatedStep.subject = div.innerHTML;
					}
					updatedStep[field] = newContent;
				}
				return updatedStep;
			});
			setSequences(updatedSequence);
		},
		[selectedCampaign, sequences, activeSequenceStep, previewMode, setPreviewMode],
	);

	// Cleanup timeout on unmount
	useEffect(() => {
		return () => {
			if (saveTimeoutRef.current) {
				clearTimeout(saveTimeoutRef.current);
			}
		};
	}, []);

	// Update activeSequenceStep when selectedCampaign changes
	useEffect(() => {
		const currentStep = sequences.find(s => s.id === activeSequenceStep?.id);
		if (currentStep) {
			setActiveSequenceStep(currentStep);
		} else {
			setActiveSequenceStep(sequences[0] || null);
		}
	}, [selectedCampaign, sequences, activeSequenceStep?.id]);

	// Ensure there's always at least one step in the sequence
	useEffect(() => {
		if (sequences.length === 0) {
			addStep();
		}
	}, [sequences.length, addStep]);

	return (
		<div className="flex">
			{/* Increase sidebar width to 291px and add proper padding */}
			<div className="m-4 w-[291px]">
				<LayoutGroup id="sequence-steps">
					{/* Sidebar for sequence steps */}
					<Reorder.Group
						axis="y"
						values={sequences}
						onReorder={handleReorder}
						layoutScroll
						style={{
							willChange: "transform",
							position: "relative",
						}}
						transition={{
							type: "spring",
							bounce: 0.15,
							duration: 0.35,
						}}>
						<AnimatePresence initial={false} mode="popLayout">
							{sequences.map(seq => {
								const isActive = activeSequenceStep?.id === seq.id;
								return (
									<SequenceStepComponent
										key={seq.id}
										seq={seq}
										value={seq}
										isActive={isActive}
										isLast={seq.step_index === sequences.length - 1}
										onStepClick={id => {
											const newStep = sequences.find(s => s.id === id);
											if (newStep) {
												setActiveSequenceStep(newStep);
											}
										}}
										onStepDelete={id => {
											const newSequences = sequences.filter(s => s.id !== id);
											const updatedSequences = newSequences.map((s, index) => ({ ...s, step_index: index }));
											setSequences(updatedSequences);
											setActiveSequenceStep(updatedSequences[0] || null);
										}}
										onDelayChange={(id, delay) => {
											const newStepDelay = sequences.map(seq => {
												if (seq.id === id) {
													return { ...seq, delay_hours: delay };
												}
												return seq;
											});
											setSequences(newStepDelay);
										}}
									/>
								);
							})}
						</AnimatePresence>
					</Reorder.Group>

					{/* Button to add a new step */}
					<motion.div
						layout
						className="group ml-[8px] inline-flex cursor-pointer items-center gap-[10px]"
						onClick={addStep}
						whileHover={{ scale: 1.02 }}
						whileTap={{ scale: 0.98 }}
						transition={{
							type: "spring",
							bounce: 0,
							duration: 0.2,
						}}>
						<PlusCircleSVG
							width={16}
							height={16}
							className="shrink-0 text-[#AAAAAA] transition-transform duration-200 ease-out group-hover:rotate-90 group-hover:scale-110"
						/>
						<span className="ease text-[13px] font-normal leading-[18px] text-[#666666] transition-colors duration-150 group-hover:text-[#333333]">
							Add another step
						</span>
					</motion.div>
				</LayoutGroup>
			</div>

			{/* Main content area - updated styling */}
			<div className="flex h-[calc(100vh-80px)] flex-1 flex-col border-x-[0.5px] border-[#D9D9D9] bg-white">
				<ComposerToolbar
					previewMode={previewMode}
					onPreviewChange={setPreviewMode}
					defaultTestEmail={defaultTestEmail}
					onTestEmailChange={setDefaultTestEmail}
					leads={Object.values(leadsByCampaignId[selectedCampaign.id] || {}).flat()}
				/>
				{/* Email editor */}
				{activeSequenceStep && (
					<div className="flex-1 overflow-auto" data-body-editor>
						<EmailEditor
							subject={activeSequenceStep?.subject || ""}
							html={activeSequenceStep?.body || ""}
							onSubjectChange={newContent => handleContentUpdate(newContent, "subject")}
							onHtmlChange={newContent => handleContentUpdate(newContent, "body")}
							isFollowUp={activeSequenceStep?.step_index !== 0}
							previewMode={previewMode}
							previewContent={liquidPreview}
							onPreviewChange={setPreviewMode}
							campaignId={selectedCampaign.id}
							customFieldsData={customFieldsData}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
export default Sequences;
