import { Skeleton } from "../ui/skeleton";
import { Table, TableBody, TableHeader, TableHeaderRow, TableHead } from "../ui/table";
import { Button } from "../ui/button";
import SearchIcon from "@/assets/icons/svgs/search.svg?react";
import ArrowUpIcon from "@/assets/icons/svgs/arrow-up.svg?react";

const defaultHeaders = [
	{ key: "fullName", label: "First & last name" },
	{ key: "email", label: "Email" },
	{ key: "linkedin", label: "LinkedIn" },
	{ key: "job_title", label: "Job Title" },
	{ key: "company", label: "Company" },
	{ key: "phone", label: "Phone" },
];

export function LeadsTableSkeleton() {
	return (
		<div className="flex h-[calc(100vh-60px)] w-full flex-col">
			<div className="flex items-center gap-2 px-2 py-3">
				<div className="relative flex-1">
					<div className="flex h-8 w-full items-center gap-2">
						<SearchIcon className="text-label-label-muted shrink-0" />
						<Skeleton className="h-8 w-full" />
					</div>
				</div>
				<Button variant="secondary" disabled>
					<ArrowUpIcon className="text-label-label-muted" />
					Import more
				</Button>
			</div>

			<div className="relative flex flex-1 flex-col overflow-hidden">
				<div className="absolute inset-0 overflow-auto">
					<Table>
						<TableHeader>
							<TableHeaderRow>
								{defaultHeaders.map(header => (
									<TableHead key={header.key} label={header.label} className="min-w-[170px] whitespace-nowrap" />
								))}
							</TableHeaderRow>
						</TableHeader>
						<TableBody>
							{Array.from({ length: 10 }).map((_, rowIndex) => (
								<tr key={rowIndex} className="border-b border-gray-200">
									{defaultHeaders.map((_, colIndex) => (
										<td key={colIndex} className="px-4 py-2">
											<Skeleton
												className="h-5 w-[120px]"
												delay={rowIndex * 0.05} // Stagger the animation
											/>
										</td>
									))}
								</tr>
							))}
						</TableBody>
					</Table>
				</div>
			</div>

			<div className="border-t border-gray-200 bg-white px-4 py-2">
				<div className="flex items-center justify-between">
					<div className="text-sm text-gray-500">
						<Skeleton className="h-5 w-[200px]" />
					</div>
					<div className="flex items-center gap-2">
						<Skeleton className="h-8 w-[300px]" />
					</div>
				</div>
			</div>
		</div>
	);
}
