import * as React from "react";
const SvgSidebarExpand = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 16, height: 16, viewBox: "0 0 16 16", fill: "none", ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.43393 5.87357C8.68779 5.60695 9.10979 5.59666 9.37647 5.85057L11.1265 7.51727C11.2585 7.64307 11.3333 7.81753 11.3333 8.00001C11.3333 8.18248 11.2585 8.35694 11.1265 8.48274L9.37647 10.1494C9.10979 10.4033 8.68779 10.393 8.43393 10.1264C8.18006 9.85981 8.19035 9.43781 8.45703 9.18394L9.00001 8.66667H6.00001C5.63182 8.66667 5.33334 8.36819 5.33334 8.00001C5.33334 7.63182 5.63182 7.33334 6.00001 7.33334H9.00001L8.45703 6.81607C8.19035 6.5622 8.18006 6.1402 8.43393 5.87357Z", fill: "#1A1A1A", style: {
  fill: "#1A1A1A",
  fill: "color(display-p3 0.1020 0.1020 0.1020)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4 2.66667C2.52724 2.66667 1.33334 3.86057 1.33334 5.33334V10.6667C1.33334 12.1394 2.52724 13.3333 4 13.3333H12C13.4728 13.3333 14.6667 12.1394 14.6667 10.6667V5.33334C14.6667 3.86057 13.4728 2.66667 12 2.66667H4ZM13.3333 10.6667C13.3333 11.403 12.7364 12 12 12H6.66667C6.29848 12 6 11.7015 6 11.3333V10C4.89543 10 4 9.10457 4 8.00001C4 6.89544 4.89543 6.00001 6 6.00001V4.66667C6 4.29848 6.29848 4.00001 6.66667 4.00001H12C12.7364 4.00001 13.3333 4.59696 13.3333 5.33334V10.6667Z", fill: "#1A1A1A", style: {
  fill: "#1A1A1A",
  fill: "color(display-p3 0.1020 0.1020 0.1020)",
  fillOpacity: 1
} }));
export default SvgSidebarExpand;
