import { Button } from "@/components/ui/button";
import { SignInButton, SignUpButton, useUser } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import TextAnimation from "@/components/TextAnimation/TextAnimation";
import { AnimatePresence } from "motion/react";
import { Card, CardContent } from "@/components/ui/card";
import BlueLogo from "../../assets/blue-logo.svg?react";
import { Redirect } from "wouter";

const TESTIMONIALS = [
	{
		company: "Electric AI",
		content: (
			<>
				is booking <span className="font-semibold text-[#171717]">3x more meetings</span> every week
			</>
		),
	},
	{
		company: "Density",
		content: (
			<>
				saw <span className="font-semibold text-[#171717]">positive replies jump 52%</span>
			</>
		),
	},
	{
		company: "Aurora",
		content: (
			<>
				has won clients <span className="font-semibold text-[#171717]">over $400m in pipeline</span>
			</>
		),
	},
	{
		company: "AngelList",
		content: (
			<>
				<span className="font-semibold text-[#171717]">doubled their open rate</span>
			</>
		),
	},
	{
		company: "Ledge",
		content: (
			<>
				<span className="font-semibold text-[#171717]">booked 31 demos</span> in their first 60 days
			</>
		),
	},
	{
		company: "Pod People",
		content: (
			<>
				<span className="font-semibold text-[#171717]">quadrupled outbound with no new hires</span>
			</>
		),
	},
];

export default function Page() {
	const [currentIndex, setCurrentIndex] = useState(0);

	const user = useUser();
	if (user.isSignedIn) return <Redirect to="/" />;

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex(current => (current + 1) % TESTIMONIALS.length);
		}, 5000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className="bg-linear-to-br flex min-h-screen flex-col from-orange-100 via-purple-100 to-blue-500">
			<div className="flex flex-1 flex-col items-center justify-center">
				<div className="flex flex-col items-center space-y-8">
					<div className="rounded-lg bg-white/30 px-4 py-2 backdrop-blur-md">
						<BlueLogo className="text-brand-primary h-[24px] w-[136px]" />
					</div>
					<Card className="bg-background-bg-base border-background-bg-border shadow-low mx-auto w-full max-w-sm border-[0.5px] p-10 text-center">
						<CardContent className="p-0">
							<h1 className="mb-8 text-2xl font-bold text-gray-800">Your first campaign is just a sign-up away.</h1>
							<div className="flex flex-col items-center justify-center space-y-2">
								<SignUpButton mode="modal">
									<Button variant="default" className="w-full">
										Sign up
									</Button>
								</SignUpButton>
								<SignInButton mode="modal" forceRedirectUrl="/">
									<Button variant="outline" className="w-full">
										Sign in
									</Button>
								</SignInButton>
							</div>
						</CardContent>
					</Card>
					<AnimatePresence mode="wait">
						<TextAnimation
							key={currentIndex}
							company={TESTIMONIALS[currentIndex].company}
							content={TESTIMONIALS[currentIndex].content}
						/>
					</AnimatePresence>
				</div>
			</div>
		</div>
	);
}
