import { AvailableField } from "./types";

export const defaultFieldsAvailable: AvailableField[] = [
	{ id: "email", label: "Email", type: "default" },
	{ id: "first_name", label: "First Name", type: "default" },
	{ id: "last_name", label: "Last Name", type: "default" },
	{ id: "linkedin_url", label: "LinkedIn URL", type: "default" },
	{ id: "job_title", label: "Job Title", type: "default" },
	{ id: "company_name", label: "Company Name", type: "default" },
	{ id: "phone_number", label: "Phone Number", type: "default" },
];
